.e-menu{
    &-wrapper{
        .e-ul{
            .e-menu-item{
                transition: all 0.45s ease;
                &.e-focused{
                    background-color:rgba(50,25,25,0.25);
                }
            } 
        }
    }
}

$shadow-color: #051d36;
.b-guide{
    &__wrap{
        
        display: flex;
        flex-direction: column;
        transition: opacity 0.45s ease, transform 0.45s ease;
        transform: scale3d(1, 1, 1);
        background:rgba(237, 242, 247,1);
        pointer-events: all;

        &.fileupload{
            
        }
        @include mq('tablet', max) {
            flex-direction: column-reverse;
        }

        &.fullscreen{
            
            left:0;
            right:0;
            top:0;
            bottom:0;
            position: fixed;
            z-index: 1000;
        }
        &.hide{
            overflow: hidden;
            opacity: 0;
            transform: scale3d(1.1, 1.1, 1);
            pointer-events: none;
        }
    }
    &__steps{
        display:block;
        border-top:1px solid rgba($black1, 0.15);
        border-bottom:1px solid rgba($black1, 0.15);
        flex: 0 0 5rem;
        background:rgba(237, 242, 247,1);
        @include mq('desktop', max) {
            flex: 0 0 3rem;
        }
        .ui.steps{
            .step{
                justify-content: left;
                border-radius: 0;
                flex: 1 1 100%;
                width:100%;
                background:$grey-200;
                display: flex;
                min-width: 0;
                align-items: left;
                border-left:1px solid rgba($white1, 0.15);
                border-right:1px solid rgba($black1, 0.15);
                padding:0;
                //height:5rem;
                transition: color 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
                &.active{
                    box-shadow: inset 0 -6px 0 $primary;
                }
                &::after{
                    display:none;
                }
                .content{
                    flex: 1 1 100%;
                    padding: 1.5rem 1rem;
                    display: block;
                    overflow: hidden;
                    @include mq('desktop', max) {
                        padding: 0.75rem;
                    }
                    .title{
                        color:$base-text-color;
                        font-weight:$bold;
                    }
                }
                .title{
                    font-size: 1rem;
                    font-weight:$bold;
                    display: block;
                    @include text-ellipsis;
                    @include mq('desktop', max) {
                        font-size: 0.875rem;
                    }
                }
                .description{
                    display: block;
                    font: 0.875rem $normal $sans-serif;
                    @include text-ellipsis;
                    @include mq('desktop', max) {
                        font-size: 0.75rem;;
                    }
                    & + .title{
                        margin-top:0.375rem;
                    }
                }
                /**/
                
                i.icon{
                    flex: 0 0 2.5rem;
                    margin:0 0 0 1.25rem;
                    color:$secondary;
                    &.corner{
                        position: absolute;
                        margin:0 -0.25rem -0.25rem 0;
                        opacity: 0;
                        transition: opacity 0.45s ease,  transform 0.45s ease-in-out ;
                        transform: scale3d(1.35, 1.35, 1);
                        color:$tertiary-shade;
                        height: 1rem;
                        width: 1rem;
                    }
                    @include mq('desktop', max) {
                        &.large{
                            width:30px;
                            height:30px;
                            line-height: 30px;
                            flex: 0 0 2rem;
                            margin:0.825rem 0 0 0.75rem;
                        }
                    }
                }
                i.icons .corner.icon{
                    font-size: 1rem;
                }
                &.completed{
                    color:darken($primary-shade, 20%);
                    i.icon.corner{
                        opacity: 1;
                        transform: scale3d(1, 1, 1);
                        color:$primary-shade;
                    }
                }
                .icon-stack{
                    position: relative;
                }
                &__button{
                    background: $primary;
                    display:flex;
                    flex: 1 1 auto;
                    justify-content: left;
                    text-align: left;
                    flex-direction: row;
                    color: $primary-text;
                    text-decoration: none;
                    transition: background 0.35s ease;
                    cursor: pointer;
                    align-items: center;
                    height:100%;
                    .content{
                        .title,
                        .description{
                            color:$grey-white;
                        }
                    }
                    &.disabled{
                        pointer-events: none;
                        opacity: 0.4;
                    }
                    &:hover,
                    &:focus{
                        background: darken($primary,15%);
                    }
                }
            }
        }
    }
    &__content{
        overflow: auto;
        display:flex;
        margin: 0.5rem;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: center;
        .fileupload &{
            border: 4px dashed transparent;
            border-radius: 1rem;
            transition: border 0.35s ease;
            &:hover{
                border: 4px dashed darken($primary-shade,10%);
            }
        }
        .e-upload{ 
            border:0;
            .e-file-select-wrap{
                margin: 0 0 1rem 0;
                background:$grey-100;
                border-radius: 0.5rem;
                font-weight:$bold;
                border:1px dashed $grey-300;
            }
            &-files{
                border:0;
            }
            &-file-list{
                background: $white1;
                border-radius: 0.5rem;
                margin: 0 0 1rem 0;
                border:none;
                
                box-shadow:
                0 0.3px 0.3px rgba($shadow-color, 0.02),
                0 0.7px 0.8px rgba($shadow-color, 0.028),
                0 1.3px 1.5px rgba($shadow-color, 0.035),
                0 2.2px 2.7px rgba($shadow-color, 0.042),
                0 4.2px 5px rgba($shadow-color, 0.05),
                0 10px 12px rgba($shadow-color, 0.07);

                }
        }

    }
    &__slider{
        flex:1 1 auto;
        max-width:800px;
        overflow: auto;
        position: relative;
    }
     
    
    &__close{
        border-left:1px solid rgba($white1, 0.15);
        border-right:1px solid rgba($black1, 0.15);
        display: flex;
        align-items: center;
        body & .icon-button{
            flex: 1 1 auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 0;
            background:$grey-200;
            transition: background 0.35s ease;
            &:hover{
                background:$red;
            }
            .icon{
                margin-top:1.25rem;
                @include mq('desktop', max) {
                    margin-top:0.75rem;
                }
            }
        }

    }
}
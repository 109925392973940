.btn{
    &__wrapper{
        display: block;
        overflow: hidden;
    }
    &__group{
        display:inline;
        &.left{
            float:left;
        }
        &.right{
            float:right;
            button{
                float:right;
            }
        }
    }
}
 
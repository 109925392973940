.programme{
    &__item{
        display: flex;
        flex-direction: row;
        padding:0.75rem 0; 

    }
    &__time{
        flex: 0 0 80px;
        padding: 0 1rem 0 0;
        
    }
    &__des{
        flex: 1 1 auto; 
        
    }
}
﻿.page-nav {
    font-family: $sans-serif;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 8;
    width: auto;
    min-height: 72px;
    @include themeColours(background, body1,0.9);
    padding: 8px 4px 0 4px;
    padding-bottom: env(safe-area-inset-bottom);
    overflow: hidden;
    transition: opacity ease 0.4s;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);

    .light-theme & {
        border-top: 2px solid $white3;
    }
    /*move above if there are page controls on mobile*/
    &--above-controls {
        bottom: 72px;
        min-height: 62px;
        border-bottom: 1px dashed $black4;
    }

    .icon-button {
        float: right;
        margin: 0 0 0 4px;
        height: 48px;
        width: auto;
        line-height: 48px;
        //background: rgba(55,55,55, 0.85);
        @include themeColours(background, bg1,0.1);


        .icon {
            line-height: 50px;
            width: 48px;
            height: 48px;
        }

        &:hover {
            @include schemeColours(1);
            .icon {
                color: $white1 !important;
            }
        }
    }

    &__options {
        float: left;
    }

    &__detail {
        float: left;
        display: inline;
        @include themeColours(color, text1);
        @include themeColours(background-color, bg1, 0.1);
        line-height: 32px;
        border-radius: 32px;
        padding: 0 16px;
        margin: 9px 8px 0 8px;
    }

    &__left .icon-buton {
        float: right;
    }

    &__pages {
        float: right;
        padding: 0 16px;
    }

    &__link {
        float: left;
        display: inline;
        line-height: 48px;
        border-radius: 48px;
        min-width: 48px;
        padding: 0 8px;
        text-align: center;
        margin: 0 2px 0 2px;
        text-decoration: none;
        transition: all 0.2s ease;
        box-sizing: border-box;

        @include themeColours(color, text1);
        @include themeColours(background-color, bg1, 0.1);

        &--spacer {
            min-width: unset;
            background: transparent !important;
            pointer-events: none;
        }

        &--on,
        &:hover {
            color: $white1 !important;
            @include schemeColours(4);
        }
    }

    @include mq('phablet') {
        & __right {
            display: inline;
            float: right;
        }

        &__left {
            float: left;
        }

        .sm--pushed & {
            opacity: 0;
        }

        &__detail {
            display: none;
        }
    }

    @media only screen and (max-width: 350px) {
        &__right .icon-button + .icon-button {
            display: none;
        }
    }
}

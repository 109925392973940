.filter{
    display:flex;
    width: 100%;
    margin-top:0.75rem;

    &__col{
        flex:1 1 auto;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        & + & {
            align-items: flex-end;
            white-space: nowrap;
            flex-direction: row-reverse;
        } 

    } 
    .ui.label{
        margin-top:0.5rem;
        font-size: 0.875rem;
        line-height: 1rem;
        font-family: $sans-serif;
        padding:0.5rem 0.75rem;
        animation: searchZoomIn 0.35s ease 0.35s 1 alternate backwards;

        &.bg--none{ 
            background:none;
        }
    }
    .map.marker.icon{
        font-size:1rem;
    }
    
    .ui.toggle.checkbox {
        margin:-0.5rem 0;

        input,
        .box:before, 
        label:before{
            width:3rem;
        }
        input:checked~.box,
        input:checked~label{
            &:before{
                background-color: $primary!important;
            }
            &:after {
                left: 1.5rem;
            }
        }
        
    }
    .ui.icon.button.dropdown{
        font-size: 0.875rem;
        margin-top:0.5rem; 
        padding-top:0.5625rem;
        padding-bottom:0.5625rem;
        overflow: unset;
        animation: searchZoomIn 0.35s ease 0.35s 1 alternate backwards;
        .menu>.item{
            font-size:0.875rem;
        }
    }  
    
    .b-left-panel &{
        background:$white1;
        border-radius: 0.5rem;
        margin:0 1rem;
        padding-bottom:0.5rem;
        width:auto;
        flex-direction: column-reverse;
        width: 100%;
        &__col{
            width:100%;
            & + .filter__col{
                border-bottom:1px dashed $grey-400;
                padding-bottom: 0.5rem;
            } 
        }
    }
}
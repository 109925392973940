//  LN only for now. Move into core/reset.scss when ready
* {
    box-sizing: border-box;
}
@import '_fonts.scss'; 
@import '../../../sass/abstracts/__modules.scss'; 
@import '../../../sass/core/__modules.scss';   
@import '../../../sass/layout/__modules.scss';  
@import '../../../sass/components/__modules.scss'; 
@import '../../../sass/pages/__modules.scss';   
@import '../../../sass/themes/__modules.scss'; 
@import '../../../sass/vendors/_vendor-colours.scss';
@import '../../../sass/syncfusion/__modules.scss'; 
@import '../../../sass/semantic/__modules.scss';    
/**/
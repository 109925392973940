﻿.nav {
    display: block;
    text-align: center;
    height: 40px;
    font: $bold 14px/40px $sans-serif;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    width: auto;
    position: absolute;
    //background: rgba($grey-200, 0.85);
    background: rgba(lighten($brand-neutral,18%), 0.85);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px); 
    //border-bottom: 1px solid $grey-400;  
    border-bottom: 1px solid lighten($brand-neutral,8%);  
    .icos-button,
    .ui.button.icon {
        border-radius: 0;
        background: transparent;
        transition: all ease 0.35s;
        border-radius: 0.25rem;
        padding:0;
        width: 2.25rem;
        height: 2.25rem;
        margin:0.125rem;
        color:$white1;
        display:inline;
        float:left;
        color: $iconDark;
        &.branding{
            border-radius: 0;
            margin:0;
            width: 2.5rem;
            height: 2.5rem;
        }
        @include mq('tablet-wide', max) {
            width: 2.75rem;
            height: 2.75rem;
            &.branding{
                border-radius: 0;
                margin:0;
                width: 3rem;
                height: 3rem;
            }
        }
        @include mq('phablet', max) {
            width: 3.125rem;
            height: 3.125rem;
        }
        &:hover,
        &:focus,
        &:active {
            //background-color: rgba($primary, 0.25);
            background-color:rgba(50,25,25,0.25);
            color: $iconDark;
            .nav__stack--open &{
                color: $black2;
                background-color: darken($nav-bg, 5%);
            }
        }
        .icos,
        .icon {
            border-radius: 0;
            line-height: 2.25rem;
            width: 2.25rem;
            height: 2.25rem;
            color: $iconDark;
            @include mq('tablet-wide', max) {
                line-height: 2.75rem;
                width: 2.75rem;
                height: 2.75rem;
            }
            .nav__stack--open &{
                color: $black2;
            }
        }
    }
    & &__stack--open{
        .ui.button.icon{
            color: $white1;
            .icon,
            .icon:hover,
            .icon:focus,
            .icon:active{
                color: $black2;
            } 
            &:hover,
            &:focus,
            &:active{
                background-color: darken($nav-bg, 10%);
            }             
        }
    }
    &__icons {
        float: none;
        position: fixed;
        display: inline-flex;
        //display:none;
        flex-wrap: nowrap;
        //left: 50%;
        right: unset;
        //transform: translateX(-50%);
        //border-radius: 4px;
        margin: 0 auto;
        transition: all ease 0.4s;
        padding: 0 0;
        /*padding-bottom: env(safe-area-inset-bottom);*/
        box-shadow: 0 0 10px rgba(230, 206, 206, 0.8);  
        box-shadow:
            0 1.1px 2.2px rgba(0, 0, 0, 0.034),
            0 2.7px 5.3px rgba(0, 0, 0, 0.048),
            0 5px 10px rgba(0, 0, 0, 0.06),
            0 8.9px 17.9px rgba(0, 0, 0, 0.072),
            0 16.7px 33.4px rgba(0, 0, 0, 0.086),
            0 40px 80px rgba(0, 0, 0, 0.12);
        z-index: 30;
        width: auto;
        color: $white1;
        //@include schemeColours(2);
        background-color: $primary-shade;
        &.white{
            background: $white1;
        }

        &--main {
            overflow: hidden;
            bottom: 0;
            width:100%;
            left:0;
            right:0;
            display: flex;
            //padding-bottom: constant(safe-area-inset-bottom);
            //padding-bottom: env(safe-area-inset-bottom);
            padding-bottom:0.75rem;
    
            @include mq('phablet', min) {
                border-radius: 0px;
                width: auto;
                display: inline-flex;
                //min-width:96px;
            }
            .icon__divider{
                @include mq('phablet', max) {
                    display: inline;
                    float:left;
                    width:0.5rem;
                    flex: 0 0 0.25rem;
                    border-left:1px solid lighten($primary, 5%);
                    margin:0.5rem 0 0.5rem 0.25rem;
                    width: 0.25rem;

                }
            }
            .icos-button,
            .ui.button.icon{
                padding:0;
                float:left;
                display:inline;
                @include mq('phablet', max) {
                    flex: 1 1 auto;
                    width:auto;
                    height:2.75rem;
                    max-width: none;
                }
                .icos,
                .icon {
                    color: $iconDark;
                    font-size:1rem;
                    @include mq('phablet', max) {
                        color: white;
                    }
                    &.searchengin{
                        font-size:1.375rem;
                    }
                    //@include themeColours(color,icon1);
                }
                &:hover { 
                    
                    background-color:rgba(50,25,25,0.25);
                    @include mq('phablet', max) {
                        background-color: darken($primary-shade, 10%)!important;
                    }
                    .icon {

                        color: $primary;
                        @include mq('phablet', max) {
                            color: $grey-white;
                        }
                    }
                }
                @include mq('phablet', min) {
                    &.hide--desktop{
                        display:none;
                    }
                }

            }
            @include mq('tablet-wide', min) {
                .branding{
                    display:none;
                }
            }
        }

        &--page {
            top: 8px;
            pointer-events:auto;
            display: none;

            .light-theme & .icos {
                color: $white1;
            }
            body &.white .icos{
                color: $iconDark;

            }
        }

        .icos-priority,
        .icos-priority:before {
            line-height: 40px !important;
        }

        body & .icos-button {
            &:link{
                //background-color: transparent;
                //max-width: 50px;
            } 

            &:hover,
            &--selected {
                background-color:rgba(50,25,25,0.25);
                //@include bgAlpha($black1,0.6);
            }

            &:focus {
                background-color: transparent;
            }
        }
        body &.white .icos-button {
            background-color: transparent;
            max-width: 50px;

            &:hover,
            &--selected {
                background:$primary;
            }

            &:focus {
                background-color: transparent;
            }
        }

        body .sm--pushed &--main {
            //left: 4px;
            transform: none;
            box-shadow: none;
            background: none;
            margin: 0;

            .icos-button {
                //@include bgAlpha($black1,0.6);
            }

            .icos-button + .icos-button {
                /*animate hide*/
                overflow: hidden;
                max-width: 0px;
                opacity: 0;
                transition: all ease 0.4s;
            }
        }

        @include mq('phablet', min) {
            position: absolute;

            &--main {
                float: left;
                display: inline-flex;
                position: static;
                transform: none;
                background: none !important;
                box-shadow: none;

                .icos-button:hover {
                    
                    //@include schemeColours(2);
                }
            }
        }
    }
    /////////////////////////////////////
    
    &__controls {
        float: right;
        display: inline;
        .nav__mobile-left {
            float: left;
            display: inline;
            //max-width: 160px;
        }

        .nav__mobile-right {
            float: right;
            display: inline;
        }
        .e-menu-wrapper{
            background:none;
            height:2.5rem; 
            float:left;
            display:inline;
            & ul{
                background:none;

                .e-menu-item{
                    height:2.25rem; 
                    transition: all 0.35s ease;
                    color: $primary;
                    font-weight: $semibold;
                    border-radius:0.25rem;
                    margin:0.125rem;
                    padding-left:1rem;
                    padding-right:1rem;
                    line-height: 2.25rem;

                    &.e-selected{
                        background-color:rgba(50,25,25,0.25);
                    }
                }
            }
        }
        //synfusion
        .dock-menu {
            .e-menu-item{
                overflow: hidden;
                @include text-ellipsis();
                .e-caret{
                    display: none;
                }
                &.e-menu-caret-icon{
                    padding-right:1rem;
                }
            }
        }

        @include mq('phablet') {
            display: block;
            width: 100vw;
            box-sizing: border-box;
            //background: rgba(0,0,0,0.8);
            height: 72px;
            padding: 0 0.125rem 2px 0.125rem;
            padding-top: env(safe-area-inset-top);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border-bottom: 1px solid #111;

            border-color: rgba($bgDark1, 0.05);
            background-color: rgba($bgBlack, 0.85);


            background: rgba($grey-200, 0.85);
            border-bottom: 1px solid rgba($grey-400, 0.85); 

            //@include themeColours(border-color, bg1,0.05);
            //@include themeColours(background-color, body1,0.85);
            
            &--mobile-top {
                height: 56px;
                top: 0;
            }

            &--mobile-bottom {
                bottom: 0;
                position: fixed;
            }
        }
    }
    

    @include mq('tablet-wide') {
        //@include bgAlpha($black1, 0.65);
        height: 48px;

        .icos-button
        .ui.button.icon {
            padding: 0;
            margin:0;
            transition: margin ease 0.2s;
            width: 44px;
            height: 44px;
            margin:0.125rem;
            //border-radius: 40px;

            .icos,
            i.icon {
                //border-radius: 48px;
                height: 44px; 
                width: 44px; 
                line-height: 44px;

                &::before {
                    line-height: 44px;
                }

                .icos-search::before {
                    line-height: 38px;
                }
            }
        }
        .dock-menu{
            display:none;
        }
    }


    &__stack {
        float: left;
        display: inline;
        position: relative;
        

        @include mq(tablet) {
            //overflow: hidden;
            width: 160px;
            height: 48px;
            borde6:1px solid #ff0;
        }
        

        &-divider {
            float: left;
            display: inline;
            margin: 6px 2px;
            height: 28px;
            border-left: 1px solid #ccc;

            @include mq(tablet-wide) {
                margin-top: 10px;
            }
        }

        &-icons {
            float: left;
            display: inline;
            transition: margin 0.6s ease;
            .icos-inbox{
                font-size:1rem;
            }

            .nav__stack--open & {
                position: fixed;
                z-index: 201;
                margin-top:0.75rem;
            }

            @include mq(tablet) {

                .nav__stack--open & {
                    left:1rem;
                    top:0.125rem;
                    width: 180px;
                    display: none;
                }
            }
            @include mq(phablet) {
                display: none;
            }
        }
        &--open{
            .nav & .icos-button .icos{
                color:$white1;
            }
        }
        
    }

    @include mq('phablet') {
        border-color: transparent;
        //height: 0;
        pointer-events: none;
        overflow: visible;
        top: 0;
        bottom: 0;
        height: auto;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        background: none !important;

        &__icons--main,
        &__stack-icons{
            pointer-events: auto;
            .light-theme & .icos {
                color: $white1;
            }
        }
        pointer-events: none;

        .mobile--hide {
            display: none;
        }

        &__controls {
            pointer-events: auto;
            
            
            .icos-button {
                padding: 0;
                pointer-events: auto;
                margin: 0.25rem 0.125rem;
                transition: margin ease 0.2s;
                height: 3rem;
                width: 3rem;

                .icos {
                    border-radius: 40px;
                    height: 3rem;
                    line-height: 3rem;
                    font-size: 22px;
                }
            }
            //.icos-angle-left
        }

        .nav__controls--mobile-top {
            position: absolute;
            z-index: 10;
        }

        .nav__controls--mobile-bottom {
            transition: opacity ease 0.4s;

            .sm--pushed & {
                opacity: 0;
                overflow: hidden;
                max-width: 0;
                height: 0;
            }
        }
    }



    &__icons--page {
        height: 48px;
        //overflow:hidden;
        .icos-button {
            margin: 4px 2px;
            border-radius: 40px;

            .icos {
                border-radius: 40px;
                height: 40px;

                &::before {
                    line-height: 42px;
                }
            }
        }
    }

    .icos-menu {
        float: left;
        display: inline;
        margin-left: 8px;

        @include mq('phablet') {
            margin-left: 0;
        }

        .icos-button {
            border: 0;
        }

        .icos-menu__list {
            margin-top: 40px;
            display: flex;
            flex-direction: column;

            @include mq('tablet-wide') {
                margin-top: 46px;
            }

            @include mq('phablet') {
                flex-direction: column-reverse;
                bottom: 52px;
                right: 0px;
                z-index: 10000;
            }
        }
    }
    @include mq('phablet', min) {
        .desktop-hide {
            display: none !important;
        }
    }
    @include mq('phablet') {
        .mobile-hide {
            display: none !important;
        }
    }

    @include mq('phablet', min) {
        .tablet-hide {
            //display: none !important;
        }
    }
    
}
.e-menu-wrapper.dock-menu{
    border-radius:0 0 0.375rem 0.375rem;
    ul{
        background:none;
        padding:0.125rem 0 0 0;
        .e-menu-item{
            height:2.25rem; 
            transition: all 0.35s ease;
            color: $primary;
            font-weight: $semibold;
            border-radius:0.25rem;
            margin:0.125rem;
            line-height: 2.25rem;

            &.e-focused.e-selected{
                background-color:rgba(50,25,25,0.25);
                
            }
            &.e-selected{
                background-color:rgba(50,25,25,0.15);
            }
        }
    }
}














.b-searchbar{
    .ui.segment{
        border:0;
        background:$primary-shade; 
        animation: searchBarAni 0.35s ease 0.35s 1 alternate backwards;
        @include mq('tablet-wide', max) {
            padding:0.75rem 0;
        }
    }
    .ui.form .fields{ 
        @include mq('tablet-wide', max) {
            flex-direction: column;
            margin:0.25rem 0.5rem;
        }
        &:last-of-type{
            margin-bottom:0;
        }
        
        select{
            background:rgba($white1,0.1);
            border-radius: 0;
            color:$white1;
            font-size:1.125rem;
            height:50px;
            transition: height 0.35s ease;
            &:required:invalid {
                color:#a383a3;
            }
            &[disabled], &:disabled{
                opacity: 0.4;
                cursor: not-allowed;
            }
            // we use invalid like a placeholder
            // 2019-03-05 added not selector for firefox and ie
            &:not(:focus):invalid {
                color:#a383a3;
            }
            &:focus-visible{ 
                border-color:$primary;
                box-shadow: none;
            }
            & option{
                color:$black2;
                &[value=""][disabled]{
                    display: none;
                } 
            }
            @include mq('tablet-wide', max) {
                border-radius:0.25rem;
                font-size:1rem;
                height:42px;
            }
        }
        
        & > .field{
            padding:0;
            &:first-child{
                padding-left:0.5rem;
                input{
                    border-radius: 0.25rem 0 0 0.25rem;
                }
            }
            &:last-child{
                input,
                select{
                    border-radius:0 0.25rem 0.25rem 0 ;
                }
            }
            @include mq('tablet-wide', max) {
                padding:0rem 0.5rem 0.5rem 0.5rem;
                margin:0!important;
            }
        }
        
        .b-left-panel &{
            flex-direction: column;
            .ui.segment{
                padding:0.75rem 0;
            }
            & > .field{
                padding:0rem 0.5rem 0.5rem 0.5rem;
                margin:0!important;
            }
            input{
                border-radius:0.25rem;
                font-size:1rem;
            }
            select{
                border-radius:0.25rem;
                font-size:1rem;
                height:42px;
            }
        }
    }
    
    /*semantic*/
    .ui.form input[type=search], 
    .ui.form input[type=text]{
        background:rgba($white1,0.1);
        border-radius: 0;
        color:$white1;
        font-size:1.25rem;
        border-right:1px solid rgba($white1,0.25);
        transition: border 0.35s ease;
        padding-top:0.75rem;
        padding-bottom:0.75rem;
        &::placeholder{
            color:rgba($white1,0.4);
        }
        &:focus{
            border-color: $tertiary  !important;
        }
        .b-left-panel &{
            border:0;
            font-size:1rem;
        }
        
        @include mq('tablet-wide', max) {
            border-radius:0.25rem;
            font-size:1rem;
            border-right:0;
        }
    }
    .ui.button{
        margin-bottom:0.25rem;
        height: 50px;
        &:first-of-type{
            margin-left:0.25rem;
        }
        @include mq('tablet-wide', max) {
            margin:0.25rem 0.5rem!important;
            height: 44px;
           .button__text.hidden{
               display: inline-block;
               max-width: 500px;
               padding-right:1.5rem;
           }
           i.icon{
               float:left;
               display: inline;
           }
        }
        .b-left-panel &{
            margin:0.25rem 0.5rem;
            height: 44px;
           .button__text.hidden{
               display: inline-block;
               max-width: 500px;
               padding-right:1.5rem;
           }
           i.icon{
               float:left;
               display: inline;
           }
        }
    }
    i.icon{
        font-size:1rem;
    }
    /* Sync fusion */
    .e-input-group{
        border:0!important;
        border-right:1px solid rgba($white1,0.25)!important;
        &.e-input-focus{
            border:0;

            &:before,
            &:after{
                display:none; 
            }
        }
        & input.e-input,
        &.e-input-focus input.e-input {
            padding: 0.875rem 1.25rem!important;
        }
        .ui.form &.e-input-focus  input[type=search]:focus, 
        .ui.form &.e-input-focus  input[type=text]:focus{
            border:0!important;
        }
        .e-ddl-hidden{
            display: none;
        }
        input.e-input{
            border-right:0px!important;
            border-radius: 0.25rem 0 0 0.25rem!important;
            &:focus{
                border:none!important;
            }
        }
         input.e-input, 
        textarea.e-input, 
        input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus), 
        textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]), 
        &.e-control-wrapper input.e-input, 
        &.e-control-wrapper textarea.e-input, 
        &.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus){
            border:0px;
        }

        .e-input-group-icon{
            background:rgba($white1,0.1)!important;
            color:rgba($white1,0.6);
            margin-top:0px;
            margin-bottom:0px;
            padding-right:0.75rem;
            &:hover{
                color:rgba($white1,0.8)!important;
            }
        }
        .e-clear-icon{
            padding-right:0.5rem;
            color:rgba($white1,0.6)!important;
            background:rgba($white1,0.1)!important; 
        }
    }
}
@keyframes searchBarAni{
    from{
        opacity: 0;
        transform: translate(0, -12px);
        pointer-events: none;
        
    }
    to {
        opacity: 1;
        transform: translate(0, 0);
        pointer-events: auto;
    }
    
}

.dimmer{
    &.dimmed.dimmable>.ui.animating, 
    &.dimmed.dimmable>.ui.visible, 
    &.ui.active{
        background-color: rgba(darken($primary-shade,10%),.45);
    }

    .ui.loader::before{
        display:none;
    }
    .ui.loader::after{
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite; 
        opacity: 0.0; 

        /* you dont need the stuff below, but its what I used to create the loading circle */
        border: 6px solid $secondary!important;
        border-radius: 30px;
        display: inline-block;
        text-align: center;
    }
        
    /*&::after{
        border-top-color: darken($primary, 15%);
    }*/
} 
@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
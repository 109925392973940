.b-welcome{
    &{
        display: flex;
        flex: 1 1 auto;
        width: auto;
        //border:1px solid blue;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        pointer-events: all;
    } 
    &__wrap{ 
        display: flex;
        flex: 1 1 auto;
        margin:11.25rem 4rem 4rem 4rem;
        height: 100%;
        align-items: center;
        justify-content: center;
        //border:1px solid green;
    }   
    &__col{
        flex:0 0 50%;
        //border:1px solid #f0f;
        max-width: 580px;
        
    }
    &__intro{
        display: block;
        min-height: 140px;
        h2{
            line-height:2rem;
        }
        &-btns{
            display: flex;
            .ui.button{
                flex:1;
                padding:1.25rem 0.75rem 1rem 0.75rem;
                max-width: 33.3333%;
                margin-right:0.5rem;
                &.secondary.inverted{
                    color:$primary-shade !important; 
                    .icon{
                        color:$primary !important;

                    }
                }
                
            }
            .ui.floating.label{   
                margin: 0 0 0 -1.25em!important;
            }
            .icon.large{
                min-height:1.5rem;
                width:1.5rem;
                margin-left:auto!important;
                margin-right:auto!important;
            }
            .h5{
                padding-top:0.25rem;
            }
        }
    }
    &__cards{
        position: relative;
        height: 400px;
    }
    &__card{
        position: absolute;
        background: #fff;
        display: block;
        height: 400px;
        width: 100%;
        margin: 0;
        border-radius: 1rem;
        z-index: 0;
        transform: translateY(2rem);
        opacity: 0;
        transition: transform 0.35s ease, opacity 0.5s ease;
        pointer-events: none;
        &.stacked{
            transform:translate(0, 0) scale(1);
            opacity: 1;
            pointer-events: all;
        }
        @for $i from 1 through 7 {
            $margin: 0px - ($i * 18px);
            $scale: 1 - ($i *0.05); 
            $index: 0 - $i; 
            $order: $i + 1;
            $rotate: $i *2deg;

            &.stacked:nth-of-type(#{$order}){
                transform:translate(0, $margin) scale( $scale);
                //transform:rotate($rotate);
                z-index: $index;
            }
        }
        &.stacked:nth-of-type(n+7){
            transform:translate(0, -126px) scale( 0.65);
            //transform:rotate($rotate);
            z-index: -8;
            opacity: 0;
            pointer-events: none;
        }
        .project{
            margin:0;
            box-shadow: none;
            height:100%;
            overflow: hidden;
        }
        &-nav{
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 1rem 0 0 0;
            gap: 0.5rem;
            
        }
        
    }
    
}
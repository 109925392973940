.ui.b-border{
    &--none{
        border:0;
    }
    &--radius-12{
        border-radius: 0.75rem;
    }
    &--radius-16{
        border-radius: 1rem;
    }
}
﻿// Opus 2 Network
.lex-network .branding-logo {
    //@include lex-network-svg(#16a5c0, #51bcde); 
    background-image:url('../images/O-logo.svg');

}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    
    .lex-network .icon-button:hover .branding-logo {
        @include lex-network-svg(lighten($primary-text, 10%),$primary-text);
    }
}
.lex-network{
    .branding{
        overflow:hidden;
    }
    .branding-logo {
        display: block;
        position: relative;
        height: 48px;
        width: 48px;
        opacity: 1;
        transition: opacity ease 0.3s, height ease 0.3s;
        background-repeat:  no-repeat;
        background-position:  center center;
        background-size:50%;
        transition: background 0.35s ease;
        margin:0 auto;
        @include mq('tablet-wide', min) {
            display:none;
        }
    }
    .sm__menu .branding-logo{
        height: 40px;
        width: 44px;
        @include mq('tablet-wide', min) {
            display:block;
        }
    }


    .hamburger-inner {
        opacity: 0;
    }

    .sm--pushed {
        .branding-logo {
            height: 0;
            opacity: 0;
        }

        .hamburger-inner {
            opacity: 1;
        }
    } 

}
.lex-network{
    .client-logo{
        background-image:url(../images/O-logo.svg);
    }
    .app-logo{
        background-image:url(../images/lex-network-white.svg);
        &.purple{
            background-image:url(../images/opus2-logo-purple.svg);
        }
    }
    .header .app-logo{
        display: block;
        min-height:6rem;
        min-width: 26rem;
        background-size:contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-top:4rem;
        margin-bottom:2rem;
        @include mq('tablet', max) {
            margin-top:2rem;
            width: 100%;
            min-width:160px;
            &.purple{
                display: none;
            }
        }
    }
    .branding{
        background-color: $primary-shade;
        &:focus,
        &:active,
        &:hover{
            background-color: darken($primary-shade, 10%)!important;
        }
    }
}
.owner-logo{
    background-image: url(../images/opus2-logo-white.svg) ;
} 



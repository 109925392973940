﻿.sidepanel {
    position: absolute;
    top: 0;
    bottom: 0;
    transform: scale3d(1, 1, 1);
    display: flex;
    height: auto;
    z-index: 49;
    transition: all ease 0.35s;
    opacity: 1;
    pointer-events: auto;

    &.sidepanel--right {
        right: 0;
        box-shadow: 0 2.8px 2.2px rgba(112,144,176, 0.02), 0 6.7px 5.3px rgba(112,144,176, 0.028), 0 12.5px 10px rgba(112,144,176, 0.035), 0 22.3px 17.9px rgba(112,144,176, 0.042), 0 41.8px 33.4px rgba(112,144,176, 0.05), 0 100px 80px rgba(112,144,176, 0.07);

        &.hide {
            opacity: 0;
            transform: scale3d(1, 1, 1) translateX(50%);
            pointer-events: none;
        }
    }

    &.large {
        width: 30rem
    }

    &.medium {
        width: 22rem
    }

    &.small {
        width: 14rem
    }

    .e-control {
        flex: 1 1 auto;
    }
}

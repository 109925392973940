.e-input{
    &-group {
        width: auto;
        flex: 1 1 auto;
        margin-right:0.25rem;
        &.inline-flex{
            width:100%;
            margin:0;
            //padding-top: 16px;
            border:0;
            &::before,
            &::after{
                display:none;
            }
        }
    }
    &-group &-group-icon{
        margin-top:14px;
        margin-bottom:0;

        &.large{
            font-size:1.625rem;
            margin:0;
        }
        &.medium{
            font-size:1.25rem;
        }
        &.small{
            font-size:1rem;
        }
        &.xsmall{
            font-size:8.125rem;
        }
        
    
    }
    body .e-control-wrapper &-group-icon{
        //overwrite icon inside wrapper
        margin-top:0;
    }
     
    
}
.e-float-input{

    & input,
    &.e-control-wrapper input{
        font-weight: $semibold;
    }
    .e-input-group &,
    .e-input-group &.e-control-wrapper{
        //margin-top:0;
    }
    input{
        margin-top:0;
        //hide spinner - use syncfusions control instead
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}


label.e-float-text, 
.e-float-input label.e-float-text, 
.e-float-input.e-control-wrapper label.e-float-text, 
.e-float-input:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, 
.e-float-input.e-control-wrapper:not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom{
    //font-size:14px;
    //top:-9px;
}
.e-input-group input.e-input,
.e-input-group textarea.e-input,
.e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper textarea.e-input,
.e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
  border-bottom-width: 1px;
}
﻿@keyframes fadeLeft {
    0% {
        opacity: 0;
        transform: translateX(3rem)
    }

    100% {
        opacity: 1;
        transform: translateX(0)
    }
}
@keyframes fadeLeft16 {
    0% {
        opacity: 0;
        margin-left: 76px;
    }

    100% {
        opacity: 1;
        margin-left: 16px;
    }
}
@keyframes fadeUpTran{
    0% {
        opacity: 0;
        transform: translateY(40px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}
@keyframes fadeUp {
    0% {
        opacity: 0;
        margin-top: 60px;
    }

    100% {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes flashBackground {
    0% {
        background-position: 0% 5%
    }

    50% {
        background-position: 100% 96%
    }

    100% {
        background-position: 0% 5%
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes element-queries {
    0% {
        visibility: inherit;
    }
}


@keyframes fadeInOut-Down {
    0% {
        opacity: 0;
        margin-top: 60px;
    }
    35%, 65% {
        opacity: 1;
        margin-top: 80px;
    }
    100% {
        opacity: 0;
        margin-top: 100px;
    }
}
@keyframes zoomUp {
    0%{
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

}
@keyframes zoomIn {

    100% {
        opacity: 1;
        transform: scale(1.0);
        pointer-events: auto;
    }
    0%{
        opacity: 0;
        transform: scale(1.3);
    }
}
@keyframes zoomOut {
    0% {
        height: auto;
        opacity: 1;
        transform: scale(1.0);
        pointer-events: auto;
    }

    0.01% {
        left: auto;
    }
    100% {
        opacity: 0;
        transform: scale(1.3);
    }
    99.99% {
        height: 0;
        position:absolute;
        left:-99999px;
    }
}
@keyframes zoomInOut {
    30%, 70% {
        opacity: 1;
        transform: scale(1.0);
        pointer-events: auto;
    }
    0%, 100% {
        opacity: 0;
        transform: scale(1.3);
    }
    0.01% {
        height: auto;
    }
}
@keyframes zoomInOut10 {
    8%, 92% {
        opacity: 1;
        transform: scale(1.0);
        pointer-events: auto;
    }

    0%, 100% {
        opacity: 0.5;
        transform: scale(1.3);
    }
    0.01%,
    99.99%{
        top:100px;
    }
    100% {
        top:-9999px;
    }
}
@keyframes searchZoomIn {
    0%{
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
        pointer-events: auto;
    }
    
}
@keyframes mapZoomIn {
    0%{
        opacity: 0;
        transform: translateX(100px) translateY(-100px);
    }
    100% {
        opacity: 1;
        transform: translateX(0) translateY(0);
        pointer-events: auto;
    }
    
}
@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

.slide{
    &__wrap{
        display: block;
        width:100%;
        height:100%;
        position: absolute;
        perspective: 80vh;
    }
    &-enter{
        transition-delay: 0.25s;
        transform: translate(0, 44px) translateZ(-125px) rotateX(-15deg);
        &-active{
            transform: translate(0, 0);
        }

    }
    &-exit{
        opacity: 1;
        &-active{
            transform: translateY(100%);
            opacity: 0;
            transition:all 0.75s cubic-bezier(.21,0,1,.44);

        }
    } 

}
.glide{
    display: block;
    width:100vw;
    height:100vh;
    position: absolute;
    transition: all 0.65s ease;
    &--down{
        &-enter{
            transition-delay: 0.25s;
            transform: translate(0, 44px) translateZ(-125px) rotateX(-15deg);
            &-active{
                transform: translate(0, 0);
            }

        }
        &-exit{
            opacity: 1;
            &-active{
                transform: translateY(100%);
                opacity: 0;
                transition:all 0.75s cubic-bezier(.21,0,1,.44);

            }
        } 
    }
}



﻿.e-list {
    &view {
        font-size: 0.875rem;
        border: 0;
        transition: all 0.35s ease;
        transform: scale3d(1, 1, 1);
        opacity: 1;

        .hide & {
            transform: scale3d(0.9, 0.9, 1);
            opacity: 0;
        }

        &.border--none {
            .e-list-header {
                border: 0;
            }

            .e-content {
                border: 0;
            }
        }

        &:not(.e-list-template) .e-list-item {
            display: block;
            background: none;
            float: none;
            padding: 0.375rem 1.25rem;
            overflow: hidden;
            height: auto;
        }

        .text {
            flex: 1 1 auto;
            padding-left: 0.25rem;

            &--no-wrap {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .subtext {
            flex: 1 1 auto;
            display: block;
            line-height: normal;
            font-size: 0.6875rem;
            margin: -0.5rem 0 0 -0.25rem;
            padding: 0.125rem 0.125rem 0.125rem 0.125rem;



            &--no-wrap {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .icon {
                margin-right: 0.25rem;
            }
        }

        h3 .subtext {
            margin: 0rem 0 0 0em;
            font-weight:normal;
        }

        .e-content {
            @include hideScrollUntilHover();
        }

        .e-list-header {
            background: none;
            display: flex;
            align-items: stretch;
            padding: 0.25rem 1.25rem 0.25rem 1.25rem;

            .icon-button {
                flex: 0 0 auto;
                margin-top: 0.25rem;
            }

            .e-text {
                flex: 1 1 auto;
                padding: 0.75rem 0;
            }
        }

        .listWrapper {
            display: block;
            overflow: hidden;
            min-height: 1rem;
            height: auto;
            transition: opacity ease 0.35s;

            &--flex {
                display: flex;
                align-items: stretch;
            }

            .e-switch-wrapper {
                margin: 0.75rem 0.25rem;
            }

            &--disabled {
                .e-avatar,
                .text {
                    opacity: 0.5;
                }
            }
        }

        .e-avatar {
            margin: 0 0.5rem 0 0.125rem;
            flex: 0 0 3em;
        }

        &--cards {
            .e-list-parent {
                padding-bottom: 8rem;
            }

            &:not(.e-list-template) .e-list-item {
                //display: flex;
                //align-items: flex-start;
                //justify-content: left;
                //flex-wrap: wrap;
                overflow: hidden;
                padding: 0.5rem 1rem 1rem 1rem;
                margin: 1rem 1rem 0 1rem;
                position: relative;
                background: $white1;
                transition: all 0.35s ease;
                //mutli shadow
                box-shadow: 0 2.8px 2.2px rgba(112,144,176, 0.02), 0 6.7px 5.3px rgba(112,144,176, 0.028), 0 12.5px 10px rgba(112,144,176, 0.035), 0 22.3px 17.9px rgba(112,144,176, 0.042), 0 41.8px 33.4px rgba(112,144,176, 0.05), 0 56px 80px rgba(112,144,176, 0.07);

                &.active,
                &.e-hover {
                    cursor: pointer;

                    &:hover {
                        background: $primary;
                    }
                }
            }

            h3 {
                padding-left: 0;
                padding-right: 0;
            }
        }


        .e-list-item:nth-child(1) .e-avatar {
            background-color: $primary-shade;
        }

        .e-list-item:nth-child(2) .e-avatar {
            background-color: $secondary-shade;
        }

        .e-list-item:nth-child(3) .e-avatar {
            background-color: $tertiary-shade;
        }

        .e-list-item:nth-child(4) .e-avatar {
            background-color: $purple;
        }

        .e-list-item:nth-child(5) .e-avatar {
            background-color: darken($primary-shade, 10%);
        }

        .e-list-item:nth-child(6) .e-avatar {
            background-color: darken($secondary-shade, 10%);
        }

        .e-list-item:nth-child(7) .e-avatar {
            background-color: darken($tertiary-shade, 10%);
        }

        .e-list-item:nth-child(8) .e-avatar {
            background-color: darken($purple, 10%);
        }

        .e-list-item:nth-child(9) .e-avatar {
            background-color: darken($primary-shade, 20%);
        }

        .e-list-item:nth-child(10) .e-avatar {
            background-color: darken($secondary-shade, 20%);
        }

        .e-list-item:nth-child(11) .e-avatar {
            background-color: darken($tertiary-shade, 20%);
        }

        .e-list-item:nth-child(12) .e-avatar {
            background-color: darken($purple, 20%);
        }

        .e-switch-wrapper {
            flex: 0 0 2rem;
        }

        .icon.icos-thumbs-down {
            color: $grey-500;
        }

        .icon-button {
            margin-left: 0.5rem;
        }
    }

    &view &-header {
        font-size: 1rem;
    }

    .sidepanel &view {
        display: flex;
        flex-direction: column;

        .e-content {
            flex: 1 1 auto;
            padding-top: 0.5rem;
            @include hideScrollUntilHover();
        }
    }

    .sidepanel.hide &view {
        transform: none;
        opacity: 1;
    }
}

﻿.icos {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icos' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icos-thumbs-up:before {
    content: "\f087";
}

.icos-thumbs-down:before {
    content: "\f088";
}
.icos-happy-face:before {
    content: "\e90e";
}

.icos-sad-face:before {
    content: "\e90f";
}
.icos-microsoft:before {
    content: "\e909";
}

.icos-network:before {
    content: "\e90a";
}

.icos-handshake:before {
    content: "\e908";
}

.icos-external-link:before {
    content: "\e907";
}

.icos-crown:before {
    content: "\e906";
}

.icos-sharefile:before {
    content: "\e904";
}

.icos-barrister:before {
    content: "\e900";
}

.icos-dx:before {
    content: "\e901";
}

.icos-fax:before {
    content: "\e902";
}

.icos-route:before {
    content: "\e903";
}

.icos-case:before {
    content: "\61";
}

.icos-calendar:before {
    content: "\62";
}

.icos-gear:before {
    content: "\63";
}

.icos-chat:before {
    content: "\64";
}

.icos-dashboard:before {
    content: "\65";
}

.icos-bell:before {
    content: "\66";
}

.icos-history:before {
    content: "\67";
}

.icos-pencil:before {
    content: "\68";
}

.icos-attention:before {
    content: "\69";
}

.icos-filter:before {
    content: "\6a";
}

.icos-search:before {
    content: "\6c";
}

.icos-save:before {
    content: "\6d";
}

.icos-add:before {
    content: "\6e";
}

.icos-remove-1:before {
    content: "\6f";
}

.icos-menu:before {
    content: "\70";
}

.icos-more-h:before {
    content: "\74";
}

.icos-more-v:before {
    content: "\75";
}

.icos-file:before {
    content: "\76";
}

.icos-bullhorn:before {
    content: "\77";
}

.icos-list:before {
    content: "\78";
}

.icos-chart:before {
    content: "\79";
}

.icos-list-ul:before {
    content: "\7a";
}

.icos-location-arrow:before {
    content: "\41";
}

.icos-paper-plane:before {
    content: "\42";
}

.icos-pie-chart:before {
    content: "\43";
}

.icos-check:before {
    content: "\44";
}

.icos-watch:before {
    content: "\45";
}

.icos-credit-card:before {
    content: "\46";
}

.icos-ios-paper:before {
    content: "\48";
}

.icos-note:before {
    content: "\49";
}

.icos-folder-open-o:before {
    content: "\4a";
}

.icos-calendar-check-o:before {
    content: "\4b";
}

.icos-calendar-1:before {
    content: "\4c";
}

.icos-gavel:before {
    content: "\4d";
}

.icos-envelope:before {
    content: "\4e";
}

.icos-question:before {
    content: "\4f";
}

.icos-refresh:before {
    content: "\50";
}

.icos-commenting:before {
    content: "\51";
}

.icos-phone:before {
    content: "\52";
}

.icos-map-marker:before {
    content: "\53";
}

.icos-chevron-left:before {
    content: "\54";
}

.icos-chevron-right:before {
    content: "\55";
}

.icos-chevron-down:before {
    content: "\56";
}

.icos-chevron-up:before {
    content: "\57";
}

.icos-angle-left:before {
    content: "\59";
}

.icos-angle-right:before {
    content: "\5a";
}

.icos-angle-up:before {
    content: "\30";
}

.icos-angle-double-left:before {
    content: "\31";
}

.icos-angle-double-right:before {
    content: "\32";
}

.icos-angle-double-up:before {
    content: "\33";
}

.icos-angle-double-down:before {
    content: "\34";
}

.icos-cross:before {
    content: "\35";
}

.icos-remove:before {
    content: "\6b";
}

.icos-telephone:before {
    content: "\37";
}

.icos-plus-circle:before {
    content: "\39";
}

.icos-times-circle:before {
    content: "\21";
}

.icos-add-email:before {
    content: "\22";
}

.icos-arrow-down:before {
    content: "\24";
}

.icos-arrow-left:before {
    content: "\25";
}

.icos-arrow-right:before {
    content: "\26";
}

.icos-arrow-up:before {
    content: "\27";
}

.icos-print-1:before {
    content: "\29";
}

.icos-twitter:before {
    content: "\2a";
}

.icos-linkedin:before {
    content: "\2b";
}

.icos-at:before {
    content: "\2c";
}

.icos-help:before {
    content: "\2d";
}

.icos-web:before {
    content: "\2e";
}

.icos-share-alt:before {
    content: "\2f";
}

.icos-home:before {
    content: "\3a";
}

.icos-star-o:before {
    content: "\3b";
}

.icos-star:before {
    content: "\3c";
}

.icos-comments:before {
    content: "\3d";
}

.icos-banknote:before {
    content: "\3e";
}

.icos-screen-max:before {
    content: "\58";
}

.icos-screen-min:before {
    content: "\3f";
}

.icos-tick:before {
    content: "\40";
}

.icos-lock:before {
    content: "\5b";
}

.icos-load-c:before {
    content: "\5d";
}

.icos-angle-down:before {
    content: "\5e";
}

.icos-selectall:before {
    content: "\5f";
}

.icos-deselectall:before {
    content: "\60";
}

.icos-clone:before {
    content: "\7b";
}

.icos-flag:before {
    content: "\7c";
}

.icos-bin:before {
    content: "\7d";
}

.icos-sync:before {
    content: "\7e";
}

.icos-android-hand:before {
    content: "\5c";
}

.icos-upload:before {
    content: "\e000";
}

.icos-reply:before {
    content: "\e001";
}

.icos-reply-all:before {
    content: "\e002";
}

.icos-forward:before {
    content: "\e003";
}

.icos-play:before {
    content: "\e004";
}

.icos-pause:before {
    content: "\e005";
}

.icos-doc:before {
    content: "\e007";
}

.icos-download:before {
    content: "\e008";
}

.icos-building:before {
    content: "\e009";
}

.icos-learn:before {
    content: "\e00a";
}

.icos-angle-right-end:before {
    content: "\e00b";
}

.icos-angle-left-end:before {
    content: "\e00c";
}

.icos-powerpoint:before {
    content: "\e00d";
}

.icos-exclamation:before {
    content: "\e00f";
}

.icos-tip:before {
    content: "\e00e";
}

.icos-sign-out:before {
    content: "\e010";
}

.icos-diary:before {
    content: "\e011";
}

.icos-diary1:before {
    content: "\e012";
}

.icos-user:before {
    content: "\47";
}

.icos-users:before {
    content: "\e013";
}

.icos-settings:before {
    content: "\e014";
}

.icos-screen:before {
    content: "\71";
}

.icos-outlook:before {
    content: "\72";
}

.icos-pdf:before {
    content: "\e006";
}

.icos-outlook-cal:before {
    content: "\73";
}

.icos-address-book:before {
    content: "\e015";
}

.icos-copy:before {
    content: "\e016";
}

.icos-rss:before {
    content: "\e017";
}

.icos-pin:before {
    content: "\e018";
}

.icos-repeat:before {
    content: "\e019";
}

.icos-excel:before {
    content: "\38";
}

.icos-brief:before {
    content: "\36";
}

.icos-priority:before {
    content: "\28";
}

.icos-link:before {
    content: "\e01a";
}

.icos-video:before {
    content: "\e01b";
}

.icos-filter-settings:before {
    content: "\e01d";
}

.icos-archive:before {
    content: "\e01e";
}

.icos-shred:before {
    content: "\e01f";
}

.icos-return:before {
    content: "\e020";
}

.icos-filter-menu:before {
    content: "\e01c";
}

.icos-stack:before {
    content: "\e021";
}

.icos-inbox:before {
    content: "\e022";
}

.icos-draft:before {
    content: "\e023";
}

.icos-xero:before {
    content: "\e024";
}

.icos-sage:before {
    content: "\e025";
}

.icos-quickbooks:before {
    content: "\e026";
}

.icos-myob:before {
    content: "\e027";
}

.icos-msteams:before {
    content: "\e90c";
}

.icos-mobile:before {
    content: "\e905";
}

.icos-palette:before {
    content: "\e40a";
}

.icos-clock:before {
    content: "\e924";
}

.icos-camera:before {
    content: "\f03d";
}

.icos-folder:before {
    content: "\e92f";
}

.icos-folder-open:before {
    content: "\e930";
}

.icos-eye:before {
    content: "\e9ce";
}

.icos-sensitive:before {
    content: "\e9d1";
}
.icos-share-ios:before {
    content: "\e90b";
}
.icos-sharepoint:before {
    content: "\e90d";
}
.icos-hyperlaw:before {
    content: "\e910";
}
﻿.hamburger {
    padding: 3px 0 0 0;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    margin: 0;
    overflow: visible;
    height: 40px;
    width:40px;
    border-radius: 0;
    @include mq('tablet-wide', min) {
        width:44px;
    } 
    @include mq('phablet', max) {
        margin: 0 auto;
    }

    .sm--pushed &{
        //background: darken($primary, 10%);
    }

    &:hover {
        opacity: 0.7;
    }

    &-box {
        width: 22px;
        height: 22px;
        display: inline-block;
        position: relative;
    }

    &-inner {
        display: block;
        top: 50%;
        margin-top: 2px;
        top: 2px;
        transition-duration: 0.275s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &,
        &::before,
        &::after {
            width: 22px;
            height: 2px;
            border-radius: 5px;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
            content: "";
            display: block;
            background-color:$grey-white;
        }

        .icon-button:hover &,
        .icon-button:hover &::before,
        .icon-button:hover &::after {
            background-color:$grey-white;
        }

        &::before {
            top: 7px;
            transition: opacity 0.125s 0.275s ease;
        }

        &::after {
            bottom: 0;
            top: 14px;
            transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        .sm--pushed & {
            transform: translate3d(0, 7px, 0) rotate(135deg);
            transition-delay: 0.075s;
            opacity: 1;
            background-position: -200px 0;
            background-color:$grey-white;

            &::before {
                transition-delay: 0s;
                opacity: 0;
                background-color:$grey-white;
            }

            &::after {
                transform: translate3d(0, -14px, 0) rotate(-270deg);
                transition-delay: 0.075s;
                background-color:$grey-white;
            }
        }
    }
}

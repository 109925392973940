.b-address{
    display: flex;
    flex-direction:row-reverse;
    @include mq('tablet', max) {
        flex-direction: column-reverse;
    }
    &__card.ui.card{
        margin:0 0 2rem 0;
    }
    &__title{
        margin:1rem 0.75rem 0 0.75rem;
        padding-bottom: 0.75rem;
        font-size:1.5rem;
        line-height: 2rem;
        font-family: $sans-serif;
        color:$primary-shade;
        font-weight:bold;
    }
    .location{
        font-size:0.875rem;
    }
    &__content{
        display:block;
        flex: 1 1 auto;
        margin: auto 0;
        text-align: center;
        overflow: hidden;
        @include mq('tablet', max) {
            padding:2rem 1rem;
        }
    }
    &__info{
        font-size:0.875rem;
        line-height: 1.5rem;
        display: block;
        padding:0 0.75rem;
        overflow: hidden;
        &-column{
            display: block;
            padding:0.25rem 0 0.75rem 0;
            overflow: hidden;
        }
    }
    &__map{
        overflow: hidden;
        width: auto;
        height: auto;
        display: block;
        flex:0 0 50%;
        background: $grey-200;
        position: relative;
        border-radius: 0.5rem;
        min-height: 320px;
        @include mq('tablet', max) {
            flex:0 0 320px;
        }
        &-inner{
            position: relative;
            width:100%;
            height: 100%;
            margin:0;
            overflow: hidden;
            .google__map & {
                //hack to hide google controls
                height:calc(100% + 300px);
                margin:-150px 0;
            }

        }
        &-iframe{
            position: relative;
            width:100%;
            height:100%;
            margin:0;
            border-radius: 0.5rem;
            overflow: hidden;
            display: block;
        }
        .leaflet-container{
            left:0;
            top:0;
            width:100%;
            height:100%;
        }

    }
    &__link{
        text-overflow: ellipsis;
        overflow:hidden;
        display: block;
        white-space:nowrap;
        font-size: 0.875rem;
        color:$primary-shade;
        transition: color 0.35s ease;
        backface-visibility: hidden;
        padding:0.25rem 0;
        &:hover{
            color:darken($primary-shade,15%);
            text-decoration: underline;
            .icon.small{
                text-decoration: none;
            }
        }
        &.placeholder{
            color:$grey-500;
            &:hover{
                color:$grey-600;
            }
        }
        .icon.small{
            font-size: 1rem;
            //fix hover animation judder
            backface-visibility: hidden;
            transform: translateZ(0);
        }
    }
}
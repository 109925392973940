.e-btn{
    &-group{
        &.flex{
            display: flex;

        }
        input {
            opacity: 1;
            position: relative;
        }
        &:not(.e-rtl):not(.e-vertical) .e-btn {
            margin:0 0 0 0.25rem;
        }
    }
}

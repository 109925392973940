.dragdrop {
    &-container {
        width: 100%;
    }

    &-item {
        border: 1px solid $opus-grey-purple;
        border-radius: 5px;
        cursor: grab;
        padding: 16px;
        margin: 16px 8px;

        &:active {
            box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, .2), 1px 3px 15px 2px rgba(0, 0, 0, .2);
        }

        &_icon {
            cursor: pointer;
            margin-left: 8px !important;
        }
    }
}
.weather{
    display: inline-flex;
    flex-direction: row;
    align-items:center;

    &__details{
        line-height: 1rem;
        display: flex;
        flex-direction: column;
        text-align: right;
        padding-right:0.25rem;
    }
    &__icon{
        width:4rem;
        text-align: center;
    }
    &__temp{ 
        width:4rem;
        font-size: 2rem;
        text-align: center; 
    }
}
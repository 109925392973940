.ui.search>.results .result .image {
    border-radius: 100%;
    float: left;
    height: 60px;
    margin-right: 1em;
    width: 60px;
}

.ui.search>.results .result .image+.content {
    margin: 0;
}

.ui.search .prompt {
    border-radius: 1em;
}

.ui.labeled.input>.label {
    background-color: $primary;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}

input:focus {
    border-color: $secondary !important;
}

@for $i from 1 through 12 {
    $h: ($i - 1) * 0.1s + 0.15s;

    .column:nth-child(#{$i}) .b-result{
        animation: searchZoomIn  0.5s ease $h alternate backwards;
    }
}

.b-result {
    position: relative;

    //transform: scale3d(0.9, 0.9, 1);
    //transition: trasform 0.35s ease, opacity 0.35s ease;
    //loop animation in
    
    &__grid{
        &.ui.grid{
            margin: 0 0.5rem ;
            padding-bottom:1rem;
        }
        .extra.content.zero-padding{
            padding:0!important;
            overflow: hidden;
            .b-left-panel &{
                max-height:3.125rem;
            }   
        }
        .leaflet-container{
            transition: width 0.35s ease,height 0.35s 0.5s ease,opacity 0.35s ease ;
            max-width: 500px;
            max-height: 500px;
            .b-left-panel &{
                opacity: 0;
            }   
        }
        .b-left-panel &{
            &.ui.grid{
                margin: 0 0;
            }
            .column{
                width:100%!important;
            }
            
        }
    }
    &__title{
        margin-top:1rem;
        padding:1rem;
        display: block;
        width: 100%;
        h2{
            font-weight:$bold;
        }
    }
    &-banner {
        max-width: 100%;
    }

    &-contact {
        background-color: $primary;
        border-radius: 0 0 0.25rem 0.25rem;
        padding: 0.5rem;
        position: absolute;
        right: 16px;
        top: 0;
        width: 36px;        
    }

    &-head {
        display: flex;
        font-size: 16px !important;
        margin-bottom: 1em;
    }
    &-details {
        display: block;
        text-align: left;
    }

    &-profile {
        border-radius: 100%;
        margin-right: 1em;
        max-height: 60px;
    }
    & h2 {
        margin: 0;
    }
    .header{
        h4{
            margin:0;
            padding:0;
            font-size: 1.125rem;
            line-height: 1.375rem;
            font-weight: $bold;
            animation: fadeLeft 0.35s ease 0.35s alternate backwards;
        }
        h5{
            margin:0;
            padding:0.125rem 0 0 0;
            color:$opus-purple;
            font-size: 0.875rem;
        }
        padding: 0 0 0.25rem 0;
    }
    .content:not(.extra){
        display: flex;
        flex-direction: row;
        overflow: hidden;
        a{
            color:$primary-shade;
            text-decoration:underline;
            &:hover{
                color:darken($primary-shade, 10%);
            }
        }
        .date{
            display: inline-block;
        }
        
    }
    &.person{
        .content{
            padding:0.875rem;
        }
        .extra{
            display:block;
            .ui.button.small{
                font-size:0.75rem;
                & + .label{
                    font-size:0.75rem!important;
                }
            }
            .ui.button.medium{
                font-size:0.875rem;
            } 
            .ui.button.float-right{
                float:right;
                display:inline;
            }
        }
    }
    &.office{
        .header{
            font-size:1rem;
            font-weight: 600;
        }
        .description{
            font-size:0.875rem;
            color:$black3

        }
        .content{

            &:not(.extra){
                min-height: 9.5rem;
            }
        
        }
        .extra{
            display:block;
            .ui.button.small{
                font-size:0.75rem;
                & + .label{
                    font-size:0.75rem!important;
                }
            }
            .ui.button.medium{
                font-size:0.875rem;
            } 
            .ui.button.float-right{
                float:right;
                display:inline;
            }
        }
    }
    &__areas{
        .ui.card > &.extra{ 
            display:block;
            height: 48px;
            position: relative;
            overflow: visible;
            padding:0;
        }
        .meta{
            display: block;
            max-height: 34px;
            height: auto;
            overflow:hidden;
            cursor: default;
            position:absolute;
            width:100%;
            background: $white1;
            border-radius:0 0 0.285714rem 0.285714rem;
            transition: max-height 0.5s ease, background 0.35s ease;
            padding: 0.625rem 1.875rem 0.5rem 0.875rem; 
            &:hover{
                max-height: 200px;
                cursor: default;
                background: $secondary-tint;
                transition-delay: 0.45s;

            }
            .icon{
                position: absolute;
                right:0.5rem;
                top:0.75rem;
            }
        }
        .label{
            white-space: nowrap;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__content{
        display: inline-flex;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        padding-left:0.5rem;
        .ui.card & .description{
            padding:0.25rem 0 0.25rem 0;
            font-size:0.75rem;
            
        }
        .office.ui.card & .description{
            font-size:0.875rem; 
        }
        .ui.card & .ui.label{
            font-family:$sans-serif !important;
            &.circular{
                margin-top:0.25rem;
                padding:0.25rem 0.5rem!important;                
                &.secondary{
                    color:$grey-white;
                }
                &.mini{
                    line-height: 0.75rem;
                }
                //clear:both;
            }
            &.tiny{
                padding:0.25rem 0.375rem;
                font-size:0.75rem;
            }
        }
        &-button.ui.button{
            display: block;
            position: absolute;
            width:100%;
            height: 100%;
            left:0;
            right:0;
            top:0;
            bottom:0;
            transition: all 0.35s ease;
            background-color: transparent;
            max-height: none;
            border-radius: 0;
            z-index: 1;
            &:hover{
                background-color: rgba($black1,0.25);
            }
            &.disabled{
                pointer-events: none;
            }
        }
    }
    &__avatar{
        display: inline;
        flex: 0 0 158px;
        overflow: hidden;
        width: 158px;
        height: 156px;
        margin:-0.875rem -0.875rem  -0.875rem 0.5rem;
        padding-right:0.5rem;
        border-radius: 0.25rem 0 0 0;
 
        &-skew{
            width: 300px;
            height: 300px;
            margin: -50px -160px 0 0.25rem;
            //transform: skew(-17deg);
            background: $opus-purple;
            border-radius: 300px;
            overflow: hidden;
            position: relative;
            display: block;
            //border-left:4px solid rgba($white1, 1);
            //box-shadow: 0 0 0 2px rgba($primary, 1);
        }
        &-img{
            //transform: skew(17deg);
            background: url('../images/avatar/large/user-blank.png');
            background-size:cover;
            position: absolute;
            left: 0;
            top:50px;
            width: 160px;
            height: 160px;
            border-radius: 0 0 0.25rem 0;
            &.building{
                background: url('../images/avatar/large/company-blank.png');
                background-size:cover;
            }
        }
        .b-result.office &{
            width: 180px;
            height: 180px;
            &-img{
                width: 180px;
                height: 180px;
            }
        }
    }
    &__type{
        display: block;
        margin-top:0.25rem;

    }

    &-previous {
        &__header {
             
            border-bottom:1px solid $secondary;
            margin:1rem 1.25rem;
            display:block;
            h4{
                line-height:2rem;
            }
        }
        &__item {
            margin: 0 1rem;
            a {
                color: $black3;
                background-color: darken($nav-bg, 5%);
                padding: 0.75rem 1rem 0.75rem 0.75rem;
                border-radius: 0.5rem;
                cursor: pointer;
                margin-top: 0.5rem;
                display: block;
                transition: all 0.35s ease;
                &:hover {
                    background-color: darken($nav-bg, 10%); 
                }
            }

        }
        &__add{
            display: inline-block;
            padding:0 0.25rem;
            color: $grey-500;
        }
    }
    &__map{
        position: relative;
    }
    &__map-error{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:100%;
        text-align: center;
        background-color: $grey-200;
        color: $grey-500;
        i.icons .corner.icon{
            margin-left:1rem;
        }
        p{
            text-align: center;
            color: $grey-500;
            font-size:0.875rem;
            line-height:1.25rem;
            margin-top:0.5rem!important;
        }
        .b-left-panel &{
            //background-color: #f0f; 

        }
    }
    &__link{
        display: inline-block;
        margin-right:0.5rem;
    }
    &__location{
        display: inline-block;
        margin-right:0.5rem; 
    }
    &__dist{
        position: absolute;
        left: 1.25rem;
        bottom: 1rem;
        z-index: 2;
        .b-left-panel &{
            position: relative;
            margin:0.75rem 1rem!important;
            left:auto;
            bottom:auto;
        }    
    }
    
}
﻿/* toggleMenu functionality */
/* Start Menu */
.sm {
    &__item {
        display: block;
        float: none;
        @include text-ellipsis;
        @include mq('tablet-wide', max) {
            opacity: 0;
            transition: 0.5s, opacity 1s;
            margin-left: -1.25rem;
            .sm--pushed &,
            .sm__level.e-open &{
                opacity: 1;
                margin-left: 0;
                &.sm__bar {
                    border-top: 2px solid rgba(255,255,255,0.2);
                    margin: 0.5rem 1rem 0 1rem;
                    padding-bottom: 0.5rem;
                }
            }
            //loop animation in
            @for $i from 1 through 8 {
                $h: $i * 0.05s + 0.15s;
                &:nth-child(#{$i}) {
                    transition-delay: $h;
                }
            }
        }
        & .icos,
        & .icon {
            display: inline;
            float: left;
            margin: 0 0.5rem 0 -0.125rem;
            font-size: 1rem;
            width: 1.75rem;
            height: 2.5rem;

            &::before {
                line-height: 2.5rem;
            }
        }

        &::before {
            position: absolute;
            left: 10px;
            z-index: -1;
            color: rgba(0,0,0,0.2);
            line-height: 3.5;
            display: block;
        }
    }

    &__pusher {
        position: relative;
        left: 0;
        height: 100%;
        perspective: 1000px;
        transition: all 0.5s;
        &--level0{
            // menu width plus icon gap
            transform: translate3d(300px, 0px, 0px)!important;
        }
        &--level1{
            transform: translate3d(340px, 0px, 0px)!important;
        }
        &--level2{
            transform: translate3d(380px, 0px, 0px)!important;
        }
        &--level3{
            transform: translate3d(420px, 0px, 0px)!important;
        }

        
        @include mq('tablet-wide', max) {
            .sm__menu.e-sidebar.e-left.e-close.e-dock{
                width:300px;
                transform:none;
            }
        }
        @include mq('tablet-wide', min) {
            padding-left:2.75rem;
            &,
            &.sm--pushed {
                transform:none!important;
            }
            &.sm--hidden{
                padding-left:0;
            }
        }
        
    }

    &__menu {
        position: absolute; /* we can't use fixed here :( */
        top: 0;
        left: 0;
        z-index: 1;
        width: 300px!important;
        height: 100%;
        transform: translate3d(-100%, 0, 0);
        border:0!important;
        background: none;

        & > .sm__level,
        & > .e-sidebar.e-left.e-open.sm__level{
            box-shadow: none;
            transform: translate3d(0, 0, 0);
        }
        .e-sidebar.e-left.e-close{
            visibility: visible;
        }
        
        @include mq('tablet-wide', min) {
            transform:none!important;
            width:2.75rem!important;
            transition: width ease 0.35s;
            z-index: 160;
            .sm--pushed &{
                width:210px!important;
            }
        }

        
    }
    
    &__level {
        position: absolute;
        top: 0;
        left: 0;
        bottom:0;
        width: 100%;
        height: auto;
        transform: translate3d(-115%, 0, 0); 
        transition: all 0.5s;
        background-color:$primary;
        z-index: 10;
        padding:0 0.125rem;
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        
        &.e-sidebar.e-left.e-close{
            transform: translate3d(-130%, 0, 0);
        }
        &.sm__level--2{
            z-index: 20;
        }
        &.sm__level--3{
            z-index: 30;
        }
        
        @include mq('tablet-wide', min) {
            .sm__menu > & {
                top:0px; 
            }
            .sm__menu.e-dock > & {
                top:40px; 
            }
            .sm__menu > & &{
                top:0;
            }
            &.sm__level--1{ 
                width:260px;
                z-index: 170;
            }
            &.sm__level--2{ 
                width:260px;
                z-index: 180;
            }
        }

        & > ul {
            margin: 0;
            padding: 0.5rem 0 0 0;
            list-style: none;
            @include mq('tablet-wide', min) {
                &.bottom{
                    position: absolute;
                    bottom:0;
                    left:0.125rem;
                    right:0.125rem;
                }
            }   
        }

        & > h2,
        .sm__title h2{
            margin: 0;
            padding: 1.5rem 1.5rem 1.5rem 2.25rem;
            color: rgba(255,255,255,0.6);
            font-weight: 300;
            font-size: 2em;
            @include mq('tablet-wide', min) {
                padding: 1.5rem 1.75rem 1.5625rem 1.75rem;
            }
        }

        &-icon{ 
            position: absolute;
            right: 0;
            top: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity ease 0.2s;
            width: 2.5rem;
            height:2.5rem;

            .sm__close & { 
                    opacity: 1;
                    padding-top:0.5rem;
                    font-size:1.25rem;
                    color: rgba(255,255,255,0.26);
             }
            @include mq('tablet-wide', min) {
                left: 0;
                right: unset;
                top: unset;
                bottom:0;
                margin-left:0.125rem;
                .home{
                    display:none;
                }
            }
            
        }
        &.e-sidebar{
            width:300px;
            box-shadow: 1px 0 1px rgba(0,0,0,0.2);
            transform: translate3d(-40px, 0, 0);
            transition: all 0.4s ease!important;
            border:0;
            @include mq('tablet-wide', min) {
                width:260px!important;
                margin-left:44px!important;
                &.sm__level--2{
                    margin-left:88px!important;
                }
            }
        }
        
        &.e-open {
            @include mq('tablet-wide', max) {
                &.e-sidebar.e-left.sm__level{
                    &--1{
                        transform: translate3d(-40px, 0, 0)!important;
                    }
                    &--2{
                        transform: translate3d(-80px, 0, 0);
                    }
                    &--3{
                        transform: translate3d(-120px, 0, 0);
                    }
                }
            }
            @include mq('tablet-wide', min) {
                width:260px; 
                opacity: 1;
                pointer-events: auto;
                //sf overwrite
                transition: all ease 0.4s!important;
                transform: scale3d(1, 1, 1)!important;
                &.sm__level--1{ 
                    width:260px;
                    z-index: 170;
                }
                &.sm__level--2{ 
                    width:260px;
                    z-index: 180;
                }
            }
        }
        &.e-close{
            @include mq('tablet-wide', min) {
                pointer-events: none;
                visibility: visible;
                opacity: 0; 
                margin-top:0;
                //sf overwrite
                transform: scale3d(0.9, 0.9, 1)!important;
                transition: all ease 0.4s!important;
            }
        }
    }
    &__link {
        display: block;
        inline-size:max-content;
        padding: 0 0.875rem 0 2.375rem;
        border-radius: 0.25rem;
        outline: none;
        font-size: 1rem;
        color: $white1;
        line-height: 2.5rem;
        text-decoration: none;
        cursor: pointer;
        background:none;
        text-align: left;
        width:100%;
        transition: background 0.3s ease;
        @include text-ellipsis;
        .icon.angle.left,
        .icos-angle-left {
            margin: 0 -0.75rem 0 -1.75rem; 
            opacity: 0.6;
        }

        &:hover {
            color:$white1;
            background: rgba(darken($primary-shade, 16%), 0.4);
            .e-dock &{
                background: rgba(darken($primary-shade, 16%), 0.6);
            }
        }

        @include mq('tablet-wide', min) {
            padding:0 0 0 0.5rem;
            .e-sidebar:not(.e-dock) &,
            ul ul .sm__item &{
                padding: 0 1.825rem 0 1.75rem;
                .icon.angle.left,
                .icos-angle-left{
                    float:right;
                    margin: 0 -1rem 0 0.5rem!important;
                    transform: rotate(180deg);  
                }
            }
        }
    }

    &__header {
        display: block;
        opacity: 0;
        transition: 0.5s, opacity 1s;
        transition-delay: 0.15s;
        margin-top:0.25rem;
        padding-top: env(safe-area-inset-top);

        .sm--pushed & {
            opacity: 1;
        }

        &-link {
            display: block;
            color: $white1;
            text-decoration: none;
            font-size: 1rem;
            text-align: center;
            padding-top: 0.75rem;
            padding-bottom: 0.5rem;
            height:auto;
            width:100%;
            background:none;
            cursor:pointer;
            border-radius:0.25rem;
            transition: 0.35s ease background-color;

            &:hover { 
                color:$white1;
                background: rgba(darken($primary-shade, 16%), 0.6);
            } 

            & > i.icon { 
                display: flex;
                position: absolute;
                margin: 0 0 0 1.875rem;
                background: rgba(255, 255, 255, 0.15);
                font-size:1rem;
                display:block;
                border-radius: 1rem;
                width:1.75rem;
                height:1.75rem;
                line-height:1.75rem;
            }
        }
        @include mq('tablet-wide', min) {
            display:none;
        }
    }
    &__branding{
        height:40px;
        display:none;
        background: rgba(0,0,0,0.85);
        .light-theme & { 
            background:#eaeef3;
            border-bottom: 1px solid $white3;
        }
        
        .icos-button{
            background:none;
            border-radius: 0;
            width: 44px;
        }
        position: absolute;
        z-index: 161;
        @include mq('tablet-wide', min) {
            display:block;
        }
    }
    &__close{
        position: absolute;
        top: 0;
        left: 0;
        bottom:0;
        width: 300px;
        height: auto;
        transform:none!important;
        background: rgba(0,0,0,0.2);
        transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
        opacity: 0;
        display: block; 
        pointer-events: none;
        z-index: 9;
        outline: none;
        box-shadow: none;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active{
            border:0;
            outline: none;
            box-shadow: none;
        }
        .sm__level--2 + &{
            z-index: 19;
            margin-left:-40px!important;
            background: rgba(0,0,0,0.27);
        }
        .sm__level--3 + &{
            z-index: 29;
            background: rgba(0,0,0,0.34);
            margin-left:-80px!important;
        }
        .e-open + &{
            opacity: 1;
            pointer-events: auto;
        }
        @include mq('tablet-wide', min) {
            width: 260px;
            .sm__level--1 + &{
                z-index: 169;
                margin:0 0 0 0!important;
            }
            .sm__level--2 + &{
                z-index: 179;
                margin:0 0 0 2.75rem!important;
            }
            .sm__level--3 + &{
                z-index: 189;
                margin:0 0 0 2.75rem!important;
            }
            
        }
    }
    
    &__pusher-close{
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        bottom:0;
        width: 100%;
        height: auto;
        transform:none!important;
        background: rgba(0,0,0,0.3);
        transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
        opacity: 0;
        display: block; 
        pointer-events: none;
        z-index: 140;
        outline: none;
        box-shadow: none;
        cursor: pointer;
        &:hover,
        &:focus{
            border:0;
            outline: none;
            box-shadow: none;
        }

        .sm--pushed > &{
            opacity: 1; 
            pointer-events: auto;
            backdrop-filter:blur(1px); 
        }
    }
}





.card{
    &.company,
    &.contact{
        .ui.small.image{
            width:6rem;
            min-height: 6rem;
            min-width: 6rem;
            margin-left:1rem;
            margin-top:1rem;
        }
        &>.content{
            & > .header:not(.ui){
                margin:2rem 0 0 0;
                font-family: $sans-serif;
                z-index: 1;
                position: relative;
                pointer-events: none;
            }
            & > .meta{
                z-index: 1;
                position: relative;
                color: $grey-800;
                pointer-events: none;
                font-weight: $semibold;
            }
        }
        .description{
            padding-bottom:1rem;
            
            &.profile{
                padding: 0.5rem 0.75rem 1rem 0.75rem;
                p{
                    font-weight:$normal;
                    font-size: 0.875rem;
                    padding: 0.25rem 0rem;
                }
                h4,
                h5{  
                    padding: 0;
                }
                button.fluid{
                    margin: 0.25rem 0; 
                }
                div.dropdown{
                    margin: 0.5rem 0 0.25rem 0;
                }
            }
        }
    }
    &__full-links{
        margin:0 0.5rem;
        display: block;
        overflow: hidden;
        
    }
    &__link{
        text-overflow: ellipsis;
        overflow:hidden;
        display: block;
        white-space:nowrap;
        font-size: 0.875rem;
        color:$primary;
        transition: color 0.35s ease;
        backface-visibility: hidden;
        &:hover{
            color:$primary-shade;
        }
        &.placeholder{
            color:$grey-500;
            &:hover{
                color:$grey-600;
            }
        }
        .icon.small{
            font-size: 1rem;
            //fix hover animation judder
            backface-visibility: hidden;
            transform: translateZ(0);
        }
    }
    &__image{
        margin:-7rem -8rem -11rem 0;
        position: absolute;
        height:32rem;
        width: 32rem;
        background-color: $primary;
        border-radius: 16rem;
        right:0;
        overflow: hidden;
        //stop ios flicker
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;
        animation: zoomUp 0.45s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.45s alternate backwards;   
        @include mq('tablet-wide') {
            height: 20rem;
            width: 20rem;
            margin: 1.5rem auto 0 auto;
        
        }
        @include mq('tablet') {
            height: 16rem;
            width: 16rem;
        
        }
        @include mq('tablet-small') {
            height: 14rem;
            width: 14rem;
            margin: 1.5rem auto 0 auto;
        
        }
        @include mq('phone') {
            height: 8rem;
            width: 8rem;
            overflow: visible;
            margin-bottom: 1.5rem;
            &.no--links{
                margin-bottom:0;
            }
        }
        & + .label.pointing{
            position: absolute;
            margin: 1.25rem 0 0 0;
            opacity: 0;
            transition: all 0.35s ease;
            z-index: 4;
            pointer-events: none;
        }
        & > img{
            transition: all 0.5s ease; 
            border:2px solid $white1;
            background-color:$grey-400;
        }
        &-link{
            position: relative;
            cursor: pointer;
            transform: scale3d(1, 1, 1);
            transition: all 0.35s ease;
            &:hover{
                transform: scale3d(0.96, 0.96, 1);
                & + .label.pointing{
                    transition: all 0.35s ease 0.7s;
                    margin: 1.25rem 0 0 -2rem;
                    opacity: 1;
                }
                & > img{
                    -webkit-filter: brightness(80%);
                    filter: brightness(80%);
                }
            }
            &:hover > img{
                border-color:$primary;
            }
        }

        .e-avatar{
            &.e-avatar-large{
                transition: all 0.5s ease; 
                background-color:$primary;
                width: 300px;
                height: 300px;
                line-height: 6rem;
                font-size: 2.75rem;
                margin:4.5rem 0 0 0;
                font-style: normal; 
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                display: block;
                text-align:center;
                border-radius: 8rem;
                img{
                    display:block;
                }
                .b-profile-hero &{
                    margin:0;
                    height:32rem;
                    width:32rem;
                    width:100%;
                    height: 100%;
                }
                @include mq('tablet-wide') {
                    width: 100%;
                    height:100%;
                    margin:0;
                
                }
            }
        } 
        i.icon.add,
        i.icon.camera{
            color: $primary;
            margin:0;
            border-radius: 100%;
            background:$white1;
            width: 1.5rem;
            font-size:1.5rem;
        }
    }
    &__hero{
        display: block;
        margin:-1rem -1rem 1rem -1rem;
        width:auto;
        min-height: 10rem;
        overflow: hidden;
        background:$primary-shade;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        align-items: stretch;
        color:$grey-white;
        border-radius: 0.25rem 0.25rem 0 0;
        
        @include mq('tablet-small') {
            text-align: center; 
            flex-direction: column-reverse;
            padding-bottom:2rem;
        
        }
        .b-profile-hero &{ 
            background: none;
            margin:0;
            border-radius: 0;
            overflow: visible;
            min-height: 420px;
            @media only screen and (min-width: 992px){
                .ui.grid .column &{
                    margin: 0 auto;
                    max-width: 1234px;
                    padding:0 0 0 2.5rem;
                }
            }
        }
        &-col {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            justify-content: center;
            position: relative;
            padding: 2rem;
            @include mq('tablet-small') {
                width: 100% !important;
                margin:0 auto;
                padding: 1rem 2rem;
            }
            .b-profile-hero & {
                padding-left:0;

                @include mq('tablet-small') {
                    padding-right: 0;
                }
            }
            & + & {
                flex: 0 0 280px;
                padding:0;
                overflow: hidden;
                width: 280px;
                min-height: 280px;
                @include mq('tablet-small') {
                    padding-top: 0;
                    min-height: auto;
                    flex: 1 1 auto;
                }
                .b-profile-hero & {
                    overflow: visible;
                    flex: 0 0 400px;
                    width: 400px;
                    padding:0;
                    min-height: 380px;

                    @include mq('tablet') {
                        flex: 1 0 auto;
                        width: auto;
                        min-height: 100px;
                    }
                }
            }
        }
        & .label.pointing{
            position: absolute;
            top:0;
            right:0;
            margin: 1rem 3rem 0 0;
            opacity: 0;
            transition: all 0.5s ease;
            z-index:1;
            pointer-events: none;;
        }
        &-buttons{
            display:block;
            overflow: hidden;
            margin-top:2.75rem; 
            animation: fadeUpTran 0.45s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.65s alternate backwards;  
            
            @include mq('tablet-small') {
                margin-bottom: 2rem;
                margin-top: 1.5rem;
                .button {
                    font-size: 1rem !important;
                    padding: .75rem 1.5rem;
                }
            }
        }
        h2{
            font-size: 1.75rem;
            animation: fadeUpTran 0.35s ease 0.35s alternate backwards;
            @include mq('tablet-small') {
                text-align: center;
            }
        }
        h3{
            padding-left:0;
            padding-top:1rem;
        }
        & {
            .header,
            .meta{
                @include mq('tablet-small') {
                    text-align: center; 
                
                }
            }
            .header h3,
            h4{
                padding-top:0rem;
                padding-bottom:0rem;
                margin-top: -1rem;
                color:$tertiary;
                padding-left:0;
                margin-bottom: 0.5rem;
                animation: fadeLeft 0.35s ease 0.6s alternate backwards;
                @include mq('tablet-small') {
                    text-align: center; 
                
                }
            }
            .label.circular{
                
                animation: fadeLeft 0.35s ease 0.65s alternate backwards;
            }

            
        }
        &-user{
            display: block;
            padding:0.5rem 0;
            margin-top:1rem;
            .ui.image.label{
                background: rgba($grey-300, 0.1);
                color: $white1;
                img{
                    margin-right: 0.5rem;
                }  
            }
        }
        .label{clear:both;}
        &-location{
            display: block;
            clear:both;
            margin-top:1rem;
            animation: fadeLeft 0.35s ease 0.70s alternate backwards;
            & i.icon{
                margin-right:0.25rem!important;
            }
            a{
                color:$grey-white;
                &:hover{
                    color:$grey-white;
                    text-decoration: underline;
                }
            }
            .b-profile-hero &{
                padding-top:1.25rem;
            }
        }
        h3 + &-location{
            margin-top:0;
        }
        .b-sticky-header &-location{
            margin-top:0;
        }
        .ui.card & .meta{
            color: $grey-white;
            display: block;
            .icon{
                color: $secondary;
            }
        }
        i.icon.camera{
            position: absolute;
            top:6rem;
            left:15rem;
            color: $primary;
            margin:0 1rem 1rem 0;
            border-radius: 100%;
            width: 1em;
            background:$white1;
            transition: transform 0.35s ease;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            z-index:1;
        }
        .label.pointing{
            margin: 3rem 0.5rem 0 0;
            max-width:190px;
            line-height: 1rem;
            &:before{
                left:170px;
            }
        }

        &:hover{
            i.icon.camera{
                transform: scale3d(1.1, 1.1, 1);
            } 
            .label.pointing{
                transition: all 0.35s ease 0.7s;
                opacity: 1;
                margin: 3.5rem 0.5rem 0 0;
                max-width:190px;
            } 
        }
        &-image{
            transform:scale3d(1.05, 1.05, 1);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            display: block;
            width: 100%;
            height: 100%;
            transition: transform 0.35s ease, filter 0.35s ease ;
           
            .company &.empty{
                filter: blur(10px);
                background-image: url('../images/avatar/tiny/company-blank.png');
            }
            .contact &.empty{
                filter: blur(10px);
                background-image: url('../images/avatar/tiny/user-blank.png');
            }
            .card__hero-link:hover &{
                transform:scale3d(1.1, 1.1, 1); 
                -webkit-filter: brightness(80%);
                filter: brightness(80%);
            }
 
        } 
        &-socials {
        
            height: 100%;
            position: absolute;
            right:1rem;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq('phone') {
                //height: 24rem;
                //width: 24rem;
                top:5.75rem;
                right:unset;
                margin:0 auto;
            
            }
            
            &-center{
                flex: 0 0 auto;
                flex-direction: column;
                display: flex;
                height: auto;
                width: 3.25rem;
                overflow: hidden;
                margin: 0 auto;
                background-color: rgba($primary-shade,0.65);
                border-radius: 3rem;
                text-align: center;
                padding:0.5rem 0 0.25rem 0;
                backdrop-filter:blur(2px);
                &:empty{
                    padding:0;
                }
                @include mq('phone') {
                    flex-direction: row;
                    backdrop-filter: none;
                    background:none;
                    width: auto;
                
                }
            }
            .icon.button{
                background:transparent;
                transition: color 0.35s ease;
                padding:0rem 0.75rem;
                border-radius: 0;
                height:2.5rem;
                width:3rem;
                line-height:2.125rem;
                flex: 0 0 2.5rem;
                display: block;
                color:$secondary;
                position: static!important;
                transform:none!important;
                &:hover{
                    color:lighten($secondary, 20%);
                }
            }
            i.icon{
                font-size:1.75rem;
                opacity: 1!important;
                position: static!important;
            }
            i.icons{
                display: inline;
                float:left;
                opacity: 1;
                .corner.icon{
                    font-size:0.875rem;
                    text-shadow: 
                        -2px -2px 0 $primary-shade, 
                        2px -2px 0 $primary-shade, 
                        -2px 2px 0 $primary-shade, 
                        2px 2px 0 $primary-shade
                    ;
                }
            }
        }  
        
        .event &{

            &-location{
                padding-top:0rem;
            }

        }   
    }
    & > .extra a:not(.ui){
        font-weight: $semibold;
    }
    
    .content{
        position: relative;
    }
}
@charset "UTF-8";
* {
  box-sizing: border-box;
}

/*fonts*/
/*Lato*/
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Lato-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/Lato-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Lato-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/Lato-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "icos";
  src: url("../fonts/icos.ttf") format("truetype"), url("../fonts/icos.woff") format("woff"), url("../fonts/icos.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/*semantic ui*/
@font-face {
  font-family: "icons";
  src: url(../fonts/icons.eot);
  src: url(../fonts/icons.eot?#iefix) format("embedded-opentype"), url(../fonts/icons.woff2) format("woff2"), url(../fonts/icons.woff) format("woff"), url(../fonts/icons.ttf) format("truetype"), url(../fonts/icons.svg#icons) format("svg");
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
@font-face {
  font-family: "outline-icons";
  src: url(../fonts/outline-icons.eot);
  src: url(../fonts/outline-icons.eot?#iefix) format("embedded-opentype"), url(../fonts/outline-icons.woff2) format("woff2"), url(../fonts/outline-icons.woff) format("woff"), url(../fonts/outline-icons.ttf) format("truetype"), url(../fonts/outline-icons.svg#icons) format("svg");
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
@font-face {
  font-family: "brand-icons";
  src: url(../fonts/brand-icons.eot);
  src: url(../fonts/brand-icons.eot?#iefix) format("embedded-opentype"), url(../fonts/brand-icons.woff2) format("woff2"), url(../fonts/brand-icons.woff) format("woff"), url(../fonts/brand-icons.ttf) format("truetype"), url(../fonts/brand-icons.svg#icons) format("svg");
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-decoration: inherit;
  text-transform: none;
}
@font-face {
  font-family: "b-icons";
  src: url("../fonts/b-icons.eot?yz6szo");
  src: url("../fonts/b-icons.eot?yz6szo#iefix") format("embedded-opentype"), url("../fonts/b-icons.ttf?yz6szo") format("truetype"), url("../fonts/b-icons.woff?yz6szo") format("woff"), url("../fonts/b-icons.svg?yz6szo#b-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
/*//////////// OPUS2 COLOURS ////////////////*/
/*20%*/
/*20%*/
/*20%*/
/*10%*/
/*10%*/
/* 5%*/
/*///////////////////////////////////////////*/
/*
$tertiary: #38e8b9;
$tertiary-text: #222;
$tertiary-tint: #78fee9;
$tertiary-tint-text: #222;
$tertiary-shade: #22b589;
$tertiary-shade-text: #fff;
*/
/*// NEW COLOURS END  //*/
/*grad mixins*/
/*strips*/
/*break points*/
/*sizes*/
/*height*/
/*trackbar*/
/*Lex logo Colours*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Lato, sans-serif;
  font-weight: 500;
  display: block;
  /*
  @include margin-trailer(1);
  @include margin-leader(1);
  */
}

/*
h1,
.h1{
    //@include type-scale(xxlarge);
}*/
h2,
.h2 {
  font-size: 1.25rem;
}

h3,
.h3 {
  font-size: 1.125rem;
  line-height: 1.6875rem;
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
h5.inline,
.h5.inline {
  display: inline-block;
  padding: 0 0.25rem;
  margin: 0.25rem;
}

h6,
.h6 {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.highlight--primary {
  background: #802480;
}

.highlight--secondary {
  background: #0bb5c4;
}

.highlight--tertiary {
  background: #e2ab3b;
}

p {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #212529;
}

/*Scheme Bg Colours*/
.blueSkin .bg--color-scheme {
  background-color: #2f6eea;
}
.aquaSkin .bg--color-scheme {
  background-color: #1b89b7;
}
.pinkSkin .bg--color-scheme {
  background-color: #c22fcb;
}
.purpleSkin .bg--color-scheme {
  background-color: #862cda;
}
.greenSkin .bg--color-scheme {
  background-color: #0c970f;
}
.turquoiseSkin .bg--color-scheme {
  background-color: #20988e;
}
.redSkin .bg--color-scheme {
  background-color: #da2c34;
}
.orangeSkin .bg--color-scheme {
  background-color: #fd8228;
}

/*/////////////////*/
/*Scheme Font Colours*/
.bg--color-white {
  background: #fafafa;
}
.bg--color-red {
  background: #f05151;
  color: #fff;
}
.bg--color-blue1 {
  background-color: #4285f4;
  color: #fff;
}
.bg--color-blue2 {
  background-color: #2f6eea;
  color: #fff;
}
.bg--color-blue3 {
  background-color: #295cc1;
  color: #fff;
}
.bg--color-blue4 {
  background-color: #1947a2;
  color: #fff;
}
.bg--color-aqua1 {
  background-color: #41c7f0;
  color: #fff;
}
.bg--color-aqua2 {
  background-color: #1b89b7;
  color: #fff;
}
.bg--color-aqua3 {
  background-color: #167da8;
  color: #fff;
}
.bg--color-aqua4 {
  background-color: #0d709a;
  color: #fff;
}
.bg--color-pink1 {
  background-color: #df3fe9;
  color: #fff;
}
.bg--color-pink2 {
  background-color: #c22fcb;
  color: #fff;
}
.bg--color-pink3 {
  background-color: #a219aa;
  color: #fff;
}
.bg--color-pink4 {
  background-color: #7a0c80;
  color: #fff;
}
.bg--color-purple1 {
  background-color: #9e50e7;
  color: #fff;
}
.bg--color-purple2 {
  background-color: #862cda;
  color: #fff;
}
.bg--color-purple3 {
  background-color: #6b23af;
  color: #fff;
}
.bg--color-purple4 {
  background-color: #4c0d87;
  color: #fff;
}
.bg--color-red1 {
  background-color: #f64a52;
  color: #fff;
}
.bg--color-red2 {
  background-color: #da2c34;
  color: #fff;
}
.bg--color-red3 {
  background-color: #b1161e;
  color: #fff;
}
.bg--color-red4 {
  background-color: #7e0b11;
  color: #fff;
}
.bg--color-orange1 {
  background-color: #ef8c43;
  color: #fff;
}
.bg--color-orange2 {
  background-color: #fd8228;
  color: #fff;
}
.bg--color-orange3 {
  background-color: #cd6203;
  color: #fff;
}
.bg--color-orange4 {
  background-color: #a75105;
  color: #fff;
}
.bg--color-green1 {
  background-color: #24b527;
  color: #fff;
}
.bg--color-green2 {
  background-color: #0c970f;
  color: #fff;
}
.bg--color-green3 {
  background-color: #0a800a;
  color: #fff;
}
.bg--color-green4 {
  background-color: #065e06;
  color: #fff;
}
.bg--color-turquoise1 {
  background-color: #28afa4;
  color: #fff;
}
.bg--color-turquoise2 {
  background-color: #20988e;
  color: #fff;
}
.bg--color-turquoise3 {
  background-color: #0c756c;
  color: #fff;
}
.bg--color-turquoise4 {
  background-color: #085952;
  color: #fff;
}
.bg--color-grey1 {
  background-color: #7b7d84;
  color: #fff;
}
.bg--color-grey2 {
  background-color: #4c5765;
  color: #fff;
}
.bg--color-grey3 {
  background-color: #3b4552;
  color: #fff;
}
.bg--color-grey4 {
  background-color: #2d3540;
  color: #fff;
}
.bg--color-grey--200 {
  background-color: #e8edf3;
  color: #333;
}
.bg--color-lightgrey1 {
  background-color: #f3f4f5;
  color: #333;
}
.bg--color-lightgrey2 {
  background-color: #e8edf3;
  color: #333;
}
.bg--color-lightgrey3 {
  background-color: #dee2e6;
  color: #333;
}
.bg--color-lightgrey4 {
  background-color: #ced4da;
  color: #333;
}
.bg--color-primary {
  background-color: #802480;
  color: #fff;
}
.bg--color-primary-10 {
  background: #a82fa8;
}
.bg--color-primary-20 {
  background: #c941c9;
}
.bg--color-primary-d10 {
  background: #581958;
}
.bg--color-primary-d20 {
  background: #300e30;
}
.bg--color-primary-shade {
  background-color: #572258;
  color: #fff;
}
.bg--color-primary-shade-10 {
  background: #7b307d;
}
.bg--color-primary-shade-20 {
  background: #a03ea2;
}
.bg--color-primary-shade-d10 {
  background: #331433;
}
.bg--color-primary-shade-d20 {
  background: #0e060e;
}
.bg--color-primary-tint {
  background-color: #a82fa8;
  color: #222;
}
.bg--color-primary-tint-10 {
  background: #c941c9;
}
.bg--color-primary-tint-20 {
  background: #d568d5;
}
.bg--color-primary-tint-d10 {
  background: #802480;
}
.bg--color-primary-tint-d20 {
  background: #581958;
}
.bg--color-secondary {
  background-color: #0bb5c4;
  color: #222;
}
.bg--color-secondary-10 {
  background: #10dff2;
}
.bg--color-secondary-20 {
  background: #41e6f4;
}
.bg--color-secondary-d10 {
  background: #088894;
}
.bg--color-secondary-d20 {
  background: #065c63;
}
.bg--color-secondary-shade {
  background-color: #07727c;
  color: #fff;
}
.bg--color-secondary-shade-10 {
  background: #0a9fac;
}
.bg--color-secondary-shade-20 {
  background: #0ccbdc;
}
.bg--color-secondary-shade-d10 {
  background: #04464b;
}
.bg--color-secondary-shade-d20 {
  background: #02191b;
}
.bg--color-secondary-tint {
  background-color: #10dff2;
  color: #222;
}
.bg--color-secondary-tint-10 {
  background: #41e6f4;
}
.bg--color-secondary-tint-20 {
  background: #71ecf7;
}
.bg--color-secondary-tint-d10 {
  background: #0bb5c4;
}
.bg--color-secondary-tint-d20 {
  background: #088894;
}
.bg--color-tertiary {
  background-color: #e2ab3b;
  color: #122635;
}
.bg--color-tertiary-10 {
  background: #e9be67;
}
.bg--color-tertiary-20 {
  background: #efd194;
}
.bg--color-tertiary-d10 {
  background: #cc931e;
}
.bg--color-tertiary-d20 {
  background: #9f7318;
}
.bg--color-tertiary-shade {
  background-color: #cc931e;
  color: #122635;
}
.bg--color-tertiary-shade-10 {
  background: #e2ab3b;
}
.bg--color-tertiary-shade-20 {
  background: #e9be67;
}
.bg--color-tertiary-shade-d10 {
  background: #9f7318;
}
.bg--color-tertiary-shade-d20 {
  background: #735311;
}
.bg--color-tertiary-tint {
  background-color: #e9be67;
  color: #122635;
}
.bg--color-tertiary-tint-10 {
  background: #efd194;
}
.bg--color-tertiary-tint-20 {
  background: #f6e4c0;
}
.bg--color-tertiary-tint-d10 {
  background: #e2ab3b;
}
.bg--color-tertiary-tint-d20 {
  background: #cc931e;
}
.dark-theme .bg--color-theme {
  background: #131619;
}
.light-theme .bg--color-theme {
  background: #f0f0f0;
}
.light-theme .bg--color-shade-10 {
  background: rgba(19, 22, 25, 0.1);
}
.light-theme .bg--color-shade-20 {
  background: rgba(19, 22, 25, 0.2);
}
.light-theme .bg--color-shade-30 {
  background: rgba(19, 22, 25, 0.3);
}
.light-theme .bg--color-shade-40 {
  background: rgba(19, 22, 25, 0.4);
}
.dark-theme .bg--color-shade-10 {
  background: rgba(240, 240, 240, 0.1);
}
.dark-theme .bg--color-shade-20 {
  background: rgba(240, 240, 240, 0.2);
}
.dark-theme .bg--color-shade-30 {
  background: rgba(240, 240, 240, 0.3);
}
.dark-theme .bg--color-shade-40 {
  background: rgba(240, 240, 240, 0.4);
}

.text--color-blue1, .blueSkin .text--color1 {
  color: #4285f4;
  fill: #2f6eea;
}
.text--color-blue2, .blueSkin .text--color2 {
  color: #2f6eea;
  fill: #2f6eea;
}
.text--color-blue3, .blueSkin .text--color3 {
  color: #295cc1;
  fill: #295cc1;
}
.text--color-blue4, .blueSkin .text--color4 {
  color: #1947a2;
  fill: #1947a2;
}
.text--color-aqua1, .aquaSkin .text--color1 {
  color: #41c7f0;
  fill: #1b89b7;
}
.text--color-aqua2, .aquaSkin .text--color2 {
  color: #1b89b7;
  fill: #1b89b7;
}
.text--color-aqua3, .aquaSkin .text--color3 {
  color: #167da8;
  fill: #167da8;
}
.text--color-aqua4, .aquaSkin .text--color4 {
  color: #0d709a;
  fill: #0d709a;
}
.text--color-pink1, .pinkSkin .text--color1 {
  color: #df3fe9;
  fill: #df3fe9;
}
.text--color-pink2, .pinkSkin .text--color2 {
  color: #c22fcb;
  fill: #c22fcb;
}
.text--color-pink3, .pinkSkin .text--color3 {
  color: #a219aa;
  fill: #a219aa;
}
.text--color-pink4, .pinkSkin .text--color4 {
  color: #7a0c80;
  fill: #7a0c80;
}
.text--color-purple1, .purpleSkin .text--color1 {
  color: #9e50e7;
  fill: #9e50e7;
}
.text--color-purple2, .purpleSkin .text--color2 {
  color: #862cda;
  fill: #862cda;
}
.text--color-purple3, .purpleSkin .text--color3 {
  color: #6b23af;
  fill: #6b23af;
}
.text--color-purple4, .purpleSkin .text--color4 {
  color: #4c0d87;
  fill: #4c0d87;
}
.text--color-green1, .greenSkin .text--color1 {
  color: #24b527;
  fill: #24b527;
}
.text--color-green2, .greenSkin .text--color2 {
  color: #0c970f;
  fill: #0c970f;
}
.text--color-green3, .greenSkin .text--color3 {
  color: #0a800a;
  fill: #0a800a;
}
.text--color-green4, .greenSkin .text--color4 {
  color: #065e06;
  fill: #065e06;
}
.text--color-turquoise1, .turquoiseSkin .text--color1 {
  color: #28afa4;
  fill: #28afa4;
}
.text--color-turquoise2, .turquoiseSkin .text--color2 {
  color: #20988e;
  fill: #20988e;
}
.text--color-turquoise3, .turquoiseSkin .text--color3 {
  color: #0c756c;
  fill: #0c756c;
}
.text--color-turquoise4, .turquoiseSkin .text--color4 {
  color: #085952;
  fill: #085952;
}
.text--color-red1, .redSkin .text--color1 {
  color: #f64a52;
  fill: #f64a52;
}
.text--color-red2, .redSkin .text--color2 {
  color: #da2c34;
  fill: #da2c34;
}
.text--color-red3, .redSkin .text--color3 {
  color: #b1161e;
  fill: #b1161e;
}
.text--color-red4, .redSkin .text--color4 {
  color: #7e0b11;
  fill: #7e0b11;
}
.text--color-orange1, .orangeSkin .text--color1 {
  color: #ef8c43;
  fill: #ef8c43;
}
.text--color-orange2, .orangeSkin .text--color2 {
  color: #fd8228;
  fill: #fd8228;
}
.text--color-orange3, .orangeSkin .text--color3 {
  color: #cd6203;
  fill: #cd6203;
}
.text--color-orange4, .orangeSkin .text--color4 {
  color: #a75105;
  fill: #a75105;
}
.text--color-grey1 {
  color: #7b7d84;
  fill: #7b7d84;
}
.text--color-grey2 {
  color: #4c5765;
  fill: #4c5765;
}
.text--color-grey3 {
  color: #3b4552;
  fill: #3b4552;
}
.text--color-grey4 {
  color: #2d3540;
  fill: #2d3540;
}
.text--color-white {
  color: #fff;
  fill: #fff;
}
.text--color-black {
  color: #000;
  fill: #000;
}
.text--color-primary {
  color: #802480;
  fill: #802480;
}
.text--color-primary-shade {
  color: #572258;
  fill: #572258;
}
.text--color-secondary {
  color: #0bb5c4;
  fill: #0bb5c4;
}

.border--color-blue1, .blueSkin .border--color1 {
  border-color: #4285f4;
}
.border--color-blue2, .blueSkin .border--color2 {
  border-color: #2f6eea;
}
.border--color-blue3, .blueSkin .border--color3 {
  border-color: #295cc1;
}
.border--color-blue4, .blueSkin .border--color4 {
  border-color: #1947a2;
}
.border--color-aqua1, .aquaSkin .border--color1 {
  border-color: #41c7f0;
}
.border--color-aqua2, .aquaSkin .border--color2 {
  border-color: #1b89b7;
}
.border--color-aqua3, .aquaSkin .border--color3 {
  border-color: #167da8;
}
.border--color-aqua4, .aquaSkin .border--color4 {
  border-color: #0d709a;
}
.border--color-pink1, .pinkSkin .border--color1 {
  border-color: #df3fe9;
}
.border--color-pink2, .pinkSkin .border--color2 {
  border-color: #c22fcb;
}
.border--color-pink3, .pinkSkin .border--color3 {
  border-color: #a219aa;
}
.border--color-pink4, .pinkSkin .border--color4 {
  border-color: #7a0c80;
}
.border--color-purple1, .purpleSkin .border--color1 {
  border-color: #9e50e7;
}
.border--color-purple2, .purpleSkin .border--color2 {
  border-color: #862cda;
}
.border--color-purple3, .purpleSkin .border--color3 {
  border-color: #6b23af;
}
.border--color-purple4, .purpleSkin .border--color4 {
  border-color: #4c0d87;
}
.border--color-green1, .greenSkin .border--color1 {
  border-color: #24b527;
}
.border--color-green2, .greenSkin .border--color2 {
  border-color: #0c970f;
}
.border--color-green3, .greenSkin .border--color3 {
  border-color: #0a800a;
}
.border--color-green4, .greenSkin .border--color4 {
  border-color: #065e06;
}
.border--color-turquoise1, .turquoiseSkin .border--color1 {
  border-color: #28afa4;
}
.border--color-turquoise2, .turquoiseSkin .border--color2 {
  border-color: #20988e;
}
.border--color-turquoise3, .turquoiseSkin .border--color3 {
  border-color: #0c756c;
}
.border--color-turquoise4, .turquoiseSkin .border--color4 {
  border-color: #085952;
}
.border--color-red1, .redSkin .border--color1 {
  border-color: #f64a52;
}
.border--color-red2, .redSkin .border--color2 {
  border-color: #da2c34;
}
.border--color-red3, .redSkin .border--color3 {
  border-color: #b1161e;
}
.border--color-red4, .redSkin .border--color4 {
  border-color: #7e0b11;
}
.border--color-orange1, .orangeSkin .border--color1 {
  border-color: #ef8c43;
}
.border--color-orange2, .orangeSkin .border--color2 {
  border-color: #fd8228;
}
.border--color-orange3, .orangeSkin .border--color3 {
  border-color: #cd6203;
}
.border--color-orange4, .orangeSkin .border--color4 {
  border-color: #a75105;
}
.border--color-grey1 {
  border-color: #7b7d84;
}
.border--color-grey2 {
  border-color: #4c5765;
}
.border--color-grey3 {
  border-color: #3b4552;
}
.border--color-grey4 {
  border-color: #2d3540;
}
body .border--color-primary {
  border-color: #802480;
}
body .border--color-secondary {
  border-color: #0bb5c4;
}
body .border--color-tertiary {
  border-color: #e2ab3b;
}

/*grads*/
.blueSkin .bg--color-grad-scheme, body .bg--color-blue-grad, .blueSkin .bg--color-grad-scheme .popupCon, body .bg--color-blue-grad .popupCon {
  background: #2f6eea; /* The Fallback */
  background: linear-gradient(45deg, #33afe2 0%, #2f6eea 100%);
}
.aquaSkin .bg--color-grad-scheme, body .bg--color-aqua-grad, .aquaSkin .bg--color-grad-scheme .popupCon, body .bg--color-aqua-grad .popupCon {
  background: #1b89b7; /* The Fallback */
  background: linear-gradient(45deg, #2f9dea 0%, #1b89b7 100%);
}

.purpleSkin .bg--color-grad-scheme,
body .bg--color-purple-grad,
.purpleSkin .bg--color-grad-scheme .popupCon,
body .bg--color-purple-grad .popupCon {
  background: #862cda; /* The Fallback */
  background: linear-gradient(45deg, #4c2ac9 0%, #862cda 100%);
}

.pinkSkin .bg--color-grad-scheme,
body .bg--color-pink-grad,
.pinkSkin .bg--color-grad-scheme .popupCon,
body .bg--color-pink-grad .popupCon {
  background: #c22fcb; /* The Fallback */
  background: linear-gradient(45deg, #d33255 0%, #c22fcb 100%);
}

.greenSkin .bg--color-grad-scheme,
body .bg--color-green-grad,
.greenSkin .bg--color-grad-scheme .popupCon,
body .bg--color-green-grad .popupCon {
  background: #0c970f;
  background: linear-gradient(45deg, #1f8db3 0%, #0c970f 100%);
}

.turquoiseSkin .bg--color-grad-scheme,
body .bg--color-turquoise-grad,
.turquoiseSkin .bg--color-grad-scheme .popupCon,
body .bg--color-turquoise-grad .popupCon {
  background: #20988e; /* The Fallback */
  background: linear-gradient(45deg, #419b22 0%, #20988e 100%);
}

.redSkin .bg-color--grad-scheme,
body .bg--color-red-grad,
.redSkin .bg--color-grad-scheme .popupCon,
body .bg--color-red-grad .popupCon {
  background: #da2c34; /* The Fallback */
  background: linear-gradient(45deg, #f98d27 1%, #da2c34 100%);
}

.orangeSkin .bg--color-grad-scheme,
body .bg--color-orange-grad,
.orangeSkin .bg--color-grad-scheme .popupCon,
body .bg--color-orange-grad .popupCon {
  background: #fd8228; /* The Fallback */
  background: linear-gradient(45deg, #dc3034 0%, #fd8228 100%);
}

.bg--color-red-flash {
  background: linear-gradient(128deg, #da2c34, #ef8c43);
  background-size: 400% 400%;
  animation: flashBackground 2.5s ease infinite;
}

.bg--none,
.b-bg--none {
  background: none !important;
}
.bg--none.segment,
.b-bg--none.segment {
  box-shadow: none !important;
}

.svg-stroke--blue1 {
  stroke: #4285f4;
}
.pinkSkin .svg-stroke, .svg-stroke--pink1 {
  stroke: #df3fe9;
}
.purpleSkin .svg-stroke, .svg-stroke--purple1 {
  stroke: #9e50e7;
}
.orangeSkin .svg-stroke, .svg-stroke--orange1 {
  stroke: #ef8c43;
}
.redSkin .svg-stroke, .svg-stroke--red1 {
  stroke: #f64a52;
}
.turquoiseSkin .svg-stroke, .svg-stroke--turquoise1 {
  stroke: #28afa4;
}
.greenSkin .svg-stroke, .svg-stroke--green1 {
  stroke: #24b527;
}

.link-button {
  font-size: 16px;
  line-height: 32px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 16px;
  border-radius: 24px;
  margin: 0 auto 0 auto;
  display: inline-block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  transition: background ease 0.2s;
  width: auto;
}
.link.underline {
  text-decoration: underline;
}
.link.primary {
  color: #802480;
  transition: all 0.35s ease;
  text-decoration-color: rgba(128, 36, 128, 0.25);
}
a:hover .link.primary, .link.primary:hover {
  color: #581958;
  text-decoration-color: rgba(128, 36, 128, 0.5);
}
.link.secondary {
  color: #088894;
  transition: all 0.35s ease;
  text-decoration-color: rgba(11, 181, 196, 0.25);
}
a:hover .link.secondary, .link.secondary:hover {
  color: #065c63;
  text-decoration-color: rgba(11, 181, 196, 0.5);
}
.link--disabled {
  pointer-events: none;
  cursor: default;
}
.blueSkin .link--style1 {
  background-color: #2f6eea;
  border-color: #2f6eea;
}
.aquaSkin .link--style1 {
  background-color: #1b89b7;
  border-color: #1b89b7;
}
.pinkSkin .link--style1 {
  background-color: #c22fcb;
  border-color: #c22fcb;
}
.purpleSkin .link--style1 {
  background-color: #862cda;
  border-color: #862cda;
}
.greenSkin .link--style1 {
  background-color: #0c970f;
  border-color: #0c970f;
}
.turquoiseSkin .link--style1 {
  background-color: #20988e;
  border-color: #20988e;
}
.redSkin .link--style1 {
  background-color: #da2c34;
  border-color: #da2c34;
}
.orangeSkin .link--style1 {
  background-color: #fd8228;
  border-color: #fd8228;
}
.blueSkin a:hover .link--style1, .blueSkin .link--style1:hover {
  background-color: #295cc1;
  border-color: #295cc1;
}
.aquaSkin a:hover .link--style1, .aquaSkin .link--style1:hover {
  background-color: #167da8;
  border-color: #167da8;
}
.pinkSkin a:hover .link--style1, .pinkSkin .link--style1:hover {
  background-color: #a219aa;
  border-color: #a219aa;
}
.purpleSkin a:hover .link--style1, .purpleSkin .link--style1:hover {
  background-color: #6b23af;
  border-color: #6b23af;
}
.greenSkin a:hover .link--style1, .greenSkin .link--style1:hover {
  background-color: #0a800a;
  border-color: #0a800a;
}
.turquoiseSkin a:hover .link--style1, .turquoiseSkin .link--style1:hover {
  background-color: #0c756c;
  border-color: #0c756c;
}
.redSkin a:hover .link--style1, .redSkin .link--style1:hover {
  background-color: #b1161e;
  border-color: #b1161e;
}
.orangeSkin a:hover .link--style1, .orangeSkin .link--style1:hover {
  background-color: #cd6203;
  border-color: #cd6203;
}
.blueSkin .link--style2 {
  background-color: #1947a2;
  border-color: #1947a2;
}
.aquaSkin .link--style2 {
  background-color: #0d709a;
  border-color: #0d709a;
}
.pinkSkin .link--style2 {
  background-color: #7a0c80;
  border-color: #7a0c80;
}
.purpleSkin .link--style2 {
  background-color: #4c0d87;
  border-color: #4c0d87;
}
.greenSkin .link--style2 {
  background-color: #065e06;
  border-color: #065e06;
}
.turquoiseSkin .link--style2 {
  background-color: #085952;
  border-color: #085952;
}
.redSkin .link--style2 {
  background-color: #7e0b11;
  border-color: #7e0b11;
}
.orangeSkin .link--style2 {
  background-color: #a75105;
  border-color: #a75105;
}
.blueSkin a:hover .link--style2, .blueSkin .link--style2:hover {
  background-color: #295cc1;
  border-color: #295cc1;
}
.aquaSkin a:hover .link--style2, .aquaSkin .link--style2:hover {
  background-color: #167da8;
  border-color: #167da8;
}
.pinkSkin a:hover .link--style2, .pinkSkin .link--style2:hover {
  background-color: #a219aa;
  border-color: #a219aa;
}
.purpleSkin a:hover .link--style2, .purpleSkin .link--style2:hover {
  background-color: #6b23af;
  border-color: #6b23af;
}
.greenSkin a:hover .link--style2, .greenSkin .link--style2:hover {
  background-color: #0a800a;
  border-color: #0a800a;
}
.turquoiseSkin a:hover .link--style2, .turquoiseSkin .link--style2:hover {
  background-color: #0c756c;
  border-color: #0c756c;
}
.redSkin a:hover .link--style2, .redSkin .link--style2:hover {
  background-color: #b1161e;
  border-color: #b1161e;
}
.orangeSkin a:hover .link--style2, .orangeSkin .link--style2:hover {
  background-color: #cd6203;
  border-color: #cd6203;
}
.link--style3 {
  background: transparent;
}
.blueSkin a:hover .link--style3, .blueSkin .link--style3:hover {
  background-color: #2f6eea;
  border-color: #2f6eea;
}
.aquaSkin a:hover .link--style3, .aquaSkin .link--style3:hover {
  background-color: #1b89b7;
  border-color: #1b89b7;
}
.pinkSkin a:hover .link--style3, .pinkSkin .link--style3:hover {
  background-color: #c22fcb;
  border-color: #c22fcb;
}
.purpleSkin a:hover .link--style3, .purpleSkin .link--style3:hover {
  background-color: #862cda;
  border-color: #862cda;
}
.greenSkin a:hover .link--style3, .greenSkin .link--style3:hover {
  background-color: #0c970f;
  border-color: #0c970f;
}
.turquoiseSkin a:hover .link--style3, .turquoiseSkin .link--style3:hover {
  background-color: #20988e;
  border-color: #20988e;
}
.redSkin a:hover .link--style3, .redSkin .link--style3:hover {
  background-color: #da2c34;
  border-color: #da2c34;
}
.orangeSkin a:hover .link--style3, .orangeSkin .link--style3:hover {
  background-color: #fd8228;
  border-color: #fd8228;
}
.link--style4 {
  background: rgba(255, 255, 255, 0.1);
}
.blueSkin a:hover .link--style4, .blueSkin .link--style4:hover {
  background-color: #2f6eea;
  border-color: #2f6eea;
}
.aquaSkin a:hover .link--style4, .aquaSkin .link--style4:hover {
  background-color: #1b89b7;
  border-color: #1b89b7;
}
.pinkSkin a:hover .link--style4, .pinkSkin .link--style4:hover {
  background-color: #c22fcb;
  border-color: #c22fcb;
}
.purpleSkin a:hover .link--style4, .purpleSkin .link--style4:hover {
  background-color: #862cda;
  border-color: #862cda;
}
.greenSkin a:hover .link--style4, .greenSkin .link--style4:hover {
  background-color: #0c970f;
  border-color: #0c970f;
}
.turquoiseSkin a:hover .link--style4, .turquoiseSkin .link--style4:hover {
  background-color: #20988e;
  border-color: #20988e;
}
.redSkin a:hover .link--style4, .redSkin .link--style4:hover {
  background-color: #da2c34;
  border-color: #da2c34;
}
.orangeSkin a:hover .link--style4, .orangeSkin .link--style4:hover {
  background-color: #fd8228;
  border-color: #fd8228;
}
.link--blue {
  background: #4285f4;
}
a:hover .link--blue, .link--blue:hover {
  background: #2f6eea;
}
.link--red {
  background: #f64a52;
}
a:hover .link--red, .link--red:hover {
  background: #da2c34;
}

/*end abstracts*/
body {
  color: #000;
  font-family: Lato, sans-serif;
  line-height: 20px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
}

form {
  display: unset;
}

input, textarea {
  /*clear safari settings*/
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.icos {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icos" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icos-thumbs-up:before {
  content: "\f087";
}

.icos-thumbs-down:before {
  content: "\f088";
}

.icos-happy-face:before {
  content: "\e90e";
}

.icos-sad-face:before {
  content: "\e90f";
}

.icos-microsoft:before {
  content: "\e909";
}

.icos-network:before {
  content: "\e90a";
}

.icos-handshake:before {
  content: "\e908";
}

.icos-external-link:before {
  content: "\e907";
}

.icos-crown:before {
  content: "\e906";
}

.icos-sharefile:before {
  content: "\e904";
}

.icos-barrister:before {
  content: "\e900";
}

.icos-dx:before {
  content: "\e901";
}

.icos-fax:before {
  content: "\e902";
}

.icos-route:before {
  content: "\e903";
}

.icos-case:before {
  content: "a";
}

.icos-calendar:before {
  content: "b";
}

.icos-gear:before {
  content: "c";
}

.icos-chat:before {
  content: "d";
}

.icos-dashboard:before {
  content: "e";
}

.icos-bell:before {
  content: "f";
}

.icos-history:before {
  content: "g";
}

.icos-pencil:before {
  content: "h";
}

.icos-attention:before {
  content: "i";
}

.icos-filter:before {
  content: "j";
}

.icos-search:before {
  content: "l";
}

.icos-save:before {
  content: "m";
}

.icos-add:before {
  content: "n";
}

.icos-remove-1:before {
  content: "o";
}

.icos-menu:before {
  content: "p";
}

.icos-more-h:before {
  content: "t";
}

.icos-more-v:before {
  content: "u";
}

.icos-file:before {
  content: "v";
}

.icos-bullhorn:before {
  content: "w";
}

.icos-list:before {
  content: "x";
}

.icos-chart:before {
  content: "y";
}

.icos-list-ul:before {
  content: "z";
}

.icos-location-arrow:before {
  content: "A";
}

.icos-paper-plane:before {
  content: "B";
}

.icos-pie-chart:before {
  content: "C";
}

.icos-check:before {
  content: "D";
}

.icos-watch:before {
  content: "E";
}

.icos-credit-card:before {
  content: "F";
}

.icos-ios-paper:before {
  content: "H";
}

.icos-note:before {
  content: "I";
}

.icos-folder-open-o:before {
  content: "J";
}

.icos-calendar-check-o:before {
  content: "K";
}

.icos-calendar-1:before {
  content: "L";
}

.icos-gavel:before {
  content: "M";
}

.icos-envelope:before {
  content: "N";
}

.icos-question:before {
  content: "O";
}

.icos-refresh:before {
  content: "P";
}

.icos-commenting:before {
  content: "Q";
}

.icos-phone:before {
  content: "R";
}

.icos-map-marker:before {
  content: "S";
}

.icos-chevron-left:before {
  content: "T";
}

.icos-chevron-right:before {
  content: "U";
}

.icos-chevron-down:before {
  content: "V";
}

.icos-chevron-up:before {
  content: "W";
}

.icos-angle-left:before {
  content: "Y";
}

.icos-angle-right:before {
  content: "Z";
}

.icos-angle-up:before {
  content: "0";
}

.icos-angle-double-left:before {
  content: "1";
}

.icos-angle-double-right:before {
  content: "2";
}

.icos-angle-double-up:before {
  content: "3";
}

.icos-angle-double-down:before {
  content: "4";
}

.icos-cross:before {
  content: "5";
}

.icos-remove:before {
  content: "k";
}

.icos-telephone:before {
  content: "7";
}

.icos-plus-circle:before {
  content: "9";
}

.icos-times-circle:before {
  content: "!";
}

.icos-add-email:before {
  content: '"';
}

.icos-arrow-down:before {
  content: "$";
}

.icos-arrow-left:before {
  content: "%";
}

.icos-arrow-right:before {
  content: "&";
}

.icos-arrow-up:before {
  content: "'";
}

.icos-print-1:before {
  content: ")";
}

.icos-twitter:before {
  content: "*";
}

.icos-linkedin:before {
  content: "+";
}

.icos-at:before {
  content: ",";
}

.icos-help:before {
  content: "-";
}

.icos-web:before {
  content: ".";
}

.icos-share-alt:before {
  content: "/";
}

.icos-home:before {
  content: ":";
}

.icos-star-o:before {
  content: ";";
}

.icos-star:before {
  content: "<";
}

.icos-comments:before {
  content: "=";
}

.icos-banknote:before {
  content: ">";
}

.icos-screen-max:before {
  content: "X";
}

.icos-screen-min:before {
  content: "?";
}

.icos-tick:before {
  content: "@";
}

.icos-lock:before {
  content: "[";
}

.icos-load-c:before {
  content: "]";
}

.icos-angle-down:before {
  content: "^";
}

.icos-selectall:before {
  content: "_";
}

.icos-deselectall:before {
  content: "`";
}

.icos-clone:before {
  content: "{";
}

.icos-flag:before {
  content: "|";
}

.icos-bin:before {
  content: "}";
}

.icos-sync:before {
  content: "~";
}

.icos-android-hand:before {
  content: "\\";
}

.icos-upload:before {
  content: "\e000";
}

.icos-reply:before {
  content: "\e001";
}

.icos-reply-all:before {
  content: "\e002";
}

.icos-forward:before {
  content: "\e003";
}

.icos-play:before {
  content: "\e004";
}

.icos-pause:before {
  content: "\e005";
}

.icos-doc:before {
  content: "\e007";
}

.icos-download:before {
  content: "\e008";
}

.icos-building:before {
  content: "\e009";
}

.icos-learn:before {
  content: "\e00a";
}

.icos-angle-right-end:before {
  content: "\e00b";
}

.icos-angle-left-end:before {
  content: "\e00c";
}

.icos-powerpoint:before {
  content: "\e00d";
}

.icos-exclamation:before {
  content: "\e00f";
}

.icos-tip:before {
  content: "\e00e";
}

.icos-sign-out:before {
  content: "\e010";
}

.icos-diary:before {
  content: "\e011";
}

.icos-diary1:before {
  content: "\e012";
}

.icos-user:before {
  content: "G";
}

.icos-users:before {
  content: "\e013";
}

.icos-settings:before {
  content: "\e014";
}

.icos-screen:before {
  content: "q";
}

.icos-outlook:before {
  content: "r";
}

.icos-pdf:before {
  content: "\e006";
}

.icos-outlook-cal:before {
  content: "s";
}

.icos-address-book:before {
  content: "\e015";
}

.icos-copy:before {
  content: "\e016";
}

.icos-rss:before {
  content: "\e017";
}

.icos-pin:before {
  content: "\e018";
}

.icos-repeat:before {
  content: "\e019";
}

.icos-excel:before {
  content: "8";
}

.icos-brief:before {
  content: "6";
}

.icos-priority:before {
  content: "(";
}

.icos-link:before {
  content: "\e01a";
}

.icos-video:before {
  content: "\e01b";
}

.icos-filter-settings:before {
  content: "\e01d";
}

.icos-archive:before {
  content: "\e01e";
}

.icos-shred:before {
  content: "\e01f";
}

.icos-return:before {
  content: "\e020";
}

.icos-filter-menu:before {
  content: "\e01c";
}

.icos-stack:before {
  content: "\e021";
}

.icos-inbox:before {
  content: "\e022";
}

.icos-draft:before {
  content: "\e023";
}

.icos-xero:before {
  content: "\e024";
}

.icos-sage:before {
  content: "\e025";
}

.icos-quickbooks:before {
  content: "\e026";
}

.icos-myob:before {
  content: "\e027";
}

.icos-msteams:before {
  content: "\e90c";
}

.icos-mobile:before {
  content: "\e905";
}

.icos-palette:before {
  content: "\e40a";
}

.icos-clock:before {
  content: "\e924";
}

.icos-camera:before {
  content: "\f03d";
}

.icos-folder:before {
  content: "\e92f";
}

.icos-folder-open:before {
  content: "\e930";
}

.icos-eye:before {
  content: "\e9ce";
}

.icos-sensitive:before {
  content: "\e9d1";
}

.icos-share-ios:before {
  content: "\e90b";
}

.icos-sharepoint:before {
  content: "\e90d";
}

.icos-hyperlaw:before {
  content: "\e910";
}

/*font icoss*/
.icos-stack {
  position: relative;
}
.icos-stack .icos--large + .icos--xxsmall {
  position: absolute;
  left: 1.625rem;
  top: 1.25rem;
}

.icos-quoted::before {
  font-family: "Open Sans", sans-serif !important;
  content: "£";
}

.icos-email::before {
  content: "N";
}

.icos-email-sent::before {
  content: "B";
}

.icos.icos-number,
.icos.icos-currency {
  font-family: Lato, sans-serif !important;
}

/*custom svg icoss - with shades*/
[class^=icos-svg]:before,
[class*=" icos-svg"]:before {
  display: block;
  width: 40px;
  height: 40px;
  background-size: 36px 36px;
  content: " ";
  transform: translateZ(0);
  background-repeat: no-repeat;
  background-position: center center;
}

/*
.icos-svg-diary:before {
    background-image: url(../d-svg/diary-31.svg);
}

.icos-svg-diary-av:before {
    background-image: url(../d-svg/diary-av.svg);
}

.icos-svg-users:before {
    background-image: url(../d-svg/users.svg);
}

.icos-svg-map-marker:before {
    background-image: url(../d-svg/map-marker.svg);
}*/
/*///////////////*/
.icos {
  font-size: 20px;
  line-height: 40px;
  display: inline-block;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  text-align: center;
  margin: 0 auto 0 auto;
  padding: 0;
}
.icos--xxlarge, .icos.xxlarge, .icos-button.xxlarge, .icos-button.xxlarge > .icos {
  border-radius: 80rem;
  width: 5rem;
  height: 5rem;
  font-size: 3.5rem;
  line-height: 5rem;
}
.icos--xxlarge::before, .icos.xxlarge::before, .icos-button.xxlarge::before, .icos-button.xxlarge > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--xxxlarge, .icos.xxxlarge .icos-button.xxxlarge, .icos-button.xxxlarge > .icos {
  border-radius: 80rem;
  width: 12rem;
  height: 8rem;
  font-size: 5rem;
  line-height: 8rem;
}
.icos--xxxlarge::before, .icos.xxxlarge .icos-button.xxxlarge::before, .icos-button.xxxlarge > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--xlarge, .icos.xlarge, .icos-button.xlarge, .icos-button.xlarge > icos {
  border-radius: 3rem;
  width: 3rem;
  height: 3rem;
  font-size: 36px;
  line-height: 3rem;
}
.icos--xlarge::before, .icos.xlarge::before, .icos-button.xlarge::before, .icos-button.xlarge > icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--large, .icos.large, .icos-button.large, .icos-button.large > .icos {
  border-radius: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 26px;
  line-height: 2.5rem;
}
.icos--large::before, .icos.large::before, .icos-button.large::before, .icos-button.large > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--medium, .icos.medium, .icos-button.medium, .icos-button.medium > .icos {
  border-radius: 2.125rem;
  width: 2.125rem;
  height: 2.125rem;
  font-size: 20px;
  line-height: 2.125rem;
}
.icos--medium::before, .icos.medium::before, .icos-button.medium::before, .icos-button.medium > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--small, .icos.small, .icos-button.small, .icos-button.small > .icos {
  border-radius: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  font-size: 16px;
  line-height: 1.75rem;
}
.icos--small::before, .icos.small::before, .icos-button.small::before, .icos-button.small > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--xsmall, .icos.xsmall, .icos-button.xsmall, .icos-button.xsmall > .icos {
  border-radius: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 14px;
  line-height: 1.5rem;
}
.icos--xsmall::before, .icos.xsmall::before, .icos-button.xsmall::before, .icos-button.xsmall > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--xxsmall, .icos.xxsmall, .icos-button.xxsmall, .icos-button.xxsmall > .icos {
  border-radius: 1.125rem;
  width: 1.125rem;
  height: 1.125rem;
  font-size: 10px;
  line-height: 1.125rem;
}
.icos--xxsmall::before, .icos.xxsmall::before, .icos-button.xxsmall::before, .icos-button.xxsmall > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos--xxxsmall, .icos.xxxsmall, .icos-button.xxxsmall, .icos-button.xxxsmall > .icos {
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  font-size: 10px;
  line-height: 1rem;
}
.icos--xxxsmall::before, .icos.xxxsmall::before, .icos-button.xxxsmall::before, .icos-button.xxxsmall > .icos::before {
  font-size: inherit;
  line-height: inherit;
}
.icos__img {
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 0;
  margin: 0;
  border-radius: 40px;
  position: absolute;
  display: block;
  background-size: cover;
  background-position: center center;
}
.icos__group {
  height: auto;
  float: none;
}
.icos__group.align-left {
  float: left;
  display: inline;
}
.icos__group.align-right {
  float: right;
  display: inline;
}
.icos__group > .icos, .icos__group > .icos-button {
  margin: 0 0.125rem 0 0;
}
.icos.inline {
  display: inline-block;
}
.icos.inline.icos--small {
  width: 1rem;
  height: 1rem;
  line-height: normal;
}
.icos.right {
  display: inline;
  float: right;
}
.icos.left {
  display: inline;
  float: left;
}
.icos.border--white {
  border: 1px solid #fff;
}
.icos.border--black {
  border: 1px solid #000;
}
.icos.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.45);
  position: static;
}

.icos-button {
  width: 40px;
  height: 40px;
  transition: all ease 0.4s;
  border-radius: 40px;
  shape-rendering: crispEdges;
  text-align: center;
  line-height: 40px;
  background-image: none;
  text-decoration: none;
  display: inline;
  float: left;
  position: relative;
  cursor: pointer;
  padding: 0 0;
}
.icos-button .icos {
  pointer-events: none;
  color: #fff;
  display: block;
}
.dark-theme .icos-button .icos {
  color: rgba(255, 255, 255, 0.8);
}
.light-theme .icos-button .icos {
  color: rgba(17, 17, 17, 0.8);
}
body .icos-button .icos.dark {
  color: rgba(17, 17, 17, 0.8);
}
body .icos-button .icos.light {
  color: rgba(255, 255, 255, 0.8);
}
.icos-button .icos-button:hover .icos-button .icos {
  color: #fff !important;
}
body .icos-button.icos-button--style2 {
  background: transparent;
}
body .icos-button.icos-button--style2.primary:hover, body .icos-button.icos-button--style2.primary:focus, body .icos-button.icos-button--style2.primary:active {
  background-color: #581958;
}
body .icos-button.icos-button--style2.secondary:hover, body .icos-button.icos-button--style2.secondary:focus, body .icos-button.icos-button--style2.secondary:active {
  background-color: #088894;
}
body .icos-button.icos-button--style2.tertiary:hover, body .icos-button.icos-button--style2.tertiary:focus, body .icos-button.icos-button--style2.tertiary:active {
  background-color: #cc931e;
}
body .icos-button.icos-button--style2.white:hover, body .icos-button.icos-button--style2.white:focus, body .icos-button.icos-button--style2.white:active {
  background-color: rgba(255, 255, 255, 0.3);
}
body .icos-button.icos-button--style3 {
  background: transparent;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  padding: 0;
}
body .icos-button.icos-button--style3 .icos {
  color: inherit;
}
body .icos-button.icos-button--style3 .icos::before {
  padding-right: 0px;
  color: inherit;
}
.dark-theme .icos-button.icos-button--style3 {
  color: rgba(255, 255, 255, 0.8);
}
.light-theme .icos-button.icos-button--style3 {
  color: rgba(17, 17, 17, 0.8);
}
.dark-theme .icos-button.icos-button--style3 {
  border-color: rgba(255, 255, 255, 0.6);
}
.light-theme .icos-button.icos-button--style3 {
  border-color: rgba(17, 17, 17, 0.6);
}
body .icos-button.icos-button--style3:hover {
  color: #fff !important;
  border-color: transparent;
}

.icos-small {
  position: absolute;
  margin: -20px 0 0 0px !important;
  width: 20px;
  height: 20px;
  line-height: 24px !important;
  font-size: 12px;
}

.icos-button.icos--push-left {
  margin-right: 16px;
}

.icos-black {
  color: #000;
}

.icos-white {
  color: #fff;
}

.icos-green {
  color: #0c970f;
}

.icos-orange {
  color: #fd8228;
}

.icos-red {
  color: #da2c34;
}

.icos-button .icos-paper-plane {
  font-size: 16px;
  padding-right: 3px;
}

.icos-button {
  background-color: #1b89b7;
}
.icos-button:hover, .icos-button:active, .icos-button:focus {
  outline: none;
  background-color: #167da8;
}
.icos-button.icos-button--red {
  background-color: #da2c34;
}
.icos-button.icos-button--red:hover, .icos-button.icos-button--red:active, .icos-button.icos-button--red:focus {
  background-color: #b1161e;
}
.icos-button.icos-button--red:hover .icos, .icos-button.icos-button--red:active .icos, .icos-button.icos-button--red:focus .icos {
  color: #fff !important;
}
.icos-button.icos-button--green {
  background-color: #24b527;
}
.icos-button.icos-button--green:hover, .icos-button.icos-button--green:active, .icos-button.icos-button--green:focus {
  color: #fff !important;
  background-color: #0a800a;
}
.icos-button.icos-button--pink {
  background-color: #a219aa;
}
.icos-button.icos-button--pink:hover, .icos-button.icos-button--pink:active, .icos-button.icos-button--pink:focus {
  background-color: #7a0c80;
}
.icos-button.icos-button--blue {
  background-color: #295cc1;
}
.icos-button.icos-button--blue:hover, .icos-button.icos-button--blue:active, .icos-button.icos-button--blue:focus {
  background-color: #1947a2;
}
.icos-button.icos-button--green {
  background-color: #0a800a;
}
.icos-button.icos-button--green:hover, .icos-button.icos-button--green:active, .icos-button.icos-button--green:focus {
  background-color: #065e06;
}
.icos-button.icos-button--off, .icos-button.icos-button--off:hover, .icos-button.icos-button--off:active, .icos-button.icos-button--off:focus {
  background: #afacac;
  pointer-events: none;
}
.icos-button.icos-button--primary, .icos-button.primary {
  background-color: #802480;
  color: #fff;
}
.icos-button.icos-button--primary:hover, .icos-button.icos-button--primary:active, .icos-button.icos-button--primary:focus, .icos-button.primary:hover, .icos-button.primary:active, .icos-button.primary:focus {
  background: #572258;
  color: #fff;
}
.icos-button.icos-button--grey300 {
  background-color: #dee2e6;
  color: #fff;
}
.icos-button.icos-button--grey300:hover, .icos-button.icos-button--grey300:active, .icos-button.icos-button--grey300:focus {
  background: #572258;
  color: #fff;
}
body .icos-button.icos-button--form {
  display: block;
  float: none;
  width: 3rem;
  height: 4.75rem;
  min-height: 4.75rem;
  line-height: 4.75rem;
  border-radius: 0;
  background: none;
  color: #000;
}
body .icos-button.icos-button--form:hover, body .icos-button.icos-button--form:active, body .icos-button.icos-button--form:focus {
  background: #802480;
  color: #fff;
}
.icos-button--disabled, .icos-button.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}

.blueSkin .icos-button--selected,
.blueSkin .icos-button--style2.icos-button--selected,
.blueSkin .icos-menu:hover .icos-button {
  background-color: #295cc1;
  border-color: #295cc1;
}
.aquaSkin .icos-button--selected,
.aquaSkin .icos-button--style2.icos-button--selected,
.aquaSkin .icos-menu:hover .icos-button {
  background-color: #167da8;
  border-color: #167da8;
}
.pinkSkin .icos-button--selected,
.pinkSkin .icos-button--style2.icos-button--selected,
.pinkSkin .icos-menu:hover .icos-button {
  background-color: #a219aa;
  border-color: #a219aa;
}
.purpleSkin .icos-button--selected,
.purpleSkin .icos-button--style2.icos-button--selected,
.purpleSkin .icos-menu:hover .icos-button {
  background-color: #6b23af;
  border-color: #6b23af;
}
.greenSkin .icos-button--selected,
.greenSkin .icos-button--style2.icos-button--selected,
.greenSkin .icos-menu:hover .icos-button {
  background-color: #0a800a;
  border-color: #0a800a;
}
.turquoiseSkin .icos-button--selected,
.turquoiseSkin .icos-button--style2.icos-button--selected,
.turquoiseSkin .icos-menu:hover .icos-button {
  background-color: #0c756c;
  border-color: #0c756c;
}
.redSkin .icos-button--selected,
.redSkin .icos-button--style2.icos-button--selected,
.redSkin .icos-menu:hover .icos-button {
  background-color: #b1161e;
  border-color: #b1161e;
}
.orangeSkin .icos-button--selected,
.orangeSkin .icos-button--style2.icos-button--selected,
.orangeSkin .icos-menu:hover .icos-button {
  background-color: #cd6203;
  border-color: #cd6203;
}
body .icos-button--selected.primary,
body .icos-button--style2.icos-button--selected.primary,
body .icos-menu:hover .icos-button.primary {
  background-color: #581958;
}
body .icos-button--selected.secondary,
body .icos-button--style2.icos-button--selected.secondary,
body .icos-menu:hover .icos-button.secondary {
  background-color: #088894;
}
body .icos-button--selected.tertiary,
body .icos-button--style2.icos-button--selected.tertiary,
body .icos-menu:hover .icos-button.tertiary {
  background-color: #cc931e;
}

.icos-menu {
  display: inline-block;
  position: relative;
  overflow: visible;
  /*
  &:hover .icos-menu__list,
  &--open .icos-menu__list {
      max-height: 500px;
  }*/
}
.icos-menu__list {
  position: absolute;
  width: 200px;
  display: block;
  white-space: nowrap;
  padding: 0;
  margin: 32px 0 0 -162px;
  z-index: 10;
  -webkit-box-shadow: 3px 3px 5px #666;
  box-shadow: 0 0 10px rgb(0, 0, 0);
  box-shadow: 0 2.2px 2.2px rgba(0, 0, 0, 0.039), 0 5.3px 5.3px rgba(0, 0, 0, 0.057), 0 9.9px 10px rgba(0, 0, 0, 0.07), 0 17.6px 17.9px rgba(0, 0, 0, 0.083), 0 33px 33.4px rgba(0, 0, 0, 0.101), 0 79px 80px rgba(0, 0, 0, 0.14);
  max-height: 0;
  opacity: 0;
  transition: all ease 0.4s;
  overflow: hidden;
  transform: scale3d(0.9, 0.9, 1);
  pointer-events: none;
  background-color: #e0e0e0;
}
.dark-theme .icos-menu__list {
  background-color: #333333;
}
.light-theme .icos-menu__list {
  background-color: #e0e0e0;
}
body .icos-menu__list.dark {
  background-color: #333;
}
body .icos-menu__list.light {
  background-color: #e0e0e0;
}
.icos-button.small + .icos-menu__list {
  margin: 26px 0 0 -172px;
}
.icos-menu__list.medium {
  width: 250px;
  margin: 32px 0 0 -212px;
}
.icos-button.small + .icos-menu__list.medium {
  margin: 26px 0 0 -222px;
}
.icos-menu__item {
  width: 100%;
  display: block;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  border: 0;
}
.icos-menu__item.seperator {
  margin: 0.25rem 0.5rem;
  height: 0.125rem;
  width: calc(100% - 1rem);
  background: #999;
}
.icos-menu__button {
  background: none;
  padding: 0 0.5rem 0 0.75rem;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1rem;
  height: auto;
  font-weight: normal;
  line-height: 2.5rem;
  display: block;
  min-height: 2rem;
  width: auto;
  text-align: left;
  color: #111;
}
.dark-theme .icos-menu__button {
  color: white;
}
.light-theme .icos-menu__button {
  color: #111111;
}
@media only screen and (min-width: 1024px) {
  .icos-menu__button {
    line-height: 2.25rem;
    font-size: 0.875rem;
  }
}
body .icos-menu__list.dark .icos-menu__button {
  color: #fff;
}
body .icos-menu__list.light .icos-menu__button {
  color: #111;
}
.icos-menu__button:hover {
  color: #fff;
}
.blueSkin .icos-menu__button:hover {
  background-color: #2f6eea;
}
.aquaSkin .icos-menu__button:hover {
  background-color: #1b89b7;
}
.pinkSkin .icos-menu__button:hover {
  background-color: #c22fcb;
}
.purpleSkin .icos-menu__button:hover {
  background-color: #862cda;
}
.greenSkin .icos-menu__button:hover {
  background-color: #0c970f;
}
.turquoiseSkin .icos-menu__button:hover {
  background-color: #20988e;
}
.redSkin .icos-menu__button:hover {
  background-color: #da2c34;
}
.orangeSkin .icos-menu__button:hover {
  background-color: #fd8228;
}
body .icos-menu__button:hover.primary {
  color: #fff;
  background-color: #300e30;
}
body .icos-menu__button:hover.secondary {
  color: #222;
  background-color: #065c63;
}
body .icos-menu__button:hover.tertiary {
  color: #122635;
  background-color: #9f7318;
}
.icos-menu--open .icos-menu__list, .enable-hover .icos-menu:hover .icos-menu__list {
  max-height: 500px;
  opacity: 1;
  pointer-events: auto;
  transform: scale3d(1, 1, 1);
}

.icos-load-c::before {
  border-width: 4px;
  border-radius: 24px;
  border-style: solid;
  border-right-color: #e0e0e0 !important;
  content: " ";
  animation: spin 1.5s ease infinite;
}
.blueSkin .icos-load-c::before {
  border-color: #2f6eea;
}
.aquaSkin .icos-load-c::before {
  border-color: #1b89b7;
}
.pinkSkin .icos-load-c::before {
  border-color: #c22fcb;
}
.purpleSkin .icos-load-c::before {
  border-color: #862cda;
}
.greenSkin .icos-load-c::before {
  border-color: #0c970f;
}
.turquoiseSkin .icos-load-c::before {
  border-color: #20988e;
}
.redSkin .icos-load-c::before {
  border-color: #da2c34;
}
.orangeSkin .icos-load-c::before {
  border-color: #fd8228;
}

.icos-loading {
  border-width: 4px;
  border-radius: 52px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgb(255, 255, 255);
  content: " ";
  animation: spin 1.5s ease infinite;
}
.icos-loading.primary-shade {
  border-color: rgba(87, 34, 88, 0.2);
  border-right-color: #572258;
}
.icos-loading.secondary-shade {
  border-color: rgba(7, 114, 124, 0.2);
  border-right-color: #07727c;
}
.icos-loading.tertiary-shade {
  border-color: rgba(204, 147, 30, 0.2);
  border-right-color: #cc931e;
}

.icos-min-max {
  opacity: 1;
  position: relative;
  transform: rotate(90deg);
}
.icos-min-max::before {
  position: absolute;
  background-color: #fff;
  width: 16px;
  height: 4px;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -2px;
  display: block;
  content: " ";
}
.icos-min-max::after {
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 16px;
  left: 50%;
  margin-left: -2px;
  top: 50%;
  margin-top: -8px;
  display: block;
  content: " ";
}
.icos-min-max--closed::before {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
}
.icos-min-max--closed::after {
  transition: all 0.5s ease-in-out;
  transform: rotate(-90deg);
  opacity: 1;
}
.icos-min-max--open {
  opacity: 1;
}
.icos-min-max--open::before {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}
.icos-min-max--open::after {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}

i.icon.b-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "b-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feather-alt:before {
  content: "\e900";
}

.b-icons-margin__none {
  margin: 0 !important;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeLeft16 {
  0% {
    opacity: 0;
    margin-left: 76px;
  }
  100% {
    opacity: 1;
    margin-left: 16px;
  }
}
@keyframes fadeUpTran {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeUp {
  0% {
    opacity: 0;
    margin-top: 60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes flashBackground {
  0% {
    background-position: 0% 5%;
  }
  50% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes element-queries {
  0% {
    visibility: inherit;
  }
}
@keyframes fadeInOut-Down {
  0% {
    opacity: 0;
    margin-top: 60px;
  }
  35%, 65% {
    opacity: 1;
    margin-top: 80px;
  }
  100% {
    opacity: 0;
    margin-top: 100px;
  }
}
@keyframes zoomUp {
  0% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes zoomIn {
  100% {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  0% {
    opacity: 0;
    transform: scale(1.3);
  }
}
@keyframes zoomOut {
  0% {
    height: auto;
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  0.01% {
    left: auto;
  }
  100% {
    opacity: 0;
    transform: scale(1.3);
  }
  99.99% {
    height: 0;
    position: absolute;
    left: -99999px;
  }
}
@keyframes zoomInOut {
  30%, 70% {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  0%, 100% {
    opacity: 0;
    transform: scale(1.3);
  }
  0.01% {
    height: auto;
  }
}
@keyframes zoomInOut10 {
  8%, 92% {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
  0%, 100% {
    opacity: 0.5;
    transform: scale(1.3);
  }
  0.01%, 99.99% {
    top: 100px;
  }
  100% {
    top: -9999px;
  }
}
@keyframes searchZoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    pointer-events: auto;
  }
}
@keyframes mapZoomIn {
  0% {
    opacity: 0;
    transform: translateX(100px) translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
    pointer-events: auto;
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.slide__wrap {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  perspective: 80vh;
}
.slide-enter {
  transition-delay: 0.25s;
  transform: translate(0, 44px) translateZ(-125px) rotateX(-15deg);
}
.slide-enter-active {
  transform: translate(0, 0);
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.75s cubic-bezier(0.21, 0, 1, 0.44);
}

.glide {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  transition: all 0.65s ease;
}
.glide--down-enter {
  transition-delay: 0.25s;
  transform: translate(0, 44px) translateZ(-125px) rotateX(-15deg);
}
.glide--down-enter-active {
  transform: translate(0, 0);
}
.glide--down-exit {
  opacity: 1;
}
.glide--down-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.75s cubic-bezier(0.21, 0, 1, 0.44);
}

/* Layout for search container */
.search__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(10, 10, 10, 0.9);
  color: #e0e0e0;
  font-family: Lato, sans-serif;
}
.js .search__wrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.search__wrapper::before, .search__wrapper::after {
  content: "";
  position: absolute;
  width: calc(100% + 1em);
  height: calc(100% + 1em);
  pointer-events: none;
  border: 1em solid rgba(0, 0, 0, 0.4);
}
.search__wrapper::before {
  top: 0;
  left: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}
.search__wrapper::after {
  right: 0;
  bottom: 0;
  border-top-width: 0;
  border-left-width: 0;
}
.search__wrapper .icon-wrapper {
  font-size: 2em;
  position: absolute;
  top: 1.25em;
  right: 1.25em;
  display: none;
}
.search__wrapper .icon-wrapper .icon {
  line-height: 40px;
  display: block;
}
.search__wrapper .icon-wrapper .icon:before {
  line-height: inherit;
}
.js .search__wrapper .icon-wrapper {
  display: block;
}

.search__form {
  margin: 5em 0;
  position: relative;
  z-index: 9;
}
.search__form .autocomplete {
  margin: 0 10%;
  width: 80%;
}

.search-input {
  font-family: inherit;
  height: auto;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 80%;
  color: #fff;
  float: none;
  padding: 0.05em 0.1em;
  border: 0;
  border-bottom: 2px solid;
  background: none;
  font-size: 4.5vw;
  outline: none;
}
@media only screen and (max-width: 1247px) {
  .search-input {
    font-size: 6vw;
  }
}
@media only screen and (max-width: 1023px) {
  .search-input {
    font-size: 8vw;
  }
}
@media only screen and (max-width: 583px) {
  .search-input {
    font-size: 9.5vw;
  }
}
.search-input::placeholder, .search-input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.3;
  color: #fff;
  line-height: 1;
  padding-top: 8px;
  font-size: 4vw;
}
@media only screen and (max-width: 1023px) {
  .search-input::placeholder, .search-input::-webkit-input-placeholder {
    font-size: 6vw;
    line-height: 6vw;
    padding-top: 12px;
    line-height: 1;
  }
}
@media only screen and (max-width: 583px) {
  .search-input::placeholder, .search-input::-webkit-input-placeholder {
    font-size: 8.5vw;
    line-height: 1;
    padding-top: 18px;
    display: block;
  }
}
@media only screen and (max-width: 583px) {
  .search-input::-ms-input-placeholder {
    font-size: 9vw;
  }
}
.search-input::-ms-clear {
  display: none;
}
.search-input::-webkit-search-cancel-button, .search-input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search-info {
  font-size: 90%;
  font-weight: normal;
  display: block;
  width: 75%;
  margin: 0 auto;
  padding: 0.85em 0;
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
}

@media only screen and (max-width: 583px) {
  .search-info {
    display: none;
  }
}
.search__links {
  display: flex;
  width: 82%;
}
@media only screen and (max-width: 583px) {
  .search__links {
    position: absolute;
    top: 180px;
  }
}

.search__col {
  width: 33.33%;
  text-align: left;
  min-height: 15.5rem;
}
.search__col:nth-child(2) {
  margin: 0 3em;
}
.search__col > h3 {
  font-size: 1.15em;
  padding: 0 0.5rem 1rem 0.5rem;
  margin: 0;
}
.search__col > p {
  font-size: 1.15em;
  line-height: 1.4;
  margin: 0.75em 0 0 0;
}
.search__col > .list > .item {
  font-size: 1em;
  line-height: 2rem;
  margin: 0;
  float: none;
  display: block;
}
.search__col .link {
  color: #fff;
  cursor: pointer;
  display: block;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.125rem 0.5rem 0.125rem 0.25rem;
  transition: background-color ease 0.2s;
}
.blueSkin .search__col .link:hover, .blueSkin .search__col .link:active, .blueSkin .search__col .link:focus {
  background-color: #295cc1;
}
.aquaSkin .search__col .link:hover, .aquaSkin .search__col .link:active, .aquaSkin .search__col .link:focus {
  background-color: #167da8;
}
.pinkSkin .search__col .link:hover, .pinkSkin .search__col .link:active, .pinkSkin .search__col .link:focus {
  background-color: #a219aa;
}
.purpleSkin .search__col .link:hover, .purpleSkin .search__col .link:active, .purpleSkin .search__col .link:focus {
  background-color: #6b23af;
}
.greenSkin .search__col .link:hover, .greenSkin .search__col .link:active, .greenSkin .search__col .link:focus {
  background-color: #0a800a;
}
.turquoiseSkin .search__col .link:hover, .turquoiseSkin .search__col .link:active, .turquoiseSkin .search__col .link:focus {
  background-color: #0c756c;
}
.redSkin .search__col .link:hover, .redSkin .search__col .link:active, .redSkin .search__col .link:focus {
  background-color: #b1161e;
}
.orangeSkin .search__col .link:hover, .orangeSkin .search__col .link:active, .orangeSkin .search__col .link:focus {
  background-color: #cd6203;
}
.search__col .link .icon {
  float: left;
  display: inline;
  margin: 0 0;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  opacity: 0.7;
}

.search__nav {
  position: relative;
  z-index: 13;
}
.search__nav .icon-menu__list {
  margin: 48px 0 0 -142px;
  z-index: 13;
}
.search__nav .icon-menu--open .icon-menu__list, .enable-hover .search__nav .icon-menu:hover .icon-menu__list {
  max-height: 530px;
}
.search__nav .icon-button {
  width: 56px;
  height: 56px;
  padding: 0;
  margin: 0 8px;
}
.search__nav .icon {
  line-height: 62px;
  font-size: 32px;
}
.search__nav .icon.icos-user, .search__nav .icon.icos-barrister {
  font-size: 36px;
}
.search__nav .icon-button {
  width: 56px;
  height: 56px;
  padding: 0;
  margin: 0 8px;
}

@media only screen and (max-width: 583px) {
  .search__nav {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
  .search__nav .icon {
    line-height: 46px;
    font-size: 22px;
  }
  .search__nav .icon-button {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0 4px;
  }
}
/************************/
/* Transitions 			*/
/************************/
.search__wrapper {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}
.search__wrapper.search--open {
  pointer-events: auto;
  opacity: 1;
}
.search__wrapper::before {
  transform: translate3d(-15px, -15px, 0);
  transition: transform 0.5s;
}
.search__wrapper::after {
  transform: translate3d(15px, 15px, 0);
  transition: transform 0.5s;
}
.search__wrapper .icon-wrapper .icon-button {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
}
.search__wrapper.search--open::before, .search__wrapper.search--open::after {
  transform: translate3d(0, 0, 0);
}
.search__wrapper.search--open .icon-wrapper .icon-button {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* Search form with input and description */
.search__form {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
}
.search--open .search__form {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.search__col {
  opacity: 0;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.5s, transform 0.5s;
}
.search--open .search__col {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.search--open .search__col:nth-child(2) {
  transition-delay: 0.1s;
}
.search--open .search__col:nth-child(3) {
  transition-delay: 0.2s;
}
.search--open .search__links--fade-out .search__col {
  opacity: 0;
}
.search--open .search__links--fade-in .search__col {
  opacity: 1;
}

.search__filter {
  display: block;
  width: 45%;
  position: relative;
  margin-top: -3rem;
  min-height: 2rem;
  box-sizing: border-box;
}
@supports (-ms-ime-align: auto) {
  .search__filter {
    width: 50%;
  }
}
@media only screen and (max-width: 1247px) {
  .search__filter {
    width: 65%;
  }
  @supports (-ms-ime-align: auto) {
    .search__filter {
      width: 70%;
    }
  }
}
@media only screen and (max-width: 1023px) {
  .search__filter {
    width: 85%;
  }
  @supports (-ms-ime-align: auto) {
    .search__filter {
      width: 90%;
    }
  }
}
.search__filter-icon {
  right: 0;
  position: absolute;
  z-index: 8;
  margin-top: -28px;
}
.search__filter .icon:before {
  line-height: inherit;
}
.search__filter .icon--xsmall {
  position: absolute;
  margin: -24px 0 0 2px;
  line-height: 24px;
  font-weight: bold;
}
.search__filter-group {
  opacity: 0;
  position: absolute;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.5s, transform 0.5s, max-height 0.5s;
  display: block;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
}
.search__filter--open .search__filter-group {
  opacity: 1;
  transition: opacity 0.5s 0.5s, transform 0.5s 0.5s, max-height 0.5s;
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
  max-height: 200px;
  overflow: visible;
  z-index: 5;
}

@media only screen and (max-width: 583px) {
  .search__form {
    margin: 1em 0 1em;
    padding: 2rem 0;
    position: absolute;
    top: 2.5rem;
  }
  .search__filter {
    margin: 1em 0 1em;
    position: absolute;
    top: 182px;
  }
  .search__wrapper .icon-wrapper {
    top: 1rem;
    right: 1rem;
  }
  .search-info {
    text-align: left;
  }
  .search__col {
    width: 100%;
  }
  .search__col:nth-child(2), .search__col:nth-child(3) {
    display: none;
  }
}
.ui.b-padding--x2 {
  padding: 2em;
}
.ui.b-padding--x3 {
  padding: 3em;
}
.ui.b-padding--x4 {
  padding: 4em;
}

.ui.b-margin--x2 {
  margin: 2rem;
}
.ui.b-margin--x3 {
  margin: 3rem;
}
.ui.b-margin--x4 {
  margin: 4rem;
}

.ui.b-shadow--none {
  box-shadow: none;
}
.ui.b-shadow--sm {
  box-shadow: 0 0.3px 0.3px rgba(5, 29, 54, 0.02), 0 0.7px 0.8px rgba(5, 29, 54, 0.028), 0 1.3px 1.5px rgba(5, 29, 54, 0.035), 0 2.2px 2.7px rgba(5, 29, 54, 0.042), 0 4.2px 5px rgba(5, 29, 54, 0.05), 0 10px 12px rgba(5, 29, 54, 0.07);
}
.ui.b-shadow {
  box-shadow: 0 0.8px 0.8px rgba(5, 29, 54, 0.02), 0 2px 2px rgba(5, 29, 54, 0.028), 0 3.8px 3.8px rgba(5, 29, 54, 0.035), 0 6.7px 6.7px rgba(5, 29, 54, 0.042), 0 12.5px 12.5px rgba(5, 29, 54, 0.05), 0 30px 30px rgba(5, 29, 54, 0.07);
}
.ui.b-shadow--lg {
  box-shadow: 0 2.8px 2.2px rgba(5, 29, 54, 0.02), 0 6.7px 5.3px rgba(5, 29, 54, 0.028), 0 12.5px 10px rgba(5, 29, 54, 0.035), 0 22.3px 17.9px rgba(5, 29, 54, 0.042), 0 41.8px 33.4px rgba(5, 29, 54, 0.05), 0 100px 80px rgba(5, 29, 54, 0.07);
}
.ui.b-shadow--flat {
  box-shadow: 0px 0px 2.2px rgba(5, 29, 54, 0.02), 0px 0px 5.3px rgba(5, 29, 54, 0.028), 0px 0px 10px rgba(5, 29, 54, 0.035), 0px 0px 17.9px rgba(5, 29, 54, 0.042), 0px 0px 33.4px rgba(5, 29, 54, 0.05), 0px 0px 80px rgba(5, 29, 54, 0.07);
}

.ui.b-border--none {
  border: 0;
}
.ui.b-border--radius-12 {
  border-radius: 0.75rem;
}
.ui.b-border--radius-16 {
  border-radius: 1rem;
}

html {
  font-size: 16px;
}

html,
body {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  background: #572258;
}

.root,
#root {
  height: 100vh;
  width: 100vw;
  position: relative;
  background: none;
  overflow: hidden;
  background: #122635;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .root,
  #root {
    height: auto;
  }
}
@supports (-ms-ime-align: auto) {
  .root,
  #root {
    height: auto;
  }
}

button {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  border: 0;
  padding: 0;
  font: inherit;
  align-items: unset;
}

form {
  height: 100%;
  position: relative;
  background: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  form {
    height: auto;
  }
}
@supports (-ms-ime-align: auto) {
  form {
    height: auto;
  }
}

.b-container {
  position: relative;
  overflow: hidden;
  background: #34495e;
  height: 100%;
}
.b-container__main {
  perspective: 80vh;
  position: relative;
  height: 100%;
  width: 100%;
  background: #495057;
  transition: transform 0.4s ease;
}
.sm--pushed .b-container__main {
  transform: scale3d(0.95, 0.95, 1);
}
.b-container__inner {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  background-color: #F0F5F7;
  transition: opacity 1.35s ease, transform 0.75s ease, background-color 0.4s ease;
}
.b-container__scroller {
  pointer-events: all;
  overflow-y: auto;
  padding-top: 48px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  transition: padding ease 0.4s;
  scrollbar-width: none;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  /*
  scroll-snap-type: y proximity;
  scroll-snap-type: y mandatory;
  perspective: 300px;
  */
}
.sm--hidden .b-container__scroller {
  padding: 0 0 0 0;
  background-color: #122635;
}
.b-container__scroller::-webkit-scrollbar {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .b-container__scroller {
    padding-top: 40px;
  }
  .sm--hidden .b-container__scroller {
    padding-top: 0;
  }
}
@media only screen and (max-width: 583px) {
  .b-container__scroller {
    padding: 0 0 3.75rem 0;
  }
  .sm--hidden .b-container__scroller {
    padding: 0 0 0 0;
  }
}

.scrollbar {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.scrollbar:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
body .scrollbar--light {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
body .scrollbar--light::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
body .scrollbar--light::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
body .scrollbar--light:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
body .scrollbar--dark {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
  scrollbar-track-color: rgba(255, 255, 255, 0.05);
}
body .scrollbar--dark::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
body .scrollbar--dark::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
body .scrollbar--dark:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
body .scrollbar--dark::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
}
body .scrollbar--dark:hover::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.15);
}

/*
transform: translate(0, 44px) rotateX(-15deg);
outer perpective
perspective: 1000px;
*/
.screen-split {
  height: 100%;
  position: absolute;
  width: 100%;
  transition: all 0.6s ease;
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.screen-split--record1 {
  z-index: 2;
  border-right: 1px solid #666;
}
.screen-split--record2 {
  z-index: 1;
  right: 0;
}
.screen-split--hide {
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
}
.screen-split--40 {
  width: 40%;
}
@media only screen and (max-width: 1247px) {
  .screen-split--40 {
    width: 100%;
  }
  .screen-split--40.screen-split--record2 {
    width: 0;
    border-color: transparent;
  }
}
.screen-split--50 {
  width: 50%;
}
@media only screen and (max-width: 1247px) {
  .screen-split--50 {
    width: 100%;
  }
  .screen-split--50.screen-split--record2 {
    width: 0;
    border-color: transparent;
  }
}
.screen-split--60 {
  width: 60%;
}
@media only screen and (max-width: 1247px) {
  .screen-split--60 {
    width: 100%;
  }
  .screen-split--60.screen-split--record2 {
    width: 0;
    border-color: transparent;
  }
}

.b-section {
  display: block;
  transition: margin 0.45s ease, opacity 0.35s ease;
  padding: 1rem 0;
}
.b-section.center {
  max-width: 1234px;
  margin: 0 auto;
  animation: squeezeToPanel 0.65s ease 1 alternate backwards;
}
.b-section.b-left-panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(2px);
  background: rgba(240, 245, 247, 0.7);
  border-right: 1px solid #F0F5F7;
  overflow: auto;
  max-width: 400px;
  animation: squeezeToPanel 0.65s ease 1 alternate backwards;
  padding-bottom: 5rem;
}
@media only screen and (max-width: 1023px) {
  .b-section.b-left-panel {
    max-width: 1234px;
    margin: 0 auto;
    animation: squeezeToPanel 0.65s ease 1 alternate backwards;
    position: relative;
  }
}
.b-section__wrap {
  display: block;
  margin: 0 0 0 0;
}
@media only screen and (max-width: 1023px) {
  .b-section__wrap {
    margin: 0.25rem 0 0 0;
  }
}
@media only screen and (max-width: 583px) {
  .b-section__wrap {
    margin: 3.5rem 0 0 0;
  }
}

@keyframes squeezeToPanel {
  0% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.resize-sensor {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  visibility: hidden;
  max-width: 100%;
}

a {
  color: #0bb5c4;
  text-decoration: none;
}

.text-align--left {
  text-align: left;
}
.text-align--centre {
  text-align: center;
}
.text-align--right {
  text-align: right;
}

.profile-page {
  overflow-x: hidden;
}
.profile-page__masthead {
  display: block;
  min-height: 0.5rem;
}
.profile-page__masthead .ui.grid {
  margin-top: -1rem;
}

.parallax__wrap {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  background-color: white;
  perspective: 300px;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 40px;
  bottom: 0;
  scroll-snap-type: y proximity;
  scroll-snap-type: y mandatory;
  perspective: 300px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
}
.parallax__wrap::-webkit-scrollbar {
  display: none;
}
.parallax__section {
  min-height: calc(100% - 3rem);
  scroll-snap-align: start;
  scroll-margin-top: 2.5rem;
  position: relative;
  transform-style: preserve-3d;
  border-top: 1px solid #dee2e6;
}
.parallax__section:last-child {
  min-height: 100%;
}
.parallax__section.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.parallax__section.flex-center .b-section__wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.parallax__section.hide {
  display: none;
}
.parallax__section.intro {
  z-index: 1;
}
.parallax__section.intro > .mid {
  background-color: #F0F5F7;
  transform: translateZ(0) scale(1);
}
.parallax__section.intro > .top {
  transform: translateZ(50px) scale(0.83333);
}
.parallax__section.outro {
  background-color: teal;
  z-index: 1;
}
.parallax__layer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  pointer-events: none;
}
.parallax__layer.align--top {
  align-items: flex-start;
}
.parallax__layer.base {
  transform: translateZ(-150px) scale(1.5);
}
.parallax__layer.top {
  transform: translateZ(50px) scale(0.83333);
}

@media only screen and (min-width: 768px) {
  .dgrid .tablet--hide {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .dgrid .mobile--hide {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .ui.grid .column > [class*=mobile].column + .column {
    margin-top: 1rem;
  }
}

/* toggleMenu functionality */
/* Start Menu */
.sm__item {
  display: block;
  float: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1023px) {
  .sm__item {
    opacity: 0;
    transition: 0.5s, opacity 1s;
    margin-left: -1.25rem;
  }
  .sm--pushed .sm__item, .sm__level.e-open .sm__item {
    opacity: 1;
    margin-left: 0;
  }
  .sm--pushed .sm__item.sm__bar, .sm__level.e-open .sm__item.sm__bar {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    margin: 0.5rem 1rem 0 1rem;
    padding-bottom: 0.5rem;
  }
  .sm__item:nth-child(1) {
    transition-delay: 0.2s;
  }
  .sm__item:nth-child(2) {
    transition-delay: 0.25s;
  }
  .sm__item:nth-child(3) {
    transition-delay: 0.3s;
  }
  .sm__item:nth-child(4) {
    transition-delay: 0.35s;
  }
  .sm__item:nth-child(5) {
    transition-delay: 0.4s;
  }
  .sm__item:nth-child(6) {
    transition-delay: 0.45s;
  }
  .sm__item:nth-child(7) {
    transition-delay: 0.5s;
  }
  .sm__item:nth-child(8) {
    transition-delay: 0.55s;
  }
}
.sm__item .icos, .sm__item .icon {
  display: inline;
  float: left;
  margin: 0 0.5rem 0 -0.125rem;
  font-size: 1rem;
  width: 1.75rem;
  height: 2.5rem;
}
.sm__item .icos::before, .sm__item .icon::before {
  line-height: 2.5rem;
}
.sm__item::before {
  position: absolute;
  left: 10px;
  z-index: -1;
  color: rgba(0, 0, 0, 0.2);
  line-height: 3.5;
  display: block;
}
.sm__pusher {
  position: relative;
  left: 0;
  height: 100%;
  perspective: 1000px;
  transition: all 0.5s;
}
.sm__pusher--level0 {
  transform: translate3d(300px, 0px, 0px) !important;
}
.sm__pusher--level1 {
  transform: translate3d(340px, 0px, 0px) !important;
}
.sm__pusher--level2 {
  transform: translate3d(380px, 0px, 0px) !important;
}
.sm__pusher--level3 {
  transform: translate3d(420px, 0px, 0px) !important;
}
@media only screen and (max-width: 1023px) {
  .sm__pusher .sm__menu.e-sidebar.e-left.e-close.e-dock {
    width: 300px;
    transform: none;
  }
}
@media only screen and (min-width: 1024px) {
  .sm__pusher {
    padding-left: 2.75rem;
  }
  .sm__pusher, .sm__pusher.sm--pushed {
    transform: none !important;
  }
  .sm__pusher.sm--hidden {
    padding-left: 0;
  }
}
.sm__menu {
  position: absolute; /* we can't use fixed here :( */
  top: 0;
  left: 0;
  z-index: 1;
  width: 300px !important;
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  border: 0 !important;
  background: none;
}
.sm__menu > .sm__level, .sm__menu > .e-sidebar.e-left.e-open.sm__level {
  box-shadow: none;
  transform: translate3d(0, 0, 0);
}
.sm__menu .e-sidebar.e-left.e-close {
  visibility: visible;
}
@media only screen and (min-width: 1024px) {
  .sm__menu {
    transform: none !important;
    width: 2.75rem !important;
    transition: width ease 0.35s;
    z-index: 160;
  }
  .sm--pushed .sm__menu {
    width: 210px !important;
  }
}
.sm__level {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  transform: translate3d(-115%, 0, 0);
  transition: all 0.5s;
  background-color: #802480;
  z-index: 10;
  padding: 0 0.125rem;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
.sm__level.e-sidebar.e-left.e-close {
  transform: translate3d(-130%, 0, 0);
}
.sm__level.sm__level--2 {
  z-index: 20;
}
.sm__level.sm__level--3 {
  z-index: 30;
}
@media only screen and (min-width: 1024px) {
  .sm__menu > .sm__level {
    top: 0px;
  }
  .sm__menu.e-dock > .sm__level {
    top: 40px;
  }
  .sm__menu > .sm__level .sm__level {
    top: 0;
  }
  .sm__level.sm__level--1 {
    width: 260px;
    z-index: 170;
  }
  .sm__level.sm__level--2 {
    width: 260px;
    z-index: 180;
  }
}
.sm__level > ul {
  margin: 0;
  padding: 0.5rem 0 0 0;
  list-style: none;
}
@media only screen and (min-width: 1024px) {
  .sm__level > ul.bottom {
    position: absolute;
    bottom: 0;
    left: 0.125rem;
    right: 0.125rem;
  }
}
.sm__level > h2,
.sm__level .sm__title h2 {
  margin: 0;
  padding: 1.5rem 1.5rem 1.5rem 2.25rem;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 2em;
}
@media only screen and (min-width: 1024px) {
  .sm__level > h2,
  .sm__level .sm__title h2 {
    padding: 1.5rem 1.75rem 1.5625rem 1.75rem;
  }
}
.sm__level-icon {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease 0.2s;
  width: 2.5rem;
  height: 2.5rem;
}
.sm__close .sm__level-icon {
  opacity: 1;
  padding-top: 0.5rem;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.26);
}
@media only screen and (min-width: 1024px) {
  .sm__level-icon {
    left: 0;
    right: unset;
    top: unset;
    bottom: 0;
    margin-left: 0.125rem;
  }
  .sm__level-icon .home {
    display: none;
  }
}
.sm__level.e-sidebar {
  width: 300px;
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
  transform: translate3d(-40px, 0, 0);
  transition: all 0.4s ease !important;
  border: 0;
}
@media only screen and (min-width: 1024px) {
  .sm__level.e-sidebar {
    width: 260px !important;
    margin-left: 44px !important;
  }
  .sm__level.e-sidebar.sm__level--2 {
    margin-left: 88px !important;
  }
}
@media only screen and (max-width: 1023px) {
  .sm__level.e-open.e-sidebar.e-left.sm__level--1 {
    transform: translate3d(-40px, 0, 0) !important;
  }
  .sm__level.e-open.e-sidebar.e-left.sm__level--2 {
    transform: translate3d(-80px, 0, 0);
  }
  .sm__level.e-open.e-sidebar.e-left.sm__level--3 {
    transform: translate3d(-120px, 0, 0);
  }
}
@media only screen and (min-width: 1024px) {
  .sm__level.e-open {
    width: 260px;
    opacity: 1;
    pointer-events: auto;
    transition: all ease 0.4s !important;
    transform: scale3d(1, 1, 1) !important;
  }
  .sm__level.e-open.sm__level--1 {
    width: 260px;
    z-index: 170;
  }
  .sm__level.e-open.sm__level--2 {
    width: 260px;
    z-index: 180;
  }
}
@media only screen and (min-width: 1024px) {
  .sm__level.e-close {
    pointer-events: none;
    visibility: visible;
    opacity: 0;
    margin-top: 0;
    transform: scale3d(0.9, 0.9, 1) !important;
    transition: all ease 0.4s !important;
  }
}
.sm__link {
  display: block;
  inline-size: max-content;
  padding: 0 0.875rem 0 2.375rem;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  color: #fff;
  line-height: 2.5rem;
  text-decoration: none;
  cursor: pointer;
  background: none;
  text-align: left;
  width: 100%;
  transition: background 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sm__link .icon.angle.left,
.sm__link .icos-angle-left {
  margin: 0 -0.75rem 0 -1.75rem;
  opacity: 0.6;
}
.sm__link:hover {
  color: #fff;
  background: rgba(29, 11, 29, 0.4);
}
.e-dock .sm__link:hover {
  background: rgba(29, 11, 29, 0.6);
}
@media only screen and (min-width: 1024px) {
  .sm__link {
    padding: 0 0 0 0.5rem;
  }
  .e-sidebar:not(.e-dock) .sm__link, ul ul .sm__item .sm__link {
    padding: 0 1.825rem 0 1.75rem;
  }
  .e-sidebar:not(.e-dock) .sm__link .icon.angle.left,
  .e-sidebar:not(.e-dock) .sm__link .icos-angle-left, ul ul .sm__item .sm__link .icon.angle.left,
  ul ul .sm__item .sm__link .icos-angle-left {
    float: right;
    margin: 0 -1rem 0 0.5rem !important;
    transform: rotate(180deg);
  }
}
.sm__header {
  display: block;
  opacity: 0;
  transition: 0.5s, opacity 1s;
  transition-delay: 0.15s;
  margin-top: 0.25rem;
  padding-top: env(safe-area-inset-top);
}
.sm--pushed .sm__header {
  opacity: 1;
}
.sm__header-link {
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  height: auto;
  width: 100%;
  background: none;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: 0.35s ease background-color;
}
.sm__header-link:hover {
  color: #fff;
  background: rgba(29, 11, 29, 0.6);
}
.sm__header-link > i.icon {
  display: flex;
  position: absolute;
  margin: 0 0 0 1.875rem;
  background: rgba(255, 255, 255, 0.15);
  font-size: 1rem;
  display: block;
  border-radius: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75rem;
}
@media only screen and (min-width: 1024px) {
  .sm__header {
    display: none;
  }
}
.sm__branding {
  height: 40px;
  display: none;
  background: rgba(0, 0, 0, 0.85);
  position: absolute;
  z-index: 161;
}
.light-theme .sm__branding {
  background: #eaeef3;
  border-bottom: 1px solid #e0e0e0;
}
.sm__branding .icos-button {
  background: none;
  border-radius: 0;
  width: 44px;
}
@media only screen and (min-width: 1024px) {
  .sm__branding {
    display: block;
  }
}
.sm__close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  height: auto;
  transform: none !important;
  background: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  opacity: 0;
  display: block;
  pointer-events: none;
  z-index: 9;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.sm__close:hover, .sm__close:focus, .sm__close:active {
  border: 0;
  outline: none;
  box-shadow: none;
}
.sm__level--2 + .sm__close {
  z-index: 19;
  margin-left: -40px !important;
  background: rgba(0, 0, 0, 0.27);
}
.sm__level--3 + .sm__close {
  z-index: 29;
  background: rgba(0, 0, 0, 0.34);
  margin-left: -80px !important;
}
.e-open + .sm__close {
  opacity: 1;
  pointer-events: auto;
}
@media only screen and (min-width: 1024px) {
  .sm__close {
    width: 260px;
  }
  .sm__level--1 + .sm__close {
    z-index: 169;
    margin: 0 0 0 0 !important;
  }
  .sm__level--2 + .sm__close {
    z-index: 179;
    margin: 0 0 0 2.75rem !important;
  }
  .sm__level--3 + .sm__close {
    z-index: 189;
    margin: 0 0 0 2.75rem !important;
  }
}
.sm__pusher-close {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  transform: none !important;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  opacity: 0;
  display: block;
  pointer-events: none;
  z-index: 140;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.sm__pusher-close:hover, .sm__pusher-close:focus {
  border: 0;
  outline: none;
  box-shadow: none;
}
.sm--pushed > .sm__pusher-close {
  opacity: 1;
  pointer-events: auto;
  backdrop-filter: blur(1px);
}

.tab__wrap {
  display: block;
  margin: 24px 16px 0 16px;
  text-transform: uppercase;
  border-bottom: 2px solid #dfdfdf;
  display: flex;
}
.blueSkin .tab__wrap {
  border-color: #4285f4;
}
.aquaSkin .tab__wrap {
  border-color: #41c7f0;
}
.pinkSkin .tab__wrap {
  border-color: #df3fe9;
}
.purpleSkin .tab__wrap {
  border-color: #9e50e7;
}
.greenSkin .tab__wrap {
  border-color: #24b527;
}
.turquoiseSkin .tab__wrap {
  border-color: #28afa4;
}
.redSkin .tab__wrap {
  border-color: #f64a52;
}
.orangeSkin .tab__wrap {
  border-color: #ef8c43;
}
.tab__wrap-left {
  width: 100%;
}
.tab__page-icons {
  width: 50px;
}
.tab__page-icons .icon-button {
  margin: -6px 0 0 4px;
  transition: all ease 0.6s;
}
.tab__page-icons .icon-button .icon::before {
  line-height: 42px;
}
.tab__page-icons .icon-button:hover {
  transform: rotate(180deg);
}
.tab__link {
  display: inline-block;
  line-height: 32px;
  font-size: 14px;
  text-decoration: none;
  padding: 0 6px;
  margin: 0 6px 0 0;
  border-bottom: 4px solid transparent;
  font-weight: bold;
  transition: border 0.25s ease, color 0.25s ease;
}
.dark-theme .tab__link {
  color: rgba(255, 255, 255, 0.5);
}
.light-theme .tab__link {
  color: rgba(17, 17, 17, 0.5);
}
.dark-theme .tab__link--selected, .dark-theme .tab__link:hover {
  color: white;
}
.light-theme .tab__link--selected, .light-theme .tab__link:hover {
  color: #111111;
}
.blueSkin .tab__link--selected, .blueSkin .tab__link:hover {
  border-color: #4285f4;
}
.aquaSkin .tab__link--selected, .aquaSkin .tab__link:hover {
  border-color: #41c7f0;
}
.pinkSkin .tab__link--selected, .pinkSkin .tab__link:hover {
  border-color: #df3fe9;
}
.purpleSkin .tab__link--selected, .purpleSkin .tab__link:hover {
  border-color: #9e50e7;
}
.greenSkin .tab__link--selected, .greenSkin .tab__link:hover {
  border-color: #24b527;
}
.turquoiseSkin .tab__link--selected, .turquoiseSkin .tab__link:hover {
  border-color: #28afa4;
}
.redSkin .tab__link--selected, .redSkin .tab__link:hover {
  border-color: #f64a52;
}
.orangeSkin .tab__link--selected, .orangeSkin .tab__link:hover {
  border-color: #ef8c43;
}

.nav {
  display: block;
  text-align: center;
  height: 40px;
  font: 600 14px/40px Lato, sans-serif;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  width: auto;
  position: absolute;
  background: rgba(237, 233, 236, 0.85);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-bottom: 1px solid #d6cdd3;
}
.nav .icos-button,
.nav .ui.button.icon {
  border-radius: 0;
  background: transparent;
  transition: all ease 0.35s;
  border-radius: 0.25rem;
  padding: 0;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0.125rem;
  color: #fff;
  display: inline;
  float: left;
  color: #802480;
}
.nav .icos-button.branding,
.nav .ui.button.icon.branding {
  border-radius: 0;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
}
@media only screen and (max-width: 1023px) {
  .nav .icos-button,
  .nav .ui.button.icon {
    width: 2.75rem;
    height: 2.75rem;
  }
  .nav .icos-button.branding,
  .nav .ui.button.icon.branding {
    border-radius: 0;
    margin: 0;
    width: 3rem;
    height: 3rem;
  }
}
@media only screen and (max-width: 583px) {
  .nav .icos-button,
  .nav .ui.button.icon {
    width: 3.125rem;
    height: 3.125rem;
  }
}
.nav .icos-button:hover, .nav .icos-button:focus, .nav .icos-button:active,
.nav .ui.button.icon:hover,
.nav .ui.button.icon:focus,
.nav .ui.button.icon:active {
  background-color: rgba(50, 25, 25, 0.25);
  color: #802480;
}
.nav__stack--open .nav .icos-button:hover, .nav__stack--open .nav .icos-button:focus, .nav__stack--open .nav .icos-button:active,
.nav__stack--open .nav .ui.button.icon:hover,
.nav__stack--open .nav .ui.button.icon:focus,
.nav__stack--open .nav .ui.button.icon:active {
  color: #131619;
  background-color: #dfeaee;
}
.nav .icos-button .icos,
.nav .icos-button .icon,
.nav .ui.button.icon .icos,
.nav .ui.button.icon .icon {
  border-radius: 0;
  line-height: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  color: #802480;
}
@media only screen and (max-width: 1023px) {
  .nav .icos-button .icos,
  .nav .icos-button .icon,
  .nav .ui.button.icon .icos,
  .nav .ui.button.icon .icon {
    line-height: 2.75rem;
    width: 2.75rem;
    height: 2.75rem;
  }
}
.nav__stack--open .nav .icos-button .icos,
.nav__stack--open .nav .icos-button .icon,
.nav__stack--open .nav .ui.button.icon .icos,
.nav__stack--open .nav .ui.button.icon .icon {
  color: #131619;
}
.nav .nav__stack--open .ui.button.icon {
  color: #fff;
}
.nav .nav__stack--open .ui.button.icon .icon,
.nav .nav__stack--open .ui.button.icon .icon:hover,
.nav .nav__stack--open .ui.button.icon .icon:focus,
.nav .nav__stack--open .ui.button.icon .icon:active {
  color: #131619;
}
.nav .nav__stack--open .ui.button.icon:hover, .nav .nav__stack--open .ui.button.icon:focus, .nav .nav__stack--open .ui.button.icon:active {
  background-color: #cfdfe5;
}
.nav__icons {
  float: none;
  position: fixed;
  display: inline-flex;
  flex-wrap: nowrap;
  right: unset;
  margin: 0 auto;
  transition: all ease 0.4s;
  padding: 0 0;
  /*padding-bottom: env(safe-area-inset-bottom);*/
  box-shadow: 0 0 10px rgba(230, 206, 206, 0.8);
  box-shadow: 0 1.1px 2.2px rgba(0, 0, 0, 0.034), 0 2.7px 5.3px rgba(0, 0, 0, 0.048), 0 5px 10px rgba(0, 0, 0, 0.06), 0 8.9px 17.9px rgba(0, 0, 0, 0.072), 0 16.7px 33.4px rgba(0, 0, 0, 0.086), 0 40px 80px rgba(0, 0, 0, 0.12);
  z-index: 30;
  width: auto;
  color: #fff;
  background-color: #572258;
}
.nav__icons.white {
  background: #fff;
}
.nav__icons--main {
  overflow: hidden;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  padding-bottom: 0.75rem;
}
@media only screen and (min-width: 584px) {
  .nav__icons--main {
    border-radius: 0px;
    width: auto;
    display: inline-flex;
  }
}
@media only screen and (max-width: 583px) {
  .nav__icons--main .icon__divider {
    display: inline;
    float: left;
    width: 0.5rem;
    flex: 0 0 0.25rem;
    border-left: 1px solid #942a94;
    margin: 0.5rem 0 0.5rem 0.25rem;
    width: 0.25rem;
  }
}
.nav__icons--main .icos-button,
.nav__icons--main .ui.button.icon {
  padding: 0;
  float: left;
  display: inline;
}
@media only screen and (max-width: 583px) {
  .nav__icons--main .icos-button,
  .nav__icons--main .ui.button.icon {
    flex: 1 1 auto;
    width: auto;
    height: 2.75rem;
    max-width: none;
  }
}
.nav__icons--main .icos-button .icos,
.nav__icons--main .icos-button .icon,
.nav__icons--main .ui.button.icon .icos,
.nav__icons--main .ui.button.icon .icon {
  color: #802480;
  font-size: 1rem;
}
@media only screen and (max-width: 583px) {
  .nav__icons--main .icos-button .icos,
  .nav__icons--main .icos-button .icon,
  .nav__icons--main .ui.button.icon .icos,
  .nav__icons--main .ui.button.icon .icon {
    color: white;
  }
}
.nav__icons--main .icos-button .icos.searchengin,
.nav__icons--main .icos-button .icon.searchengin,
.nav__icons--main .ui.button.icon .icos.searchengin,
.nav__icons--main .ui.button.icon .icon.searchengin {
  font-size: 1.375rem;
}
.nav__icons--main .icos-button:hover,
.nav__icons--main .ui.button.icon:hover {
  background-color: rgba(50, 25, 25, 0.25);
}
@media only screen and (max-width: 583px) {
  .nav__icons--main .icos-button:hover,
  .nav__icons--main .ui.button.icon:hover {
    background-color: #331433 !important;
  }
}
.nav__icons--main .icos-button:hover .icon,
.nav__icons--main .ui.button.icon:hover .icon {
  color: #802480;
}
@media only screen and (max-width: 583px) {
  .nav__icons--main .icos-button:hover .icon,
  .nav__icons--main .ui.button.icon:hover .icon {
    color: #fff;
  }
}
@media only screen and (min-width: 584px) {
  .nav__icons--main .icos-button.hide--desktop,
  .nav__icons--main .ui.button.icon.hide--desktop {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .nav__icons--main .branding {
    display: none;
  }
}
.nav__icons--page {
  top: 8px;
  pointer-events: auto;
  display: none;
}
.light-theme .nav__icons--page .icos {
  color: #fff;
}
body .nav__icons--page.white .icos {
  color: #802480;
}
.nav__icons .icos-priority,
.nav__icons .icos-priority:before {
  line-height: 40px !important;
}
body .nav__icons .icos-button:hover, body .nav__icons .icos-button--selected {
  background-color: rgba(50, 25, 25, 0.25);
}
body .nav__icons .icos-button:focus {
  background-color: transparent;
}
body .nav__icons.white .icos-button {
  background-color: transparent;
  max-width: 50px;
}
body .nav__icons.white .icos-button:hover, body .nav__icons.white .icos-button--selected {
  background: #802480;
}
body .nav__icons.white .icos-button:focus {
  background-color: transparent;
}
body .sm--pushed .nav__icons--main {
  transform: none;
  box-shadow: none;
  background: none;
  margin: 0;
}
body .sm--pushed .nav__icons--main .icos-button + .icos-button {
  /*animate hide*/
  overflow: hidden;
  max-width: 0px;
  opacity: 0;
  transition: all ease 0.4s;
}
@media only screen and (min-width: 584px) {
  .nav__icons {
    position: absolute;
  }
  .nav__icons--main {
    float: left;
    display: inline-flex;
    position: static;
    transform: none;
    background: none !important;
    box-shadow: none;
  }
}
.nav__controls {
  float: right;
  display: inline;
}
.nav__controls .nav__mobile-left {
  float: left;
  display: inline;
}
.nav__controls .nav__mobile-right {
  float: right;
  display: inline;
}
.nav__controls .e-menu-wrapper {
  background: none;
  height: 2.5rem;
  float: left;
  display: inline;
}
.nav__controls .e-menu-wrapper ul {
  background: none;
}
.nav__controls .e-menu-wrapper ul .e-menu-item {
  height: 2.25rem;
  transition: all 0.35s ease;
  color: #802480;
  font-weight: 500;
  border-radius: 0.25rem;
  margin: 0.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 2.25rem;
}
.nav__controls .e-menu-wrapper ul .e-menu-item.e-selected {
  background-color: rgba(50, 25, 25, 0.25);
}
.nav__controls .dock-menu .e-menu-item {
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nav__controls .dock-menu .e-menu-item .e-caret {
  display: none;
}
.nav__controls .dock-menu .e-menu-item.e-menu-caret-icon {
  padding-right: 1rem;
}
@media only screen and (max-width: 583px) {
  .nav__controls {
    display: block;
    width: 100vw;
    box-sizing: border-box;
    height: 72px;
    padding: 0 0.125rem 2px 0.125rem;
    padding-top: env(safe-area-inset-top);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-bottom: 1px solid #111;
    border-color: rgba(0, 0, 0, 0.05);
    background-color: rgba(19, 22, 25, 0.85);
    background: rgba(232, 237, 243, 0.85);
    border-bottom: 1px solid rgba(206, 212, 218, 0.85);
  }
  .nav__controls--mobile-top {
    height: 56px;
    top: 0;
  }
  .nav__controls--mobile-bottom {
    bottom: 0;
    position: fixed;
  }
}
@media only screen and (max-width: 1023px) {
  .nav {
    height: 48px;
  }
  .nav .icos-button .ui.button.icon {
    padding: 0;
    margin: 0;
    transition: margin ease 0.2s;
    width: 44px;
    height: 44px;
    margin: 0.125rem;
  }
  .nav .icos-button .ui.button.icon .icos,
  .nav .icos-button .ui.button.icon i.icon {
    height: 44px;
    width: 44px;
    line-height: 44px;
  }
  .nav .icos-button .ui.button.icon .icos::before,
  .nav .icos-button .ui.button.icon i.icon::before {
    line-height: 44px;
  }
  .nav .icos-button .ui.button.icon .icos .icos-search::before,
  .nav .icos-button .ui.button.icon i.icon .icos-search::before {
    line-height: 38px;
  }
  .nav .dock-menu {
    display: none;
  }
}
.nav__stack {
  float: left;
  display: inline;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .nav__stack {
    width: 160px;
    height: 48px;
    borde6: 1px solid #ff0;
  }
}
.nav__stack-divider {
  float: left;
  display: inline;
  margin: 6px 2px;
  height: 28px;
  border-left: 1px solid #ccc;
}
@media only screen and (max-width: 1023px) {
  .nav__stack-divider {
    margin-top: 10px;
  }
}
.nav__stack-icons {
  float: left;
  display: inline;
  transition: margin 0.6s ease;
}
.nav__stack-icons .icos-inbox {
  font-size: 1rem;
}
.nav__stack--open .nav__stack-icons {
  position: fixed;
  z-index: 201;
  margin-top: 0.75rem;
}
@media only screen and (max-width: 767px) {
  .nav__stack--open .nav__stack-icons {
    left: 1rem;
    top: 0.125rem;
    width: 180px;
    display: none;
  }
}
@media only screen and (max-width: 583px) {
  .nav__stack-icons {
    display: none;
  }
}
.nav .nav__stack--open .icos-button .icos {
  color: #fff;
}
@media only screen and (max-width: 583px) {
  .nav {
    border-color: transparent;
    pointer-events: none;
    overflow: visible;
    top: 0;
    bottom: 0;
    height: auto;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    background: none !important;
    pointer-events: none;
  }
  .nav__icons--main, .nav__stack-icons {
    pointer-events: auto;
  }
  .light-theme .nav__icons--main .icos, .light-theme .nav__stack-icons .icos {
    color: #fff;
  }
  .nav .mobile--hide {
    display: none;
  }
  .nav__controls {
    pointer-events: auto;
  }
  .nav__controls .icos-button {
    padding: 0;
    pointer-events: auto;
    margin: 0.25rem 0.125rem;
    transition: margin ease 0.2s;
    height: 3rem;
    width: 3rem;
  }
  .nav__controls .icos-button .icos {
    border-radius: 40px;
    height: 3rem;
    line-height: 3rem;
    font-size: 22px;
  }
  .nav .nav__controls--mobile-top {
    position: absolute;
    z-index: 10;
  }
  .nav .nav__controls--mobile-bottom {
    transition: opacity ease 0.4s;
  }
  .sm--pushed .nav .nav__controls--mobile-bottom {
    opacity: 0;
    overflow: hidden;
    max-width: 0;
    height: 0;
  }
}
.nav__icons--page {
  height: 48px;
}
.nav__icons--page .icos-button {
  margin: 4px 2px;
  border-radius: 40px;
}
.nav__icons--page .icos-button .icos {
  border-radius: 40px;
  height: 40px;
}
.nav__icons--page .icos-button .icos::before {
  line-height: 42px;
}
.nav .icos-menu {
  float: left;
  display: inline;
  margin-left: 8px;
}
@media only screen and (max-width: 583px) {
  .nav .icos-menu {
    margin-left: 0;
  }
}
.nav .icos-menu .icos-button {
  border: 0;
}
.nav .icos-menu .icos-menu__list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 1023px) {
  .nav .icos-menu .icos-menu__list {
    margin-top: 46px;
  }
}
@media only screen and (max-width: 583px) {
  .nav .icos-menu .icos-menu__list {
    flex-direction: column-reverse;
    bottom: 52px;
    right: 0px;
    z-index: 10000;
  }
}
@media only screen and (min-width: 584px) {
  .nav .desktop-hide {
    display: none !important;
  }
}
@media only screen and (max-width: 583px) {
  .nav .mobile-hide {
    display: none !important;
  }
}
.e-menu-wrapper.dock-menu {
  border-radius: 0 0 0.375rem 0.375rem;
}
.e-menu-wrapper.dock-menu ul {
  background: none;
  padding: 0.125rem 0 0 0;
}
.e-menu-wrapper.dock-menu ul .e-menu-item {
  height: 2.25rem;
  transition: all 0.35s ease;
  color: #802480;
  font-weight: 500;
  border-radius: 0.25rem;
  margin: 0.125rem;
  line-height: 2.25rem;
}
.e-menu-wrapper.dock-menu ul .e-menu-item.e-focused.e-selected {
  background-color: rgba(50, 25, 25, 0.25);
}
.e-menu-wrapper.dock-menu ul .e-menu-item.e-selected {
  background-color: rgba(50, 25, 25, 0.15);
}

.hamburger {
  padding: 3px 0 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  margin: 0;
  overflow: visible;
  height: 40px;
  width: 40px;
  border-radius: 0;
}
@media only screen and (min-width: 1024px) {
  .hamburger {
    width: 44px;
  }
}
@media only screen and (max-width: 583px) {
  .hamburger {
    margin: 0 auto;
  }
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger-box {
  width: 22px;
  height: 22px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 2px;
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 22px;
  height: 2px;
  border-radius: 5px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  content: "";
  display: block;
  background-color: #fff;
}
.icon-button:hover .hamburger-inner, .icon-button:hover .hamburger-inner::before, .icon-button:hover .hamburger-inner::after {
  background-color: #fff;
}
.hamburger-inner::before {
  top: 7px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger-inner::after {
  bottom: 0;
  top: 14px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.sm--pushed .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(135deg);
  transition-delay: 0.075s;
  opacity: 1;
  background-position: -200px 0;
  background-color: #fff;
}
.sm--pushed .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
  background-color: #fff;
}
.sm--pushed .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-270deg);
  transition-delay: 0.075s;
  background-color: #fff;
}

.stack__wrapper {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  height: calc(100vh - 1rem);
  width: 100%;
  max-width: 800px;
  z-index: 200;
  background: #F0F5F7;
  pointer-events: none;
  opacity: 0;
  transition: all 0.6s ease;
  border-radius: 0.5rem;
}
.nav__stack--open .stack__wrapper {
  pointer-events: auto;
  opacity: 1;
  margin: 0.5rem 0;
}
@media only screen and (max-width: 1023px) {
  .stack__wrapper {
    max-width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  .stack__wrapper {
    max-width: 100%;
    left: 0.5rem;
    right: 0.5rem;
    width: auto;
  }
}
.stack__shade {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  background-color: rgba(11, 181, 196, 0.6);
  background-color: rgba(51, 20, 51, 0.8);
  opacity: 0;
}
.sm__pusher > .stack__shade {
  width: 44px;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .sm__pusher > .stack__shade {
    display: none;
  }
}
.nav__stack--open .stack__shade {
  pointer-events: auto;
  opacity: 1;
}
.stack__shade.open {
  pointer-events: auto;
  opacity: 1;
  width: 44px;
  height: 100%;
  z-index: 180;
}
.stack {
  display: block;
  position: absolute;
  top: 3rem;
  bottom: 0;
  left: 0;
  right: 4px;
  height: auto;
  width: auto;
  color: #333;
  overflow: auto;
  text-align: left;
  padding: 0 1rem 1rem 1rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
  transition: opacity 0.5s, transform 0.5s;
}
.stack::-webkit-scrollbar {
  display: none;
}
.nav__stack--open .stack {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
@media only screen and (max-width: 1023px) {
  .stack {
    top: 3.5rem;
  }
}
@media only screen and (max-width: 583px) {
  .stack {
    top: 4rem;
  }
}
.stack__item {
  display: block;
  float: none;
  height: auto;
  overflow: hidden;
  position: relative;
  border-radius: 0.5rem;
  background-color: #dfeaee;
  margin-top: 0.375em;
  /*&:after{
      display:block;
      border:1px solid rgba($white1, 0.25);
      height:0.25rem;
      content: '';
      overflow: hidden;
  }**/
}
.stack__item:hover {
  background-color: #cfdfe5;
}
.stack__item-icon {
  margin: 0.5rem 0.5rem 0 0.25rem;
  float: left;
  display: inline;
  width: 46px;
  height: 46px;
}
.stack__item-icon .icon,
.stack__item-icon .icons {
  margin: 0.375rem;
}
.stack__item-icon .icon--large {
  color: #e0e0e0;
}
.stack__item-icon .icon--xxsmall {
  position: absolute;
  margin: 18px 0 0 -14px;
}
.stack__item-icon .e-avatar {
  margin-left: 0.375rem;
  margin-top: 0.375rem;
  background-color: #441344;
  background-color: #333;
}
.stack__item-detail {
  display: block;
  overflow: hidden;
  margin: 0 60px 0 0;
  padding: 0.5rem 0 0.75rem 0;
}
.stack__item-detail .tag {
  line-height: 0.75rem;
  padding: 0.25rem 0.5rem;
  font-weight: 400;
}
.light-theme .stack__item-detail .tag.bg--none {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.stack__item-title {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  padding: 0.25rem 0;
  margin: 0;
  border: 0;
  color: #802480;
}
.stack__item.recent {
  font-style: italic;
  opacity: 0.8;
}
.stack__item.recent:hover {
  opacity: 1;
}
.stack__item:last-child {
  border: 0;
}
.stack__item-link {
  display: block;
  color: #333;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
  line-height: normal;
  text-decoration: none;
  transition: all 0.3s ease;
}
.stack__item-close {
  position: absolute;
  right: 0;
  margin: 0.375rem 0.375rem 0 0;
  overflow: hidden;
}
.stack__item-close .icos-button,
.stack__item-close .ui.button.icon {
  margin: 0;
  color: #07727c;
}
.stack__item-close .icos-button:hover, .stack__item-close .icos-button:active, .stack__item-close .icos-button:focus,
.stack__item-close .ui.button.icon:hover,
.stack__item-close .ui.button.icon:active,
.stack__item-close .ui.button.icon:focus {
  background-color: #adc9d4 !important;
}
.stack__item .ui.label {
  background-color: #b4cdd7;
}
.stack__section-title {
  line-height: 2rem;
  border-bottom: 1px solid #0bb5c4;
  margin: 1rem 0;
  display: block;
}
.stack__close-popup {
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  color: #fff;
}
.stack__close-popup .button.ui.icon,
.stack__close-popup .icon-button {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
  margin: 0;
  display: block;
  padding: 0;
}
.nav__stack--open .stack__close-popup .button.ui.icon,
.nav__stack--open .stack__close-popup .icon-button {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.stack__close-popup .button.ui.icon i.icon,
.stack__close-popup .icon-button i.icon {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.25rem;
}
.stack__close-popup .button.ui.icon i.icon::before,
.stack__close-popup .icon-button i.icon::before {
  margin-left: -0.125rem;
}
@media only screen and (max-width: 1023px) {
  .stack__close-popup .button.ui.icon i.icon,
  .stack__close-popup .icon-button i.icon {
    line-height: 2.75rem;
    width: 2.75rem;
    height: 2.75rem;
  }
}
.stack__session-link {
  background: rgba(255, 255, 255, 0.05);
  padding: 0 1rem;
  display: block;
  float: none;
  font-weight: 400;
  cursor: pointer;
  line-height: 2rem;
}
.stack__session-text {
  float: right;
  display: inline;
  font-size: 0.75rem;
}
.stack__session-info {
  font-size: 0.825rem;
}

.page-nav {
  font-family: Lato, sans-serif;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 8;
  width: auto;
  min-height: 72px;
  padding: 8px 4px 0 4px;
  padding-bottom: env(safe-area-inset-bottom);
  overflow: hidden;
  transition: opacity ease 0.4s;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  /*move above if there are page controls on mobile*/
}
.dark-theme .page-nav {
  background: rgba(19, 22, 25, 0.9);
}
.light-theme .page-nav {
  background: rgba(240, 240, 240, 0.9);
}
.light-theme .page-nav {
  border-top: 2px solid #e0e0e0;
}
.page-nav--above-controls {
  bottom: 72px;
  min-height: 62px;
  border-bottom: 1px dashed #999;
}
.page-nav .icon-button {
  float: right;
  margin: 0 0 0 4px;
  height: 48px;
  width: auto;
  line-height: 48px;
}
.dark-theme .page-nav .icon-button {
  background: rgba(255, 255, 255, 0.1);
}
.light-theme .page-nav .icon-button {
  background: rgba(0, 0, 0, 0.1);
}
.page-nav .icon-button .icon {
  line-height: 50px;
  width: 48px;
  height: 48px;
}
.blueSkin .page-nav .icon-button:hover {
  background-color: #4285f4;
}
.aquaSkin .page-nav .icon-button:hover {
  background-color: #41c7f0;
}
.pinkSkin .page-nav .icon-button:hover {
  background-color: #df3fe9;
}
.purpleSkin .page-nav .icon-button:hover {
  background-color: #9e50e7;
}
.greenSkin .page-nav .icon-button:hover {
  background-color: #24b527;
}
.turquoiseSkin .page-nav .icon-button:hover {
  background-color: #28afa4;
}
.redSkin .page-nav .icon-button:hover {
  background-color: #f64a52;
}
.orangeSkin .page-nav .icon-button:hover {
  background-color: #ef8c43;
}
.page-nav .icon-button:hover .icon {
  color: #fff !important;
}
.page-nav__options {
  float: left;
}
.page-nav__detail {
  float: left;
  display: inline;
  line-height: 32px;
  border-radius: 32px;
  padding: 0 16px;
  margin: 9px 8px 0 8px;
}
.dark-theme .page-nav__detail {
  color: white;
}
.light-theme .page-nav__detail {
  color: #111111;
}
.dark-theme .page-nav__detail {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme .page-nav__detail {
  background-color: rgba(0, 0, 0, 0.1);
}
.page-nav__left .icon-buton {
  float: right;
}
.page-nav__pages {
  float: right;
  padding: 0 16px;
}
.page-nav__link {
  float: left;
  display: inline;
  line-height: 48px;
  border-radius: 48px;
  min-width: 48px;
  padding: 0 8px;
  text-align: center;
  margin: 0 2px 0 2px;
  text-decoration: none;
  transition: all 0.2s ease;
  box-sizing: border-box;
}
.dark-theme .page-nav__link {
  color: white;
}
.light-theme .page-nav__link {
  color: #111111;
}
.dark-theme .page-nav__link {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme .page-nav__link {
  background-color: rgba(0, 0, 0, 0.1);
}
.page-nav__link--spacer {
  min-width: unset;
  background: transparent !important;
  pointer-events: none;
}
.page-nav__link--on, .page-nav__link:hover {
  color: #fff !important;
}
.blueSkin .page-nav__link--on, .blueSkin .page-nav__link:hover {
  background-color: #1947a2;
}
.aquaSkin .page-nav__link--on, .aquaSkin .page-nav__link:hover {
  background-color: #0d709a;
}
.pinkSkin .page-nav__link--on, .pinkSkin .page-nav__link:hover {
  background-color: #7a0c80;
}
.purpleSkin .page-nav__link--on, .purpleSkin .page-nav__link:hover {
  background-color: #4c0d87;
}
.greenSkin .page-nav__link--on, .greenSkin .page-nav__link:hover {
  background-color: #065e06;
}
.turquoiseSkin .page-nav__link--on, .turquoiseSkin .page-nav__link:hover {
  background-color: #085952;
}
.redSkin .page-nav__link--on, .redSkin .page-nav__link:hover {
  background-color: #7e0b11;
}
.orangeSkin .page-nav__link--on, .orangeSkin .page-nav__link:hover {
  background-color: #a75105;
}
@media only screen and (max-width: 583px) {
  .page-nav __right {
    display: inline;
    float: right;
  }
  .page-nav__left {
    float: left;
  }
  .sm--pushed .page-nav {
    opacity: 0;
  }
  .page-nav__detail {
    display: none;
  }
}
@media only screen and (max-width: 350px) {
  .page-nav__right .icon-button + .icon-button {
    display: none;
  }
}

.web-nav {
  display: block;
  height: auto;
  position: absolute;
  z-index: 201;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 0.5rem;
  transition: all 0.3s ease;
}
.web-nav--hide {
  margin-top: -6rem;
}
.web-nav .btn {
  border-radius: 40rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease;
  font-size: 0.875rem;
}

.footer {
  font-family: Lato, sans-serif;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(51, 20, 51, 0.75);
  backdrop-filter: blur(4px);
  height: 1.625rem;
  line-height: 1.625rem;
  display: flex;
  z-index: 204;
  box-sizing: content-box;
  animation: loadFooter 0.5s ease 0.5s 1 alternate backwards;
}
@media only screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}
.footer__col {
  flex: 1 1 auto;
  padding: 0 0.75rem;
  line-height: 1.625rem;
}
.footer__link {
  color: #fff;
  font-size: 0.75rem;
  padding: 0 0.5rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  display: inline;
  float: left;
  transition: background 0.35s ease;
  margin: 0;
  border-radius: 0.25rem;
  background: transparent;
}
.footer__col.right .footer__link {
  float: right;
}
.footer__link:hover {
  background: #7b307d;
  color: #fff;
}
.footer__separator {
  color: rgba(255, 255, 255, 0.2);
  display: inline;
  float: left;
  margin: 0 0.125rem;
}
.footer__col.right .footer__separator {
  float: right;
}
.footer__menu {
  opacity: 0;
  position: absolute;
  bottom: 1.5rem;
  border-radius: 0.25rem;
  background: #8e378f;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  font-size: 0.75rem;
  overflow: hidden;
  margin: 0 0 0 -0.625rem;
  white-space: nowrap;
  z-index: 5000;
  pointer-events: none;
  transform: scale3d(0.9, 0.9, 1);
  transition: opacity 0.35s, transform 0.35s;
  text-align: left;
  cursor: pointer;
}
.footer__link:hover .footer__menu {
  pointer-events: auto;
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.footer__col.right .footer__menu {
  right: 0;
}
.footer__menu-link {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 0.25rem 0.875rem 0.25rem 0.5rem;
  cursor: pointer;
  transform: background 0.35s ease;
}
.footer__menu-link:hover {
  background: #a03ea2;
  color: #fff;
}
.footer__menu-link .icon {
  margin-right: 0.25rem;
}
.footer__menu-link--disabled {
  display: block;
  color: #fff;
  cursor: default;
  padding: 0.25rem 0.875rem 0.25rem 0.5rem;
}
.footer__menu-link--disabled .icon {
  margin-right: 0.25rem;
}

@keyframes loadFooter {
  from {
    opacity: 0;
    transform: translate3d(0, 2rem, 1);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 1);
  }
}
.sidepanel {
  position: absolute;
  top: 0;
  bottom: 0;
  transform: scale3d(1, 1, 1);
  display: flex;
  height: auto;
  z-index: 49;
  transition: all ease 0.35s;
  opacity: 1;
  pointer-events: auto;
}
.sidepanel.sidepanel--right {
  right: 0;
  box-shadow: 0 2.8px 2.2px rgba(112, 144, 176, 0.02), 0 6.7px 5.3px rgba(112, 144, 176, 0.028), 0 12.5px 10px rgba(112, 144, 176, 0.035), 0 22.3px 17.9px rgba(112, 144, 176, 0.042), 0 41.8px 33.4px rgba(112, 144, 176, 0.05), 0 100px 80px rgba(112, 144, 176, 0.07);
}
.sidepanel.sidepanel--right.hide {
  opacity: 0;
  transform: scale3d(1, 1, 1) translateX(50%);
  pointer-events: none;
}
.sidepanel.large {
  width: 30rem;
}
.sidepanel.medium {
  width: 22rem;
}
.sidepanel.small {
  width: 14rem;
}
.sidepanel .e-control {
  flex: 1 1 auto;
}

.preloader {
  position: fixed;
  top: 0;
  z-index: 9999999;
  min-height: 480px;
  width: 100%;
  background: #572258;
}
.preloader__wrap {
  position: fixed;
  top: 0;
  z-index: 9999999;
  min-height: 480px;
  width: 100%;
  height: 100%;
  background: #572258;
  display: block;
}
.preloader__logo, .preloader__svg {
  margin: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  cursor: default;
  pointer-events: none;
}
.preloader__logo {
  top: 0;
  height: 100%;
  color: #fff;
}
.preloader--open .preloader__logo {
  opacity: 1;
  transform: none;
}
.preloader__logo-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
}
.preloader__logo-primary, .preloader__logo-secondary, .preloader__logo-text {
  min-width: 320px;
  max-width: 480px;
  width: 90%;
  flex: 0 0 auto;
  margin: 0 auto;
}
.preloader__logo-primary {
  height: 20px;
  flex: 1 1 20px;
  max-height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-top: 1rem;
}
.preloader__logo-text {
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
  text-align: center;
  padding: 2.5rem 0 1.5rem 0;
  font-family: Lato, sans-serif;
}
.preloader__logo .ui.loader {
  position: relative;
  flex: 1 1 auto;
  font-family: Lato, sans-serif;
}
.preloader.ui.inverted.dimmer .ui.loader {
  /*&::after{
      border-top-color: darken($primary, 15%);
  }*/
}

@keyframes animLoadHeader {
  from {
    opacity: 0;
    transform: translate3d(0, 800px, 0);
  }
}
@keyframes animLoadedLogo {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0) translate3d(0, 50px, 0) scale3d(0.65, 0.65, 1);
  }
}
@keyframes animLoadedLoader {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0) scale3d(0.3, 0.3, 1);
  }
}
@keyframes animLoadedHeader {
  to {
    transform: translate3d(0, -100%, 0);
  }
}
.b-avatar {
  box-sizing: border-box;
}
.b-avatar.inline {
  float: left;
  display: inline;
}
.b-avatar__image {
  display: block;
  clear: both;
  border: 0.125rem solid #2f6eea;
  overflow: hidden;
  border-radius: 5rem;
  margin: 0 auto 0.5rem auto;
  position: relative;
  /*
  img {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -2.5rem;
      margin-left: -2.5rem;
      width: 5rem;
      z-index: 2;

      .large & {
          width: 2.5rem;
          height: 2.5rem;
          margin-top: -1.25rem;
          margin-left: -1.25rem;
      }

      .medium & {
          width: 2rem;
          height: 2rem;
          margin-top: 1rem;
          margin-left: 1rem;
      }
  }*/
}
.b-avatar__image.primary {
  border-color: #802480;
}
.b-avatar__image.secondary {
  border-color: #0bb5c4;
}
.b-avatar__image.border--none {
  border: 0;
}
.inline .b-avatar__image {
  float: left;
  display: inline;
  margin: 0 auto 0 auto;
}
.large .b-avatar__image {
  width: 2.5rem;
  height: 2.5rem;
}
.medium .b-avatar__image {
  width: 2rem;
  height: 2rem;
  border-width: 0.0625rem;
}
.b-avatar__image .e-avatar {
  background-color: #572258;
}
.b-avatar__image .avatar__img {
  /*width: 100%;
  height: 100%;
  max-height: 5rem;
  z-index: 52;
  position: absolute;
  display: block;
  border-radius: 4rem;
  background-size: cover;
  background-position: center center;
  top: 0;*/
}
.b-avatar__image .icon {
  background: rgba(0, 0, 0, 0.3);
  margin-left: 0;
  opacity: 0.6;
}
.b-avatar__icon {
  position: relative;
  overflow: visible;
  display: block;
}
.b-avatar__icon .icon {
  position: absolute;
  margin: 1rem 0 0 -1.25rem;
  z-index: 55;
  display: none;
  padding: 0.25rem;
}
.b-avatar__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.large .b-avatar__details {
  min-height: 2.5rem;
}
.medium .b-avatar__details {
  min-height: 2rem;
}
.b-avatar__name {
  display: block;
  align-self: stretch;
  padding: 0 1.2rem;
  text-overflow: ellipsis;
  font-weight: 600;
}
.inline .b-avatar__name {
  float: left;
  display: inline;
  padding: 0 1.2rem 0 0.5rem;
}
.large .b-avatar__name {
  font-size: 1.125rem;
}
.medium .b-avatar__name {
  font-size: 0.875rem;
}
.b-avatar__company {
  display: block;
  align-self: stretch;
  padding: 0 1.2rem;
  text-overflow: ellipsis;
  font-weight: 600;
}
.inline .b-avatar__company {
  float: left;
  display: inline;
  padding: 0 1.2rem 0 0.5rem;
}
.large .avatar__name + .b-avatar__company {
  font-size: 0.875rem;
  font-weight: normal;
}
.large .b-avatar__company {
  font-size: 1.125rem;
}
.medium .avatar__name + .b-avatar__company {
  font-size: 0.75rem;
  font-weight: normal;
}
.medium .b-avatar__company {
  font-size: 0.875rem;
}

.b-slidedeck__container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
  z-index: 4;
  min-height: 280px;
  display: block;
  float: none;
}
.popupNew .b-slidedeck__container {
  top: 46px;
  bottom: 38px;
}
.popupNew.buttons--off .b-slidedeck__container {
  top: 46px;
  bottom: 0px;
}
.e-dialog .b-slidedeck__container {
  top: 67px !important;
}
.b-slidedeck__pane {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  display: inline;
  float: left;
  padding: 0;
  margin: 0;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  font: 16px/28px Lato, sans-serif;
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.b-slidedeck__pane.padding--zero {
  padding: 0;
}
.b-slidedeck__pane .form {
  overflow: auto;
  height: 100%;
}
.b-slidedeck__wrap--slide-indv .b-slidedeck__pane {
  display: flex;
  float: none;
  transition: all ease 0.55s;
  margin: 0;
  position: absolute;
}
.b-slidedeck__pane.slide--out-right {
  margin-left: 33%;
  opacity: 0;
  pointer-events: none;
}
.b-slidedeck__pane.slide--out-back {
  opacity: 0;
  pointer-events: none;
  transform: scale3d(0.9, 0.9, 1);
}
.b-slidedeck__wrap {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: margin ease 0.6s;
}
.b-slidedeck__wrap--panes-2 {
  width: 200%;
}
.b-slidedeck__wrap--panes-2 .slidedeck__pane {
  width: 50%;
}
.b-slidedeck__wrap--panes-3 {
  width: 300%;
}
.b-slidedeck__wrap--panes-3 .slidedeck__pane {
  width: 33.3333333333%;
}
.b-slidedeck__wrap--panes-4 {
  width: 400%;
}
.b-slidedeck__wrap--panes-4 .slidedeck__pane {
  width: 25%;
}
.b-slidedeck__wrap--panes-5 {
  width: 500%;
}
.b-slidedeck__wrap--panes-5 .slidedeck__pane {
  width: 20%;
}
.b-slidedeck__wrap--panes-6 {
  width: 600%;
}
.b-slidedeck__wrap--panes-6 .slidedeck__pane {
  width: 16.6666666667%;
}
.b-slidedeck__wrap--panes-7 {
  width: 700%;
}
.b-slidedeck__wrap--panes-7 .slidedeck__pane {
  width: 14.2857142857%;
}
.b-slidedeck__wrap--panes-8 {
  width: 800%;
}
.b-slidedeck__wrap--panes-8 .slidedeck__pane {
  width: 12.5%;
}
.b-slidedeck__wrap--cur-pane-1 {
  margin-left: 0%;
}
.b-slidedeck__wrap--cur-pane-2 {
  margin-left: -100%;
}
.b-slidedeck__wrap--cur-pane-3 {
  margin-left: -200%;
}
.b-slidedeck__wrap--cur-pane-4 {
  margin-left: -300%;
}
.b-slidedeck__wrap--cur-pane-5 {
  margin-left: -400%;
}
.b-slidedeck__wrap--cur-pane-6 {
  margin-left: -500%;
}
.b-slidedeck__wrap--cur-pane-7 {
  margin-left: -600%;
}
.b-slidedeck__wrap--cur-pane-8 {
  margin-left: -700%;
}
.b-slidedeck__content {
  display: block;
  text-align: center;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  padding: 3rem 3rem 2rem 3rem;
  font-family: Lato, sans-serif;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.b-slidedeck__content::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.b-slidedeck__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.b-slidedeck__content:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 767px) {
  .b-slidedeck__content {
    padding: 1.5rem 1.5rem 1rem 1.5rem;
  }
}
.b-slidedeck__content.center-v {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.b-slidedeck__content.align-left {
  text-align: left;
}
.b-slidedeck__content.flex-column {
  display: flex;
  flex-direction: column;
}
.b-slidedeck__content .vendor {
  margin-top: 2.5rem;
}
.b-slidedeck__content .formGen.formGen--white {
  background: none;
}
.b-slidedeck__content .formGen.formGen--white label,
.b-slidedeck__content .formGen.formGen--white label.highlight {
  color: #fff;
  text-shadow: none;
}
.b-slidedeck__content .formGen.formGen--white input {
  border-color: rgba(255, 255, 255, 0.4);
  color: #fff;
}
.b-slidedeck__content .formGen.formGen--white input.highlight,
.b-slidedeck__content .formGen.formGen--white input:focus {
  border-color: rgba(255, 255, 255, 0.8);
  color: #333;
  background-color: rgba(255, 255, 255, 0.4);
}
.b-slidedeck__content .formGen.formGen--white input.highlight:focus,
.b-slidedeck__content .formGen.formGen--white input:read-only:focus {
  background: none;
  color: #fff;
}
.b-slidedeck__content .form {
  min-height: unset;
  height: auto;
}
.b-slidedeck__content h3 {
  font: 500 1.25rem/2.125rem Lato, sans-serif;
  padding: 0 0.75rem;
  margin: 0;
}
.b-slidedeck__content h4,
.b-slidedeck__content h5 {
  padding: 0 0.75rem;
}
.b-slidedeck__content p {
  font: 1rem/1.5rem Lato, sans-serif;
  padding: 0.25rem 0.75rem;
  margin: 0;
}
.b-slidedeck__controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.popup--height-full .b-slidedeck__container {
  top: 0;
  bottom: 0;
}
.b-slidedeck__loading {
  display: block;
  position: relative;
  padding: 1.5rem 0 1.5rem 0;
}
.b-slidedeck__header {
  padding: 1rem 0;
  display: block;
}
.b-slidedeck__message {
  transition: all 0.35s ease;
  opacity: 1;
  margin: 0 auto;
  position: absolute;
  display: block;
  width: 100%;
}
.b-slidedeck__message.hide {
  opacity: 0;
  margin-top: 2rem;
}
.b-slidedeck__message.hide--move-up {
  opacity: 0;
  margin-top: -2rem;
}
.b-slidedeck__message-loop {
  display: block;
  height: 2.125rem;
  padding: 1rem 0 2.5rem 0;
  overflow: hidden;
  position: relative;
}
.b-slidedeck__note {
  color: #000;
  padding: 0.75rem;
}
.b-slidedeck__buttons {
  display: block;
  flex: 0 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0 0.5rem 0;
  background: #e8edf3;
  border-top: 1px solid #dee2e6;
}
.b-slidedeck__buttons .btn {
  margin-top: 0.5rem;
}
.b-slidedeck__content .e-upload {
  margin-top: -1rem;
}
.b-slidedeck__title {
  text-align: left;
  margin: -0.5rem 0 2rem 0;
}

.b-address {
  display: flex;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 767px) {
  .b-address {
    flex-direction: column-reverse;
  }
}
.b-address__card.ui.card {
  margin: 0 0 2rem 0;
}
.b-address__title {
  margin: 1rem 0.75rem 0 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: Lato, sans-serif;
  color: #572258;
  font-weight: bold;
}
.b-address .location {
  font-size: 0.875rem;
}
.b-address__content {
  display: block;
  flex: 1 1 auto;
  margin: auto 0;
  text-align: center;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .b-address__content {
    padding: 2rem 1rem;
  }
}
.b-address__info {
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: block;
  padding: 0 0.75rem;
  overflow: hidden;
}
.b-address__info-column {
  display: block;
  padding: 0.25rem 0 0.75rem 0;
  overflow: hidden;
}
.b-address__map {
  overflow: hidden;
  width: auto;
  height: auto;
  display: block;
  flex: 0 0 50%;
  background: #e8edf3;
  position: relative;
  border-radius: 0.5rem;
  min-height: 320px;
}
@media only screen and (max-width: 767px) {
  .b-address__map {
    flex: 0 0 320px;
  }
}
.b-address__map-inner {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.google__map .b-address__map-inner {
  height: calc(100% + 300px);
  margin: -150px 0;
}
.b-address__map-iframe {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0.5rem;
  overflow: hidden;
  display: block;
}
.b-address__map .leaflet-container {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.b-address__link {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  font-size: 0.875rem;
  color: #572258;
  transition: color 0.35s ease;
  backface-visibility: hidden;
  padding: 0.25rem 0;
}
.b-address__link:hover {
  color: #200d21;
  text-decoration: underline;
}
.b-address__link:hover .icon.small {
  text-decoration: none;
}
.b-address__link.placeholder {
  color: #89929b;
}
.b-address__link.placeholder:hover {
  color: #3d4c5c;
}
.b-address__link .icon.small {
  font-size: 1rem;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.b-toggle {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}
.b-toggle input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.ui.form .b-float-input .b-toggle label {
  cursor: pointer;
  display: block;
  background: #dee2e6;
  color: rgba(18, 38, 53, 0.6);
  font-size: 1rem;
  border-radius: 0.25rem;
  padding: 1rem 0.25rem 0.4375rem 0.5rem;
  transition: all 0.5s ease;
  height: 3.125rem;
  width: auto;
  position: static;
  margin: 0;
  pointer-events: unset !important;
}
.ui.form .b-float-input .b-toggle input:checked ~ label {
  background: rgba(27, 165, 138, 0.4);
  color: #212529;
  font-weight: 600;
}
.ui.form .b-float-input .b-toggle input:focus ~ label {
  background: rgba(34, 209, 175, 0.7) !important;
}
input:checked ~ .b-toggle__control {
  background-color: #178f78;
}
input:checked ~ .b-toggle__control:after {
  left: 1.5rem;
}
.b-toggle__control {
  position: absolute;
  top: 0.6375rem;
  right: 0.5rem;
  height: 1.75rem;
  width: 3rem;
  border-radius: 0.875rem;
  background-color: rgba(183, 9, 17, 0.45);
  transition: background-color 0.25s ease;
  pointer-events: none;
}
.b-toggle__control:after {
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.875rem;
  background: #fff;
  transition: left 0.25s ease;
}

::-ms-clear {
  display: none;
}

.b-float-input {
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]) {
  background: #dee2e6 !important;
  border: none !important;
  border-radius: 0.25rem !important;
  padding: 1.5rem 0.25rem 0.4375rem 0.5rem !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  font-family: Lato, sans-serif;
  /*ie*/
  /*edge*/
  /*ie*/
  /*edge*/
  /*ie*/
  /*edge*/
  /*note double :: for edge - will not work together with one*/
  /* not supported in edge :( */
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):focus {
  background: #22D1AF !important;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio])::placeholder {
  opacity: 1;
  transition: all 0.35s;
  color: rgba(0, 0, 0, 0.3);
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):-ms-input-placeholder {
  opacity: 0;
  transition: none;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio])::-ms-input-placeholder {
  opacity: 1;
  transition: all 0.2s;
  color: rgba(0, 0, 0, 0.3);
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):not(:focus)::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio])::placeholder-shown:focus {
  opacity: 1;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):placeholder-shown:not(:focus) + * {
  font-size: 1rem;
  opacity: 0.6;
  top: 1rem;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):not(:focus):-ms-input-placeholder + * {
  font-size: 18px;
  opacity: 0.6;
  top: 1rem;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):not(:focus)::-ms-input-placeholder + label {
  font-size: 1rem;
  opacity: 1;
  top: 1rem;
}
.ui.form .b-float-input input:not([type=checkbox]):not([type=radio]):focus + * {
  color: rgba(0, 0, 0, 0.6);
}
.b-float-input .ui.selection.dropdown {
  min-width: 4rem;
  border: 0;
  background: #dee2e6;
  min-height: 50px;
  padding: 1.625rem 0.5rem 0.5rem 0.5rem;
}
.b-float-input .ui.selection.dropdown.active {
  background: #22D1AF;
}
.b-float-input .ui.selection.dropdown.active + label {
  z-index: 11;
}
.b-float-input .ui.selection.dropdown > .text:not(.default) {
  font-weight: 600;
}
.b-float-input .ui.selection.dropdown:not(.button) > .default.text {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 600;
}
.b-float-input .ui.selection.dropdown .dropdown.icon {
  margin-top: -0.25rem !important;
}
.b-float-input .ui.selection.dropdown .menu .selected.item,
.b-float-input .ui.selection.dropdown .selected {
  background: rgba(34, 209, 175, 0.4);
}
.ui.form .b-float-input label {
  margin-left: 0.5rem;
  font: 500 0.75rem Lato, sans-serif;
  color: #122635;
  transform: none;
  top: 0.5rem;
  position: absolute;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.35s ease;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .ui.form .fields > .b-float-input {
    margin-bottom: 0.75rem;
  }
}

.modal {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
  flex-wrap: wrap;
  display: flex;
}
.modal.small-h {
  min-height: 550px;
}
@media (max-width: 1200px) {
  .modal {
    width: 96vw;
  }
}
@media (max-width: 768px) {
  .modal {
    align-items: center;
    height: 95%;
    min-height: auto;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .modal .content {
    padding: 0 !important;
  }
}
.modal .navigation {
  background-color: #F0F5F7;
  flex: 1 0 25%;
  padding: 1rem;
}
@media (max-width: 768px) {
  .modal .navigation {
    background-color: unset;
    align-self: flex-start;
    flex: 1 0 100%;
    height: 80vh;
    padding: 0;
  }
}
.modal .navigation-hide {
  display: none;
}
.modal .navigation-show {
  animation: fadeSlide 0.16s;
  display: block;
}
.modal .navigation .link {
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  padding: 0.5rem;
  transition: background-color 0.16s ease;
}
@media (max-width: 768px) {
  .modal .navigation .link {
    margin-bottom: 1rem;
    padding: 0.75rem;
  }
}
.modal .navigation .link-active {
  background-color: #dfeaee;
}
@media (max-width: 768px) {
  .modal .navigation .link-active {
    background-color: #dfeaee;
  }
}
.modal .content {
  flex: 1 0 75%;
  max-height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
}
@media (max-width: 768px) {
  .modal .content {
    flex: 1 0 100%;
    display: none;
    height: 80vh;
    max-height: 100%;
  }
}
.modal .content-panel {
  animation: fadeSlide 0.16s;
  display: none;
}
.modal .content-panel i {
  float: right;
}
.modal .content-panel-active {
  display: block;
}
.modal .content-active {
  animation: fadeSlide 0.16s;
  display: block;
}
.modal .context {
  align-self: flex-end;
  border-top: 1px solid #afacac;
  flex: 1 0 100%;
  padding: 1rem;
  margin-top: 1rem;
}
.modal-dimmer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.16s ease;
  width: 100vw;
}
.modal-active {
  opacity: 1;
  pointer-events: all;
}
.modal-mobile-control {
  display: none !important;
  margin-right: 0.5rem !important;
}
@media (max-width: 768px) {
  .modal-mobile-control {
    display: block;
  }
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.tabs {
  /*
  @include mq('tablet-wide') {
      .segment{
          padding-left:0;
          padding-right:0;

      }
  }*/
}
.tabs--main {
  margin-top: -3.5rem;
  overflow-x: hidden;
}
.tabs--main .ui.attached.segment {
  border-radius: 0;
  border: 0;
  background: none;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media only screen and (max-width: 767px) {
  .tabs--main .ui.attached.segment {
    padding-top: 1rem;
  }
}
.tabs--main .ui.tabular.menu {
  display: block;
  text-align: center !important;
  border: 0;
  min-height: unset;
  width: 100%;
  padding: 0 1rem 0 1rem;
  width: calc(1127px + 2rem);
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: 0px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: nowrap;
  scrollbar-face-color: transparent;
  scrollbar-track-color: transparent;
}
.tabs--main .ui.tabular.menu::-webkit-scrollbar {
  width: 0px !important;
  background: transparent;
  display: none;
}
.tabs--main .ui.tabular.menu::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 0;
}
@media only screen and (max-width: 1247px) {
  .tabs--main .ui.tabular.menu {
    width: calc(933px + 2rem) !important;
  }
}
@media only screen and (max-width: 1247px) {
  .tabs--main .ui.tabular.menu {
    width: calc(933px + 2rem) !important;
  }
}
.tabs--main .ui.tabular.menu .item {
  background: #c3b7c0;
  margin: 0 0.25rem;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.75rem 1.25rem;
  border: 0;
  transition: all 0.35s ease;
  display: inline-block;
}
.tabs--main .ui.tabular.menu .item.active {
  background: #f0f5f7;
  color: #572258;
}
.tabs--main .ui.tabular.menu .item:last-of-type {
  margin-right: 1rem;
}
.tabs-horizontal {
  text-align: left;
}
.tabs-horizontal-link {
  border-bottom: 2px solid #89929b;
  cursor: pointer;
  padding: 0 1rem 0.5rem;
  margin: 0 1.5rem 1rem 0;
}
.tabs-horizontal-active {
  border-color: #572258;
}
.tabs-content {
  animation: fadeSlide 0.16s;
  padding: 0 !important;
}
.tabs-content-active {
  display: block;
}
.tabs-content .margin-fix {
  margin: 0 !important;
}
@media only screen and (max-width: 767px) {
  .tabs .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@keyframes fadeSlide {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.filter {
  display: flex;
  width: 100%;
  margin-top: 0.75rem;
}
.filter__col {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.filter__col + .filter__col {
  align-items: flex-end;
  white-space: nowrap;
  flex-direction: row-reverse;
}
.filter .ui.label {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: Lato, sans-serif;
  padding: 0.5rem 0.75rem;
  animation: searchZoomIn 0.35s ease 0.35s 1 alternate backwards;
}
.filter .ui.label.bg--none {
  background: none;
}
.filter .map.marker.icon {
  font-size: 1rem;
}
.filter .ui.toggle.checkbox {
  margin: -0.5rem 0;
}
.filter .ui.toggle.checkbox input,
.filter .ui.toggle.checkbox .box:before,
.filter .ui.toggle.checkbox label:before {
  width: 3rem;
}
.filter .ui.toggle.checkbox input:checked ~ .box:before,
.filter .ui.toggle.checkbox input:checked ~ label:before {
  background-color: #802480 !important;
}
.filter .ui.toggle.checkbox input:checked ~ .box:after,
.filter .ui.toggle.checkbox input:checked ~ label:after {
  left: 1.5rem;
}
.filter .ui.icon.button.dropdown {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  overflow: unset;
  animation: searchZoomIn 0.35s ease 0.35s 1 alternate backwards;
}
.filter .ui.icon.button.dropdown .menu > .item {
  font-size: 0.875rem;
}
.b-left-panel .filter {
  background: #fff;
  border-radius: 0.5rem;
  margin: 0 1rem;
  padding-bottom: 0.5rem;
  width: auto;
  flex-direction: column-reverse;
  width: 100%;
}
.b-left-panel .filter__col {
  width: 100%;
}
.b-left-panel .filter__col + .filter__col {
  border-bottom: 1px dashed #ced4da;
  padding-bottom: 0.5rem;
}

.b-searchbar {
  /*semantic*/
  /* Sync fusion */
}
.b-searchbar .ui.segment {
  border: 0;
  background: #572258;
  animation: searchBarAni 0.35s ease 0.35s 1 alternate backwards;
}
@media only screen and (max-width: 1023px) {
  .b-searchbar .ui.segment {
    padding: 0.75rem 0;
  }
}
@media only screen and (max-width: 1023px) {
  .b-searchbar .ui.form .fields {
    flex-direction: column;
    margin: 0.25rem 0.5rem;
  }
}
.b-searchbar .ui.form .fields:last-of-type {
  margin-bottom: 0;
}
.b-searchbar .ui.form .fields select {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  color: #fff;
  font-size: 1.125rem;
  height: 50px;
  transition: height 0.35s ease;
}
.b-searchbar .ui.form .fields select:required:invalid {
  color: #a383a3;
}
.b-searchbar .ui.form .fields select[disabled], .b-searchbar .ui.form .fields select:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.b-searchbar .ui.form .fields select:not(:focus):invalid {
  color: #a383a3;
}
.b-searchbar .ui.form .fields select:focus-visible {
  border-color: #802480;
  box-shadow: none;
}
.b-searchbar .ui.form .fields select option {
  color: #131619;
}
.b-searchbar .ui.form .fields select option[value=""][disabled] {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .b-searchbar .ui.form .fields select {
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 42px;
  }
}
.b-searchbar .ui.form .fields > .field {
  padding: 0;
}
.b-searchbar .ui.form .fields > .field:first-child {
  padding-left: 0.5rem;
}
.b-searchbar .ui.form .fields > .field:first-child input {
  border-radius: 0.25rem 0 0 0.25rem;
}
.b-searchbar .ui.form .fields > .field:last-child input,
.b-searchbar .ui.form .fields > .field:last-child select {
  border-radius: 0 0.25rem 0.25rem 0;
}
@media only screen and (max-width: 1023px) {
  .b-searchbar .ui.form .fields > .field {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
    margin: 0 !important;
  }
}
.b-left-panel .b-searchbar .ui.form .fields {
  flex-direction: column;
}
.b-left-panel .b-searchbar .ui.form .fields .ui.segment {
  padding: 0.75rem 0;
}
.b-left-panel .b-searchbar .ui.form .fields > .field {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  margin: 0 !important;
}
.b-left-panel .b-searchbar .ui.form .fields input {
  border-radius: 0.25rem;
  font-size: 1rem;
}
.b-left-panel .b-searchbar .ui.form .fields select {
  border-radius: 0.25rem;
  font-size: 1rem;
  height: 42px;
}
.b-searchbar .ui.form input[type=search],
.b-searchbar .ui.form input[type=text] {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0;
  color: #fff;
  font-size: 1.25rem;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
  transition: border 0.35s ease;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.b-searchbar .ui.form input[type=search]::placeholder,
.b-searchbar .ui.form input[type=text]::placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.b-searchbar .ui.form input[type=search]:focus,
.b-searchbar .ui.form input[type=text]:focus {
  border-color: #e2ab3b !important;
}
.b-left-panel .b-searchbar .ui.form input[type=search],
.b-left-panel .b-searchbar .ui.form input[type=text] {
  border: 0;
  font-size: 1rem;
}
@media only screen and (max-width: 1023px) {
  .b-searchbar .ui.form input[type=search],
  .b-searchbar .ui.form input[type=text] {
    border-radius: 0.25rem;
    font-size: 1rem;
    border-right: 0;
  }
}
.b-searchbar .ui.button {
  margin-bottom: 0.25rem;
  height: 50px;
}
.b-searchbar .ui.button:first-of-type {
  margin-left: 0.25rem;
}
@media only screen and (max-width: 1023px) {
  .b-searchbar .ui.button {
    margin: 0.25rem 0.5rem !important;
    height: 44px;
  }
  .b-searchbar .ui.button .button__text.hidden {
    display: inline-block;
    max-width: 500px;
    padding-right: 1.5rem;
  }
  .b-searchbar .ui.button i.icon {
    float: left;
    display: inline;
  }
}
.b-left-panel .b-searchbar .ui.button {
  margin: 0.25rem 0.5rem;
  height: 44px;
}
.b-left-panel .b-searchbar .ui.button .button__text.hidden {
  display: inline-block;
  max-width: 500px;
  padding-right: 1.5rem;
}
.b-left-panel .b-searchbar .ui.button i.icon {
  float: left;
  display: inline;
}
.b-searchbar i.icon {
  font-size: 1rem;
}
.b-searchbar .e-input-group {
  border: 0 !important;
  border-right: 1px solid rgba(255, 255, 255, 0.25) !important;
}
.b-searchbar .e-input-group.e-input-focus {
  border: 0;
}
.b-searchbar .e-input-group.e-input-focus:before, .b-searchbar .e-input-group.e-input-focus:after {
  display: none;
}
.b-searchbar .e-input-group input.e-input, .b-searchbar .e-input-group.e-input-focus input.e-input {
  padding: 0.875rem 1.25rem !important;
}
.ui.form .b-searchbar .e-input-group.e-input-focus input[type=search]:focus, .ui.form .b-searchbar .e-input-group.e-input-focus input[type=text]:focus {
  border: 0 !important;
}
.b-searchbar .e-input-group .e-ddl-hidden {
  display: none;
}
.b-searchbar .e-input-group input.e-input {
  border-right: 0px !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.b-searchbar .e-input-group input.e-input:focus {
  border: none !important;
}
.b-searchbar .e-input-group input.e-input,
.b-searchbar .e-input-group textarea.e-input,
.b-searchbar .e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.b-searchbar .e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]), .b-searchbar .e-input-group.e-control-wrapper input.e-input, .b-searchbar .e-input-group.e-control-wrapper textarea.e-input, .b-searchbar .e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus) {
  border: 0px;
}
.b-searchbar .e-input-group .e-input-group-icon {
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 0.75rem;
}
.b-searchbar .e-input-group .e-input-group-icon:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}
.b-searchbar .e-input-group .e-clear-icon {
  padding-right: 0.5rem;
  color: rgba(255, 255, 255, 0.6) !important;
  background: rgba(255, 255, 255, 0.1) !important;
}

@keyframes searchBarAni {
  from {
    opacity: 0;
    transform: translate(0, -12px);
    pointer-events: none;
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
  }
}
.btn__wrapper {
  display: block;
  overflow: hidden;
}
.btn__group {
  display: inline;
}
.btn__group.left {
  float: left;
}
.btn__group.right {
  float: right;
}
.btn__group.right button {
  float: right;
}

.b-list__wrap {
  overflow: hidden;
}
.b-list__wrap.secondary, .b-list__wrap.ui.secondary.segment {
  background: #0bb5c4;
}
.b-list__wrap.ui.primary.segment {
  background: #802480;
}
.b-list.links {
  margin: -0.75rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  font-weight: bold;
}
.spacing-x15 .b-list.links {
  margin: -1.5rem -1rem -1.5rem -1rem;
}
.b-list.links .b-list__item {
  display: block;
  padding: 0;
  margin: 0;
}
.b-list.links .b-list__link {
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  border-radius: 0.375rem;
}
.b-list.links .b-list__link font-family:Arial, .b-list.links .b-list__link Helvetica, sans-serif .spacing-x15 .b-list.links .b-list__link {
  padding: 0.75rem 1rem;
}
.b-list.links .b-list__link:hover {
  background: #581958;
}
.b-list__text.no-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.b-list.links i.icon {
  margin-right: 1rem;
}
.b-list.links i.icon.corner.right {
  margin: 0;
  right: 0.75rem;
  bottom: 0.125rem;
  text-shadow: -1px -1px 0 #802480, 1px -1px 0 #802480, -1px 1px 0 #802480, 1px 1px 0 #802480;
}

.b-list-people {
  margin: -0.25rem 0 -0.5rem 0;
}
.b-list-people__title {
  display: block;
  padding: 3rem 0 1.5rem 0;
}
.b-list-people__title.center {
  text-align: center;
}
.b-list-people__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}
.b-list-people__list.center {
  align-items: center;
  justify-content: center;
}
.b-list-people__list .b-list-people__item {
  border: 0;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
  margin: 0 0rem;
  text-align: left;
}
.b-list-people__list .b-list-people__item.center {
  text-align: center;
}
.b-list-people__list.row {
  flex-direction: row;
}
.b-list-people__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  padding: 0.25rem 0 0.25rem 0;
  border-bottom: 1px solid #e8edf3;
  width: 100%;
}
.b-list-people__item:last-child {
  border: 0;
}
.b-list-people__list.row .b-list-people__item {
  margin-right: 0.75rem;
}
.b-list-people__item.center {
  text-align: center;
}
.b-list-people__link {
  color: #07727c;
  font-size: 0.875rem;
  flex: 1 1 auto;
  transition: text-decoration 0.5s ease;
  text-decoration-color: rgba(11, 181, 196, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.b-list-people__list.center .b-list-people__link {
  text-align: center;
}
.b-list-people__link strong {
  display: block;
  font-size: 1rem;
  color: #802480;
}
.b-list-people__link:hover {
  color: #07727c;
  text-decoration: underline;
}
.b-list-people__link:hover strong {
  color: #441344;
}
.b-list-people__link:hover .b-list-people__image {
  border-color: rgba(11, 181, 196, 0.6);
}
.b-list-people__link:hover .b-list-people__image .e-avatar {
  transform: scale3d(1.2, 1.2, 1);
}
.b-list-people__icons {
  padding-left: 0.75rem;
  width: 94px;
  flex: 0 0 94px;
  text-align: right;
}
.b-list-people__icons button {
  margin: 0.25rem 0 0 0.25rem !important;
}
.b-list-people__name {
  display: block;
  color: #802480;
  font-weight: 600;
}
.b-list-people__job {
  display: block;
  color: #0bb5c4;
}
.b-list-people__image {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  transition: all ease 0.35s;
  margin: 0 1rem 0 0;
  overflow: hidden;
}
.b-list-people__list.row .b-list-people__image {
  margin: 0;
}
.b-list-people__image.large {
  width: 8rem;
  height: 8rem;
  border-radius: 8rem;
}
.b-list-people__image.large .e-avatar {
  width: 8rem;
  height: 8rem;
  border-radius: 8rem;
  background-size: cover;
  margin: -0.1875rem;
  transition: all 0.35s ease;
}
.b-list-people__image.medium {
  width: 6rem;
  height: 6rem;
  border-radius: 6rem;
}
.b-list-people__image.medium .e-avatar {
  width: 6rem;
  height: 6rem;
  border-radius: 6rem;
  background-size: cover;
  margin: -0.1875rem;
  transition: all 0.35s ease;
}
.b-list-people__image.small {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
}
.b-list-people__image.small .e-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  background-size: cover;
  margin: -0.1875rem;
  transition: all 0.35s ease;
}
.b-list-people__image.tiny {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
}
.b-list-people__image.tiny .e-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  background-size: cover;
  margin: -0.1875rem;
  transition: all 0.35s ease;
}
.b-list-people__image.border {
  border: 3px solid rgba(11, 181, 196, 0.3);
}
.b-list-people__image + .b-list-people__info {
  margin-top: 0.75rem;
}
.b-list-people__info {
  display: block;
}

.b-award__wrap {
  display: block;
  overflow: hidden;
  text-align: center;
}
.slick-slider + .b-award__wrap {
  margin-top: 2rem;
}
.b-award {
  display: inline-block;
  margin: 0 0.5rem 1rem 0.5rem;
  height: 7.875rem;
  width: auto;
}
.b-award__wrap.small .b-award {
  height: 6rem;
}
.b-award__wrap.tiny .b-award {
  height: 4.5rem;
}
.b-award svg {
  width: 100% !important;
  height: 100% !important;
}
.b-award img {
  width: auto !important;
  height: 100% !important;
}

.side-filter {
  background: #e8edf3;
  height: calc(100vh - 1rem);
  width: 400px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 180;
  transform: translate(200px, 0) scale3d(0.9, 0.9, 1);
  transition: opacity 0.5s, transform 0.5s;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0.5rem;
}
.side-filter.open {
  opacity: 1;
  pointer-events: all;
  transform: translate(0, 0) scale3d(1, 1, 1);
}
@media only screen and (max-width: 479px) {
  .side-filter {
    width: calc(100vw - 1rem);
    height: calc(100vh - 2rem);
  }
}
.side-filter__header {
  background: #572258;
  color: #fff;
  padding: 1rem;
  top: 0;
  width: 100%;
  z-index: 100;
  flex: 0 0 56px;
}
.side-filter__header h3 {
  line-height: normal;
}
.side-filter__header .ui.button.icon {
  float: right;
  margin: -0.375rem -0.375rem -0.375rem 0;
  color: #fff;
  background: transparent;
  transition: all 0.35s ease;
  width: 2.25rem;
  height: 2.25rem;
}
.side-filter__header .ui.button.icon > i.icon {
  margin: -0.125rem -0.25rem 0 -0.25rem !important;
}
.side-filter__header .ui.button.icon:hover {
  background: #331433;
}
.side-filter__filters {
  padding: 0rem 1rem 1rem 1rem;
}
.side-filter__filters a.ui.label {
  background-color: #ced4da;
  transition: background-color 0.35s ease;
}
.side-filter__filters a.ui.label:hover {
  background-color: #b1bbc4;
}
.side-filter__filters a.ui.label:hover i.icon {
  color: #000 !important;
}
.side-filter__filters span {
  border: 1px solid #802480;
  border-radius: 15px;
  background-color: #fff;
  display: inline-block;
  padding: 0.25rem 0.5rem;
}
.side-filter__filters span i {
  color: #802480;
  margin-left: 0.25rem;
}
.side-filter__topic {
  background: #fff;
  border-radius: 5px;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
}
.side-filter__content {
  flex: 1 1 auto;
  overflow: auto;
  padding: 1rem 0 0 0;
  margin: 0;
}
.side-filter__footer {
  flex: 0 0 72px;
  padding: 1rem;
  overflow: hidden;
  bottom: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #ced4da;
}
.side-filter__shade {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 20, 51, 0.8);
  opacity: 0;
}
.side-filter__shade.open {
  pointer-events: auto;
  opacity: 1;
  z-index: 180;
}

.b-pagination {
  position: sticky;
  width: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  padding: 0.5rem;
  bottom: 0;
  border-top: 1px solid #fff;
}
.b-left-panel + .b-pagination {
  width: 24.25rem;
  position: absolute;
}
@media only screen and (max-width: 1023px) {
  .b-left-panel + .b-pagination {
    position: sticky;
    width: 100%;
  }
}
.b-pagination-controls {
  border-radius: 5px;
  box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow: hidden;
  /*
  @media (max-width: 583px) {
      .item {
          display: none !important;

          &:nth-child(-n + 2) {
              display: block !important;
          }

          &:nth-last-child(-n + 2) {
              display: block !important;
          }
      }
  }*/
}
.b-pagination-controls__back, .b-pagination-controls__forward {
  background-color: #0bb5c4 !important;
  color: #fff !important;
}
.b-pagination-controls .active {
  color: #572258 !important;
}

/*from cdn*/
.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after, .slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
} 
/*theme*/
.slick-dots, .slick-next, .slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots li button:before, .slick-next:before, .slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-loading .slick-list {
  background: center center no-repeat #fff;
}

.slick-next, .slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}

.slick-next:focus:before, .slick-next:hover:before, .slick-prev:focus:before, .slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before, .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

.slick-next:before, [dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

[dir=rtl] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.slick-dots li button:focus, .slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before, .slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: #000;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
} 
/**/
.slick {
  /*
  &__card{

  } 
  */
}
.slick-dots {
  position: relative !important;
  bottom: 0 !important;
}
.slick-dots li {
  width: 3rem;
  height: 0.375rem;
}
.slick-dots li button {
  width: inherit;
  height: inherit;
  padding: 0;
  background: rgba(7, 114, 124, 0.15);
  transition: background 0.75s ease;
  border-radius: 0.125rem;
}
.slick-dots li button:before {
  width: inherit;
  height: inherit;
  font-size: 0.5rem !important;
  content: " ";
}
.slick-dots li.slick-active button {
  background: #0bb5c4;
}
.slick__quote {
  font-size: 1.5rem;
  font-style: italic;
  line-height: 2rem;
}
.slick__title {
  font-weight: 600;
}
.slick-arrow {
  display: none;
}

.b-map {
  position: absolute;
  background: #999;
  height: 100%;
  max-height: 100vh;
  width: 100%;
  right: 0;
  left: auto;
  bottom: 0;
  top: 0;
  display: block;
  clip-path: circle(96px at 98% 3%);
  overflow: hidden;
  animation: mapZoomIn 0.35s ease 0.55s 1 alternate backwards;
  transition: all 0.75s ease;
}
@media only screen and (max-width: 1500px) {
  .b-map {
    opacity: 0;
  }
}
.b-map.open {
  width: 100%;
  margin: 0;
  clip-path: circle(150% at 98% 3%);
  opacity: 1;
}
@media only screen and (max-width: 1023px) {
  .b-map.open {
    clip-path: circle(96px at 98% 3%);
    opacity: 0;
  }
}
.b-map__image {
  width: 100%;
  height: 100%;
  background: #c3b7c0;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.b-map__image .leaflet-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.b-map__switch {
  position: absolute;
  right: -3.5rem;
  top: -4rem;
  bottom: 0;
  display: block;
  width: 12rem;
  height: 12rem;
  z-index: 15;
  padding: 0;
}
.b-map__switch .ui.button.icon {
  height: 12rem;
  width: 12rem;
  border-radius: 12rem;
  max-height: unset;
  padding: 0;
  position: relative;
  background: rgba(11, 181, 196, 0.5);
  transition: background-color 0.35s ease, transform 0.55s ease, opacity 0.35s ease;
  pointer-events: unset;
}
.b-map__switch .ui.button.icon:hover {
  background: rgba(11, 181, 196, 0.65);
}
.b-map__switch .ui.button.icon > i.icon {
  font-size: 2.25rem;
  margin: 0.5rem 0 0 -2.25rem !important;
  position: absolute;
  transition: color 0.45s ease;
  opacity: 1;
  color: #572258;
}
.b-map.open .b-map__switch .ui.button.icon {
  pointer-events: none;
  background: rgba(87, 34, 88, 0);
  transform: scale(0%);
}
.b-map__switch--list {
  pointer-events: none;
  transform: scale(150%);
  transition: transform 0.55s ease;
}
.b-map.open .b-map__switch--list {
  pointer-events: unset;
  transform: scale(100%);
}
.b-map__switch--list .ui.button.icon {
  background: rgba(87, 34, 88, 0.3);
  opacity: 0;
  pointer-events: none;
}
.b-map.open .b-map__switch--list .ui.button.icon {
  background: rgba(11, 181, 196, 0.5);
  transform: scale(100%);
  pointer-events: unset;
  opacity: 1;
}
.b-map.open .b-map__switch--list .ui.button.icon:hover {
  background: rgba(11, 181, 196, 0.65);
}

body .Toastify__toast--default {
  color: #122635;
}
body .Toastify__progress-bar--default {
  background: #0bb5c4;
}
.Toastify__toast-body {
  padding: 0 1rem;
}

.b-sticky-header {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1044px;
  color: #fff;
}
@media only screen and (max-width: 1247px) {
  .b-sticky-header {
    max-width: 988px;
    padding: 0 1rem;
  }
}
.b-sticky-header__wrapper {
  position: fixed;
  background: rgba(69, 27, 70, 0.92);
  backdrop-filter: blur(2px);
  overflow: hidden;
  z-index: 10;
  width: 100%;
  padding: 0 0 0.675rem 0;
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
  pointer-events: none;
  transition: all 0.35s ease;
}
.slide-enter-done .b-sticky-header__wrapper.isSticky {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  pointer-events: all;
}
@media only screen and (max-width: 767px) {
  .slide-enter-done .b-sticky-header__wrapper.isSticky {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);
    pointer-events: none;
  }
}
.b-sticky-header__row {
  display: flex;
  flex-direction: row;
}
.b-sticky-header__col {
  flex: 1 1 auto;
}
.b-sticky-header__col.right {
  text-align: right;
}
.b-sticky-header__col--avatar {
  flex: 0 0 4.5rem;
  width: 4.5rem;
  padding: 0.875rem 0 0 0;
}
.b-sticky-header__col--avatar .e-avatar {
  width: 3.5rem;
  height: 3.5rem;
}
.b-sticky-header .label.tiny.label {
  padding: 0.25rem 0.5rem !important;
}
.b-sticky-header .meta {
  display: inline-block;
  margin-left: 0.25rem;
  color: #fff;
  font-size: 0.75rem;
}
.b-sticky-header .meta a {
  color: #fff;
}
.b-sticky-header .meta .icon {
  color: #0bb5c4;
}
.b-sticky-header .card__hero-buttons {
  display: inline-block;
  margin-top: 1.5rem;
}
.b-sticky-header h3 {
  margin: 0.75rem 0 0.25rem 0;
  font-weight: 600;
}
.b-sticky-header .ui.button.primary.inverted {
  color: #fff !important;
}

.weather {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.weather__details {
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 0.25rem;
}
.weather__icon {
  width: 4rem;
  text-align: center;
}
.weather__temp {
  width: 4rem;
  font-size: 2rem;
  text-align: center;
}

.project {
  display: flex;
  flex-direction: row;
  margin: 1rem;
  background: #fff;
  box-shadow: 0 2.8px 2.2px rgba(112, 144, 176, 0.02), 0 6.7px 5.3px rgba(112, 144, 176, 0.028), 0 12.5px 10px rgba(112, 144, 176, 0.035), 0 22.3px 17.9px rgba(112, 144, 176, 0.042), 0 41.8px 33.4px rgba(112, 144, 176, 0.05), 0 100px 80px rgba(112, 144, 176, 0.07);
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}
.project__side-bar {
  flex: 0 0 2.125rem;
  display: flex;
  flex-direction: column;
  padding-top: 0.25rem;
}
.project__side-bar .icon {
  color: #fff !important;
  margin: 0.5rem auto;
}
.project__side-bar .icon-button {
  border-radius: 0.5rem;
}
.project__side-bar .icon-button:hover {
  background: rgba(255, 255, 255, 0.3) !important;
}
.project__side-bar .icon-button .icon {
  color: #fff !important;
}
.project__side-bar .icon-button.bottom {
  position: absolute;
  bottom: 0rem;
  left: 0;
}
.project__contents {
  flex: 1 1 auto;
  min-height: 280px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.listWrapper .project__contents {
  min-height: 180px;
}
.project__header {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
}
.project__header.center {
  justify-content: center;
  align-items: center;
}
.project__header-link {
  flex: 1 1 auto;
  color: #000;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  transition: background ease 0.35s;
}
.listWrapper .project__header-link {
  pointer-events: none;
}
.project__header-link:hover {
  background: rgba(128, 36, 128, 0.15);
}
.project__header-link .icon {
  flex: 0 0 2.5rem;
  margin: auto 0 auto 0.75rem;
}
.listWrapper .project__header-link .icon {
  display: none;
}
.project__header .h3 {
  padding: 1rem 1.5rem 0.75rem 1rem;
  display: block;
  flex: 1 1 auto;
}
.padding--x2 .project__header .h3 {
  padding: 2rem 2.5rem 1.5rem 2rem;
}
.project__header .h3 a .icon {
  text-decoration: none;
}
.project__header-people-link {
  flex: 0 0 5.75rem;
  display: block;
  height: 100%;
  margin: auto 0.5rem auto 0;
  text-decoration: none;
}
.project__header-people-link .e-avatar-stack {
  margin-top: 0.75rem;
  font-size: 0.875rem;
}
.project__activity-feed {
  border-top: 1px solid #e8edf3;
}
.project__des {
  padding: 1rem 1rem 1rem 1rem;
  line-height: 1.2rem;
  font-size: 0.75rem;
  flex: 0 1 44px;
}
.padding--x2 .project__des {
  padding: 0rem 2rem 2rem 2rem;
}
.project__footer {
  flex: 0 0 56px;
  padding: 0.5rem 0.75rem 0.75rem 0.5rem;
}
.listWrapper .project--simple {
  margin: -0.5rem -1rem -1rem -1rem;
  box-shadow: none;
  background: none;
  border-radius: 0;
}

.b-event-card {
  display: flex;
  background: #fff;
  padding: 2rem;
  border-radius: 0.375rem;
  transition: background 0.35s ease, transform 0.5s ease;
  margin: 1.5rem 1rem 1rem 1rem;
}
.b-event-card.active {
  cursor: pointer;
}
.b-event-card.active:hover {
  background: #89eff8;
  transform: scale(1.025);
}
@media only screen and (max-width: 1023px) {
  .b-event-card.ui.b-padding--x3 {
    padding: 1.5em;
  }
}
@media only screen and (max-width: 583px) {
  .b-event-card.ui.b-padding--x3 {
    padding: 1.25em;
    margin: 1rem 0rem 0.25rem 0rem;
  }
}
.b-event-card__details {
  flex: 1 1 auto;
  padding-right: 1rem;
}
@media only screen and (max-width: 583px) {
  .b-event-card__details.ui.b-padding--x3 {
    padding: 1.5em;
  }
}
.b-event-card__details .h2 {
  padding-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.75rem;
}
@media only screen and (max-width: 1023px) {
  .b-event-card__details .h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
@media only screen and (max-width: 583px) {
  .b-event-card__details .h2 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
@media only screen and (max-width: 583px) {
  .b-event-card__details .h4 {
    font-size: 0.875rem;
  }
}
.b-event-card__details .h4 + .h4 {
  padding-bottom: 1rem;
}
@media only screen and (max-width: 583px) {
  .b-event-card__details .h4 + .h4 {
    display: none;
  }
}
.b-event-card__details .p {
  color: #122635;
}
@media only screen and (max-width: 767px) {
  .b-event-card__details .p {
    display: none;
  }
}
.b-event-card__time {
  margin-left: 0.5rem;
  display: inline-block;
}
@media only screen and (max-width: 583px) {
  .b-event-card__time {
    margin-left: 0.25rem;
  }
}
.b-event-card__date {
  display: inline-block;
  margin-right: 0.5rem;
}
@media only screen and (max-width: 767px) {
  .b-event-card__date {
    margin-right: 0.25rem;
  }
}
.b-event-card__date .month--long {
  display: inline-block;
}
.b-event-card__date .month--short {
  display: none;
}
@media only screen and (max-width: 767px) {
  .b-event-card__date .month--long {
    display: none;
  }
  .b-event-card__date .month--short {
    display: inline-block;
  }
}
.b-event-card__img {
  flex: 0 0 300px;
  background-color: #dee2e6;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  border-radius: 0.25rem;
}
@media only screen and (max-width: 1023px) {
  .b-event-card__img {
    flex: 0 0 150px;
    height: 150px;
  }
}
@media only screen and (max-width: 583px) {
  .b-event-card__img {
    flex: 0 0 100px;
    height: 100px;
  }
}
.b-event-card__labels {
  padding-top: 0.75rem;
  display: block;
}
.b-event-card__user {
  padding-top: 0.75rem;
  display: block;
}
@media only screen and (max-width: 583px) {
  .b-event-card__user {
    padding-top: 0.5rem;
    font-size: 0.75rem;
  }
}

.programme__item {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0;
}
.programme__time {
  flex: 0 0 80px;
  padding: 0 1rem 0 0;
}
.programme__des {
  flex: 1 1 auto;
}

.homepage {
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  perspective: 80vh;
  pointer-events: all;
}

.reg__wrapper {
  background: #e8edf3;
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: auto;
  padding-bottom: 4rem;
}
@media only screen and (max-width: 767px) {
  .reg__wrapper .ui.icon.header .circular.icon {
    font-size: 2.5rem;
    margin-top: -1rem;
  }
}
.reg__wrapper .ui.segment {
  border-radius: 1rem;
}
@media only screen and (max-width: 583px) {
  .reg__wrapper .btn__wrapper button {
    display: block;
    float: none;
    margin: 0.5rem 0;
    width: 100%;
  }
}
@media only screen and (max-width: 583px) {
  .reg__wrapper .btn__group {
    display: block;
    float: none;
  }
  .reg__wrapper .btn__group.left {
    margin-top: 1rem;
  }
}

.b-profile-header {
  background: #451b46;
  overflow: hidden;
  width: 100%;
  pointer-events: all;
}
@media (max-width: 583px) {
  .b-profile-header {
    margin-top: 56px !important;
    padding-top: 1rem;
  }
}
.b-profile__image-wrap {
  margin: -9rem -8rem -9rem 0;
  position: absolute;
  height: 32rem;
  width: 32rem;
  background-color: #802480;
  border-radius: 16rem;
  right: 0;
  overflow: hidden;
}
.b-profile__image-wrap .e-avatar.e-avatar-large {
  transition: all 0.5s ease;
  background-color: #802480;
  width: 32rem;
  height: 32rem;
  line-height: 6rem;
  font-size: 2.75rem;
  margin: 0 0 0 0;
  font-style: normal;
  background-size: cover;
  background-repeat: none;
  position: relative;
  display: block;
  text-align: center;
}
.b-profile__image-wrap .e-avatar.e-avatar-large img {
  display: block;
}
.b-profile__image > img {
  transition: all 0.5s ease;
  border: 2px solid #fff;
  background-color: #ced4da;
}
@media (max-width: 1024px) {
  .b-profile-hero {
    width: 100% !important;
  }
}
.b-profile-hero.ui {
  align-items: center;
  background: none;
  border: none !important;
  color: #fff;
  display: flex;
  padding: 0 !important;
  border-radius: 0;
  min-height: 380px;
}
@media (max-width: 1024px) {
  .b-profile-hero.ui {
    padding: 0 2rem !important;
  }
}
@media (max-width: 425px) {
  .b-profile-hero.ui {
    padding: 0 !important;
  }
}
.b-profile-hero .card {
  background: none;
  border: 0;
  border-radius: 0;
  overflow: visible;
  box-shadow: none;
  webkit-box-shadow: none;
}
.b-profile-hero .card .content {
  padding: 0;
}
.b-profile-hero .header .ui.label.circular {
  padding: 0.375rem 0.625rem !important;
  margin-right: 0.375rem;
  margin-left: 0;
}
.b-profile-hero .ui.button.big {
  margin-right: 0.75rem;
}
.b-profile-hero .ui.button.big.inverted {
  box-shadow: 0 0 0 2px #802480 inset !important;
  color: #fff !important;
  background-color: transparent;
}
.b-profile-hero .ui.button.big.inverted:hover {
  background-color: #69296a;
}
@media (max-width: 768px) {
  .b-profile-hero {
    flex-direction: column;
  }
}
.b-profile-hero-image, .b-profile-hero-info {
  flex: 1 0;
  width: 50%;
}
@media (max-width: 768px) {
  .b-profile-hero-image, .b-profile-hero-info {
    width: 100%;
  }
}
.b-profile-hero-image {
  text-align: center;
}
.b-profile-hero-image img {
  border-radius: 5px;
  display: block;
  height: 100%;
  transform: scaleY(1.05);
  width: 100%;
}
.b-profile-hero-info h1 {
  max-width: 80%;
}
.b-profile-hero-info .b-wrapper {
  display: block;
  margin: 0 auto;
  max-width: 80%;
}
@media (max-width: 768px) {
  .b-profile-hero-info .b-wrapper {
    max-width: 100%;
    padding: 2rem;
  }
}
.b-profile-hero p {
  color: #fff;
}
.b-profile-hero.wide .ui.grid.container {
  width: calc(1400px + 2rem) !important;
}
.b-profile-tag {
  color: #e2ab3b;
  padding-left: 1rem;
}
.b-profile-tag img {
  padding-left: 0.5rem;
  position: absolute;
}
.b-profile-text__intro {
  margin: 0 auto;
  max-width: 70%;
}
.b-profile-position__relative {
  position: relative;
}
.b-profile-case {
  background-color: #572258;
  border-bottom: 2px solid #331433;
  border-top: 1px solid #451b46;
  border-left: 1px solid #451b46;
  border-right: 1px solid #451b46;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 2rem;
  text-align: center;
  padding: 1rem;
}
.b-profile-case p {
  color: #fff !important;
}
.b-profile-case-status {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.b-profile-case-status p {
  color: #e2ab3b !important;
}

.b-accordion-show {
  margin-top: 1rem;
  max-height: 100vh !important;
}
.b-accordion-target {
  cursor: pointer;
  margin: 0;
}
.b-accordion-target:after {
  content: "┿";
  position: absolute;
  right: 1rem;
}
.b-accordion-active:after {
  content: "─";
}
.b-accordion p {
  max-height: 0;
  overflow: hidden;
  transition: all 0.16s ease;
}

.login__container {
  height: 100vh;
  width: 100vw;
}
.login__wrap {
  position: relative;
  text-align: center;
  z-index: 200;
  pointer-events: none;
  opacity: 0;
  transition: all 1s ease;
  height: 100vh;
  overflow-y: auto;
}
.login__wrap--open {
  pointer-events: auto;
  opacity: 1;
}
.login__wrap .footer {
  background: rgba(232, 237, 243, 0.8);
  border-top: 1px solid rgba(222, 226, 230, 0.45);
}
.login__bg {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #572258;
  /*
  background: linear-gradient(180deg, $primary-shade 50%, $primary 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#572258",endColorstr="#802480",GradientType=1);
  */
  overflow: hidden;
  text-align: center;
}
.login__bg-sec1, .login__bg-sec2 {
  position: absolute;
  width: 160vh;
  min-width: 500px;
  min-height: 500px;
  height: 160vh;
  top: -60vh;
  transform: rotate(190deg);
  left: 45%;
  max-height: 1600px;
  max-width: 1600px;
  margin: 0 0 0 12%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: all 0.5s ease;
  animation: rotate1 1s ease 1.2s 1 alternate backwards;
  background-image: url("../../../public/assets/icons/halo.svg");
}
.login__bg-sec2 {
  top: auto;
  bottom: -100vh;
  left: 35%;
  transform: rotate(0deg);
  animation: rotate2 1s ease 0.95s 1 alternate backwards;
}
@media only screen and (max-width: 1023px) {
  .login__bg-sec1 {
    left: 15%;
    top: 45%;
  }
  .login__bg-sec2 {
    bottom: -160%;
    left: 80%;
  }
}
@media only screen and (min-height: 688px) and (max-width: 1248px) {
  .login__bg-sec1 {
    top: 35%;
  }
  .login__bg-sec2 {
    bottom: -150%;
  }
}
.login__center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: static;
  min-width: 100%;
  min-height: 100%;
  opacity: 0;
  padding: 2rem 0;
  transform: scale3d(0.9, 0.9, 1);
  transition: opacity 0.5s, transform 0.5s;
}
.login__wrap--open .login__center {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.login {
  font-family: Lato, sans-serif;
  border-radius: 0.75rem;
  margin: 0 auto;
  z-index: 2000;
  display: block;
  width: 90%;
  max-width: 450px;
  height: auto;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-shadow: 0 0.9px 2.2px rgba(0, 0, 0, 0.02), 0 2.1px 5.3px rgba(0, 0, 0, 0.028), 0 4px 10px rgba(0, 0, 0, 0.035), 0 7.1px 17.9px rgba(0, 0, 0, 0.042), 0 13.4px 33.4px rgba(0, 0, 0, 0.05), 0 32px 80px rgba(0, 0, 0, 0.07);
}
.dark-theme .login {
  background: rgba(19, 22, 25, 0.7);
}
.light-theme .login {
  background: rgba(240, 240, 240, 0.7);
}
.dark-theme .login {
  background: rgba(19, 22, 25, 0.7);
}
.light-theme .login {
  background: rgba(255, 255, 255, 0.9);
}
.page--loaded .login {
  animation: animLoadedContent 1s cubic-bezier(0.7, 0, 0.3, 1) both;
}
.login--wide {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 90%;
  max-width: none;
  height: auto;
  backdrop-filter: none;
  box-shadow: none;
  background: none !important;
  border-radius: 0;
}
@media only screen and (max-width: 1023px) {
  .login--wide {
    max-width: 500px;
    margin: 0 auto;
    flex-direction: column;
  }
}
@media only screen and (max-height: 688px) and (min-width: 1024px) {
  .login--wide {
    max-width: 500px;
    margin: 0 auto;
    flex-direction: column;
  }
}
@media only screen and (min-height: 688px) and (max-width: 1248px) {
  .login--wide {
    max-width: 500px;
    margin: 0 auto;
    flex-direction: column;
  }
}
.login .form {
  min-height: unset;
  max-height: none;
  overflow: hidden;
  transform: scale3d(1, 1, 1);
  opacity: 1;
  transition: opacity 0.35s, transform 0.65s, max-height ease 0.35s;
}
.login .form--hide {
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
}
.login .form input {
  border-radius: 0;
}
.login .form.ui .segment {
  border: 0;
  border-radius: 0.75rem;
}
.login .form.ui .segment::after {
  display: none;
}
.login .form.ui .input {
  border-radius: 0;
}
.login .form.ui input {
  border-radius: 0.25rem;
  font-size: 1.125rem;
}
.login .form.ui input:focus, .login .form.ui input[type=password]:focus {
  background: #b9f6fb;
  box-shadow: 0 0 0 0 #495057 inset;
  border-color: #89929b;
}
.login .form.ui input:focus::-webkit-input-placeholder, .login .form.ui input[type=password]:focus::-webkit-input-placeholder {
  color: rgba(73, 80, 87, 0.75);
}
.login .form.ui button + button {
  margin-top: 1rem;
}
.login .form.ui button.small {
  width: auto;
  margin: 1rem auto 0 auto;
}
.login__logo {
  z-index: 2000;
  display: inline-block;
  width: 250px;
  max-width: 450px;
  height: 6rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 1023px) {
  .login__logo {
    width: 180px;
    height: 2rem;
    margin-bottom: 1.5rem;
    display: block;
  }
}
@media only screen and (max-height: 688px) and (min-width: 1024px) {
  .login__logo {
    width: 180px;
  }
}
.login__logo-wrap {
  width: auto;
  flex: 1 1 auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1023px) {
  .login__logo-wrap {
    height: 160px;
  }
}
@media only screen and (max-height: 688px) and (min-width: 1024px) {
  .login__logo-wrap {
    height: 160px;
  }
}
@media only screen and (min-height: 688px) and (max-width: 1248px) {
  .login__logo-wrap {
    height: 160px;
  }
}
.login__logo-owner {
  display: inline-block;
  width: 164px;
  height: 6rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transition: all 0.35s ease;
  animation: appear 0.45s ease 0.35s 1 alternate backwards;
}
@media only screen and (max-width: 1023px) {
  .login__logo-owner {
    width: 160px;
    margin: 0 auto;
    clear: both;
    display: block;
    height: 3.5rem;
    margin-top: -1rem;
  }
}
@media only screen and (max-height: 688px) and (min-width: 1024px) {
  .login__logo-wrap .login__logo-owner {
    width: 138px;
  }
}
.login__logo-center {
  display: block;
}
.login__logo-reveal {
  display: inline-block;
  text-align: right;
  animation: reveal 0.75s ease 1s 1 alternate backwards;
  overflow: hidden;
  position: relative;
  width: 250px;
  margin-left: 1.5rem;
  height: 6rem;
}
@media only screen and (max-width: 1023px) {
  .login__logo-reveal {
    margin-left: 0.75rem;
    width: 180px;
  }
}
@media only screen and (max-height: 688px) and (min-width: 1024px) {
  .login__logo-reveal {
    margin-left: 0.75rem;
    width: 180px;
  }
}
.login--wide .login__form {
  width: auto;
  background: #f0f0f0;
  border-radius: 0.5rem;
  box-shadow: 0px 1.3px 2.2px rgba(0, 0, 0, 0.059), 0px 3.1px 5.3px rgba(0, 0, 0, 0.085), 0px 5.9px 10px rgba(0, 0, 0, 0.105), 0px 10.5px 17.9px rgba(0, 0, 0, 0.125), 0px 19.6px 33.4px rgba(0, 0, 0, 0.151), 0px 47px 80px rgba(0, 0, 0, 0.21);
  animation: appear 0.5s ease 1.25s 1 alternate backwards;
}
.login__form-wrap {
  flex-direction: column;
  width: auto;
  flex: 1 1 auto;
  max-width: 500px;
}
.login__form-wrap.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
.login__form .ui.form .field .ui.input input,
.login__form .ui.form .fields .field .ui.input input {
  width: 100%;
}
.login__form .ui.form .field .ui.input.icon input,
.login__form .ui.form .fields .field .ui.input.icon input {
  padding-left: 2.5rem;
}
.login__terms {
  font-size: 0.75rem;
  padding: 1rem 1rem 1.5rem 1rem;
  color: #fff;
  animation: appear 0.45s ease 1.25s 1 alternate backwards;
  display: block;
}
@media only screen and (min-width: 768px) {
  .login__terms {
    display: none;
  }
}
.login__terms .link {
  text-decoration: none;
  background: none;
  color: #fff;
}
.login__terms .link:hover {
  text-decoration: underline;
}
.login__reg {
  display: block;
  overflow: hidden;
  height: auto;
  max-height: 480px;
  padding: 0 0 1rem 0;
  transition: all 0.5s ease;
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.login__reg.hidden {
  max-height: 0;
  padding: 0;
  transform: scale3d(0.9, 0.9, 1);
  opacity: 0;
}
.login .spacer {
  margin: 0 8px;
  opacity: 0.7;
}
.login .form__list {
  padding: 0;
}

@keyframes animLoadedContent {
  from {
    transform: translate3d(0, 200px, 0);
  }
}
@keyframes reveal {
  from {
    max-width: 0px;
  }
  to {
    max-width: 250px;
  }
}
@keyframes appear {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1) translate(0, 20%);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate(0, 0);
  }
}
@keyframes rotate1 {
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1) rotate(90deg);
  }
  to {
    transform: scale3d(1, 1, 1) rotate(190deg);
    opacity: 1;
  }
}
@keyframes rotate2 {
  from {
    transform: scale3d(0.8, 0.8, 1) rotate(90deg);
    opacity: 0;
  }
  to {
    transform: scale3d(1, 1, 1) rotate(0deg);
    opacity: 1;
  }
}
.b-guide__wrap {
  display: flex;
  flex-direction: column;
  transition: opacity 0.45s ease, transform 0.45s ease;
  transform: scale3d(1, 1, 1);
  background: rgb(237, 242, 247);
  pointer-events: all;
}
@media only screen and (max-width: 767px) {
  .b-guide__wrap {
    flex-direction: column-reverse;
  }
}
.b-guide__wrap.fullscreen {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000;
}
.b-guide__wrap.hide {
  overflow: hidden;
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
  pointer-events: none;
}
.b-guide__steps {
  display: block;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  flex: 0 0 5rem;
  background: rgb(237, 242, 247);
}
@media only screen and (max-width: 1247px) {
  .b-guide__steps {
    flex: 0 0 3rem;
  }
}
.b-guide__steps .ui.steps .step {
  justify-content: left;
  border-radius: 0;
  flex: 1 1 100%;
  width: 100%;
  background: #e8edf3;
  display: flex;
  min-width: 0;
  align-items: left;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0;
  transition: color 0.35s ease-in-out, box-shadow 0.3s ease-in-out;
  /**/
}
.b-guide__steps .ui.steps .step.active {
  box-shadow: inset 0 -6px 0 #802480;
}
.b-guide__steps .ui.steps .step::after {
  display: none;
}
.b-guide__steps .ui.steps .step .content {
  flex: 1 1 100%;
  padding: 1.5rem 1rem;
  display: block;
  overflow: hidden;
}
@media only screen and (max-width: 1247px) {
  .b-guide__steps .ui.steps .step .content {
    padding: 0.75rem;
  }
}
.b-guide__steps .ui.steps .step .content .title {
  color: #212529;
  font-weight: 600;
}
.b-guide__steps .ui.steps .step .title {
  font-size: 1rem;
  font-weight: 600;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1247px) {
  .b-guide__steps .ui.steps .step .title {
    font-size: 0.875rem;
  }
}
.b-guide__steps .ui.steps .step .description {
  display: block;
  font: 0.875rem 400 Lato, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1247px) {
  .b-guide__steps .ui.steps .step .description {
    font-size: 0.75rem;
  }
}
.b-guide__steps .ui.steps .step .description + .title {
  margin-top: 0.375rem;
}
.b-guide__steps .ui.steps .step i.icon {
  flex: 0 0 2.5rem;
  margin: 0 0 0 1.25rem;
  color: #0bb5c4;
}
.b-guide__steps .ui.steps .step i.icon.corner {
  position: absolute;
  margin: 0 -0.25rem -0.25rem 0;
  opacity: 0;
  transition: opacity 0.45s ease, transform 0.45s ease-in-out;
  transform: scale3d(1.35, 1.35, 1);
  color: #cc931e;
  height: 1rem;
  width: 1rem;
}
@media only screen and (max-width: 1247px) {
  .b-guide__steps .ui.steps .step i.icon.large {
    width: 30px;
    height: 30px;
    line-height: 30px;
    flex: 0 0 2rem;
    margin: 0.825rem 0 0 0.75rem;
  }
}
.b-guide__steps .ui.steps .step i.icons .corner.icon {
  font-size: 1rem;
}
.b-guide__steps .ui.steps .step.completed {
  color: #0e060e;
}
.b-guide__steps .ui.steps .step.completed i.icon.corner {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  color: #572258;
}
.b-guide__steps .ui.steps .step .icon-stack {
  position: relative;
}
.b-guide__steps .ui.steps .step__button {
  background: #802480;
  display: flex;
  flex: 1 1 auto;
  justify-content: left;
  text-align: left;
  flex-direction: row;
  color: #fff;
  text-decoration: none;
  transition: background 0.35s ease;
  cursor: pointer;
  align-items: center;
  height: 100%;
}
.b-guide__steps .ui.steps .step__button .content .title,
.b-guide__steps .ui.steps .step__button .content .description {
  color: #fff;
}
.b-guide__steps .ui.steps .step__button.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.b-guide__steps .ui.steps .step__button:hover, .b-guide__steps .ui.steps .step__button:focus {
  background: #441344;
}
.b-guide__content {
  overflow: auto;
  display: flex;
  margin: 0.5rem;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: center;
}
.fileupload .b-guide__content {
  border: 4px dashed transparent;
  border-radius: 1rem;
  transition: border 0.35s ease;
}
.fileupload .b-guide__content:hover {
  border: 4px dashed #331433;
}
.b-guide__content .e-upload {
  border: 0;
}
.b-guide__content .e-upload .e-file-select-wrap {
  margin: 0 0 1rem 0;
  background: #f3f4f5;
  border-radius: 0.5rem;
  font-weight: 600;
  border: 1px dashed #dee2e6;
}
.b-guide__content .e-upload-files {
  border: 0;
}
.b-guide__content .e-upload-file-list {
  background: #fff;
  border-radius: 0.5rem;
  margin: 0 0 1rem 0;
  border: none;
  box-shadow: 0 0.3px 0.3px rgba(5, 29, 54, 0.02), 0 0.7px 0.8px rgba(5, 29, 54, 0.028), 0 1.3px 1.5px rgba(5, 29, 54, 0.035), 0 2.2px 2.7px rgba(5, 29, 54, 0.042), 0 4.2px 5px rgba(5, 29, 54, 0.05), 0 10px 12px rgba(5, 29, 54, 0.07);
}
.b-guide__slider {
  flex: 1 1 auto;
  max-width: 800px;
  overflow: auto;
  position: relative;
}
.b-guide__close {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
}
body .b-guide__close .icon-button {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
  background: #e8edf3;
  transition: background 0.35s ease;
}
body .b-guide__close .icon-button:hover {
  background: #f05151;
}
body .b-guide__close .icon-button .icon {
  margin-top: 1.25rem;
}
@media only screen and (max-width: 1247px) {
  body .b-guide__close .icon-button .icon {
    margin-top: 0.75rem;
  }
}

.b-search-page {
  transform: scale3d(1, 1, 1);
  transition: trasform 0.35s ease, opacity 0.35s ease;
  min-height: 100%;
  /*
  .slide-enter-done &{
      transform: scale3d(1, 1, 1);
  }*/
  /*
  .slide-appear &{
      transform: scale3d(0.9, 0.9, 1);
  }
  .slide-appear-active &{
      transform: scale3d(1, 1, 1);
  }*/
}
.slide-enter .b-search-page {
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
}
.slide-enter-active .b-search-page {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}
.slide-exit .b-search-page {
  opacity: 1;
  transform: scale3d(0.9, 0.9, 1);
}
.slide-exit-active .b-search-page {
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
}

.cases-header {
  background-color: #572258 !important;
  color: #fff !important;
}
.cases-header-info {
  display: flex;
  color: #e2ab3b;
  justify-content: space-evenly;
  padding: 1rem 0;
}
.cases-header-info span {
  align-items: center;
  display: inline-flex;
}
.cases-header-info span img {
  margin-left: 0.5rem;
}
.cases-header p {
  color: #fff !important;
  margin: 0 auto;
  max-width: 75%;
}
.cases-info {
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0;
}
.cases-info:last-of-type {
  border-bottom: none;
}
.cases-table-header {
  border-bottom: 1px solid #ced4da;
  display: flex;
  font-weight: bold;
  padding: 0.5rem 0;
}
.cases-table-header span {
  flex: 1 0 33%;
}
.cases-table-content-row {
  display: flex;
  padding: 0.5rem 0;
}
.cases-table-content-cell-third {
  flex: 1 0 33%;
}

.dash__header {
  display: flex;
  color: #fff;
  padding: 1.5rem 0;
  position: relative;
}
.dash__header-title {
  flex: 1 0;
  margin: 0;
}
.dash__header-title h1 {
  margin: 0;
}
.dash__header-title h2 {
  margin: 0;
}
.dash__header-title .month--full {
  display: block;
}
@media only screen and (max-width: 767px) {
  .dash__header-title .month--full {
    display: none;
  }
}
.dash__header-title .month--small {
  display: none;
}
@media only screen and (max-width: 767px) {
  .dash__header-title .month--small {
    display: block;
  }
}
.dash__header-bg {
  background-color: #802480;
  border-radius: 100%;
  height: 400px;
  position: absolute;
  right: -50px;
  top: -70px;
  width: 400px;
  z-index: 0;
}
.dash__weather {
  flex: 1 0;
  text-align: right;
  z-index: 1;
  padding-top: 2rem;
}
@media (max-width: 768px) {
  .dash__weather {
    display: none;
  }
}
.dash__appointments {
  border: 2px solid #572258;
  border-radius: 5px;
  display: flex;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
}
@media (max-width: 1024px) {
  .dash__appointments {
    flex-wrap: wrap;
  }
}
.dash__appointments-background {
  position: absolute;
  z-index: 1;
}
.dash__appointments-background .first {
  border: 10px solid #0bb5c4;
  border-radius: 100%;
  height: 300px;
  left: -50px;
  position: absolute;
  top: -50px;
  width: 300px;
}
@media (max-width: 768px) {
  .dash__appointments-background .first {
    left: -100px;
    top: -100px;
  }
}
.dash__appointments-card {
  flex: 1 0 25%;
  background-color: #0bb5c4;
  border-radius: 5px;
  padding: 0.6rem;
  margin: 0.5rem 0;
  overflow: hidden;
}
.dash__appointments-card span {
  background-color: #29e3f3;
  padding: 16px 19px;
  border-radius: 100%;
  margin-right: 0.5rem;
}
.dash__appointments-focus {
  background-color: #572258;
  color: #fff;
  display: flex;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.dash__appointments-title {
  color: #fff;
}
.dash__appointments-details {
  color: #e2ab3b;
}
.dash__appointments-details span {
  margin-right: 0.6rem;
}
.dash__appointments-section__l {
  flex: 1 0 80%;
}
.dash__appointments-section__s {
  flex: 1 0 20%;
  flex-direction: column;
  display: flex;
}
.dash__appointments-info {
  flex: 1 0 20%;
  max-width: 20%;
  padding: 1rem;
  z-index: 2;
}
@media (max-width: 1024px) {
  .dash__appointments-info {
    border: none;
    display: flex;
    justify-content: space-evenly;
    flex: 1 0 100%;
    max-width: 100%;
    padding-bottom: 0.5rem;
  }
}
.dash__appointments-info span {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.dash__appointments-info span img {
  margin-right: 0.5rem;
  width: 22px;
}
.dash__appointments-main {
  flex: 1 0 60%;
  text-align: center;
  padding: 1rem;
  z-index: 2;
}
@media (max-width: 1024px) {
  .dash__appointments-main {
    padding-top: 0.5rem;
  }
}
.dash__appointments-main p {
  padding: 0;
}
.dash__appointments-map {
  flex: 1 0 20%;
  z-index: 2;
}
@media (max-width: 1024px) {
  .dash__appointments-map {
    display: none;
  }
}
.dash__appointments-current {
  background-color: #572258;
  border: none;
  color: #fff;
}
.dash__appointments-current .dash__appointments-info {
  color: #e2ab3b;
}
.dash__appointments-current p {
  color: #fff;
}
.dash__appointments-more {
  text-align: center;
}
.dash__appointments-more span {
  background-color: #0bb5c4;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  font-size: 0.78571429rem;
  padding: 0.2rem 0.4rem;
}
.dash__contacts {
  display: flex;
  flex-direction: column;
}
.dash__contacts h3 {
  flex: 1 0 100%;
}
.dash__contacts p {
  border-bottom: 1px solid #e8edf3;
  flex: 1 0 100%;
  padding-bottom: 0.6rem;
}
.dash__contacts p i {
  background-color: #0bb5c4;
  border-radius: 100%;
  color: #fff;
  float: right;
  height: 36px;
  margin: 0 0.6rem;
  margin-top: -5px;
  padding: 6px 10px;
  width: 36px;
}

.timeRec {
  display: flex;
}
.timeRec-field {
  flex: 1 0 50%;
}
.timeRec-count {
  color: #e2ab3b;
  text-align: center;
}
.timeRec-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.timeRec-controls-button {
  border-radius: 5px;
  flex: 1;
  padding: 1rem 2rem;
  margin: 0 2rem;
  max-width: 144px;
}
.timeRec-controls-button__start {
  background-color: #7bfe31;
}
.timeRec-controls-button__pause {
  background-color: #FEAF31;
}
.timeRec-controls-button__stop {
  background-color: #f05151;
}
.timeRec-recent .timeRec-recent-heading, .timeRec-recent .timeRec-recent-content {
  border-bottom: 2px solid #ced4da;
  float: left;
  width: 33%;
}

.b-welcome {
  display: flex;
  flex: 1 1 auto;
  width: auto;
  align-items: center;
  justify-content: center;
  justify-content: space-around;
  pointer-events: all;
}
.b-welcome__wrap {
  display: flex;
  flex: 1 1 auto;
  margin: 11.25rem 4rem 4rem 4rem;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.b-welcome__col {
  flex: 0 0 50%;
  max-width: 580px;
}
.b-welcome__intro {
  display: block;
  min-height: 140px;
}
.b-welcome__intro h2 {
  line-height: 2rem;
}
.b-welcome__intro-btns {
  display: flex;
}
.b-welcome__intro-btns .ui.button {
  flex: 1;
  padding: 1.25rem 0.75rem 1rem 0.75rem;
  max-width: 33.3333%;
  margin-right: 0.5rem;
}
.b-welcome__intro-btns .ui.button.secondary.inverted {
  color: #572258 !important;
}
.b-welcome__intro-btns .ui.button.secondary.inverted .icon {
  color: #802480 !important;
}
.b-welcome__intro-btns .ui.floating.label {
  margin: 0 0 0 -1.25em !important;
}
.b-welcome__intro-btns .icon.large {
  min-height: 1.5rem;
  width: 1.5rem;
  margin-left: auto !important;
  margin-right: auto !important;
}
.b-welcome__intro-btns .h5 {
  padding-top: 0.25rem;
}
.b-welcome__cards {
  position: relative;
  height: 400px;
}
.b-welcome__card {
  position: absolute;
  background: #fff;
  display: block;
  height: 400px;
  width: 100%;
  margin: 0;
  border-radius: 1rem;
  z-index: 0;
  transform: translateY(2rem);
  opacity: 0;
  transition: transform 0.35s ease, opacity 0.5s ease;
  pointer-events: none;
}
.b-welcome__card.stacked {
  transform: translate(0, 0) scale(1);
  opacity: 1;
  pointer-events: all;
}
.b-welcome__card.stacked:nth-of-type(2) {
  transform: translate(0, -18px) scale(0.95);
  z-index: -1;
}
.b-welcome__card.stacked:nth-of-type(3) {
  transform: translate(0, -36px) scale(0.9);
  z-index: -2;
}
.b-welcome__card.stacked:nth-of-type(4) {
  transform: translate(0, -54px) scale(0.85);
  z-index: -3;
}
.b-welcome__card.stacked:nth-of-type(5) {
  transform: translate(0, -72px) scale(0.8);
  z-index: -4;
}
.b-welcome__card.stacked:nth-of-type(6) {
  transform: translate(0, -90px) scale(0.75);
  z-index: -5;
}
.b-welcome__card.stacked:nth-of-type(7) {
  transform: translate(0, -108px) scale(0.7);
  z-index: -6;
}
.b-welcome__card.stacked:nth-of-type(8) {
  transform: translate(0, -126px) scale(0.65);
  z-index: -7;
}
.b-welcome__card.stacked:nth-of-type(n+7) {
  transform: translate(0, -126px) scale(0.65);
  z-index: -8;
  opacity: 0;
  pointer-events: none;
}
.b-welcome__card .project {
  margin: 0;
  box-shadow: none;
  height: 100%;
  overflow: hidden;
}
.b-welcome__card-nav {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1rem 0 0 0;
  gap: 0.5rem;
}

.lex-network .branding-logo {
  background-image: url("../images/O-logo.svg");
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .lex-network .icon-button:hover .branding-logo {
    background-image: linear-gradient(transparent, transparent), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="529.6" height="308.9"><path fill="rgba(255, 255, 255, 0.9999999)" d="M155 .6L78 231.7H1L78 .6h77"/><path fill="rgba(255, 255, 255, 0.9999999)" d="M155 231.7H1l57.7 77H155v-77"/><g><path fill="rgba(255, 255, 255, 0.9999999)" d="M296.8.6h77l51.9 308h-77L296.8.6"/><path fill="rgba(255, 255, 255, 0.9999999)" d="M373.8.6L270 308.9h-77L296.8.6h77M529.6.6L425.8 308.9h-77L452.6.6h77"/></g></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center center;
  }
}
.lex-network .branding {
  overflow: hidden;
}
.lex-network .branding-logo {
  display: block;
  position: relative;
  height: 48px;
  width: 48px;
  opacity: 1;
  transition: opacity ease 0.3s, height ease 0.3s;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  transition: background 0.35s ease;
  margin: 0 auto;
}
@media only screen and (min-width: 1024px) {
  .lex-network .branding-logo {
    display: none;
  }
}
.lex-network .sm__menu .branding-logo {
  height: 40px;
  width: 44px;
}
@media only screen and (min-width: 1024px) {
  .lex-network .sm__menu .branding-logo {
    display: block;
  }
}
.lex-network .hamburger-inner {
  opacity: 0;
}
.lex-network .sm--pushed .branding-logo {
  height: 0;
  opacity: 0;
}
.lex-network .sm--pushed .hamburger-inner {
  opacity: 1;
}

.lex-network .client-logo {
  background-image: url(../images/O-logo.svg);
}
.lex-network .app-logo {
  background-image: url(../images/lex-network-white.svg);
}
.lex-network .app-logo.purple {
  background-image: url(../images/opus2-logo-purple.svg);
}
.lex-network .header .app-logo {
  display: block;
  min-height: 6rem;
  min-width: 26rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 767px) {
  .lex-network .header .app-logo {
    margin-top: 2rem;
    width: 100%;
    min-width: 160px;
  }
  .lex-network .header .app-logo.purple {
    display: none;
  }
}
.lex-network .branding {
  background-color: #572258;
}
.lex-network .branding:focus, .lex-network .branding:active, .lex-network .branding:hover {
  background-color: #331433 !important;
}

.owner-logo {
  background-image: url(../images/opus2-logo-white.svg);
}

.linkedin--bg-color {
  background-color: #0274b3;
}
.linkedin--text-color {
  background-color: #0274b3;
}
.linkedin--button {
  background-color: #0274b3;
}
.linkedin--button:hover {
  background-color: #015381;
}

.vendor {
  display: block;
  text-align: center;
  padding-bottom: 2rem;
}
.vendor__logo {
  display: block;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  margin: 0 auto;
}
.vendor__logo--large, .vendor__logo.large {
  height: 80px;
  background-size: 50%;
}
.vendor__logo--large.camera__logo:before, .vendor__logo--large.phone__logo:before, .vendor__logo.large.camera__logo:before, .vendor__logo.large.phone__logo:before {
  font-size: 2rem;
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  border-radius: 4rem;
}
.vendor__logo.camera__logo:before, .vendor__logo.phone__logo:before {
  border: 2px solid #fff;
  border-radius: 2rem;
  min-width: 2rem;
  display: inline-block;
  text-align: center;
  font-family: "icos";
  color: #fff;
}
.vendor__logo.camera__logo:before {
  content: "\f03d";
}
.vendor__logo.phone__logo:before {
  content: "R";
}

.e-avatar {
  text-overflow: ellipsis;
}
.e-avatar-stack .e-avatar, .e-listview .e-avatar-stack .e-avatar {
  margin: 0 0 0 -1.375rem;
  border: 2px solid #dee2e6;
}
.e-avatar-stack .e-avatar:first-of-type, .e-listview .e-avatar-stack .e-avatar:first-of-type {
  margin-left: 0;
}

.e-input-group {
  width: auto;
  flex: 1 1 auto;
  margin-right: 0.25rem;
}
.e-input-group.inline-flex {
  width: 100%;
  margin: 0;
  border: 0;
}
.e-input-group.inline-flex::before, .e-input-group.inline-flex::after {
  display: none;
}
.e-input-group .e-input-group-icon {
  margin-top: 14px;
  margin-bottom: 0;
}
.e-input-group .e-input-group-icon.large {
  font-size: 1.625rem;
  margin: 0;
}
.e-input-group .e-input-group-icon.medium {
  font-size: 1.25rem;
}
.e-input-group .e-input-group-icon.small {
  font-size: 1rem;
}
.e-input-group .e-input-group-icon.xsmall {
  font-size: 8.125rem;
}
body .e-control-wrapper .e-input-group-icon {
  margin-top: 0;
}

.e-float-input input, .e-float-input.e-control-wrapper input {
  font-weight: 500;
}
.e-float-input input {
  margin-top: 0;
  /* Firefox */
}
.e-float-input input::-webkit-outer-spin-button, .e-float-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.e-float-input input[type=number] {
  -moz-appearance: textfield;
}

.e-input-group input.e-input,
.e-input-group textarea.e-input,
.e-input-group input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]),
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper textarea.e-input,
.e-input-group.e-control-wrapper input.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]):not(:focus),
.e-input-group.e-control-wrapper textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
  border-bottom-width: 1px;
}

.e-btn-group.flex {
  display: flex;
}
.e-btn-group input {
  opacity: 1;
  position: relative;
}
.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn {
  margin: 0 0 0 0.25rem;
}

.e-toast-container .e-toast {
  transition: background-color 0.35s ease;
  background-color: #3d4c5c;
  cursor: default;
}
.e-toast-container .e-toast:hover {
  background-color: #29333d;
}
.e-toast-container .e-toast.e-toast-danger {
  background-color: #f05151;
}
.e-toast-container .e-toast.e-toast-danger:hover {
  background-color: #ec2222;
}
.e-toast-container .e-toast.e-toast-warning {
  background-color: #088894;
}
.e-toast-container .e-toast.e-toast-warning:hover {
  background-color: #065c63;
}
.e-toast-container .e-toast.e-toast-info {
  background-color: #300e30;
}
.e-toast-container .e-toast.e-toast-info:hover {
  background-color: #090209;
}
.e-toast-container .e-toast.e-toast-success {
  background-color: #9f7318;
}
.e-toast-container .e-toast.e-toast-success:hover {
  background-color: #735311;
}

.e-layout-content {
  max-width: 100%;
  transition: all 1.35s ease;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.e-layout-content::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.e-layout-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.e-layout-content:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
.e-layout-content.hide {
  max-width: 0;
}

.e-filemanager .e-layout {
  position: relative;
  height: 100%;
}

.e-pane,
.e-control {
  opacity: 1;
  transition: opacity 0.45s ease, transform 0.45s ease;
  transform: scale3d(1, 1, 1);
}
.e-pane.hide,
.e-control.hide {
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.e-pane.hide--zoom-in,
.e-control.hide--zoom-in {
  overflow: hidden;
  opacity: 0;
  transform: scale3d(0.9, 0.9, 1);
  pointer-events: none;
}
.e-pane.hide--zoom-out,
.e-control.hide--zoom-out {
  overflow: hidden;
  opacity: 0;
  transform: scale3d(1.1, 1.1, 1);
  pointer-events: none;
}

.e-pane {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.e-pane::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.e-pane::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.e-pane:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

.e-flex-stretch {
  align-items: stretch;
  display: flex;
}

.e-flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: #802480;
}

.e-spinner-pane {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  transition: all 0.35s ease;
}
.e-spinner-pane.hide {
  transform: scale3d(0.8, 0.8, 1);
  opacity: 0;
  pointer-events: none;
}
.e-spinner-pane.medium {
  width: 2.5rem;
  height: 2.5rem;
}
.e-spinner-pane.inline {
  position: static;
  display: inline;
}
.e-spinner-pane.right {
  float: right;
  display: inline;
}

/*
#spinner{
    position: absolute;
    top:0;
    left:0;
    border:5px solid #f0f;
    width:200px;
    height: 200px;
    display: block;
}*/
.e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical {
  border: 0;
}
.e-splitter.e-splitter--fullscreen {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}
.withMasthead .e-splitter.e-splitter--fullscreen {
  top: 54px;
}
.e-splitter.e-splitter--fullscreen .e-pane {
  position: relative;
}

.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  padding: 0;
}
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  margin: 0;
  padding: 0.25rem 0.375rem;
  height: 100%;
}
.e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-dropdown-btn, .e-filemanager .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-dropdown-btn:hover {
  height: inherit;
  padding: 0.25rem 0.375rem !important;
}
.e-toolbar .e-btn {
  transition: background ease 0.35s;
}
.e-toolbar .e-btn .icon {
  height: 25px;
  font-size: 0.875rem;
  border-radius: 0;
}
.e-toolbar .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 1rem;
}
.e-toolbar .e-tbar-btn-text,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  font-size: 0.75rem;
  padding: 0 0.25rem;
}

.e-menu-icon.icon {
  width: auto;
  height: auto;
  margin: 0;
}

.e-listview {
  font-size: 0.875rem;
  border: 0;
  transition: all 0.35s ease;
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.hide .e-listview {
  transform: scale3d(0.9, 0.9, 1);
  opacity: 0;
}
.e-listview.border--none .e-list-header {
  border: 0;
}
.e-listview.border--none .e-content {
  border: 0;
}
.e-listview:not(.e-list-template) .e-list-item {
  display: block;
  background: none;
  float: none;
  padding: 0.375rem 1.25rem;
  overflow: hidden;
  height: auto;
}
.e-listview .text {
  flex: 1 1 auto;
  padding-left: 0.25rem;
}
.e-listview .text--no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listview .subtext {
  flex: 1 1 auto;
  display: block;
  line-height: normal;
  font-size: 0.6875rem;
  margin: -0.5rem 0 0 -0.25rem;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem;
}
.e-listview .subtext--no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-listview .subtext .icon {
  margin-right: 0.25rem;
}
.e-listview h3 .subtext {
  margin: 0rem 0 0 0em;
  font-weight: normal;
}
.e-listview .e-content {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.e-listview .e-content::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.e-listview .e-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.e-listview .e-content:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
.e-listview .e-list-header {
  background: none;
  display: flex;
  align-items: stretch;
  padding: 0.25rem 1.25rem 0.25rem 1.25rem;
}
.e-listview .e-list-header .icon-button {
  flex: 0 0 auto;
  margin-top: 0.25rem;
}
.e-listview .e-list-header .e-text {
  flex: 1 1 auto;
  padding: 0.75rem 0;
}
.e-listview .listWrapper {
  display: block;
  overflow: hidden;
  min-height: 1rem;
  height: auto;
  transition: opacity ease 0.35s;
}
.e-listview .listWrapper--flex {
  display: flex;
  align-items: stretch;
}
.e-listview .listWrapper .e-switch-wrapper {
  margin: 0.75rem 0.25rem;
}
.e-listview .listWrapper--disabled .e-avatar,
.e-listview .listWrapper--disabled .text {
  opacity: 0.5;
}
.e-listview .e-avatar {
  margin: 0 0.5rem 0 0.125rem;
  flex: 0 0 3em;
}
.e-listview--cards .e-list-parent {
  padding-bottom: 8rem;
}
.e-listview--cards:not(.e-list-template) .e-list-item {
  overflow: hidden;
  padding: 0.5rem 1rem 1rem 1rem;
  margin: 1rem 1rem 0 1rem;
  position: relative;
  background: #fff;
  transition: all 0.35s ease;
  box-shadow: 0 2.8px 2.2px rgba(112, 144, 176, 0.02), 0 6.7px 5.3px rgba(112, 144, 176, 0.028), 0 12.5px 10px rgba(112, 144, 176, 0.035), 0 22.3px 17.9px rgba(112, 144, 176, 0.042), 0 41.8px 33.4px rgba(112, 144, 176, 0.05), 0 56px 80px rgba(112, 144, 176, 0.07);
}
.e-listview--cards:not(.e-list-template) .e-list-item.active, .e-listview--cards:not(.e-list-template) .e-list-item.e-hover {
  cursor: pointer;
}
.e-listview--cards:not(.e-list-template) .e-list-item.active:hover, .e-listview--cards:not(.e-list-template) .e-list-item.e-hover:hover {
  background: #802480;
}
.e-listview--cards h3 {
  padding-left: 0;
  padding-right: 0;
}
.e-listview .e-list-item:nth-child(1) .e-avatar {
  background-color: #572258;
}
.e-listview .e-list-item:nth-child(2) .e-avatar {
  background-color: #07727c;
}
.e-listview .e-list-item:nth-child(3) .e-avatar {
  background-color: #cc931e;
}
.e-listview .e-list-item:nth-child(4) .e-avatar {
  background-color: #9c31fe;
}
.e-listview .e-list-item:nth-child(5) .e-avatar {
  background-color: #331433;
}
.e-listview .e-list-item:nth-child(6) .e-avatar {
  background-color: #04464b;
}
.e-listview .e-list-item:nth-child(7) .e-avatar {
  background-color: #9f7318;
}
.e-listview .e-list-item:nth-child(8) .e-avatar {
  background-color: #8301fb;
}
.e-listview .e-list-item:nth-child(9) .e-avatar {
  background-color: #0e060e;
}
.e-listview .e-list-item:nth-child(10) .e-avatar {
  background-color: #02191b;
}
.e-listview .e-list-item:nth-child(11) .e-avatar {
  background-color: #735311;
}
.e-listview .e-list-item:nth-child(12) .e-avatar {
  background-color: #6901c8;
}
.e-listview .e-switch-wrapper {
  flex: 0 0 2rem;
}
.e-listview .icon.icos-thumbs-down {
  color: #89929b;
}
.e-listview .icon-button {
  margin-left: 0.5rem;
}
.e-listview .e-list-header {
  font-size: 1rem;
}
.sidepanel .e-listview {
  display: flex;
  flex-direction: column;
}
.sidepanel .e-listview .e-content {
  flex: 1 1 auto;
  padding-top: 0.5rem;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.sidepanel .e-listview .e-content::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.sidepanel .e-listview .e-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.sidepanel .e-listview .e-content:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
.sidepanel.hide .e-listview {
  transform: none;
  opacity: 1;
}

.e-treeview {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.e-treeview::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.e-treeview::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.e-treeview:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}
.e-treeview li {
  float: none;
  display: list-item;
  text-align: left;
}
.e-treeview .e-list-item.e-active > .e-fullrow {
  background: #bc35bc;
}
.e-treeview .e-list-item.e-active > .e-text-content {
  color: #fff;
}
.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: inherit;
}
.e-treeview .icon {
  line-height: 18px;
}
.e-dialog .e-dlg-header {
  font-weight: 500;
  font-size: 1.125rem;
}
.e-dialog .e-dlg-header .dlg-template {
  font-weight: 500 !important;
}
.e-dialog .e-dlg-header-content {
  border-bottom: 1px solid #dee2e6;
}
.e-dialog .e-footer-content {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem 0.25rem;
}
.e-dialog .e-footer-content .btn {
  margin-top: 0;
}
.e-dialog .e-footer-content .btn__group {
  padding: 0;
}

.e-contextmenu-wrapper li {
  float: none;
  display: list-item;
}

.e-navigation {
  background: #e8edf3;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  scrollbar-face-color: #dee2e6;
  scrollbar-track-color: rgba(0, 0, 0, 0.05);
}
.e-navigation::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.05);
  transition: all ease 0.35s;
}
.e-navigation::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.e-navigation:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

.e-splitter .e-split-bar.e-split-bar-horizontal {
  background: #cf54cf;
  background: none;
}

.e-dropdown-popup li {
  float: none;
  display: block;
}

.e-float-input label {
  border: 0;
  background: none;
  padding-left: 0;
}
.e-float-input input, .e-float-input.e-control-wrapper input {
  font-size: 1rem;
  padding-left: 0.25rem;
}
.e-float-input input:focus, .e-float-input.e-control-wrapper input:focus {
  padding-left: 0.25rem;
}
.e-float-input .e-float-line, .e-float-input.e-control-wrapper .e-float-line {
  position: static;
}

.e-content-wrapper {
  padding-top: 0.5rem;
}
.e-content-wrapper .col {
  position: relative;
}
.e-content-wrapper label {
  background: none;
  border: 0;
  font-family: Lato, sans-serif;
}

.e-switch-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  overflow: hidden;
  padding-left: 0;
  position: relative;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  padding: 1rem 0 0.75rem 0;
  cursor: pointer;
  color: #89929b;
  transition: color 0.25s ease;
}
.e-switch-wrapper[aria-checked=true] + .e-switch-label {
  color: #495057;
}

.col .e-switch-wrapper {
  margin-top: 1rem;
}

.e-menu-wrapper .e-ul .e-menu-item {
  transition: all 0.45s ease;
}
.e-menu-wrapper .e-ul .e-menu-item.e-focused {
  background-color: rgba(50, 25, 25, 0.25);
}

.spacing-clear-padding__bottom {
  padding-bottom: 0 !important;
}
.spacing-x15 {
  padding: 1.5rem !important;
}
.spacing-x20 {
  padding: 2rem !important;
}
.spacing-x30 {
  padding: 3rem !important;
}
@media only screen and (max-width: 583px) {
  .spacing-x30 {
    padding: 1.75rem !important;
  }
}
.spacing-x40 {
  padding: 4rem !important;
}
@media only screen and (max-width: 583px) {
  .spacing-x40 {
    padding: 2rem !important;
  }
}

.ui.button {
  transition: background-color 0.35s ease, border-color 0.35s ease, max-height 0.35s ease, marigin 0.35s ease, padding 0.35s ease;
  overflow: hidden;
  max-height: 100px;
  min-height: unset;
  font-family: Lato, sans-serif;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
.ui.button.basic, .ui.button.inverted {
  transition: box-shadow 0.35s ease, color 0.35s ease, background-color 0.35s ease;
}
.ui.button.dashed {
  border: 2px dashed #ced4da;
  background: none;
  transition: all 0.35s ease;
  color: #89929b;
}
.ui.button.dashed:hover, .ui.button.dashed:active, .ui.button.dashed:focus {
  color: #3d4c5c;
  background-color: #e8edf3;
}
.ui.button.h-medium {
  height: 6rem;
  max-height: unset;
}
.ui.button.h-large {
  height: 10rem;
  max-height: unset;
}
.ui.button.h-huge {
  height: 14rem;
  max-height: unset;
}
.ui.button.primary:not(.basic):not(.inverted) {
  background-color: #942a94;
}
.ui.button.primary:not(.basic):not(.inverted):hover, .ui.button.primary:not(.basic):not(.inverted):active, .ui.button.primary:not(.basic):not(.inverted):focus {
  background-color: #802480;
}
.ui.button.primary.basic {
  box-shadow: 0 0 0 1px #802480 inset !important;
  color: #572258;
}
.ui.button.primary.basic:hover, .ui.button.primary.basic:active, .ui.button.primary.basic:focus {
  color: #572258;
  box-shadow: 0 0 0 1px #802480 inset !important;
}
.ui.button.primary.inverted {
  background: none;
  color: #572258 !important;
  box-shadow: 0 0 0 1px #802480 inset !important;
}
.ui.button.primary.inverted:hover, .ui.button.primary.inverted:active, .ui.button.primary.inverted:focus {
  color: #fff !important;
  background-color: #802480;
}
.ui.button.secondary:not(.basic):not(.inverted) {
  background-color: #0bb5c4;
  color: #fff;
}
.ui.button.secondary:not(.basic):not(.inverted):hover, .ui.button.secondary:not(.basic):not(.inverted):active, .ui.button.secondary:not(.basic):not(.inverted):focus {
  background-color: #0a9fac;
  color: #fff;
}
.ui.button.secondary.basic {
  box-shadow: 0 0 0 1px #0a9fac inset !important;
}
.ui.button.secondary.basic:hover, .ui.button.secondary.basic:active, .ui.button.secondary.basic:focus {
  color: #3c3e4e !important;
  box-shadow: 0 0 0 1px #088894 inset !important;
}
.ui.button.secondary.inverted {
  background: none;
  color: #0bb5c4 !important;
  box-shadow: 0 0 0 1px #0bb5c4 inset !important;
}
.ui.button.secondary.inverted:hover, .ui.button.secondary.inverted:active, .ui.button.secondary.inverted:focus {
  color: #fff !important;
  background-color: #0a9fac;
}
.ui.button.tertiary:not(.basic):not(.inverted) {
  background-color: #cc931e;
  color: #fff;
}
.ui.button.tertiary:not(.basic):not(.inverted):hover, .ui.button.tertiary:not(.basic):not(.inverted):active, .ui.button.tertiary:not(.basic):not(.inverted):focus {
  background-color: #b6831b;
  color: #fff;
}
.ui.button.tertiary.basic {
  box-shadow: 0 0 0 1px #cc931e inset !important;
}
.ui.button.tertiary.inverted {
  background: none;
  color: #9f7318 !important;
  box-shadow: 0 0 0 1px #9f7318 inset !important;
}
.ui.button.tertiary.inverted:hover, .ui.button.tertiary.inverted:active, .ui.button.tertiary.inverted:focus {
  color: #fff !important;
  background-color: #9f7318;
}
.ui.button.opus:not(.basic):not(.inverted) {
  background-color: #8e378f;
  color: #fff;
}
.ui.button.opus:not(.basic):not(.inverted):hover, .ui.button.opus:not(.basic):not(.inverted):active, .ui.button.opus:not(.basic):not(.inverted):focus {
  background-color: #a03ea2;
  color: #fff;
}
.ui.button.opus.basic {
  box-shadow: 0 0 0 1px #8e378f inset !important;
}
.ui.button.opus.inverted {
  background: none;
  color: #a03ea2 !important;
  box-shadow: 0 0 0 1px #a03ea2 inset !important;
}
.ui.button.opus.inverted:hover, .ui.button.opus.inverted:active, .ui.button.opus.inverted:focus {
  color: #fff !important;
  background-color: #a03ea2;
}
.ui.button__text {
  overflow: hidden;
  display: inline-block;
  overflow: hidden;
  transition: width 0.35s ease;
}
.ui.button__text.hidden {
  max-width: 0;
  display: none;
}
.ui.button.hidden {
  max-height: 0;
  margin: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
}
.ui.buttons.small .ui.button, .ui.buttons.small .or {
  font-size: 0.75rem;
}
.ui.button.float--right {
  display: inline;
  float: right;
}
.ui.button.float--left {
  display: inline;
  float: left;
}

.dimmer {
  /*&::after{
      border-top-color: darken($primary, 15%);
  }*/
}
.dimmer.dimmed.dimmable > .ui.animating, .dimmer.dimmed.dimmable > .ui.visible, .dimmer.ui.active {
  background-color: rgba(51, 20, 51, 0.45);
}
.dimmer .ui.loader::before {
  display: none;
}
.dimmer .ui.loader::after {
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  /* you dont need the stuff below, but its what I used to create the loading circle */
  border: 6px solid #0bb5c4 !important;
  border-radius: 30px;
  display: inline-block;
  text-align: center;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.ui.search > .results .result .image {
  border-radius: 100%;
  float: left;
  height: 60px;
  margin-right: 1em;
  width: 60px;
}

.ui.search > .results .result .image + .content {
  margin: 0;
}

.ui.search .prompt {
  border-radius: 1em;
}

.ui.labeled.input > .label {
  background-color: #802480;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

input:focus {
  border-color: #0bb5c4 !important;
}

.column:nth-child(1) .b-result {
  animation: searchZoomIn 0.5s ease 0.15s alternate backwards;
}

.column:nth-child(2) .b-result {
  animation: searchZoomIn 0.5s ease 0.25s alternate backwards;
}

.column:nth-child(3) .b-result {
  animation: searchZoomIn 0.5s ease 0.35s alternate backwards;
}

.column:nth-child(4) .b-result {
  animation: searchZoomIn 0.5s ease 0.45s alternate backwards;
}

.column:nth-child(5) .b-result {
  animation: searchZoomIn 0.5s ease 0.55s alternate backwards;
}

.column:nth-child(6) .b-result {
  animation: searchZoomIn 0.5s ease 0.65s alternate backwards;
}

.column:nth-child(7) .b-result {
  animation: searchZoomIn 0.5s ease 0.75s alternate backwards;
}

.column:nth-child(8) .b-result {
  animation: searchZoomIn 0.5s ease 0.85s alternate backwards;
}

.column:nth-child(9) .b-result {
  animation: searchZoomIn 0.5s ease 0.95s alternate backwards;
}

.column:nth-child(10) .b-result {
  animation: searchZoomIn 0.5s ease 1.05s alternate backwards;
}

.column:nth-child(11) .b-result {
  animation: searchZoomIn 0.5s ease 1.15s alternate backwards;
}

.column:nth-child(12) .b-result {
  animation: searchZoomIn 0.5s ease 1.25s alternate backwards;
}

.b-result {
  position: relative;
}
.b-result__grid.ui.grid {
  margin: 0 0.5rem;
  padding-bottom: 1rem;
}
.b-result__grid .extra.content.zero-padding {
  padding: 0 !important;
  overflow: hidden;
}
.b-left-panel .b-result__grid .extra.content.zero-padding {
  max-height: 3.125rem;
}
.b-result__grid .leaflet-container {
  transition: width 0.35s ease, height 0.35s 0.5s ease, opacity 0.35s ease;
  max-width: 500px;
  max-height: 500px;
}
.b-left-panel .b-result__grid .leaflet-container {
  opacity: 0;
}
.b-left-panel .b-result__grid.ui.grid {
  margin: 0 0;
}
.b-left-panel .b-result__grid .column {
  width: 100% !important;
}
.b-result__title {
  margin-top: 1rem;
  padding: 1rem;
  display: block;
  width: 100%;
}
.b-result__title h2 {
  font-weight: 600;
}
.b-result-banner {
  max-width: 100%;
}
.b-result-contact {
  background-color: #802480;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.5rem;
  position: absolute;
  right: 16px;
  top: 0;
  width: 36px;
}
.b-result-head {
  display: flex;
  font-size: 16px !important;
  margin-bottom: 1em;
}
.b-result-details {
  display: block;
  text-align: left;
}
.b-result-profile {
  border-radius: 100%;
  margin-right: 1em;
  max-height: 60px;
}
.b-result h2 {
  margin: 0;
}
.b-result .header {
  padding: 0 0 0.25rem 0;
}
.b-result .header h4 {
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 600;
  animation: fadeLeft 0.35s ease 0.35s alternate backwards;
}
.b-result .header h5 {
  margin: 0;
  padding: 0.125rem 0 0 0;
  color: #572258;
  font-size: 0.875rem;
}
.b-result .content:not(.extra) {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
.b-result .content:not(.extra) a {
  color: #572258;
  text-decoration: underline;
}
.b-result .content:not(.extra) a:hover {
  color: #331433;
}
.b-result .content:not(.extra) .date {
  display: inline-block;
}
.b-result.person .content {
  padding: 0.875rem;
}
.b-result.person .extra {
  display: block;
}
.b-result.person .extra .ui.button.small {
  font-size: 0.75rem;
}
.b-result.person .extra .ui.button.small + .label {
  font-size: 0.75rem !important;
}
.b-result.person .extra .ui.button.medium {
  font-size: 0.875rem;
}
.b-result.person .extra .ui.button.float-right {
  float: right;
  display: inline;
}
.b-result.office .header {
  font-size: 1rem;
  font-weight: 600;
}
.b-result.office .description {
  font-size: 0.875rem;
  color: #333;
}
.b-result.office .content:not(.extra) {
  min-height: 9.5rem;
}
.b-result.office .extra {
  display: block;
}
.b-result.office .extra .ui.button.small {
  font-size: 0.75rem;
}
.b-result.office .extra .ui.button.small + .label {
  font-size: 0.75rem !important;
}
.b-result.office .extra .ui.button.medium {
  font-size: 0.875rem;
}
.b-result.office .extra .ui.button.float-right {
  float: right;
  display: inline;
}
.ui.card > .b-result__areas.extra {
  display: block;
  height: 48px;
  position: relative;
  overflow: visible;
  padding: 0;
}
.b-result__areas .meta {
  display: block;
  max-height: 34px;
  height: auto;
  overflow: hidden;
  cursor: default;
  position: absolute;
  width: 100%;
  background: #fff;
  border-radius: 0 0 0.285714rem 0.285714rem;
  transition: max-height 0.5s ease, background 0.35s ease;
  padding: 0.625rem 1.875rem 0.5rem 0.875rem;
}
.b-result__areas .meta:hover {
  max-height: 200px;
  cursor: default;
  background: #10dff2;
  transition-delay: 0.45s;
}
.b-result__areas .meta .icon {
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
}
.b-result__areas .label {
  white-space: nowrap;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.b-result__content {
  display: inline-flex;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.5rem;
}
.ui.card .b-result__content .description {
  padding: 0.25rem 0 0.25rem 0;
  font-size: 0.75rem;
}
.office.ui.card .b-result__content .description {
  font-size: 0.875rem;
}
.ui.card .b-result__content .ui.label {
  font-family: Lato, sans-serif !important;
}
.ui.card .b-result__content .ui.label.circular {
  margin-top: 0.25rem;
  padding: 0.25rem 0.5rem !important;
}
.ui.card .b-result__content .ui.label.circular.secondary {
  color: #fff;
}
.ui.card .b-result__content .ui.label.circular.mini {
  line-height: 0.75rem;
}
.ui.card .b-result__content .ui.label.tiny {
  padding: 0.25rem 0.375rem;
  font-size: 0.75rem;
}
.b-result__content-button.ui.button {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 0.35s ease;
  background-color: transparent;
  max-height: none;
  border-radius: 0;
  z-index: 1;
}
.b-result__content-button.ui.button:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.b-result__content-button.ui.button.disabled {
  pointer-events: none;
}
.b-result__avatar {
  display: inline;
  flex: 0 0 158px;
  overflow: hidden;
  width: 158px;
  height: 156px;
  margin: -0.875rem -0.875rem -0.875rem 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem 0 0 0;
}
.b-result__avatar-skew {
  width: 300px;
  height: 300px;
  margin: -50px -160px 0 0.25rem;
  background: #572258;
  border-radius: 300px;
  overflow: hidden;
  position: relative;
  display: block;
}
.b-result__avatar-img {
  background: url("../images/avatar/large/user-blank.png");
  background-size: cover;
  position: absolute;
  left: 0;
  top: 50px;
  width: 160px;
  height: 160px;
  border-radius: 0 0 0.25rem 0;
}
.b-result__avatar-img.building {
  background: url("../images/avatar/large/company-blank.png");
  background-size: cover;
}
.b-result.office .b-result__avatar {
  width: 180px;
  height: 180px;
}
.b-result.office .b-result__avatar-img {
  width: 180px;
  height: 180px;
}
.b-result__type {
  display: block;
  margin-top: 0.25rem;
}
.b-result-previous__header {
  border-bottom: 1px solid #0bb5c4;
  margin: 1rem 1.25rem;
  display: block;
}
.b-result-previous__header h4 {
  line-height: 2rem;
}
.b-result-previous__item {
  margin: 0 1rem;
}
.b-result-previous__item a {
  color: #333;
  background-color: #dfeaee;
  padding: 0.75rem 1rem 0.75rem 0.75rem;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-top: 0.5rem;
  display: block;
  transition: all 0.35s ease;
}
.b-result-previous__item a:hover {
  background-color: #cfdfe5;
}
.b-result-previous__add {
  display: inline-block;
  padding: 0 0.25rem;
  color: #89929b;
}
.b-result__map {
  position: relative;
}
.b-result__map-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  background-color: #e8edf3;
  color: #89929b;
}
.b-result__map-error i.icons .corner.icon {
  margin-left: 1rem;
}
.b-result__map-error p {
  text-align: center;
  color: #89929b;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem !important;
}
.b-result__link {
  display: inline-block;
  margin-right: 0.5rem;
}
.b-result__location {
  display: inline-block;
  margin-right: 0.5rem;
}
.b-result__dist {
  position: absolute;
  left: 1.25rem;
  bottom: 1rem;
  z-index: 2;
}
.b-left-panel .b-result__dist {
  position: relative;
  margin: 0.75rem 1rem !important;
  left: auto;
  bottom: auto;
}

.ui.icon.input > i.icon {
  max-height: 3rem;
}

.ui.form .field .ui.input__full-width input {
  width: 100%;
}
.ui.form .field .ui.input.icon input {
  padding-left: 3rem;
}
.ui.form .field .ui.input.reveal input {
  padding-right: 3rem;
}
.ui.form .field .input__reveal-btn {
  height: 3rem;
  width: 3rem;
  display: block;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #3d4c5c;
}

.error .ui.basic.red.label {
  margin-top: 0.125rem;
  background: #fac6c6 !important;
  border: 0;
  border-radius: 0.25rem;
  display: block;
  font-size: 0.75rem;
  animation: fadeIn 0.5s ease;
  overflow: hidden;
  text-align: left;
  color: #122635 !important;
}

.field__text-link {
  display: block;
  font-size: 0.675rem;
  text-align: left;
  padding-top: 0.25rem;
  max-height: 60px;
  overflow: hidden;
  transition: all 0.35s ease;
  color: #572258;
  background: transparent;
  border: 0;
}
.field__text-link:hover {
  text-decoration: underline;
  color: #572258;
  background: transparent;
  border: 0;
}
.field__text-link.right {
  text-align: right;
}
.field__text-link.hidden {
  max-height: 0;
  padding: 0;
}
.ui.form .field > label:empty {
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}
i.icon.primary {
  color: #6c1e6c;
}
i.icon.primary-tint {
  color: #a82fa8;
}
i.icon.primary-shade {
  color: #572258;
}
i.icon.primary.circular {
  box-shadow: 0 0 0 0.1em #0bb5c4 inset;
}
i.icon.secondary {
  color: #0bb5c4;
}
i.icon.secondary-tint {
  color: #10dff2;
}
i.icon.secondary-shade {
  color: #07727c;
}
i.icon.secondary.circular {
  box-shadow: 0 0 0 0.1em #802480 inset;
}
i.icon.tertiary {
  color: #e2ab3b;
}
i.icon.tertiary-tint {
  color: #e9be67;
}
i.icon.tertiary-shade {
  color: #cc931e;
}
i.icon.tertiary.circular {
  box-shadow: 0 0 0 0.1em rgba(226, 171, 59, 0.5) inset;
}

.card.company .ui.small.image, .card.contact .ui.small.image {
  width: 6rem;
  min-height: 6rem;
  min-width: 6rem;
  margin-left: 1rem;
  margin-top: 1rem;
}
.card.company > .content > .header:not(.ui), .card.contact > .content > .header:not(.ui) {
  margin: 2rem 0 0 0;
  font-family: Lato, sans-serif;
  z-index: 1;
  position: relative;
  pointer-events: none;
}
.card.company > .content > .meta, .card.contact > .content > .meta {
  z-index: 1;
  position: relative;
  color: #122635;
  pointer-events: none;
  font-weight: 500;
}
.card.company .description, .card.contact .description {
  padding-bottom: 1rem;
}
.card.company .description.profile, .card.contact .description.profile {
  padding: 0.5rem 0.75rem 1rem 0.75rem;
}
.card.company .description.profile p, .card.contact .description.profile p {
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0.25rem 0rem;
}
.card.company .description.profile h4,
.card.company .description.profile h5, .card.contact .description.profile h4,
.card.contact .description.profile h5 {
  padding: 0;
}
.card.company .description.profile button.fluid, .card.contact .description.profile button.fluid {
  margin: 0.25rem 0;
}
.card.company .description.profile div.dropdown, .card.contact .description.profile div.dropdown {
  margin: 0.5rem 0 0.25rem 0;
}
.card__full-links {
  margin: 0 0.5rem;
  display: block;
  overflow: hidden;
}
.card__link {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  font-size: 0.875rem;
  color: #802480;
  transition: color 0.35s ease;
  backface-visibility: hidden;
}
.card__link:hover {
  color: #572258;
}
.card__link.placeholder {
  color: #89929b;
}
.card__link.placeholder:hover {
  color: #3d4c5c;
}
.card__link .icon.small {
  font-size: 1rem;
  backface-visibility: hidden;
  transform: translateZ(0);
}
.card__image {
  margin: -7rem -8rem -11rem 0;
  position: absolute;
  height: 32rem;
  width: 32rem;
  background-color: #802480;
  border-radius: 16rem;
  right: 0;
  overflow: hidden;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  animation: zoomUp 0.45s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.45s alternate backwards;
}
@media only screen and (max-width: 1023px) {
  .card__image {
    height: 20rem;
    width: 20rem;
    margin: 1.5rem auto 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .card__image {
    height: 16rem;
    width: 16rem;
  }
}
@media only screen and (max-width: 639px) {
  .card__image {
    height: 14rem;
    width: 14rem;
    margin: 1.5rem auto 0 auto;
  }
}
@media only screen and (max-width: 399px) {
  .card__image {
    height: 8rem;
    width: 8rem;
    overflow: visible;
    margin-bottom: 1.5rem;
  }
  .card__image.no--links {
    margin-bottom: 0;
  }
}
.card__image + .label.pointing {
  position: absolute;
  margin: 1.25rem 0 0 0;
  opacity: 0;
  transition: all 0.35s ease;
  z-index: 4;
  pointer-events: none;
}
.card__image > img {
  transition: all 0.5s ease;
  border: 2px solid #fff;
  background-color: #ced4da;
}
.card__image-link {
  position: relative;
  cursor: pointer;
  transform: scale3d(1, 1, 1);
  transition: all 0.35s ease;
}
.card__image-link:hover {
  transform: scale3d(0.96, 0.96, 1);
}
.card__image-link:hover + .label.pointing {
  transition: all 0.35s ease 0.7s;
  margin: 1.25rem 0 0 -2rem;
  opacity: 1;
}
.card__image-link:hover > img {
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
}
.card__image-link:hover > img {
  border-color: #802480;
}
.card__image .e-avatar.e-avatar-large {
  transition: all 0.5s ease;
  background-color: #802480;
  width: 300px;
  height: 300px;
  line-height: 6rem;
  font-size: 2.75rem;
  margin: 4.5rem 0 0 0;
  font-style: normal;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  text-align: center;
  border-radius: 8rem;
}
.card__image .e-avatar.e-avatar-large img {
  display: block;
}
.b-profile-hero .card__image .e-avatar.e-avatar-large {
  margin: 0;
  height: 32rem;
  width: 32rem;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1023px) {
  .card__image .e-avatar.e-avatar-large {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
.card__image i.icon.add,
.card__image i.icon.camera {
  color: #802480;
  margin: 0;
  border-radius: 100%;
  background: #fff;
  width: 1.5rem;
  font-size: 1.5rem;
}
.card__hero {
  display: block;
  margin: -1rem -1rem 1rem -1rem;
  width: auto;
  min-height: 10rem;
  overflow: hidden;
  background: #572258;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: stretch;
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
}
@media only screen and (max-width: 639px) {
  .card__hero {
    text-align: center;
    flex-direction: column-reverse;
    padding-bottom: 2rem;
  }
}
.b-profile-hero .card__hero {
  background: none;
  margin: 0;
  border-radius: 0;
  overflow: visible;
  min-height: 420px;
}
@media only screen and (min-width: 992px) {
  .ui.grid .column .b-profile-hero .card__hero {
    margin: 0 auto;
    max-width: 1234px;
    padding: 0 0 0 2.5rem;
  }
}
.card__hero-col {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 2rem;
}
@media only screen and (max-width: 639px) {
  .card__hero-col {
    width: 100% !important;
    margin: 0 auto;
    padding: 1rem 2rem;
  }
}
.b-profile-hero .card__hero-col {
  padding-left: 0;
}
@media only screen and (max-width: 639px) {
  .b-profile-hero .card__hero-col {
    padding-right: 0;
  }
}
.card__hero-col + .card__hero-col {
  flex: 0 0 280px;
  padding: 0;
  overflow: hidden;
  width: 280px;
  min-height: 280px;
}
@media only screen and (max-width: 639px) {
  .card__hero-col + .card__hero-col {
    padding-top: 0;
    min-height: auto;
    flex: 1 1 auto;
  }
}
.b-profile-hero .card__hero-col + .card__hero-col {
  overflow: visible;
  flex: 0 0 400px;
  width: 400px;
  padding: 0;
  min-height: 380px;
}
@media only screen and (max-width: 767px) {
  .b-profile-hero .card__hero-col + .card__hero-col {
    flex: 1 0 auto;
    width: auto;
    min-height: 100px;
  }
}
.card__hero .label.pointing {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem 3rem 0 0;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 1;
  pointer-events: none;
}
.card__hero-buttons {
  display: block;
  overflow: hidden;
  margin-top: 2.75rem;
  animation: fadeUpTran 0.45s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.65s alternate backwards;
}
@media only screen and (max-width: 639px) {
  .card__hero-buttons {
    margin-bottom: 2rem;
    margin-top: 1.5rem;
  }
  .card__hero-buttons .button {
    font-size: 1rem !important;
    padding: 0.75rem 1.5rem;
  }
}
.card__hero h2 {
  font-size: 1.75rem;
  animation: fadeUpTran 0.35s ease 0.35s alternate backwards;
}
@media only screen and (max-width: 639px) {
  .card__hero h2 {
    text-align: center;
  }
}
.card__hero h3 {
  padding-left: 0;
  padding-top: 1rem;
}
@media only screen and (max-width: 639px) {
  .card__hero .header,
  .card__hero .meta {
    text-align: center;
  }
}
.card__hero .header h3,
.card__hero h4 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-top: -1rem;
  color: #e2ab3b;
  padding-left: 0;
  margin-bottom: 0.5rem;
  animation: fadeLeft 0.35s ease 0.6s alternate backwards;
}
@media only screen and (max-width: 639px) {
  .card__hero .header h3,
  .card__hero h4 {
    text-align: center;
  }
}
.card__hero .label.circular {
  animation: fadeLeft 0.35s ease 0.65s alternate backwards;
}
.card__hero-user {
  display: block;
  padding: 0.5rem 0;
  margin-top: 1rem;
}
.card__hero-user .ui.image.label {
  background: rgba(222, 226, 230, 0.1);
  color: #fff;
}
.card__hero-user .ui.image.label img {
  margin-right: 0.5rem;
}
.card__hero .label {
  clear: both;
}
.card__hero-location {
  display: block;
  clear: both;
  margin-top: 1rem;
  animation: fadeLeft 0.35s ease 0.7s alternate backwards;
}
.card__hero-location i.icon {
  margin-right: 0.25rem !important;
}
.card__hero-location a {
  color: #fff;
}
.card__hero-location a:hover {
  color: #fff;
  text-decoration: underline;
}
.b-profile-hero .card__hero-location {
  padding-top: 1.25rem;
}
h3 + .card__hero-location {
  margin-top: 0;
}
.b-sticky-header .card__hero-location {
  margin-top: 0;
}
.ui.card .card__hero .meta {
  color: #fff;
  display: block;
}
.ui.card .card__hero .meta .icon {
  color: #0bb5c4;
}
.card__hero i.icon.camera {
  position: absolute;
  top: 6rem;
  left: 15rem;
  color: #802480;
  margin: 0 1rem 1rem 0;
  border-radius: 100%;
  width: 1em;
  background: #fff;
  transition: transform 0.35s ease;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  z-index: 1;
}
.card__hero .label.pointing {
  margin: 3rem 0.5rem 0 0;
  max-width: 190px;
  line-height: 1rem;
}
.card__hero .label.pointing:before {
  left: 170px;
}
.card__hero:hover i.icon.camera {
  transform: scale3d(1.1, 1.1, 1);
}
.card__hero:hover .label.pointing {
  transition: all 0.35s ease 0.7s;
  opacity: 1;
  margin: 3.5rem 0.5rem 0 0;
  max-width: 190px;
}
.card__hero-image {
  transform: scale3d(1.05, 1.05, 1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.35s ease, filter 0.35s ease;
}
.company .card__hero-image.empty {
  filter: blur(10px);
  background-image: url("../images/avatar/tiny/company-blank.png");
}
.contact .card__hero-image.empty {
  filter: blur(10px);
  background-image: url("../images/avatar/tiny/user-blank.png");
}
.card__hero-link:hover .card__hero-image {
  transform: scale3d(1.1, 1.1, 1);
  -webkit-filter: brightness(80%);
  filter: brightness(80%);
}
.card__hero-socials {
  height: 100%;
  position: absolute;
  right: 1rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 399px) {
  .card__hero-socials {
    top: 5.75rem;
    right: unset;
    margin: 0 auto;
  }
}
.card__hero-socials-center {
  flex: 0 0 auto;
  flex-direction: column;
  display: flex;
  height: auto;
  width: 3.25rem;
  overflow: hidden;
  margin: 0 auto;
  background-color: rgba(87, 34, 88, 0.65);
  border-radius: 3rem;
  text-align: center;
  padding: 0.5rem 0 0.25rem 0;
  backdrop-filter: blur(2px);
}
.card__hero-socials-center:empty {
  padding: 0;
}
@media only screen and (max-width: 399px) {
  .card__hero-socials-center {
    flex-direction: row;
    backdrop-filter: none;
    background: none;
    width: auto;
  }
}
.card__hero-socials .icon.button {
  background: transparent;
  transition: color 0.35s ease;
  padding: 0rem 0.75rem;
  border-radius: 0;
  height: 2.5rem;
  width: 3rem;
  line-height: 2.125rem;
  flex: 0 0 2.5rem;
  display: block;
  color: #0bb5c4;
  position: static !important;
  transform: none !important;
}
.card__hero-socials .icon.button:hover {
  color: #41e6f4;
}
.card__hero-socials i.icon {
  font-size: 1.75rem;
  opacity: 1 !important;
  position: static !important;
}
.card__hero-socials i.icons {
  display: inline;
  float: left;
  opacity: 1;
}
.card__hero-socials i.icons .corner.icon {
  font-size: 0.875rem;
  text-shadow: -2px -2px 0 #572258, 2px -2px 0 #572258, -2px 2px 0 #572258, 2px 2px 0 #572258;
}
.event .card__hero-location {
  padding-top: 0rem;
}
.card > .extra a:not(.ui) {
  font-weight: 500;
}
.card .content {
  position: relative;
}

.ui.modal > .content {
  padding: 2rem;
  overflow-y: auto;
}

@media only screen and (max-width: 767px) {
  .modals.dimmer .ui.scrolling.modal {
    margin: 0;
  }
}
@media (max-width: 768px) {
  .ui.modal.visible.transition {
    display: flex !important;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex-wrap: nowrap;
  }
  .ui.modal > .content {
    flex: 1 1 auto;
    height: auto;
    max-height: unset;
  }
  .ui.modal > .header {
    flex: 0 0 auto;
    width: 100%;
  }
  .ui.modal > .actions {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media only screen and (max-width: 1247px) {
  .b-grid--3.ui[class*="three column"].grid > .column:not(.row), .b-grid--3.ui[class*="three column"].grid > .row > .column {
    width: 50%;
    padding: 1rem 1rem !important;
  }
}

.label.primary {
  background-color: #802480;
  color: #fff;
}
.label.secondary {
  background-color: #0bb5c4;
  color: #222;
}
.label.tertiary {
  background-color: #e2ab3b;
  color: #122635;
}
.label.highlight {
  background-color: #22D1AF;
  color: #fff;
}
.label.neutral {
  background-color: brand-neutral;
  color: #212529;
}

html {
  height: 100% !important;
  overflow: auto !important;
}

body {
  height: 100% !important;
}

h1, h2, h3, h4, h5 {
  margin: 2rem 0 1rem;
}

@media only screen and (min-width: 1200px) {
  .ui.grid.container {
    width: calc(1170px + 2rem) !important;
  }
}
@media only screen and (max-width: 583px) {
  .ui.segment {
    padding-left: 0;
    padding-right: 0;
  }
}

.dragdrop-container {
  width: 100%;
}
.dragdrop-item {
  border: 1px solid #c3b7c0;
  border-radius: 5px;
  cursor: grab;
  padding: 16px;
  margin: 16px 8px;
}
.dragdrop-item:active {
  box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2);
}
.dragdrop-item_icon {
  cursor: pointer;
  margin-left: 8px !important;
}

/**/
﻿* {
    //mobile only for now.
    //box-sizing: border-box;
}
body {
    color: #000;
    font-family: $sans-serif;
    line-height:20px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px; 
}

form {
    display: unset;
}

input, textarea {
    /*clear safari settings*/
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

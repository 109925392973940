.b-event-card{
    &{
        display:flex;
        background: $white1;
        padding:2rem;
        border-radius:0.375rem;
        transition: background 0.35s ease, transform 0.5s ease;
        margin:1.5rem 1rem 1rem 1rem;
        &.active{
            cursor: pointer; 
            &:hover{
                background: lighten($secondary,35%);
                transform: scale(1.025); 
            }
        }
        @include mq('tablet-wide', max) {
            &.ui.b-padding--x3{
                padding:1.5em; 
            }
        }
        @include mq('phablet', max) {
            &.ui.b-padding--x3{
                padding:1.25em; 
                margin:1rem 0rem 0.25rem 0rem;
            }
        }
        
    }
    &__details{
        flex: 1 1 auto;
        padding-right:1rem;
        @include mq('phablet', max) {
            &.ui.b-padding--x3{
                padding:1.5em; 
            }
        }
        .h2{
            padding-bottom:0.5rem;
            font-weight:$bold; 
            line-height:1.75rem;
            @include mq('tablet-wide', max) {
                font-size: 1.25rem;
                line-height:1.5rem;

            }
            @include mq('phablet', max) {
                font-size: 1rem;
                line-height:1.25rem;
            }
        }
        .h4{
            @include mq('phablet', max) {
                font-size:0.875rem;
            }
            
            & +.h4{
                padding-bottom:1rem;
                @include mq('phablet', max) {
                    display:none;
                }
            }
        }
        .p{
            color:$grey-800;
            
            @include mq('tablet', max) {
                display: none; 
            }
        }
    }
    &__time{
        margin-left:0.5rem;
        display: inline-block;
        @include mq('phablet', max) {
            margin-left:0.25rem;
        }
    }
    &__date{
        display: inline-block;
        margin-right:0.5rem;
        @include mq('tablet', max) {
            margin-right:0.25rem;
        }
        .month--long{
            display: inline-block;
        }
        .month--short{
            display: none;
        }
        @include mq('tablet', max) {
            .month--long{
                display: none;
            }
            .month--short{
                display: inline-block;
            }
        }
    }
    &__img{
        flex: 0 0 300px;
        background-color: $grey-300;
        background-repeat: no-repeat;
        background-size: cover;
        height: 200px;  
        border-radius: 0.25rem;
        @include mq('tablet-wide', max) {
            flex: 0 0 150px;   
            height: 150px;  
        }
        @include mq('phablet', max) {
            flex: 0 0 100px;   
            height: 100px;  
        }

    }
    &__labels{
        padding-top:0.75rem;
        display:block;
    }
    
    &__user{
        padding-top:0.75rem;
        display:block;
        @include mq('phablet', max) {
            padding-top:0.5rem; 
            font-size:0.75rem;
        }

    }
}
﻿/*break points*/

/*sizes*/

$phone-width: 400px;
$phone-wide-width: 480px;
$phablet-width: 584px;
$tablet-small-width: 640px;
$tablet-width: 768px;
$tablet-wide-width: 1024px;
$desktop-width: 1248px;
$desktop-wide-width: 1440px;
$desktop-wide2-width: 1632px;

$height-sx: 304px;
$height-s: 400px;
$height-m: 496px;
$height-l: 592px;
$height-lx: 688px;
$height-lxx: 784px;

$centerWidth:1234px;

$breakpoints: ( "phone": $phone-width, "phone-wide": $phone-wide-width, "phablet": $phablet-width, "tablet-small": $tablet-small-width, "tablet": $tablet-width, "tablet-wide": $tablet-wide-width, "desktop": $desktop-width, "desktop-wide": $desktop-wide-width, "desktop-wide2": $desktop-wide2-width );

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type == max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content; 
        }
    }
    @else {
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
/*height*/
$breakpointsHeights: ( "height-sx": $height-sx, "height-s": $height-s, "height-m": $height-m, "height-l": $height-l, "height-lx": $height-lx );

@mixin mqHeight($height, $type: max) {
    @if map_has_key($breakpointsHeights, $height) {
        $height: map_get($breakpointsHeights, $height);

        @media only screen and (#{$type}-height: $height) {
            @content;
        }
    }
}
@mixin text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*trackbar*/
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {

    &::-webkit-scrollbar {
        width: $size;
        background: $foreground-color;

        &-thumb {
            border-radius: 0;
            background: $foreground-color;
        }

        &-track {
            -webkit-box-shadow: inset 0 0 20px rgba(0,0,0,0.3);
            background: $background-color;
            opacity: 0.5;
            border-left: 1px solid $foreground-color;
        }
    }

    &::-webkit-scrollbar &::-webkit-scrollbar-corner {
        background: $background-color;
    }
    // For Internet Explorer
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
}

@mixin hideScrollUntilHover($theme: light) {
    overflow: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: none;
    scrollbar-face-color: $grey-300;
    scrollbar-track-color: rgba($black1, 0.05);


    &::-webkit-scrollbar {
        width: 8px;
        background: rgba($black1, 0.05);
        //background:#f0f;
        transition: all ease 0.35s;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba($black1, 0);
        border-radius: 4px;
    }

    &:hover::-webkit-scrollbar-thumb {
        background: rgba($black1, 0.15);
    }

    @if $theme == dark {
        scrollbar-track-color: rgba($white1, 0.05);

        &::-webkit-scrollbar-thumb {
            background: rgba($white1, 0);
        }

        &:hover::-webkit-scrollbar-thumb {
            background: rgba($white1, 0.15);
        }
    }
}

/*Lex logo Colours*/

@mixin lex-network-svg($color, $color2) {
    //force sass to render rgba opacity 1
    $fill: rgba($color2,0.9999999);
    $fill2: rgba($color,0.9999999); 

    background-image: linear-gradient(transparent, transparent), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="529.6" height="308.9"><path fill="#{$fill}" d="M155 .6L78 231.7H1L78 .6h77"/><path fill="#{$fill2}" d="M155 231.7H1l57.7 77H155v-77"/><g><path fill="#{$fill2}" d="M296.8.6h77l51.9 308h-77L296.8.6"/><path fill="#{$fill}" d="M373.8.6L270 308.9h-77L296.8.6h77M529.6.6L425.8 308.9h-77L452.6.6h77"/></g></svg>'); 
    background-size: contain;
    background-repeat:no-repeat;
    background-size:80%;
    background-position: center center; 
}






@mixin onlyIE() {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
}
@mixin onlyEdge() {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}



.timeRec {
    display: flex;

    &-field {
        flex: 1 0 50%;
    }

    &-count {
        color: $opus-orange;
        text-align: center;
    }

    &-controls {
        display: flex;
        flex-direction: row;
        justify-content: center;

        &-button {
            border-radius: 5px;
            flex: 1;
            padding: 1rem 2rem;
            margin: 0 2rem;
            max-width: 144px;

            &__start {
                background-color: $green;
            }

            &__pause {
                background-color: $orange;
            }

            &__stop {
                background-color: $red;
            }
        }
    }

    &-recent {

        .timeRec-recent-heading, .timeRec-recent-content {
            border-bottom: 2px solid $grey-400;
            float: left;
            width: 33%;
        }
    }
}
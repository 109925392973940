.ui.modal{
    & > .content {
        padding: 2rem;
        overflow-y:auto;
    }
}

@media only screen and (max-width: 767px){
    .modals.dimmer .ui.scrolling.modal  {
        margin: 0;
    }
}
@media (max-width: 768px){
    .ui.modal{
        &.visible.transition{
            display:flex!important;
            flex-direction: column; 
            align-items: stretch;
            justify-content: center;
            flex-wrap: nowrap;
        }

        & > .content {
            flex: 1 1 auto;
            height: auto;
            max-height: unset;

        }
        & > .header{
            flex: 0 0 auto;
            width:100%;
        }
        
        & > .actions{
            flex: 0 0 auto;
            width:100%;
        }
    }
}
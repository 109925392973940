.dash{
    &__header {
        display: flex;
        color: $white1;
        padding: 1.5rem 0;
        position: relative;

        &-title {
            flex: 1 0;
            margin: 0;
            h1{
                margin:0;
            }
            h2{
                margin:0;
            }
            .month{
                &--full{
                    display: block;
                    @include mq('tablet', max) {
                        display: none;
                    }
                }
                &--small{
                    display: none;
                    @include mq('tablet', max) {
                        display: block;
                    }
                }
            }
        }

        

        &-bg {
            background-color: $opus-purple-tint;
            border-radius: 100%;
            height: 400px;         
            position: absolute;
            right: -50px;
            top: -70px;
            width: 400px;
            z-index: 0;
        }
    }
    &__weather {
        flex: 1 0;
        text-align: right;
        z-index: 1;
        padding-top:2rem;


        @media (max-width: 768px) {
            display: none;
        }
    }


    &__appointments {
        border: 2px solid $opus-purple;
        border-radius: 5px;
        display: flex;
        margin-bottom: 1rem;
        overflow: hidden;
        position: relative;

        @media (max-width: 1024px) {
            flex-wrap: wrap;
        }

        &-background {
            position: absolute;
            z-index: 1;

            .first {
                border: 10px solid $opus-blue;
                border-radius: 100%;
                height: 300px;
                left: -50px;
                position: absolute;
                top: -50px;
                width: 300px;

                @media (max-width: 768px) {
                    left: -100px;
                    top: -100px;
                }
            }
        }

        &-card {
            flex: 1 0 25%;
            background-color: $opus-blue;
            border-radius: 5px;
            padding: .6rem;
            margin: .5rem 0;
            overflow: hidden;

            & span {
                background-color: lighten($opus-blue, 15%);
                padding: 16px 19px;
                border-radius: 100%;
                margin-right: .5rem;
            }
        }
    
        &-focus {
            background-color: $opus-purple;
            color: $white1;
            display: flex;
            padding: 1rem;
            border-radius: 5px;        
            margin-bottom: 1rem;
        }

        &-title {
            color: $white1;
        }

        &-details {
            color: $opus-orange;
            
            & span {
                margin-right: .6rem;
            }
        }
    
        &-section {
            &__l {
                flex: 1 0 80%;
            }
            &__s {
                flex: 1 0 20%;
                flex-direction: column;
                display: flex;
            }
        }

        &-info {
            flex: 1 0 20%;
            max-width: 20%;
            padding: 1rem;
            z-index: 2;

            @media (max-width: 1024px) {
                border: none;
                display: flex;
                justify-content: space-evenly;
                flex: 1 0 100%;
                max-width: 100%;
                padding-bottom: .5rem;
            }

            & span {
                display: inline-flex;
                align-items: center;
                margin-bottom: .5rem;

                & img {
                    margin-right: .5rem;
                    width: 22px;
                }
            }
        }

        &-main {
            flex: 1 0 60%;
            text-align: center;
            padding: 1rem;
            z-index: 2;

            @media (max-width: 1024px) {
                padding-top: .5rem;
            }

            & p {
                padding: 0;
            }
        }

        &-map {
            flex: 1 0 20%;
            z-index: 2;

            @media (max-width: 1024px) {
                display: none;
            }
        }

        &-current {
            background-color: $opus-purple;
            border: none;
            color: $white1;

            .dash__appointments-info {
                color: $opus-orange;
            }

            & p {
                color: $white1;
            }
        }

        &-more {
            text-align: center;

            & span {
                background-color: $opus-blue;
                border-radius: 5px;
                color: $black1;
                cursor: pointer;
                font-size: .78571429rem;
                padding: .2rem .4rem;
            }
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;

        & h3 {
            flex: 1 0 100%;
        }

        & p {
            border-bottom: 1px solid $grey-200;
            flex: 1 0 100%;
            padding-bottom: .6rem;

            & i {
                background-color: $opus-blue;
                border-radius: 100%;
                color: $white1;
                float: right;
                height: 36px;
                margin: 0 .6rem;
                margin-top: -5px;
                padding: 6px 10px;
                width: 36px;
            }
        }
    }
}
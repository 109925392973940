﻿.preloader {
    & {
        position: fixed;
        top: 0;
        z-index: 9999999;
        min-height: 480px;
        width: 100%;
        background: $primary-shade;
    }
    &__wrap {
        position: fixed;
        top: 0;
        z-index: 9999999;
        min-height: 480px;
        width: 100%;
        height: 100%;
        background: $primary-shade;
        display: block;
    }

    &__logo,
    &__svg {
        margin: 0;
        left: 0;
        width: 100%;
        opacity: 1;
        cursor: default;
        pointer-events: none;
    }

    &__logo {
        top: 0;
        height: 100%;
        color: $grey-white;
        .preloader--open &{
            opacity: 1;
            transform: none; 
        }
        &-center {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 0 auto;
            height: 100%;
        }


        &-primary,
        &-secondary,
        &-text {
            min-width: 320px;
            max-width: 480px;
            width: 90%;
            flex: 0 0 auto;
            margin: 0 auto;
        }

        &-primary {
            height: 20px;
            flex: 1 1 20px;
            max-height: 20px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size:contain;
            margin-top:1rem;
        }

        &-text {
            font-size: 0.75rem;
            letter-spacing: 0.2rem;
            text-align: center;
            padding: 2.5rem 0 1.5rem 0;
            font-family: $sans-serif;
        }
        .ui.loader{
            position: relative;
            flex:1 1 auto;
            font-family: $sans-serif;

        }
        

    }
    &.ui.inverted.dimmer .ui.loader{
        
        /*&::after{
            border-top-color: darken($primary, 15%);
        }*/
    }
}

@keyframes animLoadHeader {
    from {
        opacity: 0;
        transform: translate3d(0,800px,0);
    }
}
@keyframes animLoadedLogo {
    from{
        opacity:1;
    }
    to {
        opacity:0;
        transform: translate3d(0,100%,0) translate3d(0,50px,0) scale3d(0.65,0.65,1);
    }
}
@keyframes animLoadedLoader {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(0,-100%,0) scale3d(0.3,0.3,1);
    }
}

@keyframes animLoadedHeader {
    to {
        transform: translate3d(0,-100%,0);
    }
}


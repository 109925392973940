.modal {
    background-color: $white1;
    border-radius: 5px;
    box-shadow: 3px 3px 3px rgba($black1,.15);
    flex-wrap: wrap;
    display: flex;
    &.small-h{
        min-height: 550px;
    }

    @media (max-width: 1200px) {
        width: 96vw;
    }

    @media (max-width: 768px) {
        align-items: center;
        height: 95%;
        min-height: auto;
        width: 100%;
    }

    .content {
        @media (max-width: 768px) {
            padding: 0 !important;
        }
    }

    .navigation {
        background-color: $nav-bg;
        flex: 1 0 25%;
        padding: 1rem;

        @media (max-width: 768px) {
            background-color: unset;
            align-self: flex-start;
            flex: 1 0 100%;
            height: 80vh;
            padding: 0;
        }

        &-hide {
            display: none;
        }

        &-show {
            animation: fadeSlide .16s;
            display: block;
        }

        .link {
            border-radius: 5px;
            color: $black1;
            cursor: pointer;
            padding: .5rem;
            transition: background-color .16s ease;

            @media (max-width: 768px) {
                margin-bottom: 1rem; 
                padding: .75rem;
            }

            &-active {
                background-color: darken($nav-bg, 5%);

                @media (max-width: 768px) {
                    background-color: darken($nav-bg, 5%);
                }
            }
        }
    }
    .content {
        flex: 1 0 75%;
        max-height: auto;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 1rem;

        @media (max-width: 768px) {
            flex: 1 0 100%;
            display: none;
            height: 80vh;
            max-height: 100%;
        }

        &-panel {
            animation: fadeSlide .16s;
            display: none;

            & i {
                float: right;
            }

            &-active {
                display: block;
            }
        }

        &-active {
            animation: fadeSlide .16s;
            display: block;
        }
    }

    .context {
        align-self: flex-end;
        border-top: 1px solid $light-grey4;
        flex: 1 0 100%;
        padding: 1rem;
        margin-top: 1rem;
    }

    &-dimmer {
        align-items: center;
        background-color: rgba($black1,.45);
        display: flex;
        justify-content: center;
        height: 100vh;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity .16s ease;
        width: 100vw;
    }

    &-active {
        opacity: 1;
        pointer-events: all;
    }

    &-mobile {
        &-control {
            display: none !important;
            margin-right: .5rem !important;

            @media (max-width: 768px) {
                display: block;
            }
        }
    }
}

@keyframes fadeSlide {
    from {opacity: 0; transform: translateX(50px)}
    to {opacity: 1; transform: translateX(0)}
}
.Toastify{
    body &__toast--default{
        color: $grey-800;
    }
    body &__progress-bar--default{
        background: $secondary;
    }
    &__toast-body{
        padding:0 1rem;
    }
}

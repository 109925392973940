html { 
    height: 100% !important; 
    overflow: auto !important; 
}
body { 
    height: 100% !important; 
}
h1, h2, h3, h4, h5 {
    margin: 2rem 0 1rem;
}
@media only screen and (min-width: 1200px){
    .ui.grid.container {
        width: calc(1170px + 2rem)!important;
    }
}
.ui.segment{
    @include mq('phablet', max) {
        padding-left:0;
        padding-right:0;
    }
}


﻿.e-dialog {
    .e-dlg-header {
        font-weight: $semibold;
        font-size: 1.125rem;


        .dlg-template {
            font-weight: 500 !important;
        }

        &-content {
            border-bottom: 1px solid $grey-300;
        }
    }

    .e-footer-content {
        border-top: 1px solid $grey-300;
        padding:0.5rem 0.25rem;
        .btn{
            margin-top:0;
            &__group{
                padding:0;
            }
        }
    }
}

.tabs {
    &--main{
        margin-top:-3.5rem;
        overflow-x:hidden;

        .ui.attached{            
            &.segment{
                border-radius: 0;
                border:0;
                background:none;
                padding-top:2.5rem;
                padding-bottom:2.5rem;
                
                @include mq('tablet', max) {
                    padding-top:1rem;
                }
            }
        }
        .ui.tabular.menu{
            //display:flex;
            //justify-content: left;
            display: block;
            text-align: center!important;
            border:0;
            min-height: unset;
            width: 100%;
            padding:0 1rem 0 1rem;
            width: calc(1127px + 2rem);
            margin: 0 auto;
            overflow-x:auto;
            overflow-y: hidden;
            scrollbar-width: 0px;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            white-space: nowrap;

            
            // For Internet Explorer
            scrollbar-face-color: transparent; 
            scrollbar-track-color: transparent;

            &::-webkit-scrollbar {
                width: 0px!important;
                background: transparent; 
                display: none;
                &-track {
                    -webkit-box-shadow: none;
                    border:0;
                }
            }
            @include mq('desktop') {
                width: calc(933px + 2rem)!important;
            }
            @include mq('desktop') {
                width: calc(933px + 2rem)!important;
            }

            .item{
                //border:0;
                background:$brand-neutral;
                margin:0 0.25rem;
                border-radius: 0.25rem 0.25rem 0 0;
                padding:0.75rem 1.25rem; 
                border:0;
                transition: all 0.35s ease;
                display: inline-block;

                &.active{
                    background:$base-bg-color ;
                    color:$primary-shade;
                }
                &:last-of-type{
                    margin-right: 1rem;
                }
            }
            
        }
    }
    &-horizontal {
        text-align: left;

        &-link {
            border-bottom: 2px solid $grey-500; 
            cursor: pointer;
            padding: 0 1rem .5rem;
            margin: 0 1.5rem 1rem 0;
        }

        &-active {
            border-color: $opus-purple;
        }
    }

    &-content {
        animation: fadeSlide .16s;
        padding: 0 !important;

        &-active {
            display: block;
        }

        .margin-fix {
            margin: 0 !important;
        }
    }
    /*
    @include mq('tablet-wide') {
        .segment{
            padding-left:0;
            padding-right:0;
    
        }
    }*/
    @include mq('tablet') {
        .ui.container{
            margin-left:0!important;
            margin-right:0!important;
            
        }
    }
}

@keyframes fadeSlide {
    from {opacity: 0; transform: translateX(50px)}
    to {opacity: 1; transform: translateX(0)}
}
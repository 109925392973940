﻿html{
    font-size:16px;
}
html,
body {
    height: 100%;
    -webkit-text-size-adjust: 100%;
    //background: $grey-200;
    background: $primary-shade;
}
.root,
#root {
    height: 100vh;
    width:100vw;
    position: relative;
    background: none;
    overflow: hidden;
    background: $grey-800;

    @include onlyIE() {
        height: auto;
    }

    @include onlyEdge() {
        height: auto;
    }
}
button {
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    border: 0;
    padding: 0;
    font: inherit;
    align-items: unset;
}
form {
    height: 100%;
    position: relative;
    background: none;

    @include onlyIE() {
        height: auto;
    }
    @include onlyEdge() {
        height: auto;
    }
}
.b-container {
    position: relative;
    overflow: hidden;
    background: #34495e;
    height: 100%;
    
    &__main{
        perspective: 80vh;
        position: relative;
        height: 100%;
        width: 100%;
        background:$grey-700;
        transition:transform 0.4s ease;
        .sm--pushed &{
            transform: scale3d(0.95, 0.95, 1);
        } 
    }
    &__inner {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: hidden;
        pointer-events: none;
        background-color:$nav-bg;
        transition: opacity 1.35s ease, transform 0.75s ease, background-color 0.4s ease;
       
    }
    &__scroller{
        pointer-events: all;
        overflow-y: auto;
        padding-top: 48px;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        transition: padding ease 0.4s;
       
        scrollbar-width: none;
         display: block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: absolute;
        /*
        scroll-snap-type: y proximity;
        scroll-snap-type: y mandatory;
        perspective: 300px;
        */
        .sm--hidden &{
            padding: 0 0 0 0;
            background-color: $grey-800;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @include mq('tablet-wide', min) {
            padding-top: 40px;
            .sm--hidden &{
                padding-top:0;
            }
        }

        @include mq('phablet') {
            padding: 0 0 3.75rem 0;
            .sm--hidden &{
                padding: 0 0 0 0;
            }
        }
    }
}

.scrollbar {
    
    @include hideScrollUntilHover(light);

    body &--light {
        @include hideScrollUntilHover(light);
    }

    body &--dark {
        @include hideScrollUntilHover(dark);
    }
}
/*
transform: translate(0, 44px) rotateX(-15deg);
outer perpective
perspective: 1000px;
*/




.screen-split {
    height: 100%;
    position: absolute;
    //overflow: hidden;
    width: 100%;
    transition: all 0.6s ease;
    opacity: 1;
    transform: scale3d(1, 1, 1);

    &--record1 {
        z-index: 2;
        border-right: 1px solid #666;
    }

    &--record2 {
        z-index: 1;
        right: 0;
    }

    &--hide {
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
    }

    &--40 {
        width: 40%;

        @include mq('desktop', max) {
            width: 100%;

            &.screen-split--record2 {
                width: 0;
                border-color: transparent;
            }
        }
    }


    &--50 {
        width: 50%;

        @include mq('desktop', max) {
            width: 100%;

            &.screen-split--record2 {
                width: 0;
                border-color: transparent;
            }
        }
    }

    &--60 {
        width: 60%;

        @include mq('desktop', max) {
            width: 100%;

            &.screen-split--record2 {
                width: 0;
                border-color: transparent;
            }
        }
    }
}
.b-section{
    display: block;
    //transition: max-width 0.45s ease, margin 0.45s ease;
    transition: margin 0.45s ease, opacity 0.35s ease;
    padding:1rem 0;

    &.center{
        max-width:$centerWidth;
        margin: 0 auto;
        animation: squeezeToPanel 0.65s ease 1 alternate backwards;
    }
    &.b-left-panel{
        
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        backdrop-filter: blur(2px);
        background: rgba($nav-bg,0.7);
        border-right:1px solid $nav-bg;
        overflow: auto;
        max-width:400px;
        animation: squeezeToPanel 0.65s ease 1 alternate backwards;
        padding-bottom: 5rem;
        
        @include mq('tablet-wide', max) {
            max-width:$centerWidth;
            margin: 0 auto;
            animation: squeezeToPanel 0.65s ease 1 alternate backwards;
            position: relative;
        }
    }
    &__wrap{ 
        display: block;
        margin:0 0 0 0;
        @include mq('tablet-wide', max) {
            margin:0.25rem 0 0 0;
        }
        @include mq('phablet', max) {
            margin:3.5rem 0 0 0;
        }
    }
}
@keyframes squeezeToPanel {
    0%{
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
    }
    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
    
}


.resize-sensor {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    visibility: hidden;
    max-width: 100%;
}
a {
    color: $secondary;
    text-decoration: none;
}
.text-align{
    &--left{
        text-align: left;
    }
    
    &--centre{
        text-align: center;
    }
    
    &--right{
        text-align: right;
    }
}
.profile-page{
    overflow-x: hidden;
    .tabs .ui.grid{
        //max-width: 1020px!important;
    }
    &__masthead{
        display: block;
        min-height: 0.5rem;
        .ui.grid{
            margin-top:-1rem;
        }
    }
}
.parallax{
    &__wrap{
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        background-color:white;
        perspective: 300px;
        height: auto;
        position: absolute;
        left:0;
        right:0;
        top:0;
        padding-top:40px;
        bottom:0;
        scroll-snap-type: y proximity;
        scroll-snap-type: y mandatory;
        perspective: 300px;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &__section{
        min-height:calc(100% - 3rem);
        //overflow-x: hidden;
        //overflow-y: hidden;
        scroll-snap-align: start;
        scroll-margin-top: 2.5rem;
        
        position: relative;
        transform-style:  preserve-3d;
        border-top:1px solid $grey-300;
        
        &:nth-of-type(even){
            //background: #e0e0e0;
        }
        &:last-child{
            min-height:100%;
        }
        &.flex-center{
            display: flex;
            flex-direction: column;
            justify-content:  center;
            align-items: center;
            .b-section__wrap{
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }
        }
        &.hide{
            display:none;
        }
        &.intro{
            & > .mid{
                background-color: $nav-bg ;
                transform: translateZ(0) scale(1);
            }
            & > .top{
                transform: translateZ(50px) scale(0.83333);
            }
            z-index: 1;
        }
        &.outro{
            background-color: teal ;
            z-index: 1;
        }
        &--1{

        }
        &--2{
            
        }
        
    }
    &__layer{
        display: flex;
        //border:1px solid blue;
        align-items: center;
        justify-content: center;   

        position:  absolute;
        inset:0;
        pointer-events: none;
        // scale calculation: 1  + ((z tranform value * - 1) / perpectrive)
        &.align--top{
            align-items: flex-start;
        }
        &.base{
            transform: translateZ(-150px) scale(1.5);
            //transform: translate3d(auto, auto, -300px)
        }
        &.mid{
            //transform: translateZ(-30px) scale(1.0);
        }
        &.top{
            transform: translateZ(50px) scale(0.83333);
        }
    }
}
@include mq('tablet', min) {
    .dgrid .tablet--hide {
        display: none !important;
    }
}

@include mq('tablet') {
    .dgrid .mobile--hide {
        display: none !important;
    }
}
﻿$sans-serif: Lato, sans-serif;
$sans-serif-title: 'DM Sans', sans-serif;
$serif: 'Times New Roman', Times, serif;
$icon-font: "icos";
$light: 300;
$normal: 400;
$semibold: 500;
$bold: 600;



$base-font-size: 1rem;
$base-line-height: 1.5rem;
$rhythm-unit: "rem";


// Setup modular-scale
$ms-base: 1rem;
//$ms-ratio: $fourth;


// Headings
//#{h1, h2, h3, h4, h5, h6},
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    //color: $base-text-color;
    font-family: $sans-serif;
    font-weight: $semibold;
    display: block;

    /*
    @include margin-trailer(1);
    @include margin-leader(1);
    */
}
/*
h1,
.h1{
    //@include type-scale(xxlarge);
}*/

h2,
.h2 {
    //@include type-scale(xlarge);
    font-size: 1.25rem;
}
h3,
.h3{
    //@include type-scale(large);
    font-size: 1.125rem;
    line-height:1.6875rem;
}
h4, 
.h4 {
    font-size: 1rem;
    line-height:1.5rem; 
}
h5,
.h5 {
    //@include type-scale(base);
    font-size: 0.875rem;
    line-height: 1.3125rem;

    &.inline {
        display: inline-block;
        padding: 0 0.25rem;
        margin:0.25rem;
    }
}

h6,
.h6 {
    font-size: 0.75rem;
    line-height: 1.125rem;
    
}


small,
.small {
   // @include type-scale(small);
}
.highlight--primary {
    background:$primary;
}
.highlight--secondary {
    background: $secondary;
}
.highlight--tertiary {
    background: $tertiary;
}
p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $base-text-color;
}
.e-avatar{
    //background-color: darken($nav-bg, 45%);
    text-overflow: ellipsis;
    &-stack &,
    .e-listview &-stack &{
        margin:0 0 0 -1.375rem;
        border:2px solid $grey-300;
        &:first-of-type{
            margin-left:0;
        }
    }
}

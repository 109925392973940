.b-pagination {
    position: sticky;
    width: 100%;
    z-index: 999;
    background: rgba($white1,0.8);
    backdrop-filter:blur(2px); 
    padding:0.5rem;
    bottom:0;
    border-top:1px solid $white1;
    .b-left-panel + &{
        width:24.25rem;
        position: absolute;
        @include mq('tablet-wide', max) {
            position: sticky;
            width: 100%;
        }
    }
    &-controls {
        border-radius: 5px;
        box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, .1);
        margin: 0 auto;
        overflow: hidden;

        &__back, 
        &__forward {
            background-color: $opus-blue !important;
            color: $white1 !important;
        }

        .active {
            color: $opus-purple !important;
        }
        /*
        @media (max-width: 583px) {
            .item {
                display: none !important;

                &:nth-child(-n + 2) {
                    display: block !important;
                }

                &:nth-last-child(-n + 2) {
                    display: block !important;
                }
            }
        }*/
        
    }
}
.leaflet-container {
  top: 10vh;
  left: 10%;
  width: 80%;
  height: 80vh;
  z-index: 1;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 15px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  z-index: 1000;
}


.extra > .leaflet-container {
  top: 0vh;
  left: 0%;
  width: 100%;
  height: 200px;
}


.ui.horizontal.list:not(.celled)>.item:first-child {
    margin-left: 28px!important;
}

.e-input-group.e-control-wrapper.e-ddl.e-valid-input {
  border: none;
}

.e-ddl.e-input-group.e-control-wrapper .e-input {
  /* border-style: none; */
  padding-right: 20px;
}


.e-editable-value {
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-inplaceeditor .e-editable-action-buttons {
  position: inherit !important;
}

#toolsRTE_image {
  z-index: 10001 !important;
}

.e-richtexteditor.e-rte-tb-expand,
.e-richtexteditor.e-rte-tb-expand .e-rte-content,
.e-richtexteditor .e-rte-content {
  border: none !important;
}

#g-recaptcha > div {
  width: 100% !important; 
}
#g-recaptcha > div > div > iframe {
  width: 100% !important; 
}

.rc-anchor-normal {
  height: 74px;
  width: 100% !important;
}
.ui.message {
  color: red;
  width: 100%;
  box-shadow: none;
  background: #ffffff;
  margin: 0px;
  padding: 0px 21px 5px 21px;
  font-weight: lighter;
}
#countdown {
  color: #16a5c0;
  padding-bottom: 10px;
  font-weight: lighter;
}


/*
.ui.form .field{
  width: 100%;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;  
  background: #fff !important;
}
.ui.form .field .ui.input input{
  width: 100%;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;  
  background: #fff !important;
  border: 1px solid rgba(34,36,38,.15)!important;
}
.ui.form .field.field input:-webkit-autofill {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;  
  background-color: #fff !important;
}
body {
  background-color: rgb(234, 234, 234) !important;
}
*/
.ui.fixed.menu {
    z-index: 0;
}

/*home page styles*/
.masthead {
  display: flex;
  align-items: center;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 2.3em;
  font-weight: normal;
  color: #2ca249;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}
/*end home page styles*/



/* end of Semantic UI form styles */

/* start of sidebar styles */
.e-sidebar{
    background-color: none;
}

.e-menu1:before{
     font-family: "e-icons"; 
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
    height: 48px;
    background-color: #ffffff;
    content:'\e99a';
}

 /* header-section styles */
 #header.header-section,
 #header .search-icon {
     height: 50px;
 }

 #header #hamburger.icon-menu {
     font-size: 24px;
     float: left;
     line-height: 50px;
 }

 #header .right-header {
     height: 35px;
     padding: 7px;
     float: right;
 }

 #header .list {
     list-style: none;
     cursor: pointer;
     font-size: 16px;
     line-height: 35px;
 }

 #header .header-list {
     padding-left: 15px;
     margin: 0;
 }

 /* text input styles */
 #header .search-icon {
     float: left;
     padding-left: 15px;
     border: 0px solid #33383e !important;
     background-color: #33383e;
     cursor: text;
     width: 5em;
 }

     #header .search-icon:focus {
         outline: none;
         cursor: default;
     }

 /* end of text input styles */
 /* end of header-section styles */

 /* content area styles */
 #maintext.main-content {
     height: 100vh;
     /* z-index: 1000; */
 }

 #maintext .sidebar-menu-content {
     margin-top: 230px;
     text-align: center;
     font-size: 32px;
     color: #1784c7;
 }

 /* end of content area styles */

/* start of datagrid custom styles */
.e-gridheader{
  padding-right: 0px !important;
}

.e-content{
  overflow-y: hidden !important;
}
/* end of datagrid custom styles */

 /* menu styles */
 /* horizontal-menu styles */
 #header .header-list .horizontal-menu .e-menu-item {
     height: 35px;
     vertical-align: middle;
     font-size: 16px;
     line-height: 35px;
 }

 #header .e-menu-item .e-caret {
     line-height: 35px;
 }


/* end of horizontal-menu styles */

  /* vertical-menu styles */

  #sidebar-menu .e-menu-wrapper ul .e-menu-item.e-menu-caret-icon {
    width: 150px;
}


/* end of vertical-menu styles */


 /* Sidebar styles */
 /* docksidebar styles */


     /* end of docksidebar styles */
     /*end of  Sidebar styles */





#sidebar-menu .icon-up-hand:before {
  content: '\e801';
}

#sidebar-menu .icon-bell-alt:before {
  content: '\e802';
}

#sidebar-menu .icon-user:before {
  content: '\e803';
}

#sidebar-menu .icon-picture:before {
  content: '\e804';
}

#sidebar-menu .icon-comment-alt:before {
  content: '\e805';
}

#sidebar-menu .icon-tag:before {
  content: '\e806';
}

#sidebar-menu .icon-comment-inv-alt2:before {
  content: '\e807';
}

#sidebar-menu .icon-eye:before {
  content: '\e808';
}

#sidebar-menu .icon-globe:before {
  content: '\e809';
}

#sidebar-menu .icon-bookmark-empty:before {
  content: '\e810';
}

#sidebar-menu .icon-bookmark:before {
  content: '\e811';
}

#header .icon-menu:before {
  content: '\e812';
}


#sidebar-menu .e-menu-icon::before {
  color: #656a70;
}

/*icon styles */

/* start of chat styling */
#List {
  margin: 0 auto;
  border: 1px solid #ccc;
}

#List .e-list-item {
  height: auto;
  cursor: pointer;
}

#List .e-list-header .e-text {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 26px;
}

#List #info,
#List .name {
  font-size: 11px;
  line-height: 20px;
}

#List .name {
  padding-top: 3px;
  font-weight: 500;
  padding-left:150px;
  margin-left: 55px;
}
#List #info {
  float: right;
  margin-right:10px;
}
.pic01 {
  background-image: url("https://ej2.syncfusion.com/demos/src/grid/images/1.png");
}

.pic02 {
  background-image: url("https://ej2.syncfusion.com/demos/src/grid/images/3.png");
}

.pic03 {
  background-image: url("https://ej2.syncfusion.com/demos/src/grid/images/5.png");
}

.pic04 {
  background-image: url("https://ej2.syncfusion.com/demos/src/grid/images/2.png");
}

.img2 {
  margin-left: 10px;
  margin-top: 7px;
  font-size: 13px;
}

#List #content1 {
  width: 200px;
  background-color: aliceblue;
  display: inline-block;
  margin: 5px;
  
}

#List #info1,
#List .name1 {
  font-size: 11px;
  line-height: 20px;
  margin-left: 10px;
}

#List .name1 {
  padding-top: 3px;
  font-weight: 500;
}
#List #content {
    margin: 5px;
    width: 250px;
    margin-left: 62px;
    background-color: aliceblue;
    display:inline-block
}
#image {
    float: right;
    /* display: inline-block; */

}
#image2 {
    float: left;
    /* display: inline-block; */

}
.img1 {
    margin-right: 10px;
    margin: 5px;
    font-size: 13px;
}
.e-listview .e-list-header{
  color: white;
}
.e-listview .e-list-header{
  background: rgb(3, 41, 71);
}
#List.e-listview .e-hover {
  background-color: transparent;
}
#inputname {
  width: 275px
}
.message {
  width: 350px;
  margin: 0 auto;
}
#btn {
  float:right
}
/* end of chat styling */

﻿/*//////////// OPUS2 COLOURS ////////////////*/
//PRIMARY
$opus-purple-tint:      #802480; /*20%*/
$opus-purple:           #572258; /*20%*/   
$opus-blue:             #0bb5c4; /*20%*/
//SECONDARY
$opus-orange:           #e2ab3b; /*10%*/
$opus-green:            #22D1AF; /*10%*/
$opus-grey-purple:      #c3b7c0; /* 5%*/
/*///////////////////////////////////////////*/

  
$primary:               $opus-purple-tint;
$primary-text:          #fff;
$primary-tint:          lighten($opus-purple-tint,10%);
$primary-tint-text:     #222;    
$primary-shade:         $opus-purple;
$primary-shade-text:    #fff;

$secondary:             $opus-blue;
$secondary-text:        #222;
$secondary-tint:        lighten($opus-blue,10%);
$secondary-tint-text:   #222;
$secondary-shade:       darken($opus-blue,15%);
$secondary-shade-text:  #fff;


$tertiary:              $opus-orange;
$tertiary-text:         #122635;
$tertiary-tint:         lighten($opus-orange,10%); 
$tertiary-tint-text:    #122635;      
$tertiary-shade:        darken($opus-orange,10%);   
$tertiary-shade-text:   #122635;  

$brand-highlight:       $opus-green;
$brand-neutral:         $opus-grey-purple; 

$nav-bg:                #F0F5F7;

/*
$tertiary: #38e8b9;
$tertiary-text: #222;
$tertiary-tint: #78fee9;
$tertiary-tint-text: #222;
$tertiary-shade: #22b589;
$tertiary-shade-text: #fff;
*/

$dark: #160f3e;
$dark2:#101a1d;


$grey-50: #fafafa;
$grey-100:      #f3f4f5;
$grey-200: #e8edf3;
$grey-300: #dee2e6; 
$grey-400: #ced4da;
$grey-500:      #89929b;
$grey-600:      #3d4c5c;
$grey-700: #495057;
$grey-800:      #122635;
$grey-900: #212529;
$grey-black: #000;
$grey-white: #fff;

$base-text-color: $grey-900;
$base-bg-color: #f0f5f7;


$blue:      #31B3FE;
$indigo:    #3147FE;
$purple:    #9c31fe;
$pink:      #fe31e0;
$red:       #f05151; 
$orange:    #FEAF31;
$yellow:    #fef531;
$green:     #7bfe31;
$teal:      #31fea8;
$cyan:      #31feee;

/*// NEW COLOURS END  //*/
$blue1:     #4285f4;
$blue2:     #2f6eea;
$blue3:     #295cc1;
$blue4:     #1947a2;

$aqua1: #41c7f0;
$aqua2: #1b89b7;
$aqua3: #167da8;
$aqua4: #0d709a;


$purple1: #9e50e7;
$purple2:   #862cda;
$purple3:   #6b23af;
$purple4:   #4c0d87;

$pink1:     #df3fe9;
$pink2:     #c22fcb;
$pink3:     #a219aa;
$pink4:     #7a0c80;

$green1:    #24b527;
$green2:    #0c970f;
$green3:    #0a800a;
$green4:    #065e06;
$darkGreen1: #044304;

$turquoise1: #28afa4;
$turquoise2: #20988e;
$turquoise3: #0c756c;
$turquoise4: #085952;

$red1:      #f64a52;
$red2:      #da2c34;
$red3:      #b1161e;
$red4:      #7e0b11;

$orange1:   #ef8c43;
$orange2:   #fd8228;
$orange3:   #cd6203;
$orange4:   #a75105;

$grey1:     #7b7d84;
$grey2:     #4c5765;
$grey3:     #3b4552;
$grey4:     #2d3540;

$light-grey1: #f0f0f0;
$light-grey2: #dfdfdf;
$light-grey3: #c1bebe;
$light-grey4: #afacac;

$headerTitle: #2d3540;

$white1:            #fff;
$white2:            #f0f0f0;
$white3:            #e0e0e0;
$white4:            #ccc;

$black1:            #000;
$black2:            #131619;
$black3:            #333;
$black4:            #999;

$bgBlack:           #131619;
$bgWhite:           #f0f0f0;
$bgDark:            $bgBlack;
$bgLight:           $bgWhite;

$bgWhite1:          #fff;

$iconColourLight:   #fff;

$textLight1:        #fff;
$textDark1:         #111;

$textLight3: $white3;
$textDark3: $black3;

$bgLight1:          #fff;
$bgDark1:           #000;

$textHoverLight:    #fff;
$textHoverDark:     #111;

$navBgLight:        #f9f9f9;
$navBgDark:         #000;

$iconLight: #fff;
$iconDark: $primary;

$switchLight: #979797;
$switchDark: #ccc;


$csdColour:         $purple1;
$csdColourHover:    $purple2;
$nholColour:        #926600;
$nholColourHover:   #725002;
$cvacColour:        $orange2;
$cvacColourHover:   $orange3;
$clsColour:         $red1;
$clsColourHover:    $red2;





//bg alphas
@mixin bgAlpha($color, $opacity: 0.5) {
    background: rgba($color, $opacity);
}
@mixin schemeColours($colorSetNumber, $opacity: 1, $setBorderColour: false, $setBackgroundColour: true, $setTextColour: false) {
    $color-blue: $blue1;
    $color-aqua: $aqua1;
    $color-pink: $pink1;
    $color-purple: $purple1;
    $color-green: $green1;
    $color-turquoise: $turquoise1;
    $color-red: $red1;
    $color-orange: $orange1;

    @if $colorSetNumber == 2 {
        $color-blue: $blue2;
        $color-aqua: $aqua2;
        $color-pink: $pink2;
        $color-purple: $purple2;
        $color-green: $green2;
        $color-turquoise: $turquoise2;
        $color-red: $red2;
        $color-orange: $orange2;
    }

    @if $colorSetNumber == 3 {
        $color-blue: $blue3;
        $color-aqua: $aqua3;
        $color-pink: $pink3;
        $color-purple: $purple3;
        $color-green: $green3;
        $color-turquoise: $turquoise3;
        $color-red: $red3;
        $color-orange: $orange3;
    }

    @if $colorSetNumber == 4 {
        $color-blue: $blue4;
        $color-aqua: $aqua4;
        $color-pink: $pink4;
        $color-purple: $purple4;
        $color-green: $green4;
        $color-turquoise: $turquoise4;
        $color-red: $red4;
        $color-orange: $orange4;
    }

    .blueSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-blue, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-blue;
        }

        @if $setTextColour == true {
            color: $color-blue;
        }
    }

    .aquaSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-aqua, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-aqua;
        }

        @if $setTextColour == true {
            color: $color-aqua;
        }
    }

    .pinkSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-pink, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-pink;
        }

        @if $setTextColour == true {
            color: $color-pink;
        }
    }

    .purpleSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-purple, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-purple;
        }

        @if $setTextColour == true {
            color: $color-purple;
        }
    }

    .greenSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-green, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-green;
        }

        @if $setTextColour == true {
            color: $color-green;
        }
    }

    .turquoiseSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-turquoise, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-turquoise;
        }

        @if $setTextColour == true {
            color: $color-turquoise;
        }
    }

    .redSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-red, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-red;
        }

        @if $setTextColour == true {
            color: $color-red;
        }
    }

    .orangeSkin & {
        @if $setBackgroundColour == true {
            background-color: rgba($color-orange, $opacity);
        }

        @if $setBorderColour == true {
            border-color: $color-orange;
        }

        @if $setTextColour == true {
            color: $color-orange;
        }
    }
}
@mixin schemeColoursAttribute($attribute, $colorSetNumber, $opacity: 1) {
    $color-blue: $blue1;
    $color-aqua: $aqua1;
    $color-pink: $pink1;
    $color-purple: $purple1;
    $color-green: $green1;
    $color-turquoise: $turquoise1;
    $color-red: $red1;
    $color-orange: $orange1;

    @if $colorSetNumber == 2 {
        $color-blue: $blue2;
        $color-aqua: $aqua2;
        $color-pink: $pink2;
        $color-purple: $purple2;
        $color-green: $green2;
        $color-turquoise: $turquoise2;
        $color-red: $red2;
        $color-orange: $orange2;
    }

    @if $colorSetNumber == 3 {
        $color-blue: $blue3;
        $color-aqua: $aqua3;
        $color-pink: $pink3;
        $color-purple: $purple3;
        $color-green: $green3;
        $color-turquoise: $turquoise3;
        $color-red: $red3;
        $color-orange: $orange3;
    }

    @if $colorSetNumber == 4 {
        $color-blue: $blue4;
        $color-aqua: $aqua4;
        $color-pink: $pink4;
        $color-purple: $purple4;
        $color-green: $green4;
        $color-turquoise: $turquoise4;
        $color-red: $red4;
        $color-orange: $orange4;
    }

    .blueSkin & {
        #{$attribute}: rgba($color-blue, $opacity);
    }

    .aquaSkin & {
        #{$attribute}: rgba($color-aqua, $opacity);
    }

    .pinkSkin & {
        #{$attribute}: rgba($color-pink, $opacity);
    }

    .purpleSkin & {
        #{$attribute}: rgba($color-purple, $opacity);
    }

    .greenSkin & {
        #{$attribute}: rgba($color-green, $opacity);
    }

    .turquoiseSkin & {
        #{$attribute}: rgba($color-turquoise, $opacity);
    }

    .redSkin & {
        #{$attribute}: rgba($color-red, $opacity);
    }

    .orangeSkin & {
        #{$attribute}: rgba($color-orange, $opacity);
    }
}

$skin-Colours: blueSkin aquaSkin pinkSkin purpleSkin greenSkin turquoiseSkin redSkin orangeSkin;
$colors-list3: $blue3 $aqua3 $pink3 $purple3 $green3 $turquoise3 $red3 $orange3;


@mixin selectOptionSchemeColours() {
    @each $current-skin in $skin-Colours {

        $i: index($skin-Colours, $current-skin);

        .#{$current-skin} & {
            outline-color: nth($colors-list3, $i);
            box-shadow: 0 0 5px 5px nth($colors-list3, $i) inset;
        }
    }
}


@mixin borderAlpha($color, $opacity: 0.5) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}
@mixin alphaAttribute($attribute, $color, $opacity) {
    #{$attribute}: $color;
    #{$attribute}: rgba($color, $opacity);
}
@mixin themeColours($attribute, $colorSetNumber, $opacity: 1) {
    //text1
    $light: $textLight1;
    $dark: $textDark1;

    $light: $textLight1;
    $dark: $textDark1;

    @if $colorSetNumber == text3 {
        $light: $white3;
        $dark: $black3;
    }

    @if $colorSetNumber == hover1 {
        $light: $textHoverLight;
        $dark: $textHoverDark;
    }

    @if $colorSetNumber == bg1 {
        $light: $bgLight1;
        $dark: $bgDark1;
    }

    @if $colorSetNumber == body1 {
        $light: $bgBlack;
        $dark: $bgWhite;
    }

    @if $colorSetNumber == body2 {
        $light: $black2;
        $dark: $white2;
    }

    @if $colorSetNumber == body3 {
        $light: $black3;
        $dark: $white3;
    }

    @if $colorSetNumber == icon1 {
        $light: $iconLight;
        $dark: $iconDark;

    }

    @if $colorSetNumber == navbg {
        $light: $navBgDark;
        $dark: $navBgLight;
    }

    @if $colorSetNumber == switch {
        $light: $switchDark;
        $dark: $switchLight;
    }


    .dark-theme & {
        #{$attribute}: rgba($light, $opacity);
    }

    .light-theme & {
        #{$attribute}: rgba($dark, $opacity);
    }
}
@mixin themeSelectIcon($topPos: 1.6em) {
    //force sass to render rgba opacity 1
    $fillLight: rgba($textLight1,0.9999999);
    $fillDark: rgba($textDark1,0.9999999);

    .dark-theme & {
        background: none;
        background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"%3E%3Cpath fill="#{$fillLight}" opacity="0.75" d="M2 0L0 2h4zm0 5L0 3h4z"/%3E%3C/svg%3E') no-repeat right 1em top $topPos;
        background-size: 16px 16px;
    }

    .light-theme & {
        background: none;
        background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"%3E%3Cpath fill="#{$fillDark}" opacity="0.75" d="M2 0L0 2h4zm0 5L0 3h4z"/%3E%3C/svg%3E') no-repeat right 1em top $topPos;
        background-size: 16px 16px;

        &:focus,
        &:hover {
            background: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"%3E%3Cpath fill="#{$fillLight}" opacity="0.75" d="M2 0L0 2h4zm0 5L0 3h4z"/%3E%3C/svg%3E') no-repeat right 1em top $topPos;
            background-size: 16px 16px;
        }
    }
}
@mixin inputLockIcon($topPos: 1.6em) {
    //force sass to render rgba opacity 1
    $fillLight: rgba($textLight1,0.9999999);
    $fillDark: rgba($textDark1,0.9999999);

    .dark-theme & {
        background: none;
        background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024' %3E%3Cpath fill='#{$fillLight}' opacity='0.3' d='M832 464h-46v-154c0-76-30-146-80-198-48-50-118-84-194-84s-146 34-194 84c-50 52-80 122-80 198v154h-46c-20 0-36 16-36 34v406c0 20 16 28 36 34 20 8 156 58 320 58s302-50 320-58c20-8 36-14 36-34v-406c0-18-16-34-36-34zM368 464v-154c0-44 18-84 44-110 26-28 62-44 100-44s74 16 100 44c26 28 44 66 44 110v154z' %3E%3C/path %3E%3C/svg %3E") no-repeat right 1em top $topPos;
        background-size: 20px 20px;
    }

    .light-theme & {
        background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024' %3E%3Cpath fill='#{$fillDark}' opacity='0.3' d='M832 464h-46v-154c0-76-30-146-80-198-48-50-118-84-194-84s-146 34-194 84c-50 52-80 122-80 198v154h-46c-20 0-36 16-36 34v406c0 20 16 28 36 34 20 8 156 58 320 58s302-50 320-58c20-8 36-14 36-34v-406c0-18-16-34-36-34zM368 464v-154c0-44 18-84 44-110 26-28 62-44 100-44s74 16 100 44c26 28 44 66 44 110v154z' %3E%3C/path %3E%3C/svg %3E") no-repeat right 1em top $topPos;
        background-size: 20px 20px;
    }
}

@mixin setBorder( $color, $opacity: 1, $attribute: border, $borderWidth: 1px, $borderStyle: solid) {
    #{$attribute}: $borderWidth $borderStyle rgba($color, $opacity);
}


/*grad mixins*/
@mixin bgColorGrad( $startColor: $blue2, $endColor: #5a2ed8, $angle: 45deg) {
    background: $startColor; /* The Fallback */
    background: linear-gradient($angle, $startColor 0%,$endColor 100%);
}
@mixin bgColorGrad3Colours( $startColor: $blue2,$midColor: $blue2, $endColor: #5a2ed8, $angle: 45deg) {
    background: $startColor; /* The Fallback */
    background: linear-gradient($angle, $startColor 0%, $midColor 50%, $endColor 100%);
}
@mixin bgColorBlueGrad($angle: 45deg) {
    background: $blue2; /* The Fallback */
    background: linear-gradient($angle, #33afe2 0%, $blue2 100%);
}
@mixin bgColorAquaGrad($angle: 45deg) {
    background: $aqua2; /* The Fallback */
    background: linear-gradient($angle, #2f9dea 0%, $aqua2 100%);
}
@mixin bgColorPurpleGrad($angle: 45deg) {
    background: $purple2;  /* The Fallback */
    background: linear-gradient($angle, #4c2ac9 0%,$purple2 100%);
}
@mixin bgColorPinkGrad($angle: 45deg) {
    background: $pink2; /* The Fallback */
    background: linear-gradient($angle, #d33255 0%, $pink2 100%); 
}
@mixin bgColorGreenGrad($angle: 45deg) {
    background: $green2;
    background: linear-gradient($angle, #1f8db3 0%, $green2 100%);
}
@mixin bgColorTurquioseGrad($angle: 45deg) {
    background: $turquoise2; /* The Fallback */
    background: linear-gradient($angle, #419b22 0%, $turquoise2 100%);
}
@mixin bgColorRedGrad($angle: 45deg) {
    background: $red2; /* The Fallback */
    background: linear-gradient($angle, #f98d27 1%,$red2 100%); 
}
@mixin bgColorOrangeGrad($angle: 45deg) {
    background: $orange2; /* The Fallback */
    background: linear-gradient($angle, #dc3034 0%,$orange2 100%); 
}

/*strips*/
@mixin bgStripe($colour1: $blue2,$colour2: $blue3, $angle: 135deg) {
    background: $colour1; /*fallback*/
    background-image: linear-gradient($angle, $colour1 25%, $colour2 25%, $colour2 50%, $colour1 50%, $colour1 75%, $colour2 75%, $colour2 100%);
    background-size: 22.63px 22.63px;
}
@mixin menuBg() {
    //blue
    //@include bgColorGrad3Colours( #181621,#2c2c46, #4a5a6a, 135deg);
    //aqua
    //@include bgColorGrad3Colours( #181621,#2c2c46, #4a5a6a, 135deg);
    background-color:#27273b;
}
@mixin menuBgLevel2() {
     //blue
     @include bgColorGrad3Colours( $blue2,$blue4, #344154, 100deg);
     //aqua
     //@include bgColorGrad3Colours( $aqua2,$aqua4, #344154, 100deg);
}



﻿.e-spinner-pane .e-spinner-inner .e-spin-material {
    stroke: $primary;
}
.e-spinner-pane {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: all 0.35s ease;

    &.hide {
        transform: scale3d(0.8, 0.8, 1);
        opacity: 0;
        pointer-events: none;
    }

    &.medium {
        width: 2.5rem;
        height: 2.5rem;
    }

    &.inline {
        position: static;
        display: inline;
    }

    &.right {
        float: right;
        display: inline;
    }
}
/*
#spinner{
    position: absolute;
    top:0;
    left:0;
    border:5px solid #f0f;
    width:200px;
    height: 200px;
    display: block;
}*/

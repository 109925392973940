.label{
    &.primary{
        background-color: $primary;
        color: $primary-text;
    }
    &.secondary{
        background-color: $secondary;
        color: $secondary-text;
    }
    &.tertiary{
        background-color: $tertiary;
        color: $tertiary-text;
    }
    &.highlight{
        background-color: $brand-highlight;
        color: $white1;
    }
    &.neutral{
        background-color: brand-neutral;
        color: $grey-900;
    }
}
.b-list-people{
    margin:-0.25rem 0 -0.5rem 0;
    &__title{
        display:block;
        padding:3rem 0 1.5rem 0;
        &.center{
            text-align: center;
        }
    }
    &__list{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-bottom:1rem;
        &.center{
            align-items: center;
            justify-content: center;
        }
        
        .b-list-people__item {
            border:0;
            flex-grow: 0;
            flex-shrink: 0; 
            width:auto;
            margin:0 0rem;
            text-align: left;
            &.center{
                text-align: center;
            }
        }
        &.row{
            flex-direction: row;
        }
    }
    &__item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:0;
        margin:0;
        padding: 0.25rem 0 0.25rem 0;
        border-bottom:1px solid $grey-200;
        width: 100%;
        
        &:last-child{
            border:0;
        }
        .b-list-people__list.row &{
            margin-right:0.75rem;
        }
        &.center{
            text-align: center;
        }
    }
    &__link{
        color:$secondary-shade;
        font-size:0.875rem;
        flex:1 1 auto;
        transition: text-decoration 0.5s ease;
        text-decoration-color: rgba($secondary, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        
        .b-list-people__list.center &{
            text-align: center;
        }
        strong{
            display:block;
            font-size:1rem;
            color:$primary;
        }
        &:hover{
            color:darken($secondary,15%);
            text-decoration: underline;
            strong{
                color:darken($primary,15%);
            }
            .b-list-people__image{
                border-color: rgba($secondary, 0.6);
                .e-avatar{
                    transform: scale3d(1.2, 1.2, 1);
                }
            }

        }
        

    }
    &__icons{
        padding-left:0.75rem;
        width: 94px;
        flex: 0 0 94px; 
        text-align: right;
        button{
            margin:0.25rem 0 0 0.25rem!important;
        }
    }
    &__name{
        display:block;
        color:$primary;
        font-weight: $bold;
    }
    
    &__job{
        display:block;
        color:$secondary;
    }
    &__image{
        display: block;
        width:2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        transition: all ease 0.35s;
        margin:0 1rem 0 0;
        overflow: hidden;
        .b-list-people__list.row &{
            margin:0;
        }
        &.large{
            width:8rem;
            height: 8rem;
            border-radius: 8rem;
            .e-avatar{
                width:8rem;
                height: 8rem;
                border-radius: 8rem;
                background-size:cover;
                margin: -0.1875rem;
                transition: all 0.35s ease;
            }
        }
        &.medium{
            width:6rem;
            height: 6rem;
            border-radius: 6rem;
            .e-avatar{
                width:6rem;
                height:6rem;
                border-radius: 6rem;
                background-size:cover;
                margin: -0.1875rem;
                transition: all 0.35s ease;
            }
        }
        &.small{
            width:4rem;
            height: 4rem;
            border-radius: 4rem;
            .e-avatar{
                width:4rem;
                height:4rem;
                border-radius: 4rem;
                background-size:cover;
                margin: -0.1875rem;
                transition: all 0.35s ease;
            }
        }
        &.tiny{
            width:5rem;
            height: 5rem;
            border-radius: 5rem;
            .e-avatar{
                width:5rem;
                height:5rem;
                border-radius: 5rem;
                background-size:cover;
                margin: -0.1875rem;
                transition: all 0.35s ease;
            }
        }
        &.border{
            border:3px solid rgba($secondary, 0.3);
        }
        & + .b-list-people__info{
            margin-top:0.75rem;
        }
    }
    &__info{
        display:block;
    }

}
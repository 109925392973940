﻿.e-splitter {
    &.e-splitter-horizontal,
    &.e-splitter-vertical {
        border: 0;
    }

    &.e-splitter--fullscreen {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;

        .withMasthead & {
            top: 54px;
        }
        .e-pane{
            position:relative;
        }
    }
}

.e-toast{
    &-container &{
        transition: background-color 0.35s ease;
        background-color: $grey-600;
        cursor: default;
        &:hover{
            background-color: darken($grey-600,10%);
        }

        &.e-toast-danger {
            background-color: $red;
            &:hover{
                background-color: darken($red,10%);
            }
        }
        &.e-toast-warning {
            background-color: darken($secondary,10%);
            &:hover{
                background-color: darken($secondary,20%);
            }
        }
        &.e-toast-info {
            background-color: darken($primary,20%); 
            &:hover{
                background-color: darken($primary,30%); 
            }
        }
        &.e-toast-success {
            background-color: darken($tertiary,20%);
            &:hover{
                background-color: darken($tertiary,30%); 
            }
        }
    }
}
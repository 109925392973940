﻿.footer {
    font-family: $sans-serif;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(darken($primary-shade,10%), 0.75);
    backdrop-filter: blur(4px);
    height: 1.625rem;
    line-height: 1.625rem;
    display: flex;
    z-index: 204;
    box-sizing: content-box;
    animation: loadFooter 0.5s ease 0.5s 1 alternate backwards;
    @include mq('tablet', max) {
        display: none;
    }

    &__col {
        flex: 1 1 auto;
        padding: 0 0.75rem;
        line-height: 1.625rem;
    }

    &__link {
        color: $white1;
        font-size: 0.75rem;
        padding: 0 0.5rem;
        text-decoration: none;
        position: relative;
        cursor: pointer;
        display: inline;
        float: left;
        transition: background 0.35s ease;
        margin: 0;
        border-radius: 0.25rem;
        background: transparent;

        .footer__col.right & {
            float: right;
        }

        &:hover {
            background: lighten($opus-purple, 10%);
            color:$white1;
        }
    }

    &__separator {
        color: rgba($white1, 0.2);
        display: inline;
        float: left;
        margin: 0 0.125rem;

        .footer__col.right & {
            float: right;
        }
    }

    &__menu {
        opacity: 0;
        position: absolute;
        bottom: 1.5rem;
        border-radius: 0.25rem;
        background: lighten($opus-purple, 15%);
        box-shadow: 0 4px 10px rgba(0,0,0, 0.3);
        font-size: 0.75rem;
        overflow: hidden;
        margin: 0 0 0 -0.625rem;
        white-space: nowrap;
        z-index: 5000;
        pointer-events: none;
        transform: scale3d(0.9, 0.9, 1);
        transition: opacity 0.35s, transform 0.35s;
        text-align: left;
        cursor: pointer;

        .footer__link:hover & {
            pointer-events: auto;
            transform: scale3d(1, 1, 1);
            opacity: 1;
        }
        
        .footer__col.right & {
            right: 0;
        }

        &-link {
            display: block;
            text-decoration: none;
            color: $white1;
            padding: 0.25rem 0.875rem 0.25rem 0.5rem;
            cursor: pointer;
            transform: background 0.35s ease;


            &:hover {
                background:  lighten($opus-purple, 20%);
                color:$white1;
            }

            .icon {
                margin-right: 0.25rem;
            }

            &--disabled {
                display: block;
                color: $white1;
                cursor: default;
                padding: 0.25rem 0.875rem 0.25rem 0.5rem;

                .icon {
                    margin-right: 0.25rem;
                }
            }
        }
    }
}
@keyframes loadFooter {
    from {
        opacity: 0;
        transform: translate3d(0,2rem,1);
    }
    to { 
        opacity: 1;
        transform: translate3d(0,0,1);
    }
}

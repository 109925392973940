﻿.e-layout-content {
    max-width: 100%;
    transition: all 1.35s ease;
    @include hideScrollUntilHover();

    &.hide {
        max-width: 0;
    }
}
.e-layout {
    .e-filemanager & {
        position: relative;
        height: 100%;
    }
}
.e-pane,
.e-control {
    opacity: 1;
    transition: opacity 0.45s ease, transform 0.45s ease;
    transform: scale3d(1, 1, 1);

    &.hide {
        overflow: hidden;
        opacity: 0;
        pointer-events: none;

        &--zoom-in {
            overflow: hidden;
            opacity: 0;
            transform: scale3d(0.9, 0.9, 1);
            pointer-events: none;
        }

        &--zoom-out {
            overflow: hidden;
            opacity: 0;
            transform: scale3d(1.1, 1.1, 1);
            pointer-events: none;
        }
    }
}
.e-pane{
    @include hideScrollUntilHover(); 
}



.e-flex-stretch {
    align-items: stretch;
    display: flex;
}
.e-flex-reverse {
    display: flex;
    flex-direction: row-reverse;
}

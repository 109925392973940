$shadow-color: #051d36;
.ui.b-shadow{

    &--none{
        box-shadow: none;
    }
    &--sm{
        box-shadow:
            0 0.3px 0.3px rgba($shadow-color, 0.02),
            0 0.7px 0.8px rgba($shadow-color, 0.028),
            0 1.3px 1.5px rgba($shadow-color, 0.035),
            0 2.2px 2.7px rgba($shadow-color, 0.042),
            0 4.2px 5px rgba($shadow-color, 0.05),
            0 10px 12px rgba($shadow-color, 0.07);
    }
    &{
        box-shadow:
            0 0.8px 0.8px rgba($shadow-color, 0.02),
            0 2px 2px rgba($shadow-color, 0.028),
            0 3.8px 3.8px rgba($shadow-color, 0.035),
            0 6.7px 6.7px rgba($shadow-color, 0.042),
            0 12.5px 12.5px rgba($shadow-color, 0.05),
            0 30px 30px rgba($shadow-color, 0.07);
    }
    &--lg{
        box-shadow: 
            0 2.8px 2.2px rgba($shadow-color, 0.02), 
            0 6.7px 5.3px rgba($shadow-color, 0.028), 
            0 12.5px 10px rgba($shadow-color, 0.035), 
            0 22.3px 17.9px rgba($shadow-color, 0.042), 
            0 41.8px 33.4px rgba($shadow-color, 0.05), 
            0 100px 80px rgba($shadow-color, 0.07);
    }
    &--flat{
        //no offset
        box-shadow:
            0px 0px 2.2px rgba($shadow-color, 0.02),
            0px 0px 5.3px rgba($shadow-color, 0.028),
            0px 0px 10px rgba($shadow-color, 0.035),
            0px 0px 17.9px rgba($shadow-color, 0.042),
            0px 0px 33.4px rgba($shadow-color, 0.05),
            0px 0px 80px rgba($shadow-color, 0.07);
    }
}
.b-award{
    &__wrap{
        display:block;
        overflow:hidden;
        text-align: center;
        .slick-slider + &{
            margin-top:2rem;
        }
    }
    &{
        display:inline-block;
        margin:0 0.5rem 1rem 0.5rem;
        height: 7.875rem;
        width: auto;
        &__wrap.small &{
            height: 6rem;
        }
        &__wrap.tiny &{
            height: 4.5rem;
        }
        svg{
            width: 100%!important;
            height: 100%!important;
        }
        img{
            width: auto!important;
            height: 100%!important;
        }
    }  

}
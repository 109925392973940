
::-ms-clear {
    display: none;
}
@mixin float-label-input {
    &::placeholder {
        opacity: 1;
        transition: all .35s;
        color: rgba($black1,0.3);
    }
    /*ie*/
    &:-ms-input-placeholder {
        opacity: 0;
        transition: none;
    }
    /*edge*/
    &::-ms-input-placeholder {
        opacity: 1;
        transition: all .2s;
        color: rgba($black1,0.3);
    }

    &:placeholder-shown:not(:focus)::placeholder {
        opacity: 0;
    }
    /*ie*/
    &:not(:focus):-ms-input-placeholder {
        opacity: 0;
    }
    /*edge*/
    &:not(:focus)::-ms-input-placeholder {
        //opacity: 1;
        color: rgba($black1,0.3);
    }

    &::placeholder-shown:focus {
        opacity: 1;
    }
}
@mixin float-label-scaled {


    &:placeholder-shown:not(:focus) + * {
        font-size: 1rem;
        opacity: 0.6;
        @content;
    }
    //input:not(:placeholder-shown),
    /*ie*/
    &:not(:focus):-ms-input-placeholder + * {
        font-size: 18px;
        opacity: 0.6;
        @content;
    }
    /*edge*/
    /*note double :: for edge - will not work together with one*/
    /* not supported in edge :( */
    &:not(:focus)::-ms-input-placeholder + label {
        font-size: 1rem;
        opacity: 1;
        @content;
    }
    &:focus + * {
        color: rgba($black1,0.6);
    }
    
}
.b-float-input{
    &{
        margin:0;
        padding:0;
        border:0;
        position: relative;
    }
    .ui.form & input:not([type=checkbox]):not([type=radio]){
        background:$grey-300!important;
        border:none!important;
        border-radius: 0.25rem!important;
        padding:1.5rem 0.25rem 0.4375rem 0.5rem!important;
        font-weight: $bold!important;
        font-size:1rem!important;
        font-family: $sans-serif;

        &:focus{
            background: $brand-highlight!important;
        }
        @include float-label-input;
        @include float-label-scaled {
            top: 1rem;
        }
    }
    .ui.selection.dropdown{
        min-width:4rem;
        border:0;
        background:$grey-300;
        min-height:50px;
        padding:1.625rem 0.5rem 0.5rem 0.5rem;
        &.active{
            background: $brand-highlight;
            & + label{
                z-index: 11;
            }
        }
        & >.text:not(.default){
            font-weight: $bold;
        }
        &:not(.button)>.default.text{
            color: rgba($black1,0.3);
            font-weight: $bold;
        }
        .dropdown.icon{
            margin-top: -0.25rem!important;
        }
        .menu .selected.item, 
        .selected{
            background: rgba($brand-highlight, 0.4);
        }
    }
    .ui.form & label{
        margin-left:0.5rem;
        font: $semibold 0.75rem $sans-serif;
        color:$grey-800;
        transform:none;
        top: 0.5rem;
        position: absolute;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        transition: all 0.35s ease;
        pointer-events: none;
        
    }
    @media only screen and (max-width: 767px){
        .ui.form .fields>&{
            margin-bottom:0.75rem;
        }
    }
    
}




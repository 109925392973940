i.icon.b-icons{
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'b-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .feather-alt:before {
    content: "\e900"; 
  }

  .b-icons {
    &-margin {
      &__none {
        margin: 0 !important;
      }
    }
  }
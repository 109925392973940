.b-list{

    &__wrap{
        overflow:hidden;
        &.secondary,
        &.ui.secondary.segment{
            background: $secondary;
        }
        &.ui.primary.segment{
            background: $primary;
        }
    }
    &.links{
        margin: -0.75rem;
        display: flex;
        flex-direction: column;
        padding:0.5rem 0;
        font-weight: bold;;
        .spacing-x15 &{
            margin: -1.5rem  -1rem  -1.5rem  -1rem;
        }
    }
    &.links &__item{
        display:block;
        padding:0;
        margin:0;
    }
    
    &.links &__link{
        padding:0.5rem 0.75rem 0.5rem 1rem ;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color:$white1;
        overflow:hidden;
        border-radius: 0.375rem;
        font-family:Arial, Helvetica, sans-serif
        
        .spacing-x15 &{
            padding:0.75rem 1rem;
        }
        &:hover{
            background: darken($primary,10%);
        } 
    }
    &__text.no-wrap{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    &.links i.icon{
        margin-right:1rem;
        &.corner.right{
            margin:0;
            right: 0.75rem;
            bottom: 0.125rem;
            text-shadow: 
                -1px -1px 0 $primary, 
                1px -1px 0 $primary, 
                -1px 1px 0 $primary, 
                1px 1px 0 $primary;
        }
    }


}
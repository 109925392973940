.ui.button{
    transition:background-color 0.35s ease, border-color 0.35s ease, max-height 0.35s ease, marigin 0.35s ease,padding 0.35s ease;
    overflow:hidden;
    max-height:100px;
    min-height: unset ;
    font-family: $sans-serif;
    padding-top:0.75em;
    padding-bottom:0.75em;
    &.basic,
    &.inverted{
        transition:box-shadow 0.35s ease, color 0.35s ease, background-color 0.35s ease,;
    }
    &.dashed{
        border:2px dashed $grey-400;
        background:none;
        transition:all 0.35s ease;
        color: $grey-500;
        &:hover,
        &:active,
        &:focus{
            color: $grey-600;
            background-color: $grey-200
        }
    }
    &.h-medium{
        height:6rem; 
        max-height: unset;
    }
    &.h-large{
        height:10rem; 
        max-height: unset;
    }
    &.h-huge{
        height:14rem; 
        max-height: unset;
    }
    &.primary{
        &:not(.basic):not(.inverted){
            background-color: lighten($primary,5%);
            &:hover,
            &:active,
            &:focus{
                background-color: $primary; 
            }
        }
        &.basic{
            box-shadow: 0 0 0 1px $primary inset!important;
            color:$primary-shade;
            &:hover,
            &:active,
            &:focus{
                color:$primary-shade;
                box-shadow: 0 0 0 1px $primary inset!important;
            }
        }
        &.inverted{
            background:none;
            color:$primary-shade!important;
            box-shadow: 0 0 0 1px $primary inset!important;
            
            &:hover,
            &:active,
            &:focus{
                color:$white1!important;
                background-color: $primary;
            }
        }
    }
    &.secondary{
        &:not(.basic):not(.inverted){
            background-color: $secondary;
            color:$white1;
            &:hover,
            &:active,
            &:focus{ 
                background-color: darken($secondary, 5%);
                color:$white1;
            }
        }
        &.basic{
            box-shadow: 0 0 0 1px darken($secondary, 5%) inset!important;
            &:hover,
            &:active,
            &:focus{
                color:#3c3e4e!important;
                box-shadow: 0 0 0 1px darken($secondary, 10%) inset!important;
            }
        }
        &.inverted{
            background:none;
            color:darken($secondary, 0%)!important;
            box-shadow: 0 0 0 1px $secondary inset!important;
            
            &:hover,
            &:active,
            &:focus{
                color:$white1!important;
                background-color: darken($secondary, 5%);
            }
        }
    }
    &.tertiary{
        &:not(.basic):not(.inverted){
            background-color: darken($tertiary, 10%);
            color:$white1;
            &:hover,
            &:active,
            &:focus{
                background-color: darken($tertiary, 15%);
                color:$white1;
            }
        }
        &.basic{
          box-shadow: 0 0 0 1px darken($tertiary, 10%) inset!important;
        }
        &.inverted{
            background:none;
            color:darken($tertiary, 20%)!important;
            box-shadow: 0 0 0 1px darken($tertiary, 20%) inset!important;
            
            &:hover,
            &:active,
            &:focus{
                color:$white1!important;
                background-color: darken($tertiary, 20%);
            }
        }
    }
    &.opus{
        &:not(.basic):not(.inverted){
            background-color: lighten($opus-purple, 15%);
            color:$white1;
            &:hover,
            &:active,
            &:focus{
                background-color: lighten($opus-purple, 20%);
                color:$white1;
            }
        }
        &.basic{
          box-shadow: 0 0 0 1px lighten($opus-purple, 15%) inset!important;
        }
        &.inverted{
            background:none;
            color:lighten($opus-purple, 20%)!important;
            box-shadow: 0 0 0 1px lighten($opus-purple, 20%) inset!important;
            
            &:hover,
            &:active,
            &:focus{
                color:$white1!important;
                background-color: lighten($opus-purple, 20%);
            }
        }
    }
    &__text{
        overflow:hidden;
        display: inline-block;
        overflow: hidden;
        transition: width 0.35s ease;
        &.hidden{
            max-width:0;
            display: none;
        }
    }
    &.hidden{ 
        max-height:0; 
        margin:0!important; 
        padding-top:0;
        padding-bottom:0; 
    } 
    &s.small &, 
    &s.small .or {
         font-size: 0.75rem;
    }
    &.float{
        &--right{
            display:inline;
            float: right;
        }
        &--left{
            display:inline;
            float: left;
        }
    }
}
i.icon{
    &.primary{
        color:darken($primary,5%);
        &-tint{
            color:$primary-tint;
        } 
        &-shade{
            color:$primary-shade;
        }
        &.circular{
            box-shadow:0 0 0 .1em $secondary inset;
        } 
    }
    &.secondary{
        color:$secondary;
        &-tint{
            color:$secondary-tint;
        }
        &-shade{
            color:$secondary-shade;
        }
        &.circular{
            box-shadow:0 0 0 .1em $primary inset;
        } 
    }
    &.tertiary{
        color:$tertiary;
        &-tint{
            color:$tertiary-tint;
        }
        &-shade{
            color:$tertiary-shade;
        }
        &.circular{
            box-shadow:0 0 0 .1em rgba($tertiary,.5) inset;
        } 
    }
}
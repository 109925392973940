$toggle-width: 3rem;
$toggle-height: 1.75rem;
$toggle-gutter: 0.25rem;
$toggle-radius: 50%;

// These are our computed variables
// change at your own risk.
$toggle-radius: calc($toggle-height / 2);
$toggle-control-size: $toggle-height - ($toggle-gutter * 2);

.b-toggle{
    &{
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 22px;
        user-select: none;
    }

    input[type=checkbox] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

    }
    .ui.form .b-float-input & label{
        cursor: pointer;
        display: block; 
        background:$grey-300;
        color:rgba($grey-800, 0.6);
        font-size:1rem;
        border-radius: 0.25rem;
        padding:1rem 0.25rem 0.4375rem 0.5rem;
        transition: all 0.5s ease;
        height: 3.125rem;
        width:auto;
        position: static;
        margin:0;
        pointer-events: unset!important;
    }
    .ui.form .b-float-input & input:checked ~ label{
        background: rgba(darken($brand-highlight,10%), 0.4);
        color:$grey-900;
        font-weight:$bold;
    }
    .ui.form .b-float-input & input:focus ~ label{
        background:rgba($brand-highlight, 0.7)!important;
    }
    input:checked ~ &__control {
        background-color: darken($brand-highlight, 15%);  
        &:after {
            left: $toggle-width - $toggle-control-size - $toggle-gutter;
        }
    }
    &__control {
        position: absolute;
        top: 0.6375rem;
        right: 0.5rem;
        height: $toggle-height;
        width: $toggle-width;
        border-radius: $toggle-radius;
        background-color: rgba(darken($red1,25%),0.45);
        transition: background-color 0.25s ease;
        pointer-events: none;

        &:after {
            content: "";
            position: absolute;
            left: $toggle-gutter;
            top: $toggle-gutter;
            width: $toggle-control-size;
            height: $toggle-control-size;
            border-radius: $toggle-radius;
            background: $white1; 
            transition: left 0.25s ease;
        }
    }
}
﻿.login {
    &__container{
        height: 100vh;
        width: 100vw;
        //background:$primary-shade;
    }
    &__wrap {
        position: relative;
        text-align: center;
        z-index: 200;
        pointer-events: none;
        opacity: 0;
        transition: all 1s ease;
        height: 100vh;
        overflow-y:auto;

        &--open {
            pointer-events: auto;
            opacity: 1;
        }

        .footer {
            background: rgba($grey-200, 0.8);
            border-top: 1px solid rgba($grey-300, 0.45);
        }
    }
    &__bg{
        
        display: block;
        position: fixed;
        width:100%;
        height:100%;
        //purple
        background:$primary-shade;
        /*
        background: linear-gradient(180deg, $primary-shade 50%, $primary 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#572258",endColorstr="#802480",GradientType=1);
        */
        overflow: hidden;
        text-align: center;
        &-sec1,
        &-sec2{
            position: absolute;
            width:160vh;
            min-width: 500px;
            min-height: 500px;
            height: 160vh;
            top:-60vh;
            transform: rotate(190deg);
            left:45%;
            max-height: 1600px;
            max-width: 1600px;
            margin:0 0 0 12%;
            background-repeat: no-repeat;
            background-position:center center;
            background-size:contain;
            transition: all 0.5s ease;
            animation: rotate1 1s ease 1.2s 1 alternate backwards;
            background-image: url('../../../public/assets/icons/halo.svg');

            
        }
        &-sec2{
            top:auto;
            bottom:-100vh;
            left:35%;
            transform: rotate(0deg);
            animation: rotate2 1s ease 0.95s 1 alternate backwards;
        }
        @include mq('tablet-wide', max) {
            &-sec1{
                left:15%;
                top:45%;
            }
            &-sec2{
                bottom:-160%;
                left:80%;
            }
        }
        @media only screen and (min-height: $height-lx) and (max-width: $desktop-width)  {
            &-sec1{
                top:35%;
            }
            &-sec2{
                bottom:-150%;
            }
        }

    }

    &__center {
        //z-index: 2000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: static;
        min-width: 100%;
        min-height: 100%;
        opacity: 0;
        padding:2rem 0;
        transform: scale3d(0.9, 0.9, 1); 
        transition: opacity 0.5s, transform 0.5s;
        .login__wrap--open & {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }
    }

    & {
        font-family: $sans-serif;
        border-radius: 0.75rem;
        margin: 0 auto;
        z-index: 2000;
        display: block;
        width: 90%; 
        max-width: 450px;
        height: auto;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        box-shadow:
            0 0.9px 2.2px rgba(0, 0, 0, 0.02),
            0 2.1px 5.3px rgba(0, 0, 0, 0.028),
            0 4px 10px rgba(0, 0, 0, 0.035),
            0 7.1px 17.9px rgba(0, 0, 0, 0.042),
            0 13.4px 33.4px rgba(0, 0, 0, 0.05),
            0 32px 80px rgba(0, 0, 0, 0.07)
        ;
        @include themeColours( background, body1, 0.7);
        .dark-theme & {
            background: rgba($bgBlack, 0.7);
        }
        .light-theme & {
            background: rgba($white1, 0.9);
        }

        .page--loaded & {
            animation: animLoadedContent 1s cubic-bezier(0.7,0,0.3,1) both;
        }
        &--wide{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 90%; 
            max-width: none;
            height: auto;
            backdrop-filter: none;
            box-shadow: none;
            background: none!important;
            border-radius: 0;
            @include mq('tablet-wide', max) {
                max-width:500px;
                margin: 0 auto;
                flex-direction: column;
            }
            @media only screen and (max-height: $height-lx) and (min-width: $tablet-wide-width)  {
                max-width: 500px;
                margin: 0 auto;
                flex-direction: column;
            }
            @media only screen and (min-height: $height-lx) and (max-width: $desktop-width)  {
                max-width: 500px;
                margin: 0 auto;
                flex-direction: column;
            }
        }
    }

    .form {
        min-height: unset;
        max-height: none;
        //max-height: 200px; 
        overflow: hidden;
        transform: scale3d(1, 1, 1);
        opacity:1;
        transition: opacity 0.35s, transform 0.65s, max-height ease 0.35s;

        &--hide {
            min-height: 0;
            max-height: 0;
            overflow: hidden; 
            opacity: 0;
            transform: scale3d(0.9, 0.9, 1);
        }
        input{
            border-radius: 0;
            
        }

        //semantic ui override
        &.ui{
            .segment{
                border:0;
                border-radius: 0.75rem;
                &::after{
                    display:none;
                } 
            }
            .input{
                border-radius: 0;
            }
            input{
                border-radius: 0.25rem;
                font-size:1.125rem;
                &:focus,
                &[type="password"]:focus{
                    background:lighten($secondary-tint,35%);
                    box-shadow: 0 0 0 0 $grey-700 inset;
                    border-color:$grey-500;
                    &::-webkit-input-placeholder{
                        color:rgba($grey-700, 0.75);
                    }
                }
            }
            button + button{
                margin-top:1rem;
            }
            button.small{
                width:auto;
                margin:1rem auto 0 auto;
            }
        }
    }

    &__logo {
        //margin: 0 auto;
        z-index: 2000;
        display: inline-block;
        width: 250px;
        max-width: 450px;
        height: 6rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        right:0; 

        @include mq('tablet-wide', max) {
           width:180px;
           height: 2rem;
           margin-bottom:1.5rem;
           display: block;
        }
        @media only screen and (max-height: $height-lx) and (min-width: $tablet-wide-width)  {
            width:180px;
        }
        

        &-wrap{ 

            width: auto;
            flex:1 1 auto;
            max-width:500px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include mq('tablet-wide', max) {
                height:160px;
            }
            @media only screen and (max-height: $height-lx) and (min-width: $tablet-wide-width)  {
                height:160px;
            }
            @media only screen and (min-height: $height-lx) and (max-width: $desktop-width)  {
                height:160px;
            }
        }
        &-owner{
            display: inline-block;
            width: 164px;
            height:6rem;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: all 0.35s ease;
            animation: appear 0.45s ease 0.35s 1 alternate backwards;
            @include mq('tablet-wide', max) {
                width:160px;
                margin:0 auto;
                clear:both;
                display: block;
                height: 3.5rem;
                margin-top:-1rem;
            }
            
            @media only screen and (max-height: $height-lx) and (min-width: $tablet-wide-width)  {
                .login__logo-wrap &{
                    width:138px;
                }
            } 
        }
        &-center{
            display: block;
        }
        &-reveal{
            display: inline-block;
            text-align: right;
            animation: reveal 0.75s ease 1.0s 1 alternate backwards;
            overflow: hidden;
            position: relative;
            width: 250px;
            margin-left:1.5rem;
            height:6rem;
            @include mq('tablet-wide', max) {
             margin-left:0.75rem;
               width:180px;
            }
            @media only screen and (max-height: $height-lx) and (min-width: $tablet-wide-width)  {
                margin-left:0.75rem;
                width:180px;
            }
        }
    }

    &__form{
        .login--wide &{
            width: auto;
            background:$bgWhite;
            border-radius: 0.5rem;
            box-shadow:
                0px 1.3px 2.2px rgba(0, 0, 0, 0.059),
                0px 3.1px 5.3px rgba(0, 0, 0, 0.085),
                0px 5.9px 10px rgba(0, 0, 0, 0.105),
                0px 10.5px 17.9px rgba(0, 0, 0, 0.125),
                0px 19.6px 33.4px rgba(0, 0, 0, 0.151),
                0px 47px 80px rgba(0, 0, 0, 0.21)
                ;
            animation: appear 0.5s ease 1.25s 1 alternate backwards;
            
        }
        &-wrap{
            flex-direction: column;
            width: auto;
            flex:1 1 auto;
            max-width:500px;
            &.shake{
                animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);   
            }
        }
        .ui.form .field .ui.input input, 
        .ui.form .fields .field .ui.input input {
            width: 100%; 
        }
        .ui.form .field .ui.input.icon input, 
        .ui.form .fields .field .ui.input.icon input {
            padding-left:2.5rem; 
        }
    }

    &__terms {
        font-size: 0.75rem;
        padding: 1rem 1rem 1.5rem 1rem;
        color:$grey-white;
        animation: appear 0.45s ease 1.25s 1 alternate backwards;
        display: block;
        

        @include mq('tablet', min) {
            display: none;
        }
        .link {
            text-decoration: none;
            background:none;
            color:$grey-white;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    &__reg{
        display:block;
        overflow:hidden;
        height:auto;
        max-height:480px;
        padding:0 0 1rem 0;
        transition: all 0.5s ease;
        transform: scale3d(1, 1, 1);
        opacity:1;
        &.hidden{
            max-height:0;
            padding:0;
            transform: scale3d(0.9, 0.9, 1);
            opacity:0;
        }
    
    }

    .spacer {
        margin: 0 8px;
        opacity: 0.7;
    }

    .form__list {
        padding: 0;
    }
}
@keyframes animLoadedContent {
    from {
        //opacity: 0;
        transform: translate3d(0,200px,0);
    }
}
@keyframes reveal{
    from{
        max-width:0px;
    }
    to {
        max-width: 250px;
    }
}
@keyframes appear{
    from{
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1) translate(0, 20%);
    }
    to {
        opacity: 1;
        transform: scale3d(1, 1, 1) translate(0,0);
    }
}
@keyframes rotate1{
    from{
        opacity: 0;
        transform: scale3d(0.8, 0.8, 1) rotate(90deg);
    }
    to {
        transform: scale3d(1, 1, 1) rotate(190deg);
        opacity: 1;
    }
}
@keyframes rotate2{
    from{
        transform:scale3d(0.8, 0.8, 1) rotate(90deg) ;
        opacity: 0;
    }
    to {
        transform: scale3d(1, 1, 1) rotate(0deg);
        opacity: 1;
    }
}

.b-profile {
    &-header {
        background: darken($primary-shade, 5%);
        overflow: hidden;
        width: 100%;
        pointer-events: all;

        @media (max-width: 583px) {
            margin-top: 56px !important;
            padding-top: 1rem;
        }
    }
    &__image{
        &-wrap{
            margin:-9rem -8rem -9rem 0;
            position: absolute;
            height:32rem;
            width: 32rem;
            background-color: $primary;
            border-radius: 16rem;
            right:0;
            overflow: hidden;
            .e-avatar{
                &.e-avatar-large{
                    transition: all 0.5s ease; 
                    background-color: $primary;
                    width: 32rem;
                    height: 32rem;
                    line-height: 6rem;
                    font-size: 2.75rem;
                    margin:0 0 0 0;
                    font-style: normal;
                    background-size: cover;
                    background-repeat:none;
                    position: relative;
                    display: block;
                    text-align:center;
                    img{
                        display:block;
                    }
                }
            }
        }
        & > img{
            transition: all 0.5s ease; 
            border:2px solid $white1;
            background-color:$grey-400;
        }

        
    }
    &-hero {
        @media (max-width: 1024px) {
            width: 100% !important;
        }
        &.ui{
            align-items: center;
            background: none;
            border: none !important;
            color: $white1;
            display: flex;
            padding: 0 !important;
            border-radius: 0;
            min-height: 380px;
            @media (max-width: 1024px) {
                padding: 0 2rem !important;
            }
            @media (max-width: 425px) {
                padding: 0 !important;
            }
        }
        .card{
            background:none;
            border:0;
            border-radius: 0;
            overflow: visible;
            box-shadow: none;
            webkit-box-shadow: none;
            .content{
                padding:0;
            }
        }
        .header .ui.label.circular{
            padding:0.375rem 0.625rem!important;
            margin-right:0.375rem;
            margin-left:0;
        }
        .ui.button.big{
            margin-right:0.75rem;
            &.inverted{
                box-shadow: 0 0 0 2px $primary inset!important;
                color:$grey-white!important;
                background-color: transparent;
                &:hover{
                    background-color: lighten($primary-shade,5%);
                }
            }
        }
        @media (max-width: 768px) {
            flex-direction: column; 
        }

        &-image, &-info {
            flex: 1 0;
            width: 50%;

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        &-image {
            text-align: center;
            
            & img {
                border-radius: 5px;
                display: block;
                height: 100%;
                transform: scaleY(1.05);
                width: 100%;
            }
        }

        &-info {

            & h1 {
                max-width: 80%;
            }

            .b-wrapper {
                display: block;
                margin: 0 auto;
                max-width: 80%; 

                @media (max-width: 768px) {
                    max-width: 100%;
                    padding: 2rem;
                }
            }
        }

        & p {
            color: $white1;

        }
        &.wide .ui.grid.container{
            width:calc(1400px + 2rem)!important;
        }
    }

    &-tag {
        color: $opus-orange;
        padding-left: 1rem;

        & img {
            padding-left: .5rem;
            position: absolute;
        }
    }

    &-text {
        &__intro {
            margin: 0 auto;
            max-width: 70%;
        }
    }

    &-position {
        &__relative {
            position: relative;
        }
    }

    &-case {
        background-color: $opus-purple;
        border-bottom: 2px solid darken($opus-purple, 10%);
        border-top: 1px solid darken($opus-purple, 5%);
        border-left: 1px solid darken($opus-purple, 5%);
        border-right: 1px solid darken($opus-purple, 5%);
        border-radius: 5px;
        color: $white1;
        margin-bottom: 2rem;
        text-align: center;
        padding: 1rem;

        & p {
            color: $white1 !important;
        }

        &-status {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            & p {
                color: $opus-orange !important;
            }
        }
    }
}

.b-accordion {
    &-show {
        margin-top: 1rem;
        max-height: 100vh !important;
    }
    &-target {
        cursor: pointer;
        margin: 0;

        &:after {
            content: "\253F";
            position: absolute;
            right: 1rem;
        }
    }
    &-active {
        &:after {
            content: "\2500";
        }
    }
    p {
        max-height: 0;
        overflow: hidden;
        transition: all .16s ease;
    }
}
.ui.icon.input{
    &>i.icon{
        //max-height:3.65rem; 
        max-height:3rem;
    }
}
.ui.form .field{
  & .ui.input__full-width input{
    width: 100%;
  }
  & .ui.input.icon input{
    padding-left:3rem;
  }
  .ui.input.reveal input{
    padding-right:3rem;
  }
  .input{
    &__reveal-btn{
      height:3rem;
      width: 3rem;
      display:block;
      background: transparent;
      position: absolute;
      top:0;
      right: 0;
      cursor: pointer;
      color:$grey-600;
    }
  }
}

.error{
    .ui.basic.red.label{
        margin-top:0.125rem;
        background:lighten($red,25%)!important;
        border: 0;
        border-radius: 0.25rem;
        display:block;
        font-size:0.75rem;
        animation: fadeIn 0.5s ease;
        overflow: hidden;
        text-align: left;
        color: $grey-800!important;

    }
}
.field{
    &__text-link{
      display:block;
      font-size:0.675rem;
      text-align:left;
      padding-top:0.25rem;
      max-height:60px;
      overflow:hidden;
      transition:all 0.35s ease;
      color:$opus-purple;
      background:transparent;
      border:0;
      &:hover{
        text-decoration: underline;
        color:$opus-purple;
        background:transparent;
        border:0;
      }

      &.right{
        text-align: right;
      }
      &.hidden{
        max-height:0;
        padding:0;
      }
    }
    .ui.form & > label:empty{
      margin:0;
    }
}
@keyframes fadeIn {
    0% {
      opacity: 0;
      height:0;
    }
    100% {
      opacity: 1;
      height:auto;
    }
  }
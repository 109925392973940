.b-sticky-header{
    &{
        display: block;
        margin:0 auto;
        width: 100%;
        max-width:1044px;
        color: $white1;
        @include mq('desktop', max) {
            max-width:988px;
            padding:0 1rem;
        }
    }
    &__wrapper{
        position: fixed;
        background: rgba(darken($primary-shade, 5%), 0.92);
        backdrop-filter: blur(2px);
        overflow: hidden;
        z-index: 10;
        width: 100%;
        padding:0 0 0.675rem 0;
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
        pointer-events: none;
        transition: all 0.35s ease;
        .slide-enter-done &.isSticky{
            opacity: 1;
            transform: scale3d(1, 1, 1);
            pointer-events: all;
            
            @include mq('tablet', max) {
                opacity: 0;
                transform: scale3d(0.9, 0.9, 1);
                pointer-events: none;
            }
        }
    }
    &__row{
        display: flex;
        flex-direction: row;
    }
    &__col {
        flex: 1 1 auto;
        &.right{
            text-align: right;
        }
        &--avatar{
            flex:0 0 4.5rem;
            width:4.5rem;
            padding:0.875rem 0 0 0;
            .e-avatar{
                width: 3.5rem;
                height: 3.5rem;
            }
            
        }
    }
    .label.tiny.label{
        padding: 0.25rem 0.5rem !important;
    }
    .meta{
        display: inline-block;
        margin-left:0.25rem;
        color:$white1;
        font-size:0.75rem;
        a{
            color:$white1;
        }
        .icon{
            color:$secondary;
        }
    }
    .card__hero-buttons{
        display: inline-block;
        margin-top:1.5rem;
    }
    h3{
        margin:0.75rem 0 0.25rem 0;
        font-weight: $bold;
    }
    .ui.button.primary.inverted{
        color: $white1!important;
    }
}
﻿.e-float {
    &-input {
        label {
            border: 0;
            background: none;
            padding-left: 0;
        }
    }

    &-input input,
    &-input.e-control-wrapper input {
        font-size: 1rem;
        padding-left: 0.25rem;

        &:focus {
            padding-left: 0.25rem;
        }
    }

    &-input &-line,
    &-input.e-control-wrapper &-line {
        position: static;
    }
    
}
.e-content-wrapper{
    padding-top:0.5rem;
    .col{
        position:relative;
    }
    label{
        //overwrite old styles
        background:none;
        border:0;
        font-family: $sans-serif;
    }
}
.e-switch-label{
    font-size: 16px;
    font-style: normal;
    font-weight:$semibold;
    overflow: hidden;
    padding-left: 0;
    position: relative;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    width: 100%;
    padding:1rem 0 0.75rem 0;
    cursor: pointer;
    color:$grey-500;
    transition:color 0.25s ease;
    .e-switch-wrapper[aria-checked="true"]+&{
        color:$grey-700;
    }

}
.col .e-switch-wrapper{
    margin-top:1rem;
}
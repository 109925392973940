.b-avatar {
    box-sizing: border-box;

    &.inline {
        float: left;
        display: inline;
    }

    &__image {
        display: block;
        clear: both;
        border: 0.125rem solid $blue2;
        //height: 5rem;
        //width: 5rem;
        overflow: hidden;
        border-radius: 5rem;
        margin: 0 auto 0.5rem auto;
        position: relative;
        
        &.primary {
            border-color: $primary;
        }

        &.secondary {
            border-color: $secondary;
        }

        &.border--none {
            border:0;
        }

        .inline & {
            float: left;
            display: inline;
            margin: 0 auto 0 auto;
        }

        .large & {
            width: 2.5rem;
            height: 2.5rem;
        }

        .medium & {
            width: 2rem;
            height: 2rem;
            border-width: 0.0625rem; 
        }
        .e-avatar{
            background-color: $primary-shade;
        }
        .avatar__img {
            //use syncfusion

            /*width: 100%;
            height: 100%;
            max-height: 5rem;
            z-index: 52;
            position: absolute;
            display: block;
            border-radius: 4rem;
            background-size: cover;
            background-position: center center;
            top: 0;*/

        }
        /*
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -2.5rem;
            margin-left: -2.5rem;
            width: 5rem;
            z-index: 2;

            .large & {
                width: 2.5rem;
                height: 2.5rem;
                margin-top: -1.25rem;
                margin-left: -1.25rem;
            }

            .medium & {
                width: 2rem;
                height: 2rem;
                margin-top: 1rem;
                margin-left: 1rem;
            }
        }*/

        .icon {
            background: rgba(0,0,0,0.3);
            margin-left: 0;
            opacity: 0.6;
        }
    }

    &__icon {
        position: relative;
        overflow:visible;
        display:block;

        .icon {
            position: absolute;
            margin: 1rem 0 0 -1.25rem;
            z-index: 55;
            display: none;
            padding:0.25rem;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .large & {
            min-height: 2.5rem;
        }

        .medium & {
            min-height: 2rem;
        }
    }

    &__name {
        display: block;
        align-self: stretch;
        padding: 0 1.2rem;
        text-overflow: ellipsis;
        font-weight: 600;

        .inline & {
            float: left;
            display: inline;
            padding: 0 1.2rem 0 0.5rem;
        }

        .large & {
            font-size: 1.125rem;
        }

        .medium & {
            font-size: 0.875rem;
        }
    }

    &__company {
        display: block;
        align-self: stretch;
        padding: 0 1.2rem;
        text-overflow: ellipsis;
        font-weight: 600;

        .inline & {
            float: left;
            display: inline;
            padding: 0 1.2rem 0 0.5rem;
        }

        .large .avatar__name + & {
            font-size: 0.875rem;
            font-weight: normal;
        }

        .large & {
            font-size: 1.125rem;
        }

        .medium .avatar__name + & {
            font-size: 0.750rem;
            font-weight: normal;
        }

        .medium & {
            font-size: 0.875rem;
        }
    }
}

.cases {

    &-header {
        background-color: $opus-purple !important;
        color: $white1 !important;

        &-info {
            display: flex;
            color: $opus-orange;
            justify-content: space-evenly;
            padding: 1rem 0;

            & span {
                align-items: center;
                display: inline-flex;

                & img {
                    margin-left: .5rem;
                }
            }
        }

        & p {
            color: $white1 !important;
            margin: 0 auto;
            max-width: 75%;
        }
    }

    &-info {
        border-bottom: 1px solid $grey-400;
        padding: .5rem 0;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &-table {

        &-header {
            border-bottom: 1px solid $grey-400;
            display: flex;
            font-weight: bold;
            padding: .5rem 0;

            & span {
                flex: 1 0 33%;
            }
        }

        &-content {

            &-row {
                display: flex;
                padding: .5rem 0;
            }   
            &-cell {

                &-third {
                    flex: 1 0 33%;
                }                
            }         
        }
    }
}
.side-filter {
    background: #e8edf3;
    height: calc(100vh - 1rem);
    width: 400px;
    position: absolute;
    top: 0;
    right: 0;
    opacity:0;
    pointer-events: none;
    z-index: 180;
    transform: translate(200px, 0) scale3d(0.9, 0.9, 1);
    transition: opacity 0.5s, transform 0.5s;
    display:flex;
    flex-direction: column;
    border-radius: 0.5rem;
    overflow: hidden;
    margin:0.5rem;
    &.open{
        opacity:1;
        pointer-events:all;
        transform: translate(0, 0) scale3d(1, 1, 1);
    }
    
    @include mq('phone-wide') {
        width: calc(100vw - 1rem);
        height: calc(100vh - 2rem);
    }
    &__header {
        background: $primary-shade;
        color: $white1; 
        padding: 1rem;
        top: 0;
        width: 100%;
        z-index: 100;
        flex: 0 0 56px;
        h3{
            line-height:normal;
        }
        .ui.button.icon{
            float: right;
            margin:-0.375rem -0.375rem -0.375rem 0;
            color:$white1;
            background:transparent;
            transition: all 0.35s ease;
            width:2.25rem;
            height:2.25rem;
            & > i.icon{
                margin:-0.125rem -0.25rem 0 -0.25rem!important;
            }
            &:hover{
                background: darken($primary-shade,10%);
            }
        }
    }

    &__filters {
        padding: 0rem 1rem 1rem 1rem;
        a.ui.label{
            background-color: $grey-400;
            transition: background-color 0.35s ease;
            
            &:hover{
                background-color: darken($grey-400, 10%);
                i.icon{
                    color: $black1!important;
                }
            }
        }
        & span {
            border: 1px solid $primary;
            border-radius: 15px;
            background-color: $white1;
            display: inline-block;
            padding: .25rem .5rem; 
            
            & i {
                color: $primary;
                margin-left: .25rem;
            }
        }
    }

    &__topic {
        background: $white1;
        border-radius: 5px;
        padding: 1rem;
        margin: 0 1rem 1rem 1rem;
    }
    &__content {
        flex: 1 1 auto;
        overflow: auto;
        padding:1rem 0 0 0;
        margin:0;
    }
    &__footer {
        flex: 0 0 72px;
        padding: 1rem;
        overflow: hidden;
        bottom: 0;
        right: 0;
        width: 100%;
        border-top:1px solid $grey-400;
    }
    &__shade {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.4s ease; 
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        width: 100vw;
        height: 100vh;
        background-color: rgba(darken($primary-shade, 10%), 0.8);
        opacity: 0;
        &.open {
            pointer-events: auto;
            opacity: 1;
            z-index: 180;
        }
    }
}
.b-search-page{
    transform: scale3d(1, 1, 1);
    transition: trasform 0.35s ease, opacity 0.35s ease;
    min-height: 100%;
    .slide-enter &{
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
    }
    .slide-enter-active &{
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
    /*
    .slide-enter-done &{
        transform: scale3d(1, 1, 1);
    }*/
    .slide-exit &{
        opacity: 1;
        transform: scale3d(0.9, 0.9, 1);
    }
    .slide-exit-active &{
        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
    }/*
    .slide-appear &{
        transform: scale3d(0.9, 0.9, 1);
    }
    .slide-appear-active &{
        transform: scale3d(1, 1, 1);
    }*/
}

.b-grid{
    &--3{
        //responsive from 3-2-1 stackable
        @include mq('desktop', max) {
            &.ui[class*="three column"].grid>.column:not(.row), 
            &.ui[class*="three column"].grid>.row>.column {
                width: 50%;
                padding: 1rem 1rem!important;
            }  
        }
    }
}
﻿.web-nav {
    display: block;
    height: auto;
    position: absolute;
    z-index: 201;
    @include bgAlpha($black1, 0.4);
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 0.5rem;
    transition: all 0.3s ease;

    &--hide {
        margin-top:-6rem;
    }

    .btn {
        border-radius: 40rem;
        border: 1px solid rgba(255,255,255, 0.5);
        transition: all 0.3s ease;
        font-size:0.875rem;
    }
}

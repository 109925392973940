﻿/* Layout for search container */
.search__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: rgba(10, 10, 10, 0.9);
    color: $white3;
    font-family: $sans-serif;

    .js & {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: calc(100% + 1em);
        height: calc(100% + 1em);
        pointer-events: none;
        border: 1em solid rgba(0,0,0,0.4);
    }

    &::before {
        top: 0;
        left: 0;
        border-right-width: 0;
        border-bottom-width: 0;
    }

    &::after {
        right: 0;
        bottom: 0;
        border-top-width: 0;
        border-left-width: 0;
    }

    .icon-wrapper {
        font-size: 2em;
        position: absolute;
        top: 1.25em;
        right: 1.25em;
        display: none;

        .icon {
            line-height: 40px;
            display: block;

            &:before {
                line-height: inherit;
            }
        }

        .js & {
            display: block;
        }
    }
}

.search__form {
    margin: 5em 0;
    position: relative;
    z-index: 9;

    .autocomplete {
        margin: 0 10%;
        width:80%;
    }
}

.search-input {
    font-family: inherit;
    height: auto;
    line-height: 1;
    display: inline-block;
    box-sizing: border-box;
    width: 80%;
    color: $white1;
    float: none;
    padding: 0.05em 0.1em;
    border: 0;
    border-bottom: 2px solid;
    background: none;
    font-size: 4.5vw;
    outline: none;

    @include mq('desktop') {
        font-size: 6vw;
    }

    @include mq('tablet-wide') {
        font-size: 8vw;
    }

    @include mq('phablet') {
        font-size: 9.5vw;
    }

    

    &::placeholder,
    &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        opacity: 0.3;
        color: $white1;
        line-height: 1;
        padding-top: 8px;
        font-size: 4vw;

        @include mq('tablet-wide') {
            font-size: 6vw;
            line-height: 6vw;
            padding-top: 12px;
            line-height: 1;
        }

        @include mq('phablet') {
            font-size: 8.5vw;
            line-height: 1;
            padding-top: 18px;
            display: block;
        }
    }

    &::-ms-input-placeholder {

        @include mq('phablet') {
            font-size: 9vw;
        }
    }
    &::-ms-clear {
        display: none;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}

.search-info {
    font-size: 90%;
    font-weight: normal;
    display: block;
    width: 75%;
    margin: 0 auto;
    padding: 0.85em 0;
    text-align: right;
    color: rgba(255,255,255, 0.8);
    box-sizing: border-box;
}

@include mq('phablet') {
    .search-info {
        display: none;
    }
}

.search__links {
    display: flex;
    width: 82%;

    @include mq('phablet') {
        position: absolute;
        top: 180px;
    }
}

.search__col {
    width: 33.33%;
    text-align: left;
    min-height: 15.5rem;

    &:nth-child(2) {
        margin: 0 3em;
    }

    & > h3 {
        font-size: 1.15em;
        padding: 0 0.5rem 1rem 0.5rem;
        margin: 0;
    }

    & > p {
        font-size: 1.15em;
        line-height: 1.4;
        margin: 0.75em 0 0 0;
    }

    & > .list > .item {
        font-size: 1em;
        line-height: 2rem;
        margin: 0;
        float: none;
        display: block;
    }

    .link {
        color: $white1;
        cursor: pointer;
        display: block;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0.125rem 0.5rem 0.125rem 0.25rem;
        transition: background-color ease 0.2s;

        &:hover,
        &:active,
        &:focus {
            @include schemeColours(3,1);
        }

        .icon {
            float: left;
            display: inline;
            margin: 0 0;
            font-size: 1rem;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            opacity: 0.7;
        }
    }
}



.search__nav {
    position: relative;
    z-index: 13;

    .icon-menu {
        &__list {
            margin: 48px 0 0 -142px;
            z-index: 13;
        }
    }

    .icon-menu--open .icon-menu__list,
    .enable-hover & .icon-menu:hover .icon-menu__list {
        max-height: 530px;
    }

    .icon-button {
        width: 56px;
        height: 56px;
        padding: 0;
        margin: 0 8px;
    }

    .icon {
        line-height: 62px;
        font-size: 32px;

        &.icos-user,
        &.icos-barrister {
            font-size: 36px;
        }

        &-button {
            width: 56px;
            height: 56px;
            padding: 0;
            margin: 0 8px;
        }
    }
}

@include mq('phablet') {
    .search__nav {
        position: absolute;
        top: 1rem;
        left: 1rem;

        .icon {
            line-height: 46px;
            font-size: 22px;

            &-button {
                width: 40px;
                height: 40px;
                padding: 0;
                margin: 0 4px;
            }
        }
    }
}
/************************/
/* Transitions 			*/
/************************/

.search__wrapper {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;

    &.search--open {
        pointer-events: auto;
        opacity: 1;
    }

    &::before {
        transform: translate3d(-15px, -15px, 0);
        transition: transform 0.5s;
    }

    &::after {
        transform: translate3d(15px, 15px, 0);
        transition: transform 0.5s;
    }

    .icon-wrapper {
        .icon-button {
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
            transition: opacity 0.5s, transform 0.5s;
        }
    }

    &.search--open {
        &::before,
        &::after {
            transform: translate3d(0, 0, 0);
        }

        .icon-wrapper .icon-button {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }
    }
}
/* Search form with input and description */
.search__form {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 1);
    transition: opacity 0.5s, transform 0.5s;

    .search--open & {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}
.search__col {
    opacity: 0;
    transform: translate3d(0, -30px, 0);
    transition: opacity 0.5s, transform 0.5s;

    .search--open & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        &:nth-child(2) {
            transition-delay: 0.1s;
        }
        &:nth-child(3) {
            transition-delay: 0.2s;
        }
    }
    .search--open .search__links--fade-out & {
        opacity: 0;
    }
    .search--open .search__links--fade-in & {
        opacity: 1;
    }
}


.search {
    &__filter {
        display: block;
        width: 45%;
        position: relative;
        margin-top: -3rem;
        min-height: 2rem;
        box-sizing: border-box;

        @supports (-ms-ime-align:auto) {
            & {
                width: 50%;
            }
        }

        @include mq('desktop') {
            width: 65%;

            @supports (-ms-ime-align:auto) {
                & {
                    width: 70%;
                }
            }
        }

        @include mq('tablet-wide') {
            width: 85%;

            @supports (-ms-ime-align:auto) {
                & {
                    width: 90%;
                }
            }
        }

        &-icon {
            right: 0;
            position: absolute;
            z-index: 8;
            margin-top: -28px;
        }

        .icon:before {
            line-height: inherit;
        }

        .icon--xsmall {
            position: absolute;
            margin: -24px 0 0 2px;
            line-height: 24px;
            font-weight: bold;
        }

        &-group {
            opacity: 0;
            position: absolute;
            transform: translate3d(0, -30px, 0);
            transition: opacity 0.5s, transform 0.5s, max-height 0.5s;
            display: block;
            width: 100%;
            max-height: 0;
            overflow: hidden;
            pointer-events: none;

            .search__filter--open & {
                opacity: 1;
                transition: opacity 0.5s 0.5s, transform 0.5s 0.5s, max-height 0.5s;
                transform: translate3d(0, 0, 0);
                pointer-events: auto;
                max-height: 200px;
                overflow: visible;
                z-index: 5;
            }
        }
    }
}


@include mq('phablet') {
    .search__form {
        margin: 1em 0 1em;
        padding: 2rem 0;
        position: absolute;
        top:2.5rem;
    }

    .search__filter {
        margin: 1em 0 1em;
        position: absolute;
        top: 182px;
    }

    .search__wrapper .icon-wrapper {
        top: 1rem;
        right: 1rem;
    }

    .search-info {
        text-align: left;
    }

    .search__col {
        width: 100%;
        &:nth-child(2),
        &:nth-child(3) {
            display: none;
        }
    }
}



﻿.tab {
    &__wrap {
        display: block;
        margin: 24px 16px 0 16px;
        text-transform: uppercase;
        border-bottom: 2px solid $light-grey2;
        @include schemeColours(1,1,true,false);
        display: flex;
        // justify-content: flex-end;
        &-left {
            width: 100%;
        }
    }

    &__page-icons {
        width: 50px;

        .icon-button {
            margin: -6px 0 0 4px;
            transition: all ease 0.6s;

            .icon::before {
                line-height: 42px;
            }

            &:hover {
                transform: rotate(180deg);
            }
        }
    }

    &__link {
        display: inline-block;
        line-height: 32px;
        font-size: 14px;
        text-decoration: none;
        padding: 0 6px;
        margin: 0 6px 0 0;
        border-bottom: 4px solid transparent;
        font-weight: bold;
        transition: border 0.25s ease,color 0.25s ease;

       
        @include themeColours(color, text1, 0.5);

        &--selected,
        &:hover {
            @include themeColours (color, hover1);
        }

        &--selected,
        &:hover {
            @include schemeColours(1,1,true,false);
        } 
    }
}

﻿.e-treeview {
    @include hideScrollUntilHover();
   
    li {
        float: none;
        display: list-item;
        text-align:left;
    }

    .e-list-item.e-active {
        & > .e-fullrow {
            background: lighten($primary, 15%);
        }

        & > .e-text-content {
            color: $primary-text;

            .e-list-text {
                color: inherit;
            }
        }
    }

    .icon {
        line-height: 18px;

        &.icos-sharepoint {
           // color: $sharepoint-color; 
        }
    }
}

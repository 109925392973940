.ui.b-padding{
    &--x2{
        padding:2em;
    }
    &--x3{
        padding:3em;
    }
    &--x4{
        padding:4em; 
    }
}
.ui.b-margin{
    &--x2{
        margin:2rem;
    }
    &--x3{
        margin:3rem;
    }
    &--x4{
        margin:4rem;
    }
}
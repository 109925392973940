﻿.e-toolbar {
    & &-items &-item {
        &:not(.e-separator) {
            padding: 0;
        }

        .e-tbar-btn.e-btn {
            margin: 0;
            padding: 0.25rem 0.375rem;
            height: 100%;

            .e-filemanager &.e-dropdown-btn,
            .e-filemanager &.e-dropdown-btn:hover {
                height: inherit;
                padding: 0.25rem 0.375rem!important;
            }
        }
    }

    .e-btn {
        transition: background ease 0.35s;

        .icon {
            height: 25px;
            font-size: 0.875rem;
            border-radius:0;
        }
    }

    .e-tbar-btn .e-icons.e-btn-icon {
        font-size: 1rem;
    }

    .e-tbar-btn-text,
    .e-toolbar-items .e-toolbar-item .e-tbar-btn-text{
        font-size: 0.75rem; 
        padding: 0 0.25rem;
    }
}

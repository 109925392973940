.spacing{
    &-clear {
        &-padding {
            &__bottom {
                padding-bottom: 0 !important;
            }
        }
    }
    &-x15{
        padding:1.5rem!important;
    }
    &-x20{
        padding:2rem!important;
    }
    &-x30{
        padding:3rem!important; 
        @include mq('phablet', max) {
            padding:1.75rem!important;
        }
    }
    &-x40{
        padding:4rem!important; 
        @include mq('phablet', max) {
            padding:2rem!important;
        }
    }
}

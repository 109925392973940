﻿/*fonts*/
/*Lato*/
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/Lato-Thin.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/Lato-ThinItalic.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src:url('../fonts/Lato-Light.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 300;
    src:url('../fonts/Lato-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src:url('../fonts/Lato-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    src:url('../fonts/Lato-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Lato-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Lato-BoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Lato-Black.ttf') format('truetype'), /* Safari, Android, iOS */
}
@font-face {
    font-family: 'Lato';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/Lato-BlackItalic.ttf') format('truetype'), /* Safari, Android, iOS */
}

@font-face {
    font-family: 'icos';
    src: url('../fonts/icos.ttf') format('truetype'), url('../fonts/icos.woff') format('woff'), url('../fonts/icos.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/*semantic ui*/
@font-face{
    font-family:'icons';
    src:url(../fonts/icons.eot);
    src:url(../fonts/icons.eot?#iefix) format('embedded-opentype'),url(../fonts/icons.woff2) format('woff2'),url(../fonts/icons.woff) format('woff'),url(../fonts/icons.ttf) format('truetype'),url(../fonts/icons.svg#icons) format('svg');
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-decoration:inherit;
    text-transform:none;
}
@font-face{
    font-family:'outline-icons';
    src:url(../fonts/outline-icons.eot);
    src:url(../fonts/outline-icons.eot?#iefix) format('embedded-opentype'),url(../fonts/outline-icons.woff2) format('woff2'),url(../fonts/outline-icons.woff) format('woff'),url(../fonts/outline-icons.ttf) format('truetype'),url(../fonts/outline-icons.svg#icons) format('svg');
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-decoration:inherit;
    text-transform:none;
}
@font-face{
    font-family:'brand-icons';
    src:url(../fonts/brand-icons.eot);
    src:url(../fonts/brand-icons.eot?#iefix) format('embedded-opentype'),url(../fonts/brand-icons.woff2) format('woff2'),url(../fonts/brand-icons.woff) format('woff'),url(../fonts/brand-icons.ttf) format('truetype'),url(../fonts/brand-icons.svg#icons) format('svg');
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-decoration:inherit;
    text-transform:none;
}

@font-face {
    font-family: 'b-icons';
    src:  url('../fonts/b-icons.eot?yz6szo');
    src:  url('../fonts/b-icons.eot?yz6szo#iefix') format('embedded-opentype'),
      url('../fonts/b-icons.ttf?yz6szo') format('truetype'),
      url('../fonts/b-icons.woff?yz6szo') format('woff'),
      url('../fonts/b-icons.svg?yz6szo#b-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

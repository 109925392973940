
.b-slidedeck {
    &__container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        width: 100%;
        z-index: 4;
        min-height: 280px;
        display: block;
        float: none;

        .popupNew & {
            top: 46px;
            bottom: 38px;
        }

        .popupNew.buttons--off & {
            top: 46px;
            bottom: 0px;
        }

        .e-dialog & {
            top: 67px!important;
        }
    }

    &__pane {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: 100%;
        display: inline;
        float: left;
        padding: 0;
        margin: 0;
        text-align: center;
        display: inline-flex;
        flex-direction: column;
        //color: $white1;
        font: 16px/28px $sans-serif;
        opacity: 1; 
        transform: scale3d(1, 1, 1);


        &.padding--zero {
            padding: 0;
        }

        .form {
            overflow: auto;
            height: 100%;
        }

        .b-slidedeck__wrap--slide-indv & {
            display: flex;
            float: none;
            transition: all ease 0.55s;
            margin: 0;
            position: absolute;
        }

        &.slide--out-right {
            margin-left: 33%;
            opacity: 0;
            pointer-events: none;
        }

        &.slide--out-back {
            opacity: 0;
            pointer-events: none;
            transform: scale3d(0.9, 0.9, 1);
        }
    }

    &__wrap {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        transition: margin ease 0.6s;
        //set number of panes
        @for $i from 2 through 8 {
            $h: calc($i * 100%);
            $j: calc(100% / $i);

            &--panes-#{$i} {
                width: $h;

                .slidedeck__pane {
                    width: $j;
                }
            }
        }
        //animations
        @for $i from 1 through 8 {
            $h: ($i - 1) * -100%;

            &--cur-pane-#{$i} {
                margin-left: $h;
            }
        }
    }

    &__content {
        display: block;
        text-align: center;
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        padding: 3rem 3rem 2rem 3rem;
        font-family: $sans-serif;
        box-sizing: border-box;
        position: relative;
        @include hideScrollUntilHover();
        @include mq('tablet', max) {
            
            padding: 1.5rem 1.5rem 1rem 1.5rem;
        }
        //overflow:hidden;
        &.center-v {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        &.align-left{
            text-align: left;
        }

        &.flex-column {
            display: flex;
            flex-direction: column;
        }

        .vendor {
            margin-top: 2.5rem;
        }

        .formGen.formGen--white {
            background: none;

            label,
            label.highlight {
                color: $white1;
                text-shadow: none;
            }

            input {
                border-color: rgba(255,255,255, 0.4);
                color: $white1;
            }

            input.highlight,
            input:focus {
                border-color: rgba(255,255,255, 0.8);
                color: $black3;
                background-color: rgba(255,255,255,0.4);
            }

            input.highlight:focus,
            input:read-only:focus {
                background: none;
                color: $white1;
            }
        }

        .form {
            min-height: unset;
            height: auto;
        }

        h3 {
            font: $semibold 1.25rem/2.125rem $sans-serif;
            padding: 0 0.75rem;
            margin: 0;
        }
        h4,
        h5{
            padding: 0 0.75rem;
        }

        p {
            font: 1rem/1.5rem $sans-serif;
            padding: 0.25rem 0.75rem;
            margin: 0;
        }
    }

    &__controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .popup--height-full &__container {
        top: 0;
        bottom: 0;
    }

    &__loading {
        display: block;
        position: relative;
        padding: 1.5rem 0 1.5rem 0;
    }

    &__header {
        padding: 1rem 0;
        display: block;
    }

    &__message {
        transition: all 0.35s ease;
        opacity: 1;
        margin: 0 auto;
        position: absolute;
        display: block;
        width: 100%;


        &.hide {
            opacity: 0;
            margin-top: 2rem;

            &--move-up {
                opacity: 0;
                margin-top: -2rem;
            }
        }

        &-loop {
            display: block;
            height: 2.125rem;
            padding: 1rem 0 2.5rem 0;
            overflow: hidden;
            position: relative;
        }
    }

    &__note {
        color: $black1;
        padding: 0.75rem;
    }

    &__buttons {
        display: block;
        flex: 0 0 auto;
        bottom: 0;
        left: 0;
        right: 0; 
        padding: 0 0 0.5rem 0;
        background: $grey-200;
        border-top: 1px solid $grey-300;

        .btn {
            margin-top: 0.5rem;
        }
    }
    &__content .e-upload{
        margin-top:-1rem;
    }
    &__title{
        text-align: left;
        margin: -0.5rem 0 2rem 0;
    }
}

﻿.stack {
    &__wrapper {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        height: calc(100vh - 1rem);
        width: 100%;
        max-width: 800px;
        z-index: 200;
        background: $nav-bg;
        pointer-events: none;
        opacity: 0;
        transition: all 0.6s ease;
        border-radius: 0.5rem;
        

        .nav__stack--open & {
            pointer-events: auto;
            opacity: 1;
            margin:0.5rem 0;
        }

        @include mq(tablet-wide) {
            max-width: 80%;
        }

        @include mq(tablet) {
            max-width: 100%;
            left: 0.5rem;
            right: 0.5rem;
            width: auto;
        }
    }

    &__shade {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparent;
        z-index: 100;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.4s ease;
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);
        width: 100vw;
        height: 100vh;
        background-color: rgba($secondary, 0.6);
        background-color: rgba(darken($primary-shade, 10%), 0.8);
        opacity: 0;
        .sm__pusher > &{
            width: 44px;
            height:100%;
            @include mq('tablet-wide', max) {
                display:none;
            }
        }
        .nav__stack--open &{
            pointer-events: auto;
            opacity: 1;
        }
        &.open {
            pointer-events: auto;
            opacity: 1;
            width: 44px;
            height:100%;
            z-index: 180;
        }

    }


    & {
        display: block;
        position: absolute;
        top: 3rem;
        bottom: 0;
        left: 0;
        right: 4px;
        height: auto;
        width: auto;
        color: $black3;
        overflow: auto;
        text-align: left;
        padding: 0 1rem 1rem 1rem;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;


        &::-webkit-scrollbar {
            display: none;
        }

        opacity: 0;
        transform: scale3d(0.9, 0.9, 1);
        transition: opacity 0.5s, transform 0.5s;

        .nav__stack--open & {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }
        
        @include mq('tablet-wide', max) {
            top: 3.5rem;
        }
        @include mq('phablet', max) {
            top: 4rem;
        }
    }

    &__item {
        display: block;
        float: none;
        height: auto;
        overflow: hidden;
        //border-bottom: 1px solid $black3;
        position: relative;
        border-radius: 0.5rem;
        background-color: darken($nav-bg, 5%);
        margin-top:0.375em;
        /*&:after{
            display:block;
            border:1px solid rgba($white1, 0.25);
            height:0.25rem;
            content: '';
            overflow: hidden;
        }**/

        &:hover{
            background-color: darken($nav-bg, 10%);  
        }


        &-icon {
            margin: 0.5rem 0.5rem 0 0.25rem;
            float: left;
            display: inline;
            width: 46px;
            height: 46px;
            .icon,
            .icons{
                margin:0.375rem; 
            }
            .icon--large {
                color: $white3;
            }

            .icon--xxsmall {
                position: absolute;
                margin: 18px 0 0 -14px;
            }
            .e-avatar{
                margin-left:0.375rem;
                margin-top: 0.375rem;
                background-color: darken($primary, 15%);
                background-color:$black3;
            }
        }

        &-detail {
            display: block;
            overflow: hidden;
            margin: 0 60px 0 0;
            padding: 0.5rem 0 0.75rem 0;

            .tag {
                line-height: 0.75rem;
                padding: 0.25rem 0.5rem;
                font-weight: $normal;

                .light-theme &.bg--none {
                    color: $white1; 
                    background-color: rgba($white1, 0.15);
                }
            }
        }

        &-title {
            display: block;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: $semibold;
            padding: 0.25rem 0;
            margin: 0;
            border: 0;
            color:$primary; 
        }

        &.recent {
            font-style: italic;
            opacity: 0.8;

            &:hover {
                opacity: 1;
            }
        }

        &:last-child {
            border: 0;
        }

        &-link {
            display: block;
            color:$black3;
            padding: 0;
            cursor: pointer;
            overflow: hidden;
            line-height: normal;
            text-decoration: none;
            transition: all 0.3s ease;
        }

        &-close {
            position: absolute;
            right: 0;
            //background-color: rgba($black1,0.25);
            margin: 0.375rem 0.375rem 0 0;
            overflow: hidden;

            .icos-button,
            .ui.button.icon {
                margin: 0;
                color: $secondary-shade ;
                &:hover,
                &:active,
                &:focus{
                    background-color: darken($nav-bg, 20%)!important;
                }
                
            }
        }
        .ui.label{
            background-color: darken($nav-bg, 18%);
        }
    }

    &__section-title {
        line-height: 2rem;
        border-bottom: 1px solid $secondary;
        margin: 1rem 0;
        display: block;
    }

    &__close-popup {
        position: absolute;
        right: 0.625rem;
        top: 0.625rem;
        color: $white1;
        .button.ui.icon,
        .icon-button {
            opacity: 0;
            transform: scale3d(0.8, 0.8, 1);
            transition: opacity 0.5s, transform 0.5s;
            margin: 0;
            display: block;
            padding:0;

            .nav__stack--open & {
                opacity: 1;
                transform: scale3d(1, 1, 1);
            }
            i.icon{
                width: 2.5rem;
                height: 2.5rem;
                line-height: 2.25rem;
                &::before{
                    margin-left:-0.125rem;
                }
                
                @include mq('tablet-wide', max) {
                    line-height: 2.75rem;
                    width: 2.75rem;
                    height: 2.75rem;
                } 
            }
        }
    }

    &__session {
        &-link {
            background: rgba($white1, 0.05);
            //border-radius: 0.25rem;
            padding: 0 1rem;
            display: block;
            float: none;
            font-weight: $normal;
            cursor:pointer;
            line-height:2rem;
        }

        &-text {
            float: right;
            display: inline;
            font-size: 0.75rem;
        }

        &-info {
            font-size: 0.825rem;
        }
    }
}

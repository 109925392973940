﻿/*font icoss*/

$icosXLargeSize: 3rem;
$icosLargeSize:     2.5rem;
$icosMediumSize:    2.125rem;
$icosSmallSize:     1.75rem;
$icosXSmallSize:    1.5rem;
$icosXXSmallSize:   1.125rem;
$icosXXXSmallSize:  1rem;

.icos-stack{
    position:relative;
    .icos--large+.icos--xxsmall{
        position:absolute;
        left:1.625rem;
        top:1.25rem;
    }
}


.icos-quoted::before {
    font-family: 'Open Sans', sans-serif !important;
    content: "£";
}
.icos-email::before {
    content: "\4e";
}
.icos-email-sent::before {
    content: "\42";
}
.icos.icos-number,
.icos.icos-currency {
    font-family: $sans-serif !important;
}

/*custom svg icoss - with shades*/
[class^="icos-svg"]:before,
[class*=" icos-svg"]:before {
    display: block;
    width: 40px;
    height: 40px;
    background-size: 36px 36px;
    content: " ";
    transform: translateZ(0);
    background-repeat: no-repeat;
    background-position: center center;
}
/*
.icos-svg-diary:before {
    background-image: url(../d-svg/diary-31.svg);
}

.icos-svg-diary-av:before {
    background-image: url(../d-svg/diary-av.svg);
}

.icos-svg-users:before {
    background-image: url(../d-svg/users.svg);
}

.icos-svg-map-marker:before {
    background-image: url(../d-svg/map-marker.svg);
}*/

/*///////////////*/

.icos {
    font-size: 20px;
    line-height: 40px;
    display: inline-block;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    margin: 0 auto 0 auto;
    padding: 0;

    &--xxlarge,
    &.xxlarge,
    &-button.xxlarge,
    &-button.xxlarge > & {
        border-radius: 80rem;
        width: 5rem;
        height: 5rem;
        font-size: 3.5rem;
        line-height: 5rem;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--xxxlarge, &.xxxlarge &-button.xxxlarge, &-button.xxxlarge > & {
        border-radius: 80rem;
        width: 12rem;
        height: 8rem;
        font-size: 5rem;
        line-height: 8rem;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--xlarge,
    &.xlarge,
    &-button.xlarge,
    &-button.xlarge > icos {
        border-radius: $icosXLargeSize;
        width: $icosXLargeSize;
        height: $icosXLargeSize;
        font-size: 36px;
        line-height: $icosXLargeSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--large,
    &.large,
    &-button.large,
    &-button.large > & {
        border-radius: $icosLargeSize;
        width: $icosLargeSize;
        height: $icosLargeSize;
        font-size: 26px;
        line-height: $icosLargeSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--medium,
    &.medium,
    &-button.medium,
    &-button.medium > & {
        border-radius: $icosMediumSize;
        width: $icosMediumSize;
        height: $icosMediumSize;
        font-size: 20px;
        line-height: $icosMediumSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--small,
    &.small,
    &-button.small,
    &-button.small > & {
        border-radius: $icosSmallSize;
        width: $icosSmallSize;
        height: $icosSmallSize;
        font-size: 16px;
        line-height: $icosSmallSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--xsmall,
    &.xsmall,
    &-button.xsmall,
    &-button.xsmall > & {
        border-radius: $icosXSmallSize;
        width: $icosXSmallSize;
        height: $icosXSmallSize;
        font-size: 14px;
        line-height: $icosXSmallSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--xxsmall,
    &.xxsmall,
    &-button.xxsmall,
    &-button.xxsmall > & {
        border-radius: $icosXXSmallSize;
        width: $icosXXSmallSize;
        height: $icosXXSmallSize;
        font-size: 10px;
        line-height: $icosXXSmallSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &--xxxsmall,
    &.xxxsmall,
    &-button.xxxsmall,
    &-button.xxxsmall > & {
        border-radius: $icosXXXSmallSize;
        width: $icosXXXSmallSize;
        height: $icosXXXSmallSize;
        font-size: 10px;
        line-height: $icosXXXSmallSize;

        &::before {
            font-size: inherit;
            line-height: inherit;
        }
    }

    &__img {
        text-align: center;
        width: 100%;
        height: 100%;
        z-index: 1;
        padding: 0;
        margin: 0;
        border-radius: 40px;
        position: absolute;
        display: block;
        background-size: cover;
        background-position: center center;
    }

    &__group {
        height: auto;
        float: none;

        &.align-left {
            float: left;
            display: inline;
        }

        &.align-right {
            float: right;
            display: inline;
        }


        & > .icos,
        & > .icos-button {
            margin: 0 0.125rem 0 0;
        }
    }

    &.inline {
        display: inline-block;

        &.icos--small {
            width: 1rem;
            height: 1rem;
            line-height: normal;
        }
    }

    &.right {
        display: inline;
        float: right;
    }

    &.left {
        display: inline;
        float: left;
    }

    &.border {
        &--white {
            border: 1px solid $white1;
        }

        &--black {
            border: 1px solid $black1;
        }
    }

    &.shadow {
        box-shadow: 0 0 3px rgba(0,0,0,0.45);
        position:static;
    }
}

.icos-button {
    width: 40px;
    height: 40px;
    transition: all ease 0.4s;
    border-radius: 40px;
    shape-rendering: crispEdges;
    text-align: center;
    line-height: 40px;
    background-image: none;
    text-decoration: none;
    display: inline;
    float: left;
    position: relative;
    cursor: pointer;
    padding: 0 0;


    .icos {
        pointer-events: none;
        color: $textHoverLight;
        display: block;
        @include themeColours (color, text1, 0.8);

        body &.dark {
            color: rgba($textDark1,0.8);
        }

        body &.light {
            color: rgba($textLight1,0.8);
        }


        &-button:hover & {
            color: $white1 !important;
        }
    }



    body &.icos-button--style2 {
        background: transparent;

        &.primary:hover,
        &.primary:focus,
        &.primary:active {
            background-color: darken($primary,10%);
        }

        &.secondary:hover,
        &.secondary:focus,
        &.secondary:active {
            background-color: darken($secondary,10%);
        }

        &.tertiary:hover,
        &.tertiary:focus,
        &.tertiary:active {
            background-color: darken($tertiary,10%);
        }

        &.white:hover,
        &.white:focus,
        &.white:active {
            background-color: rgba($white1,0.3);
        }
    }

    body &.icos-button--style3 {
        background: transparent;
        border-width: 2px;
        border-style: solid;
        //line-height: 36px;
        box-sizing: border-box;
        padding: 0;

        .icos {
            //width: 100%;
            //height: 100%;
            color: inherit;

            &::before {
                //line-height: 36px;
                //width: 36px;
                padding-right: 0px;
                color: inherit;
            }
        }
    }

    &.icos-button--style3 {


        @include themeColours (color, text1, 0.8);
        @include themeColours (border-color, text1, 0.6);
    }

    body &.icos-button--style3:hover {
        color: $white1 !important;
        border-color: transparent;
    }
}
.icos-small {
    position: absolute;
    margin: -20px 0 0 -0px !important;
    width: 20px;
    height: 20px;
    line-height: 24px!important;
    font-size: 12px;
}

.icos-button.icos--push-left{
    margin-right:16px;
}
.icos-black {
    color: $black1;
}

.icos-white {
    color: $white1;
}

.icos-green {
    color: $green2;
}

.icos-orange {
    color: $orange2;
}

.icos-red {
    color: $red2;
}

.icos-button .icos-paper-plane {
    font-size: 16px;
    padding-right: 3px;
}

.icos-button {
        
    background-color: $aqua2;  



    &:hover,
    &:active,
    &:focus {
        outline: none;
        background-color: $aqua3; 
    }

    &.icos-button--red {
        background-color: $red2;

        &:hover,
        &:active,
        &:focus {
            background-color: $red3;

            .icos {
                color: $white1 !important;
            }
        }
    }

    &.icos-button--green {
        background-color: $green1;

        &:hover,
        &:active,
        &:focus {
            color: $white1 !important;
            background-color: $green3;
        }
    }

    &.icos-button--pink {
        background-color: $pink3;

        &:hover,
        &:active,
        &:focus {
            background-color: $pink4;
        }
    }

    &.icos-button--blue {
        background-color: $blue3;

        &:hover,
        &:active,
        &:focus {
            background-color: $blue4;
        }
    }

    &.icos-button--green {
        background-color: $green3;

        &:hover,
        &:active,
        &:focus {
            background-color: $green4;
        }
    }

    &.icos-button--off {
        &,
        &:hover,
        &:active,
        &:focus {
            background: $light-grey4;
            pointer-events: none;
        }
    }

    &.icos-button--primary,
    &.primary{
        background-color: $primary;
        color: $primary-text;

        &:hover,
        &:active,
        &:focus {
            background: $primary-shade;
            color: $primary-shade-text;
        }
    }

    &.icos-button--grey300 {
        background-color: $grey-300;
        color: $primary-text;

        &:hover,
        &:active,
        &:focus {
            background: $primary-shade;
            color: $primary-shade-text;
        }
    }

    body &.icos-button--form {
        display: block;
        float: none;
        //position: absolute;
        width: 3rem;
        height: 4.75rem;
        min-height: 4.75rem;
        line-height: 4.75rem;
        border-radius: 0;
        background: none;
        color: $black1;

        &:hover,
        &:active,
        &:focus {
            background: $primary;
            color: $primary-text;
        }
    }


    &--disabled,
    &.disabled{
        opacity: 0.3;
        pointer-events: none;
        cursor: default;
    }
}

.icos-button--selected,
.icos-button--style2.icos-button--selected,
.icos-menu:hover .icos-button {
    @include schemeColours(3,1,true);

    
    body &.primary {
        background-color: darken($primary,10%);
    }

    body &.secondary {
        background-color: darken($secondary,10%);
    }

    body &.tertiary {
        background-color: darken($tertiary,10%);
    }
}

.icos-menu {
    display: inline-block;
    position: relative;
    overflow: visible;

    &__list {
        position: absolute;
        width: 200px;
        display: block;
        white-space: nowrap;
        padding: 0;
        margin: 32px 0 0 -162px;
        z-index: 10;
        -webkit-box-shadow: 3px 3px 5px #666;
        box-shadow: 0 0 10px rgba(0,0,0,1);
        box-shadow: 0 2.2px 2.2px rgba(0, 0, 0, 0.039), 0 5.3px 5.3px rgba(0, 0, 0, 0.057), 0 9.9px 10px rgba(0, 0, 0, 0.07), 0 17.6px 17.9px rgba(0, 0, 0, 0.083), 0 33px 33.4px rgba(0, 0, 0, 0.101), 0 79px 80px rgba(0, 0, 0, 0.14);
        max-height: 0;
        opacity: 0;
        transition: all ease 0.4s;
        overflow: hidden;
        transform: scale3d(0.9, 0.9, 1);
        pointer-events: none;
        background-color: $white3;

        @include themeColours(background-color, body3);

        body &.dark {
            background-color: $black3;
        }

        body &.light {
            background-color: $white3;
        }

        .icos-button.small + & {
            margin: 26px 0 0 -172px;
        }

        &.medium {
            width: 250px;
            margin: 32px 0 0 -212px;

            .icos-button.small + & {
                margin: 26px 0 0 -222px;
            }
        }
    }

    &__item {
        width: 100%;
        display: block;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        border: 0;

        &.seperator {
            margin: 0.25rem 0.5rem;
            height: 0.125rem;
            width: calc(100% - 1rem);
            background: $black4;
        }
    }

    &__button {
        background: none;
        padding: 0 0.5rem 0 0.75rem;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        height: auto;
        font-weight: normal;
        line-height: 2.5rem;
        display: block;
        min-height: 2rem;
        width: auto;
        text-align: left;
        color: $textDark1;
        @include themeColours(color, text1);

        @include mq('tablet-wide', min) {
            line-height: 2.25rem;
            font-size: 0.875rem;
        }

        body .icos-menu__list.dark & {
            color: $textLight1;
        }

        body .icos-menu__list.light & {
            color: $textDark1;
        }

        &:hover {
            color: $white1;
            @include schemeColours(2);

            body &.primary {
                color: $primary-text;
                background-color: darken($primary,20%);
            }

            body &.secondary {
                color: $secondary-text;
                background-color: darken($secondary,20%);
            }

            body &.tertiary {
                color: $tertiary-text;
                background-color: darken($tertiary,20%);
            }
        }
    }
    /*
    &:hover .icos-menu__list,
    &--open .icos-menu__list {
        max-height: 500px;
    }*/
    &--open &__list,
    //mouse event
    .enable-hover &:hover &__list {
        max-height: 500px;
        opacity: 1;
        pointer-events: auto;
        transform: scale3d(1, 1, 1);
    }
}



.icos-load-c::before{

    @include schemeColours (2,1,true,false);
    border-width: 4px;
    border-radius: 24px;
    border-style: solid;
    border-right-color: $white3 !important;
    content: ' ';
    animation: spin 1.5s ease infinite;
}
.icos-loading {
    border-width: 4px;
    border-radius: 52px;
    border-style: solid;
    border-color: rgba(255,255,255,0.2);
    border-right-color: rgba(255,255,255,1);
    content: ' ';
    animation: spin 1.5s ease infinite;

    &.primary-shade{
        border-color: rgba($primary-shade,0.2);
        border-right-color: rgba($primary-shade,1);
    }

    &.secondary-shade{
        border-color: rgba($secondary-shade,0.2);
        border-right-color: rgba($secondary-shade,1);
    }

    &.tertiary-shade{
        border-color: rgba($tertiary-shade,0.2);
        border-right-color: rgba($tertiary-shade,1);
    }
}

.icos-min-max {
    opacity: 1;
    position: relative;
    transform: rotate(90deg);

    &::before {
        position: absolute;
        background-color: $white1;
        width: 16px;
        height: 4px;
        left: 50%;
        margin-left: -8px;
        top: 50%;
        margin-top: -2px;
        display: block;
        content: " ";
    }

    &::after {
        position: absolute;
        background-color: $white1;
        width: 4px;
        height: 16px;
        left: 50%;
        margin-left: -2px;
        top: 50%;
        margin-top: -8px;
        display: block;
        content: " ";
    }


    &--closed {
        &::before {
            transition: all 0.5s ease-in-out;
            transform: rotate(-90deg);
        }

        &::after {
            transition: all 0.5s ease-in-out;
            transform: rotate(-90deg);
            opacity: 1;
        }
    }

    &--open {
        opacity: 1;

        &::before {
            transition: all 0.5s ease-in-out;
            transform: rotate(90deg);
        }

        &::after {
            transition: all 0.5s ease-in-out;
            transform: rotate(90deg);
            opacity: 0;
        }
    }
}
﻿/*Scheme Bg Colours*/
.bg--color-scheme{
    @include schemeColours(2);
}
/*/////////////////*/
/*Scheme Font Colours*/

.bg--color {
    &-white {
        background: $grey-50;
    }

    &-red {
        background: $red;
        color: $white1;
    }
    // schemes
    &-blue {
        &1 {
            background-color: $blue1;
            color: $white1;
        }

        &2 {
            background-color: $blue2;
            color: $white1;
        }

        &3 {
            background-color: $blue3;
            color: $white1;
        }

        &4 {
            background-color: $blue4;
            color: $white1;
        }
    }

    &-aqua {
        &1 {
            background-color: $aqua1;
            color: $white1;
        }

        &2 {
            background-color: $aqua2;
            color: $white1;
        }

        &3 {
            background-color: $aqua3;
            color: $white1;
        }

        &4 {
            background-color: $aqua4;
            color: $white1;
        }
    }

    &-pink {
        &1 {
            background-color: $pink1;
            color: $white1;
        }

        &2 {
            background-color: $pink2;
            color: $white1;
        }

        &3 {
            background-color: $pink3;
            color: $white1;
        }

        &4 {
            background-color: $pink4;
            color: $white1;
        }
    }

    &-purple {
        &1 {
            background-color: $purple1;
            color: $white1;
        }

        &2 {
            background-color: $purple2;
            color: $white1;
        }

        &3 {
            background-color: $purple3;
            color: $white1;
        }

        &4 {
            background-color: $purple4;
            color: $white1;
        }
    }


    &-red {
        &1 {
            background-color: $red1;
            color: $white1;
        }

        &2 {
            background-color: $red2;
            color: $white1;
        }

        &3 {
            background-color: $red3;
            color: $white1;
        }

        &4 {
            background-color: $red4;
            color: $white1;
        }
    }

    &-orange {
        &1 {
            background-color: $orange1;
            color: $white1;
        }

        &2 {
            background-color: $orange2;
            color: $white1;
        }

        &3 {
            background-color: $orange3;
            color: $white1;
        }

        &4 {
            background-color: $orange4;
            color: $white1;
        }
    }

    &-green {
        &1 {
            background-color: $green1;
            color: $white1;
        }

        &2 {
            background-color: $green2;
            color: $white1;
        }

        &3 {
            background-color: $green3;
            color: $white1;
        }

        &4 {
            background-color: $green4;
            color: $white1;
        }
    }

    &-turquoise {
        &1 {
            background-color: $turquoise1;
            color: $white1;
        }

        &2 {
            background-color: $turquoise2;
            color: $white1;
        }

        &3 {
            background-color: $turquoise3;
            color: $white1;
        }

        &4 {
            background-color: $turquoise4;
            color: $white1;
        }
    }

    &-grey {
        &1 {
            background-color: $grey1;
            color: $white1;
        }

        &2 {
            background-color: $grey2;
            color: $white1;
        }

        &3 {
            background-color: $grey3;
            color: $white1;
        }

        &4 {
            background-color: $grey4;
            color: $white1;
        }

        &--200 {
            background-color: $grey-200;
            color: $black3;
        }
    }

    &-lightgrey {
        &1 {
            background-color: $grey-100;
            color: $black3;
        }

        &2 {
            background-color: $grey-200;
            color: $black3;
        }

        &3 {
            background-color: $grey-300;
            color: $black3;
        }

        &4 {
            background-color: $grey-400;
            color: $black3;
        }
    }


    &-primary {
        background-color: $primary;
        color: $primary-text;

        &-10 {
            background: lighten( $primary, 10% );
        }

        &-20 {
            background: lighten( $primary, 20% );
        }

        &-d10 {
            background: darken( $primary, 10% );
        }

        &-d20 {
            background: darken( $primary, 20% );
        }

        &-shade {
            background-color: $primary-shade;
            color: $primary-shade-text;

            &-10 {
                background: lighten( $primary-shade, 10% );
            }

            &-20 {
                background: lighten( $primary-shade, 20% );
            }

            &-d10 {
                background: darken( $primary-shade, 10% );
            }

            &-d20 {
                background: darken( $primary-shade, 20% );
            }
        }

        &-tint {
            background-color: $primary-tint;
            color: $primary-tint-text;

            &-10 {
                background: lighten( $primary-tint, 10% );
            }

            &-20 {
                background: lighten( $primary-tint, 20% );
            }

            &-d10 {
                background: darken( $primary-tint, 10% );
            }

            &-d20 {
                background: darken( $primary-tint, 20% );
            }
        }
    }

    &-secondary {
        background-color: $secondary;
        color: $secondary-text;

        &-10 {
            background: lighten( $secondary, 10% );
        }

        &-20 {
            background: lighten( $secondary, 20% );
        }

        &-d10 {
            background: darken( $secondary, 10% );
        }

        &-d20 {
            background: darken( $secondary, 20% );
        }

        &-shade {
            background-color: $secondary-shade;
            color: $secondary-shade-text;

            &-10 {
                background: lighten( $secondary-shade, 10% );
            }

            &-20 {
                background: lighten( $secondary-shade, 20% );
            }

            &-d10 {
                background: darken( $secondary-shade, 10% );
            }

            &-d20 {
                background: darken( $secondary-shade, 20% );
            }
        }

        &-tint {
            background-color: $secondary-tint;
            color: $secondary-tint-text;


            &-10 {
                background: lighten( $secondary-tint, 10% );
            }

            &-20 {
                background: lighten( $secondary-tint, 20% );
            }

            &-d10 {
                background: darken( $secondary-tint, 10% );
            }

            &-d20 {
                background: darken( $secondary-tint, 20% );
            }
        }
    }

    &-tertiary {
        background-color: $tertiary;
        color: $tertiary-text;

        &-10 {
            background: lighten( $tertiary, 10% );
        }

        &-20 {
            background: lighten( $tertiary, 20% );
        }

        &-d10 {
            background: darken( $tertiary, 10% );
        }

        &-d20 {
            background: darken( $tertiary, 20% );
        }

        &-shade {
            background-color: $tertiary-shade;
            color: $tertiary-shade-text;

            &-10 {
                background: lighten( $tertiary-shade, 10% );
            }

            &-20 {
                background: lighten( $tertiary-shade, 20% );
            }

            &-d10 {
                background: darken( $tertiary-shade, 10% );
            }

            &-d20 {
                background: darken( $tertiary-shade, 20% );
            }
        }

        &-tint {
            background-color: $tertiary-tint;
            color: $tertiary-tint-text;


            &-10 {
                background: lighten( $tertiary-tint, 10% );
            }

            &-20 {
                background: lighten( $tertiary-tint, 20% );
            }

            &-d10 {
                background: darken( $tertiary-tint, 10% );
            }

            &-d20 {
                background: darken( $tertiary-tint, 20% );
            }
        }
    }

    &-theme {
        .dark-theme & {
            background: $bgDark;
        }

        .light-theme & {
            background: $bgLight;
        }
    }

    &-shade {

        .light-theme & {
            &-10 {
                background: rgba($bgDark, 0.1 );
            }

            &-20 {
                background: rgba($bgDark, 0.2 );
            }

            &-30 {
                background: rgba($bgDark, 0.3 );
            }

            &-40 {
                background: rgba($bgDark,0.4);
            }
        }

        .dark-theme & {
            &-10 {
                background: rgba($bgLight,0.1 );
            }

            &-20 {
                background: rgba($bgLight,0.2);
            }

            &-30 {
                background: rgba($bgLight,0.3 );
            }

            &-40 {
                background: rgba($bgLight, 0.4 );
            }
        }
    }
}
.text--color {
    &-blue,
    .blueSkin & {
        &1 {
            color: $blue1;
            fill: $blue2;
        }

        &2 {
            color: $blue2;
            fill: $blue2;
        }

        &3 {
            color: $blue3;
            fill: $blue3;
        }

        &4 {
            color: $blue4;
            fill: $blue4;
        }
    }

    &-aqua,
    .aquaSkin & {
        &1 {
            color: $aqua1;
            fill: $aqua2;
        }

        &2 {
            color: $aqua2;
            fill: $aqua2;
        }

        &3 {
            color: $aqua3;
            fill: $aqua3;
        }

        &4 {
            color: $aqua4;
            fill: $aqua4;
        }
    }

    &-pink,
    .pinkSkin & {
        &1 {
            color: $pink1;
            fill: $pink1;
        }

        &2 {
            color: $pink2;
            fill: $pink2;
        }

        &3 {
            color: $pink3;
            fill: $pink3;
        }

        &4 {
            color: $pink4;
            fill: $pink4;
        }
    }

    &-purple,
    .purpleSkin & {
        &1 {
            color: $purple1;
            fill: $purple1;
        }

        &2 {
            color: $purple2;
            fill: $purple2;
        }

        &3 {
            color: $purple3;
            fill: $purple3;
        }

        &4 {
            color: $purple4;
            fill: $purple4;
        }
    }


    &-green,
    .greenSkin & {
        &1 {
            color: $green1;
            fill: $green1;
        }

        &2 {
            color: $green2;
            fill: $green2;
        }

        &3 {
            color: $green3;
            fill: $green3;
        }

        &4 {
            color: $green4;
            fill: $green4;
        }
    }

    &-turquoise,
    .turquoiseSkin & {
        &1 {
            color: $turquoise1;
            fill: $turquoise1;
        }

        &2 {
            color: $turquoise2;
            fill: $turquoise2;
        }

        &3 {
            color: $turquoise3;
            fill: $turquoise3;
        }

        &4 {
            color: $turquoise4;
            fill: $turquoise4;
        }
    }

    &-red,
    .redSkin & {
        &1 {
            color: $red1;
            fill: $red1;
        }

        &2 {
            color: $red2;
            fill: $red2;
        }

        &3 {
            color: $red3;
            fill: $red3;
        }

        &4 {
            color: $red4;
            fill: $red4;
        }
    }

    &-orange,
    .orangeSkin & {
        &1 {
            color: $orange1;
            fill: $orange1;
        }

        &2 {
            color: $orange2;
            fill: $orange2;
        }

        &3 {
            color: $orange3;
            fill: $orange3;
        }

        &4 {
            color: $orange4;
            fill: $orange4;
        }
    }

    &-grey {
        &1 {
            color: $grey1;
            fill: $grey1;
        }

        &2 {
            color: $grey2;
            fill: $grey2;
        }

        &3 {
            color: $grey3;
            fill: $grey3;
        }

        &4 {
            color: $grey4;
            fill: $grey4;
        }
    }

    &-white {
        color: $white1;
        fill: $white1;
    }

    &-black {
        color: $black1;
        fill: $black1;
    }
    &-primary {
        color: $primary;
        fill:  $primary;
    }
    &-primary-shade {
        color: $primary-shade;
        fill:  $primary-shade;
    }
    &-secondary {
        color: $secondary;
        fill:  $secondary; 
    }
    
}

.border--color {
    &-blue,
    .blueSkin & {
        &1 {
            border-color: $blue1;
        }

        &2 {
            border-color: $blue2;
        }

        &3 {
            border-color: $blue3;
        }

        &4 {
            border-color: $blue4;
        }
    }

    &-aqua,
    .aquaSkin & {
        &1 {
            border-color: $aqua1;
        }

        &2 {
            border-color: $aqua2;
        }

        &3 {
            border-color: $aqua3;
        }

        &4 {
            border-color: $aqua4;
        }
    }

    &-pink,
    .pinkSkin & {
        &1 {
            border-color: $pink1;
        }

        &2 {
            border-color: $pink2;
        }

        &3 {
            border-color: $pink3;
        }

        &4 {
            border-color: $pink4;
        }
    }

    &-purple,
    .purpleSkin & {
        &1 {
            border-color: $purple1;
        }

        &2 {
            border-color: $purple2;
        }

        &3 {
            border-color: $purple3;
        }

        &4 {
            border-color: $purple4;
        }
    }

    &-green,
    .greenSkin & {
        &1 {
            border-color: $green1;
        }

        &2 {
            border-color: $green2;
        }

        &3 {
            border-color: $green3;
        }

        &4 {
            border-color: $green4;
        }
    }

    &-turquoise,
    .turquoiseSkin & {
        &1 {
            border-color: $turquoise1;
        }

        &2 {
            border-color: $turquoise2;
        }

        &3 {
            border-color: $turquoise3;
        }

        &4 {
            border-color: $turquoise4;
        }
    }

    &-red,
    .redSkin & {
        &1 {
            border-color: $red1;
        }

        &2 {
            border-color: $red2;
        }

        &3 {
            border-color: $red3;
        }

        &4 {
            border-color: $red4;
        }
    }

    &-orange,
    .orangeSkin & {
        &1 {
            border-color: $orange1;
        }

        &2 {
            border-color: $orange2;
        }

        &3 {
            border-color: $orange3;
        }

        &4 {
            border-color: $orange4;
        }
    }

    &-grey {
        &1 {
            border-color: $grey1;
        }

        &2 {
            border-color: $grey2;
        }

        &3 {
            border-color: $grey3;
        }

        &4 {
            border-color: $grey4;
        }
    }

    body &-primary {
        border-color: $primary;
    }

    body &-secondary {
        border-color: $secondary;
    }

    body &-tertiary {
        border-color: $tertiary;
    }
}

/*grads*/
.bg--color {
    .blueSkin &-grad-scheme,
    body &-blue-grad,
    .blueSkin &-grad-scheme .popupCon,
    body &-blue-grad .popupCon {
        @include bgColorBlueGrad();
    }

    .aquaSkin &-grad-scheme,
    body &-aqua-grad,
    .aquaSkin &-grad-scheme .popupCon,
    body &-aqua-grad .popupCon {
        @include bgColorAquaGrad();
    }
}



.purpleSkin .bg--color-grad-scheme,
body .bg--color-purple-grad,
.purpleSkin .bg--color-grad-scheme .popupCon,
body .bg--color-purple-grad .popupCon {
    @include bgColorPurpleGrad();
}

.pinkSkin .bg--color-grad-scheme,
body .bg--color-pink-grad,
.pinkSkin .bg--color-grad-scheme .popupCon,
body .bg--color-pink-grad .popupCon {
    @include bgColorPinkGrad();
}

.greenSkin .bg--color-grad-scheme,
body .bg--color-green-grad,
.greenSkin .bg--color-grad-scheme .popupCon,
body .bg--color-green-grad .popupCon {
    @include bgColorGreenGrad();
}

.turquoiseSkin .bg--color-grad-scheme,
body .bg--color-turquoise-grad,
.turquoiseSkin .bg--color-grad-scheme .popupCon,
body .bg--color-turquoise-grad .popupCon {
    @include bgColorTurquioseGrad();
}

.redSkin .bg-color--grad-scheme,
body .bg--color-red-grad,
.redSkin .bg--color-grad-scheme .popupCon,
body .bg--color-red-grad .popupCon {
    @include bgColorRedGrad();
}

.orangeSkin .bg--color-grad-scheme,
body .bg--color-orange-grad,
.orangeSkin .bg--color-grad-scheme .popupCon,
body .bg--color-orange-grad .popupCon {
    @include bgColorOrangeGrad();
}

.bg--color-red-flash {
    background: linear-gradient(128deg, $red2, $orange1);
    background-size: 400% 400%;
    animation: flashBackground 2.5s ease infinite;
}

.bg--none,
.b-bg--none{
    background:none!important;
    &.segment{
        box-shadow:none!important;
    }

}
.svg-stroke {
    &--blue1 {
        stroke: $blue1;
    }

    .pinkSkin &,
    &--pink1 {
        stroke: $pink1;
    }

    .purpleSkin &,
    &--purple1 {
        stroke: $purple1;
    }

    .orangeSkin &,
    &--orange1 {
        stroke: $orange1;
    }

    .redSkin &,
    &--red1 {
        stroke: $red1;
    }

    .turquoiseSkin &,
    &--turquoise1 {
        stroke: $turquoise1;
    }
    .greenSkin &,
    &--green1 {
        stroke: $green1;
    }
}




.link{
    &-button {
        font-size: 16px;
        line-height: 32px;
        background: rgba(255,255,255,0.1);
        padding: 0 16px;
        border-radius: 24px;
        margin: 0 auto 0 auto;
        display: inline-block;
        color: #fff;
        text-align: center;
        text-decoration: none;
        transition: background ease 0.2s;
        width: auto;
    }
    &.underline{
        text-decoration: underline;
    }
    &.primary{
        color: $primary;
        transition: all 0.35s ease;
        text-decoration-color: rgba($primary,0.25);
        a:hover &,
        &:hover {
            color: darken($primary, 10%);
            text-decoration-color: rgba($primary,0.5);
        }

    }
    &.secondary{
        color: darken($secondary, 10%);
        transition: all 0.35s ease;
        text-decoration-color: rgba($secondary,0.25);
        a:hover &,
        &:hover {
            color: darken($secondary, 20%);
            text-decoration-color: rgba($secondary,0.5);
        }
        
    }
    &--disabled{
        pointer-events:none;
        cursor:default;
    }
    &--style1 {
        @include schemeColours(2,1,true);
    
        a:hover &,
        &:hover {
            @include schemeColours(3,1,true);
        }
    }
    &--style2 {
        @include schemeColours(4,1,true);
    
        a:hover &,
        &:hover {
            @include schemeColours(3,1,true);
        }
    }
    &--style3 {
        background: transparent;
    
        a:hover &,
        &:hover {
            @include schemeColours(2,1,true);
        }
    }
    &--style4 {
        @include bgAlpha ($white1, 0.1);
    
        a:hover &,
        &:hover {
            @include schemeColours(2,1,true);
        }
    }
    &--blue {
        background: $blue1;
    
        a:hover &,
        &:hover {
            background: $blue2;
        }
    }
    &--red {
        background: $red1;
    
        a:hover &,
        &:hover {
            background: $red2;
        }
    }
    
}

.project{
    display:flex;
    flex-direction: row;
    margin: 1rem;
    background: $white1;
    box-shadow: 0 2.8px 2.2px rgba(112,144,176, 0.02), 0 6.7px 5.3px rgba(112,144,176, 0.028), 0 12.5px 10px rgba(112,144,176, 0.035), 0 22.3px 17.9px rgba(112,144,176, 0.042), 0 41.8px 33.4px rgba(112,144,176, 0.05), 0 100px 80px rgba(112,144,176, 0.07);
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    
    
    &__side-bar{
        flex: 0 0 2.125rem;
        //background:darken($secondary, 10%);
        display: flex;
        flex-direction: column;
        padding-top: 0.25rem;
        .icon{
            color: $white1!important;
            margin: 0.5rem auto;
        }
        
        .icon-button{
            border-radius: 0.5rem;
            &:hover{
                background: rgba($white1, 0.3) !important;
            }
            .icon{
                color: $white1!important;
            }
            &.bottom{
                position: absolute;
                bottom:0rem;
                left:0;
            }
        }
    }
    &__contents{
        flex: 1 1 auto;
        min-height: 280px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        .listWrapper &{
            min-height: 180px;
        }
    }
    &__header {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        &.center{
            justify-content: center;
            align-items: center;
        }

        &-link{
            flex: 1 1 auto;
            color: $black1;
            text-decoration: none;
            display: flex;
            flex-direction: row; 
            transition: background ease 0.35s;
            .listWrapper &{
                pointer-events: none;
            }
            &:hover {
                background: rgba($primary, 0.15);
            }
            .icon{
                flex: 0 0 2.5rem;
                margin: auto 0 auto 0.75rem;
                .listWrapper &{
                    display: none;
                }

            }    
        }
        .h3 {
            padding: 1rem 1.5rem 0.75rem 1rem;
            display: block;
            flex: 1 1 auto;
            
            .padding--x2 &{
                padding: 2rem 2.5rem 1.5rem 2rem;
            }
            a .icon{
                text-decoration: none;
            }
        }

        &-people-link{
            flex: 0 0 5.75rem;  
            display:block;
            height: 100%;
            margin: auto 0.5rem auto 0;
            text-decoration:none;
            .e-avatar-stack{
                margin-top:0.75rem;
                font-size:0.875rem;
            }
        }
    }
    &__activity-feed{
        border-top: 1px solid $grey-200;

    }
    &__des{
        //border-top: 1px solid rgba($black1, 0.1);
        //border-bottom: 1px solid rgba($black1, 0.1);
        padding: 1rem 1rem 1rem 1rem;
        line-height: 1.2rem;
        font-size:0.75rem;
        flex: 0 1 44px;
        .padding--x2 &{
            padding: 0rem 2rem 2rem 2rem;
        }

    }
    &__footer {
        flex: 0 0 56px;
        padding:0.5rem 0.75rem 0.75rem 0.5rem;
        //align-items:flex-end;
    }
    //simple list
    &--simple{
        .listWrapper &{
            margin: -0.5rem -1rem -1rem -1rem; 
            box-shadow:none;
            background: none;
            border-radius: 0;
        
        }
    }
}
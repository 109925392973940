.reg{
    &__wrapper{
        background: $grey-200;
        display: block;
        width:100vw;
        height:100vh;
        position: absolute;
        overflow: auto;
        padding-bottom:4rem;
        @include mq('tablet', max) {
            .ui.icon.header .circular.icon {
                font-size: 2.5rem;
                margin-top:-1rem;
            }
        }
        .ui.segment{
            border-radius:1rem;
        }
        

        .btn{
            &__wrapper{
                button{
                    @include mq('phablet', max) {
                        display:block;
                        float:none;
                        margin:0.5rem 0;
                        width: 100%;
                    }
                }
            }
            &__group{
                @include mq('phablet', max) {
                    display:block;
                    float:none;
                    &.left{
                        margin-top:1rem;
                    }
                }
            }
        }
    }
}
﻿


$linkedin-color: #0274b3;
$linkedin-color-hover: darken($linkedin-color, 10%);

.linkedin {
    &--bg-color {
        background-color: $linkedin-color;
    }

    &--text-color {
        background-color: $linkedin-color;
    }

    &--button {
        background-color: $linkedin-color;

        &:hover {
            background-color: $linkedin-color-hover;
        }
    }
}
.vendor {
    display: block;
    text-align: center;
    padding-bottom: 2rem;

    &__logo {
        display: block;
        width: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        text-align: center;
        margin: 0 auto;

        &--large,
        &.large{
            height: 80px;
            background-size: 50%;

            &.camera__logo:before,
            &.phone__logo:before {
                font-size: 2rem;
                height: 4rem;
                width: 4rem;
                line-height: 4rem;
                border-radius: 4rem;
            }
        }

        &.camera__logo:before,
        &.phone__logo:before {
            border: 2px solid $white1;
            border-radius: 2rem;
            min-width: 2rem;
            display: inline-block;
            text-align: center;
            font-family: $icon-font;
            color: $white1;
        }

        &.camera__logo:before {
            content: "\f03d";
        }

        &.phone__logo:before {
            content: "\52";
        }
    }
}




.b-map{
    &{
        position:absolute;
        background: $black4;
        height: 100%;
        max-height: 100vh;
        width:100%;
        right:0;
        left:auto;
        bottom:0;
        top:0;
        display:block;
        clip-path: circle(96px at 98% 3%);
        overflow:hidden;
        animation: mapZoomIn 0.35s ease 0.55s 1 alternate backwards;
        transition: all 0.75s ease;
        @media only screen and (max-width: 1500px) {
            opacity: 0;
        }
        
        &.open{ 
            width:100%;
            margin:0;
            clip-path: circle(150% at 98% 3%);
            opacity: 1;
            @include mq('tablet-wide', max) {
                clip-path: circle(96px at 98% 3%);
                opacity: 0;
            
            }
        }
    }
    &__image{
        width:100%;
        height:100%;
        background: $brand-neutral;
        display:block;
        position: absolute;
        left:0;
        top:0; 
        bottom:0;
        right:0;
        .leaflet-container{
            width: 100%;
            height:100%;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
    }
    &__switch{
        position:absolute;
        right:-3.5rem;
        top:-4rem;
        bottom:0;
        display:block; 
        width:12rem;
        height: 12rem;
        z-index: 15; 
        padding: 0;
        .ui.button.icon{
            height: 12rem;
            width:12rem;
            border-radius: 12rem;
            max-height: unset;
            padding:0;
            position: relative;
            background:rgba($secondary,0.5);
            transition: 
                background-color 0.35s ease, 
                transform 0.55s ease, 
                opacity 0.35s ease;
            pointer-events: unset;
            &:hover{
                background:rgba($secondary,0.65);
            }
            
            & > i.icon{
                font-size: 2.25rem;
                margin: 0.5rem 0 0 -2.25rem!important;
                position: absolute;
                transition: color 0.45s ease;
                opacity: 1;
                color: $primary-shade;
            }
        }
        
        .b-map.open &{
            .ui.button.icon{
                pointer-events: none;
                background:rgba($primary-shade,0);
                transform: scale(0%);
            }

        }

        &--list{
            pointer-events: none;
            transform: scale(150%);
            transition: transform 0.55s ease;
            .b-map.open &{
                pointer-events: unset;
                transform: scale(100%);
            }
            .ui.button.icon{
                background:rgba($primary-shade,0.30);
                opacity: 0;
                pointer-events: none;
                .b-map.open &{
                    background:rgba($secondary,0.5);
                    transform: scale(100%);
                    pointer-events: unset;
                    opacity: 1;
                    &:hover{
                        background:rgba($secondary,0.65);
                    }
                }
            }
        }
    }
}